import axios from 'axios';
import * as config from '../Helpers/Config';
// import * as api                                             from  '../Helpers/api';

const client = axios.create({
    baseURL: config.API_URL_CMS,
    timeout: config.TIMEOUT
});



const request = (options) => {
    client.interceptors.response.use(response => response, error => {
        return Promise.reject(error);
    });

    const onSuccess = (response) => {
        return response;
    }

    const onError = (error) => {
        return Promise.reject(error.response || error.message);
    }
    options = Object.assign(options, {
        headers: {
            'Accept': 'application/json'
        }
    });

    return client(options).then(onSuccess).catch(onError);
}

export const lookCv = (idEmployee) =>
    request({ method: 'post', url: `cv-employee/download/` + idEmployee});

export const downloadDeliveryOrder = (idCandidate) =>
    request({ method: 'post', url: `employee/delivery-order/` + idCandidate});

export const downloadInvoice = (idTransaction) =>
    request({ method: 'post', url: `client-company/invoice/` + idTransaction});

export const confirmationEmailToForgetPassword = (options) =>
    request({ method: 'post', url: `confrimation-email`, ...options});

export const confirmationEmailToForgetPasswordClient = (options) =>
    request({ method: 'post', url: `confrimation-email-client`, ...options});