import firebase from 'firebase/app';
import 'firebase/auth';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBZz4y6vLxgqDbjco_nRasiagJl2lPByLc",
    authDomain: "avisha-web.firebaseapp.com",
    databaseURL: "https://avisha-web.firebaseio.com",
    projectId: "avisha-web",
    storageBucket: "avisha-web.appspot.com",
    messagingSenderId: "952069882435",
    appId: "1:952069882435:web:47574fb5d371432c71caf6",
    measurementId: "G-Y37B2JBTE8"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebaseConfig;