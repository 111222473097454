// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import { Container }                                        from 'reactstrap';   

class TermConPlain extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    
    render() {                                     
        return (          
            <Fragment>  
                <Container>
                <p>Selamat datang di platform AVISHA</p>

                <h5>KETENTUAN LAYANAN PELANGGAN AVISHA</h5>       
                <p className="text-justify text-16">
                    Perjanjian Ketentuan Layanan Pelanggan ini ("Perjanjian") merupakan perjanjian hukum antara
                    Anda, "Pelanggan", (sebagai perwakilan resmi dari entitas akademik, bisnis atau pemerintah),
                    dan Kami, PT. Avisha Citra Mandiri, sebuah perusahaan Indonesia yang alamat utamanya adalah
                    di Soho Pancoran, JL MT Haryono kav 2-3, Noble tower 10 th fL suite 05, Jakarta Selatan. 
                    <br/>
                    <br/>
                    Perjanjian ini adalah kontrak yang menetapkan persyaratan hubungan antara AVISHA dan
                    Pelanggan yang terikat secara hukum. Dengan menggunakan Platform atau Aplikasi sehubungan
                    dengan Layanan, Pelanggan menyatakan bahwa (1) Pelanggan telah membaca, memahami, dan
                    setuju untuk terikat oleh Perjanjian ini, dan (2) penanda tangan memiliki wewenang (atau, jika
                    ada menjadi, telah diberi wewenang) untuk masuk ke dalam Perjanjian ini untuk dan atas nama
                    entitas yang telah ditandatangani oleh penandatangan sebagai Pelanggan, dan untuk mengikat
                    perusahaan itu dengan ketentuan-ketentuan ini.
                    <br/>
                    <br/>
                    Pelanggan setuju dan memahami bahwa fitur tertentu dari Aplikasi dapat tunduk pada syarat
                    dan ketentuan tambahan dan / atau persyaratan pendaftaran. Pelanggan setuju untuk mematuhi
                    ketentuan tambahan ini dan selanjutnya setuju bahwa pelanggaran terhadap ketentuan tersebut
                    merupakan pelanggaran terhadap Perjanjian ini. AVISHA dapat secara sepihak mengubah
                    Perjanjian ini, dan setiap ketentuan tambahan (sebagian atau keseluruhan) setiap saat, dengan
                    memberi tahu Pelanggan melalui email dalam waktu 14 hari sebelum perubahan tersebut
                    berlaku.
                    <br/>
                    <br/>
                    PELANGGAN MENGAKUI DAN MENYETUJUI BAHWA AVISHA ADALAH PENYEDIA LAYANAN
                    BERBASIS TEKNOLOGI YANG MENGHUBUNGKAN PERUSAHAAN DENGAN PEKERJA HARIAN
                    YANG DIPERLUKAN OLEH BISNIS.
                    <br/>
                    <br/>
                </p>
                {/* Definition */}
                <h5>Definisi</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    “Pekerja Harian” berarti pekerja yang masuk ke dalam Kontrak PKHL dengan Pelanggan selama
                    jangka waktu tertentu, dan yang telah bekerja sama dengan AVISHA untuk mencari pekerjaan.
                    </li>
                    <li>
                    “Kontrak PKHL” berarti Perjanjian Kerja Harian Lepas atau kontrak pekerja harian.
                    </li>
                    <li>
                    "Periode Istirahat" berarti durasi antara kontrak PKHL yang harus diikuti oleh seorang pekerja
                    yang Anda perpanjang harus mengambil cuti dan mengikuti peraturan HR di tempat Anda.
                    </li>
                    <li>
                    “Hasil Kerja" adalah hasil yang diminta di dalam Permintaan Staf untuk diselesaikan oleh Pekerja
                    Harian kepada Pelanggan.
                    </li>
                    <li>
                    "Tawaran Pekerjaan" adalah pemberitahuan yang diberikan oleh AVISHA kepada satu atau lebih
                    Pekerja Harian yang mencakup uraian tentang pekerjaan yang akan diberikan ke Pekerja Harian
                    dan hasil kerja yang terkait, tanggal di mana Permintaan Staf harus diselesaikan dan jumlah
                    pembayaran harian untuk Pekerja Harian yang melamar di Permintaan Staf tersebut.
                    </li>
                    <li>
                    "Biaya Layanan" adalah biaya tambahan untuk mengkompensasi AVISHA untuk membuat, hosting,
                    mengelola dan menyediakan Platform, jaminan sosial dan penggajian.
                    </li>
                    <li>
                    "Permintaan Staf" adalah permintaan untuk layanan atau pekerjaan yang dapat diajukan oleh
                    Pelanggan yang mencakup deskripsi pekerjaan dan pekerja ideal yang harus ditempatkan oleh
                    AVISHA. Setelah seorang calon pekerja dan Anda telah dicocokkan dan telah menyetujui
                    ketentuan-ketentuan Permintaan Staf sesuai dengan Bagian 4, Permintaan Staf akan bertindak
                    sebagai Kontrak PKHL.
                    </li>
                    <li>
                    “Menyetujui Pekerja” mengacu pada Pelanggan yang memilih dan menerima kandidat tertentu
                    untuk dipekerjakan sebagai Pekerja Harian.
                    </li>
                    <li>
                    "Timesheets" berarti laporan kehadiran yang dicatat secara digital di Platform.
                    </li>
                    <li>
                    "Platform" atau "Aplikasi" berarti perangkat lunak yang digunakan oleh AVISHA untuk
                    memberikan Layanan.
                    </li>
                    <li>"Situs web" berarti situs web AVISHA di www.jobs.avisha.co.id</li>
                    <li>
                    "Perjanjian Komersial" adalah Perjanjian Komersial yang ditandatangani oleh dan antara
                    Pelanggan dan AVISHA. Jika ada perbedaan antara ketentuan apa pun dari Perjanjian Komersial
                    dan ketentuan dari Perjanjian ini dan / atau informasi apa pun yang terkandung di Situs Web,
                    Platform dan / atau Aplikasi, maka dari Perjanjian Komersial yang berlaku.
                    </li>
                    <li>"Layanan" memiliki arti yang diberikan padanya dalam Klausa 2.b Perjanjian ini.</li>
                    <li>"IP AVISHA" memiliki arti yang diberikan padanya dalam Klausa 8.a Perjanjian ini.</li>
                </ol>
                
                {/* Services */}
                <h5>Layanan AVISHA</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>AVISHA, aplikasi internet dan seluler yang dimiliki dan dioperasikan oleh PT. Avisha Citra Mandiri.</li>
                    <li>
                    AVISHA menyediakan layanan yang memungkinkan Pelanggan untuk terhubung dengan jaringan
                    AVISHA dengan Pekerja Harian yang didistribusikan secara geografis untuk mendapatkan penyedia
                    layanan untuk berbagai pekerjaan jangka pendek atau jangka panjang dan yang menangani gaji
                    dan proses administrasi Pekerja Harian tersebut (“Layanan”).
                    </li>
                </ol>

                {/* Responsible */}
                <h5>Tanggung Jawab AVISHA</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>AVISHA menghubungkan pelanggan secara langsung dengan Pekerja Harian yang telah terkualifikasi dan bersedia untuk bekerja melalui Platform.</li>
                    <li>
                    AVISHA melakukan upaya terbaik untuk memastikan Pekerja Harian memiliki kualitas dan tingkat
                    pengalaman terbaik, melalui proses recruitmen on-boarding kami yang komprehensif termasuk
                    wawancara tatap muka.
                    </li>
                    <li>
                    Jika Pelanggan memberikan persyaratan terperinci yang berlaku untuk suatu pekerjaan dalam
                    bidang Permintaan Staf (seperti Gender), AVISHA akan mencocokkan pekerja sesuai dengan
                    persyaratan tersebut. Setiap aturan atau peraturan khusus untuk Pelanggan harus disampaikan
                    kepada Pekerja pada atau sebelum hari kerja pertama secara langsung oleh Pelanggan atau
                    perwakilan mereka.
                    </li>
                    <li>
                    AVISHA mengurus pemrosesan semua pembayaran untuk Proses Harian dan proses administrasi
                    (untuk dan atas nama Pelanggan). Dengan menandatangani Perjanjian ini, Pelanggan dianggap
                    telah memberi wewenang kepada AVISHA untuk bertindak untuk dan atas nama Pelanggan secara
                    khusus untuk mengurus pembayaran dan proses administrasi tersebut serta melakukan tindakan
                    lain yang diuraikan di sini yang dilakukan oleh AVISHA untuk dan atas nama Pelanggan.
                    </li>
                    <li>
                    AVISHA akan berusaha sebaik mungkin untuk menemukan Pekerja Harian pengganti dalam kasus
                    ketidakhadiran selama periode Permintaan Staf.
                    </li>
                    <li>
                    AVISHA akan mengatur semua komunikasi dengan Pekerja Harian terkait pembayaran gaji. Gaji
                    didasarkan pada Timesheets yang telah ditinjau dan disetujui oleh Pelanggan. Tidak ada
                    pembayaran gaji yang akan dilakukan tanpa Pelanggan menyetujui Timesheets ini setidaknya 5
                    hari sebelum gaji dirilis. Untuk menghindari keraguan, dan kecuali secara eksplisit dinyatakan lain
                    dalam Perjanjian ini, istilah "hari" berarti "hari kalender".
                    </li>
                    <li>
                    AVISHA akan menangani pemutusan hubungan kerja dan penggantian Pekerja Harian, bila
                    diperlukan, untuk dan atas nama Pelanggan.
                    </li>
                </ol>
                
                {/* Staff Req */}
                <h5>Permintaan Staf</h5>
                <p className="text-justify text-16">
                Pelanggan dapat mengajukan Permintaan Staf kepada AVISHA melalui Aplikasi atau
                Situs Web(www.jobs.avisha.co.id) dari waktu ke waktu. AVISHA sepenuhnya berhak
                menerima atau menolak setiap Permintaan Staf tanpa harus memberikan alasan
                penerimaan atau penolakan kepada Pelanggan. Atau, kami dapat menghubungi
                Pelanggan untuk mendapatkan informasi lebih lanjut tentang Permintaan Staf dan
                kemudian kami dapat memberikan versi modifikasi dari Permintaan Staf. Dalam hal
                demikian, Pelanggan harus menerima Permintaan Staf yang dimodifikasi tersebut
                sebelum kami melanjutkan. Pelanggan menerima rincian seperti gaji dan jam kerja
                yang akurat. Dengan mengajukan Permintaan Staf, Anda setuju bahwa dengan
                mempekerjakan Pekerja Harian, Anda membuat Kontrak PKHL yang mengikat dan
                Anda akan diharuskan membayar Biaya Layanan. Anda memahami bahwa dengan
                membuat Kontrak PKHL yang mengikat dengan Pekerja Harian, Anda akan menjadi
                "Pemberi kerja" untuk Pekerja Harian, yang akan menjadi "karyawan" Anda
                (khususnya, "karyawan lepas harian").
                </p>

                {/* Fee & Payment */}
                <h5>Biaya dan Pembayaran</h5>
                <p className="text-justify">
                    Pelanggan setuju untuk membayar kepada AVISHA semua biaya dan ongkos sesuai
                    dengan paket yang dipilih di Situs Web, atau sesuai dengan Perjanjian Komersial.
                </p>

                {/* Responsible Customer */}
                <h5>Kewajiban Pelanggan</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    Berperilaku Baik: Pelanggan berkomitmen untuk menggunakan Platform dengan cara yang sah
                    dan tidak melakukan perilaku apa pun yang mungkin dianggap sebagai pelecehan, ilegal, dan /
                    atau merusak reputasi AVISHA.
                    </li>
                    <li>
                    Menyetujui Pekerja: Pelanggan menyetujui setelah Permintaan Staf dibuat. Pengaplikasian apa
                    pun lebih lanjut dibuat atas dasar siapa-datang-pertama-dilayani, khususnya dalam hal
                    ketersediaan Pekerja Harian. Jika Pelanggan gagal menyetujui pelamar tepat waktu, Pelanggan
                    memahami bahwa ketersediaan Pekerja Harian dapat berubah.
                    </li>
                    <li>
                    Persetujuan Timesheet: Adalah tanggung jawab Pelanggan untuk menyetujui absen pekerja tepat
                    waktu. Pembayaran pekerja hanya dapat dilakukan 3 hari setelah absen disetujui. Pekerja Harian
                    dapat menindaklanjuti secara langsung dengan Pelanggan jika pembayaran ditunda karena
                    timesheets tidak disetujui tepat waktu dan Pelanggan memahami bahwa kinerja Pekerja dapat
                    memburuk jika terjadi keterlambatan pembayaran.
                    </li>
                    <li>
                    Lingkungan kerja yang aman: Pelanggan setuju untuk menyediakan Pekerja Harian dengan
                    lingkungan kerja yang aman dan kebutuhan seperti air dan fasilitas seperti kamar mandi.
                    </li>
                    <li>
                    Gaji Harian: Merupakan kebijaksanaan penuh Pelanggan untuk menetapkan gaji harian sesuai
                    dengan peraturan internal dan lokal Anda. AVISHA tidak menetapkan nilai untuk Pekerja Harian
                    dan merupakan kebijaksanaan penuh Pekerja Harian untuk menerima atau menolak Permintaan
                    Staf berdasarkan persyaratan gaji pribadi mereka.
                    </li>
                    <li>
                    Privasi: Pelanggan berkomitmen untuk melindungi privasi setiap Pekerja Harian, termasuk tetapi
                    tidak terbatas dengan cara apa pun, tanpa mengungkapkan informasi rahasia apa pun dari Pekerja
                    Harian. Namun selain dari hal-hal tersebut di atas, Pelanggan dapat mengungkapkan informasi
                    rahasia dari Pekerja Harian apapun kepada lembaga pemerintah Republik Indonesia yang
                    berwenang dengan ketentuan bahwa setiap perintah tertulis dan / atau permintaan untuk
                    pengungkapan tersebut telah disediakan oleh Pelanggan kepada kami dalam waktu 7 hari
                    sebelum pengungkapan harus dilakukan; kami berhak atas kebijaksanaan penuh kami untuk
                    menolak atau mempertanyakan setiap perintah dan / atau permintaan yang telah diberikan
                    kepada kami jika perintah dan / atau permintaan tersebut dapat dibuktikan tidak sesuai dengan
                    ketentuan ini. Untuk menghindari keraguan, “informasi rahasia” dalam ketentuan ini berarti
                    setiap informasi yang dapat dianggap sebagai rahasia mengingat sifatnya, termasuk tetapi tidak
                    terbatas pada nama lengkap, tanggal lahir, jenis kelamin, usia, status perkawinan, alamat tempat
                    tinggal (terdaftar) atau sebaliknya), nomor telepon (ponsel atau telepon rumah) dan alamat email
                    dari setiap Pekerja Harian.
                    </li>
                    <li>
                    Penyimpanan data: Pelanggan bertanggung jawab untuk menyimpan semua data sehubungan
                    dengan Perjanjian ini, dengan cara yang ditentukan oleh undang-undang dan peraturan Republik
                    Indonesia.
                    </li>
                    <li>
                    Tidak Ada Virus: Pelanggan berjanji untuk tidak: menghindari, menghapus, mengubah,
                    menonaktifkan, menurunkan dan / atau menggagalkan konten apa pun dari Layanan;
                    menggunakan robot, spider, scraper, dan / atau sarana otomatis lainnya untuk mengakses
                    Layanan; men-dekompilasi, merekayasa balik dan / atau membongkar perangkat lunak apa pun
                    atau produk atau proses lain yang dapat diakses melalui Layanan; masukkan kode atau produk
                    apa pun atau memanipulasi konten Layanan dengan cara apa pun; atau menggunakan data
                    mining, pengumpulan data atau metode ekstraksi. Pelanggan juga berjanji untuk tidak
                    mengunggah, memposting, mengirim email, dan / atau mengirim atau mengirimkan materi apa
                    pun yang dirancang untuk mengganggu, menghancurkan, atau membatasi fungsionalitas
                    perangkat lunak komputer atau perangkat keras atau peralatan telekomunikasi yang terkait
                    dengan Layanan, termasuk virus perangkat lunak atau kode komputer lain, file atau program.
                    </li>
                    <li>
                    Perlindungan Kata Sandi: Anda bertanggung jawab untuk menjaga kerahasiaan akun dan kata
                    sandi Anda, dan Anda setuju untuk menerima tanggung jawab atas semua kegiatan yang terjadi di
                    bawah akun atau kata sandi Anda. Anda setuju bahwa informasi yang Anda berikan kepada kami
                    pada saat pendaftaran dan pada waktu lain akan benar, akurat, terkini, dan lengkap. Anda juga
                    setuju bahwa Anda akan memastikan bahwa informasi ini tetap akurat dan mutakhir setiap saat.
                    Jika Anda memiliki alasan untuk meyakini bahwa akun Anda tidak lagi aman (mis., Jika terjadi
                    kehilangan, pencurian, atau pengungkapan yang tidak sah atau penggunaan ID akun Anda, kata
                    sandi), maka Anda setuju untuk segera memberitahu kami. Anda mungkin bertanggung jawab atas
                    kerugian yang ditimbulkan oleh kami atau orang lain karena setiap penggunaan akun Anda secara
                    tidak sah.
                    </li>
                </ol>

                {/* Cancel Policy */}
                <h5>Kebijakan Pembatalan</h5>
                <p className="text-justify text-16">
                Jika Permintaan Staf dibatalkan karena alasan berikut, AVISHA berhak untuk
                mengenakan biaya pembatalan sebesar 50% dari nilai 3 hari pertama gaji Pekerja
                Harian untuk Permintaan Staf, atau biaya yang dirinci dalam Perjanjian Komersial:
                </p>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>Jika Permintaan Staf dibatalkan oleh Pelanggan dengan alasan apapun selama tiga kali berturut-turut.</li>
                    <li>Jika Pelanggan memberikan informasi yang tidak mencukupi dan / atau tidak benar sehingga mencegah Permintaan Staf untuk diselesaikan oleh orang yang bersangkutan; atau</li>
                    <li>Jika Anda atau penyelia / perwakilan Anda yang ditunjuk tidak hadir / tidak tersedia untuk mengarahkan Pekerja Harian pada saat Pekerja Harian seharusnya mulai bekerja.</li>
                </ol>

                {/* Ownership IP */}
                <h5>Kepemilikan IP</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    Pelanggan mengakui bahwa semua hak kekayaan intelektual dalam Platform, Layanan, Aplikasi,
                    Situs Web, dan metadata apapun atau informasi lain yang dihasilkan atau disampaikan kepada
                    AVISHA oleh Pekerja Harian dalam rangka melakukan Permintaan Staf (“IP AVISHA”) adalah
                    dimiliki oleh AVISHA atau pemberi lisensi atau pemasok AVISHA. Pelanggan tidak akan
                    memperoleh, dengan Perjanjian ini, hak, kepemilikan, atau kepentingan apapun dalam merek
                    dagang AVISHA atau pemberi lisensi AVISHA, afiliasi atau pemasok, atau Perjanjian ini tidak akan
                    memberikan hak kepada Pelanggan untuk menggunakan, merujuk, atau menggabungkan dalam
                    pemasaran atau materi lain yang menjadi haknya seperti nama, logo, merek dagang, atau hak
                    cipta dari AVISHA atau pemberi lisensi AVISHA, afiliasi atau pemasok. Pelanggan setuju untuk tidak
                    (a) mereproduksi, memodifikasi, mempublikasikan, mentransmisikan, mendistribusikan, secara
                    publik melakukan atau menampilkan, menjual, atau membuat karya turunan berdasarkan IP
                    AVISHA, dan / atau (b) menyewakan,meminjamkan, atau menjual akses ke IP AVISHA.
                    </li>
                    <li>
                    Pelanggan dengan ini memberikan kepada AVISHA lisensi bebas-royalti, mendunia, dapat
                    ditransfer, tidak dapat dibatalkan, lisensi untuk menggunakan, mereproduksi, memodifikasi, atau
                    menggabungkan ke dalam IP AVISHA, dan menggunakan setiap saran, permintaan peningkatan,
                    rekomendasi atau umpan balik lain yang diberikan oleh Pelanggan terkait dengan IP AVISHA.
                    </li>
                </ol>

                {/* Confidentiality */}
                <h5>Kerasahiaan</h5>
                <p className="text-justify text-16">
                Sebagaimana digunakan di sini, "Informasi Rahasia" berarti semua informasi rahasia
                yang diungkapkan oleh suatu pihak ("Pihak Pengungkap") kepada pihak lain ("Pihak
                Penerima"), baik secara lisan atau tertulis, yang ditetapkan sebagai rahasia atau yang
                secara wajar harus dipahami untuk dirahasiakan mengingat sifat informasi dan
                keadaan Pengungkap. Namun,
                </p>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    Informasi Rahasia tidak boleh mencakup informasi apa pun yang (a) diketahui atau diketahui
                    secara umum oleh publik tanpa melanggar kewajiban apapun kepada Pihak Pengungkap, (b)
                    diketahui oleh Pihak Penerima sebelum diungkapkan oleh Pihak Pengungkap tanpa pelanggaran
                    dari kewajiban apapun yang terhutang kepada Pihak Pengungkap, (c) diterima dari pihak ketiga
                    tanpa pelanggaran kewajiban apa pun yang terhutang kepada Pihak Pengungkap, atau (d)
                    dikembangkan secara independen oleh Pihak Penerima tanpa akses ke, atau penggunaan, dari
                    informasi rahasia milik Pihak Pengungkap.
                    </li>
                    <li>
                    Kecuali jika sebaliknya diizinkan secara tertulis oleh Pihak Pengungkap, Pihak Penerima harus
                    menggunakan tingkat kehati-hatian yang sama dengan yang digunakannya untuk melindungi
                    kerahasiaan informasi rahasia sejenisnya sendiri (tetapi dalam hal apa pun selain alasan yang
                    wajar) dan tidak akan mengungkapkan atau menggunakan Informasi Rahasia dari Pihak
                    Pengungkap untuk tujuan apa pun di luar ruang lingkup Perjanjian ini, dan Pihak Penerima hanya
                    akan mengungkapkan Informasi Rahasia Pihak Pengungkap kepada orang-orang dari karyawan,
                    kontraktor, dan agen Pihak Penerima yang memerlukan akses tersebut untuk melakukan
                    kewajiban atau hak pelaksanaan berdasarkan Perjanjian ini dan yang telah menandatangani
                    perjanjian kerahasiaan dengan Pihak Penerima yang berisi perlindungan tidak kalah ketat dari
                    yang ada di sini.
                    </li>
                    <li>
                    Pihak Penerima dapat mengungkapkan Informasi Rahasia dari Pihak Pengungkap sejauh
                    pengungkapan tersebut diharuskan oleh hukum, dengan Pihak Penerima memberikan Pihak
                    Pengungkap catatan tertulis dari informasi (sejauh diizinkan secara hukum) dan bantuan yang
                    wajar dalam tanggungan Pihak Pengungkap, jika Pihak Pengungkap ingin menentang
                    pengungkapan tersebut. Jika Pihak Penerima diharuskan oleh hukum untuk mengungkapkan
                    Informasi Rahasia Pihak Pengungkap sebagai bagian dari proses perdata yang mana Pihak
                    Pengungkap adalah suatu pihak, Pihak Pengungkap akan mengganti Pihak Penerima dengan biaya
                    yang wajar untuk menyusun dan menyediakan akses yang aman ke Informasi Rahasia tersebut.
                    </li>
                    <li>
                    Sekalipun demikian, Pelanggan mengakui dan menyetujui bahwa beberapa informasi yang
                    diberikannya dalam Permintaan Staf akan dikirimkan kepada Pekerja Harian yang membutuhkan
                    informasi ini untuk menanggapi permintaan untuk melakukan satu atau lebih Permintaan Staf.
                    Dengan mengajukan Permintaan Staf, Pelanggan meminta, dan secara tegas menyetujui untuk
                    memiliki rincian Permintaan Staf yang dikirim ke Pekerja Harian yang dianggap memenuhi syarat
                    oleh Pekerja untuk melakukan Permintaan Staf. Pelanggan setuju bahwa semua informasi yang
                    diberikan Pelanggan akan akurat, terkini, dan jujur ​​sejauh pengetahuannya.
                    </li>
                </ol>

                {/* Denial */}
                <h5>Penyangkalan</h5>
                <p className="text-justify text-16">
                LAYANAN DAN HASIL KERJA DIBERIKAN SEBAGAIMANA ADANYA DAN SESUAI
                KETERSEDIAAN. AVISHA SECARA TEGAS MENYANGKAL SETIAP JAMINAN DAN
                KETENTUAN APA PUN, BAIK TERSURAT MAUPUN TERSIRAT, TERMASUK JAMINAN
                ATAU KETENTUAN DAGANG, KESESUAIAN UNTUK TUJUAN TERTENTU, JUDUL,
                KETENTUAN PENGGUNAAN PROPERTI, AKURASI, ATAU TANPA PELANGGARAN.
                AVISHA TIDAK MEMBERIKAN JAMINAN BAHWA (A) LAYANAN ATAU PENGIRIMAN
                AKAN MEMENUHI KEBUTUHAN PELANGGAN; (B) LAYANAN AKAN TERSEDIA JIKA
                TIDAK ADANYA GANGGUAN, TEPAT WAKTU, AMAN ATAU BEBAS KESALAHAN; ATAU
                (C) HASIL YANG DIDAPATKAN DARI PENGGUNAAN LAYANAN ATAU PENGIRIMAN
                AKAN AKURAT ATAU DAPAT DIANDALKAN. AVISHA TIDAK DAN TIDAK BISA
                MENJAMIN PENCOCOKAN ANTARA SETIAP PERMINTAAN STAF DAN PEKERJA HARIAN
                ATAU TERDAPAT PEKERJA HARIAN DI SUATU WILAYAH GEOGRAFIS YANG DIMINTA
                OLEH PELANGGAN YANG BERSEDIA UNTUK MEMENUHI PERMINTAAN STAF DI
                WAKTU DAN TEMPAT YANG DIMINTA. PELANGGAN MENGAKUI DAN MENYETUJUI
                BAHWA AVISHA TIDAK MEMILIKI TANGGUNG JAWAB ATAS TINDAKAN, ATAU BUKAN
                TINDAKAN, DARI SETIAP PEKERJA HARIAN DIMANA TIDAK ADA HUBUNGAN
                PEKERJAAN, USAHA BERSAMA, ATAU HUBUNGAN ANTARA AVISHA DAN PEKERJA
                HARIAN. AVISHA TIDAK MENJAMIN ATAU MENANGGUNG KINERJA PEKERJA HARIAN
                DARI PERJANJIAN PERMINTAAN AVISHA MAUPUN HASIL ATAU KUALITAS DARI
                PENYEDIAAN YANG DISEDIAKAN.
                </p>

                {/* Scope of Responsiblity */}
                <h5>Batasan Tanggung Jawab</h5>
                <p className="text-justify text-16">
                DALAM KONDISI APAPUN AVISHA TIDAK AKAN BERTANGGUNG JAWAB UNTUK ANDA
                ATAU PIHAK KETIGA UNTUK SETIAP KERUGIAN ATAU APAPUN YANG SECARA TIDAK
                LANGSUNG, SECARA KONSEKUENSIAL, IDEAL, INSIDENTIL, SPESIAL ATAU DAMPAK
                PUNITIF DARI PENGGUNAAN AVISHA OLEH PELANGGAN MAUPUN DARI HASIL KERJA,
                BAHKAN JIKA AVISHA SUDAH DIBERITAHU TENTANG KEMUNGKINAN KERUSAKAN
                YANG AKAN TERJADI. TANPA MENGETAHUI APAPUN DENGAN KONTRA YANG
                TERDAPAT DI SINI, KEWAJIBAN AVISHA TERHADAP PELANGGAN ATAS SETIAP
                KERUSAKAN YANG TIMBUL DARI ATAU TERKAIT DENGAN PENGGUNAAN IP AVISHA
                OLEH PELANGGAN ATAU HASIL KERJA (UNTUK SETIAP PENYEBAB APA SAJA DAN
                TERKAIT DENGAN BENTUK TINDAKAN) AKAN SEPANJANG WAKTU TERBATAS PADA
                BIAYA PELANGGAN YANG DIBAYARKAN KEPADA AVISHA.
                </p>

                {/* Relation of AVISHA, employee, and client */}
                <h5>Hubungan antara AVISHA, Pekerja Harian dan Anda</h5>
                <p className="text-justify text-16">
                Pelanggan mengakui bahwa hubungan antara AVISHA dan Pekerja Harian adalah
                hubungan pihak kontrak independen. Para Pihak secara tegas setuju bahwa tidak
                ada kontrak kerja dalam bentuk apapun - langsung, tersirat atau yang lainnya - di
                antara AVISHA dan Pekerja Harian. Para Pihak secara tegas setuju bahwa tidak ada
                kerjasama, kemitraan, pekerjaan, atau perjanjian agensi yang ada di antara mereka
                sebagai akibat dari Perjanjian ini atau penggunaan apapun dari Platform dan / atau
                Situs Web.
                </p>

                {/* Etc */}
                <h5>Lain-Lain</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    Perjanjian ini atau klaim apapun, penyebab tindakan atau dispute ("Klaim") yang timbul dari atau
                    terkait dengan Perjanjian ini akan diatur oleh hukum Republik Indonesia terlepas dari negara asal
                    Anda atau di mana Anda mengakses AVISHA, dan terlepas dari apapun konflik prinsip-prinsip
                    hukum.
                    </li>
                    <li>
                    Jika ada perselisihan yang timbul dari Perjanjian ini, masing-masing Pihak pertama-tama akan
                    menyelesaikannya dengan cara damai. Tetapi jika upaya damai tidak dapat menyelesaikan
                    perselisihan dalam waktu 30 hari setelah perselisihan pertama kali diberitahukan secara tertulis
                    oleh satu Pihak kepada pihak lainnya, Para Pihak akan menyelesaikan perselisihan tersebut di
                    Badan Arbitrase Nasional Indonesia.
                    </li>
                    <li>
                    Keterpisahan. Jika ada ketentuan dalam Perjanjian ini, dengan alasan apapun, dianggap tidak sah
                    atau tidak dapat dilaksanakan, ketentuan lain dari Perjanjian ini tidak akan rusak dan ketentuan
                    yang tidak valid atau tidak dapat diterapkan akan dianggap dimodifikasi sehingga valid dan dapat
                    diberlakukan sampai batas maksimum yang diizinkan oleh hukum.
                    </li>
                    <li>
                    Perjanjian ini dan hak-hak dan kewajiban Anda berdasarkan Perjanjian ini tidak dapat ditugaskan,
                    didelegasikan, atau ditransfer, secara keseluruhan atau sebagian, oleh operasi hukum atau
                    sebaliknya, oleh Anda tanpa persetujuan tertulis sebelumnya dari AVISHA. Setiap upaya
                    penugasan, delegasi atau transfer yang melanggar hal tersebut di atas akan batal demi hukum.
                    AVISHA dapat mengalihkan Perjanjian ini atau salah satu haknya berdasarkan Perjanjian ini
                    kepada pihak ketiga mana pun dengan atau tanpa persetujuan tertulis Anda. Masing-masing Pihak
                    mengesampingkan Pasal 613 KUHP perdata Indonesia dalam konteks Pelanggan diizinkan untuk
                    melakukan penugasan hak-haknya di bawah ini kepada pihak ketiga mana pun dengan memberi
                    tahu AVISHA.
                    </li>
                    <li>
                    AVISHA dapat memberikan pemberitahuan apa pun yang diperlukan oleh Perjanjian ini dengan
                    pemberitahuan umum tentang Aplikasi, surat elektronik ke alamat email Anda yang direkam
                    bersama AVISHA, atau dengan komunikasi tertulis yang dikirim melalui pos kelas satu atau pos
                    prabayar ke alamat Anda yang dicatat dengan AVISHA.
                    </li>
                    <li>
                    Semua keringanan harus secara tertulis dan ditandatangani oleh pihak yang akan ditagih.
                    Pengabaian atau kegagalan apa pun untuk menegakkan ketentuan apa pun dari Perjanjian ini
                    pada satu kesempatan tidak akan dianggap sebagai pengabaian dari ketentuan lain atau
                    ketentuan tersebut pada kesempatan lain.
                    </li>
                    <li>
                    Perjanjian ini adalah perjanjian final, lengkap dan eksklusif dari para pihak berkenaan dengan
                    masalah pokok perjanjian ini dan menggantikan dan menggabungkan semua komunikasi dan
                    pemahaman sebelumnya atau kontemporer antara para pihak. Kecuali sebagaimana diizinkan di
                    sini, tidak ada modifikasi atau amandemen Perjanjian ini yang akan efektif kecuali secara tertulis
                    dan ditandatangani oleh pihak yang akan ditagih. Namun, sejauh konflik atau ketidakkonsistenan
                    antara ketentuan dalam Perjanjian ini dan Permintaan Staf, ketentuan Perjanjian ini akan berlaku.
                    Terlepas dari bahasa apa pun yang bertentangan di dalamnya, tidak ada syarat atau ketentuan

                    yang dinyatakan dalam pesanan pembelian atau dokumentasi pesanan lainnya (kecuali
                    Permintaan Staf) yang akan dimasukkan ke dalam atau membentuk bagian apa pun dari Perjanjian
                    ini, dan semua syarat dan ketentuan tersebut akan batal demi hukum.
                    </li>
                    <li>
                    AVISHA berhak kapan saja memodifikasi atau menghentikan, untuk sementara atau selamanya,
                    Aplikasi atau Layanan (atau bagiannya) dengan pemberitahuan. Anda setuju bahwa AVISHA tidak
                    akan bertanggung jawab kepada Anda atau kepada pihak ketiga mana pun atas modifikasi,
                    penangguhan, atau penghentian Aplikasi atau Layanan ini.
                    </li>
                </ol>

                {/* Etc */}
                <h5>Lain-Lain</h5>
                <ol style={{ listStyleType: "number" }} className="text-justify text-16">
                    <li>
                    Pengakhiran Tanpa Penyebab. Salah satu Pihak dapat mengakhiri Perjanjian ini tanpa sebab,
                    berlaku segera setelah pemberitahuan 30 hari kepada Pihak lainnya, setiap saat kapan pun selama
                    tidak ada Permintaan Staf yang belum dibayar atau saldo yang belum dibayar yang jatuh tempo
                    sebagaimana diatur dalam persyaratan dokumen ini.
                    </li>
                    <li>
                    Setiap Pihak dengan ini mengesampingkan Pasal 1266 KUHP Perdata Indonesia tentang ketentuan
                    pengadilan diperlukan untuk melakukan pengakhiran Perjanjian ini.
                    </li>
                    <li>
                    Ketentuan yang Berlaku. Bagian 5 ("Biaya dan Pembayaran"), 8 ("Kepemilikan IP"), 9
                    ("Kerahasiaan"), 10 ("Penyangkalan"), 11 ("Batasan Tanggung Jawab"), dan 13 ("Lain-lain") akan
                    bertahan setiap pengakhiran Perjanjian ini.
                    </li>
                </ol>

                {/* Conctact Avisha */}
                <h5>Hubungi AVISHA</h5>
                <p className="text-justify text-16">
                Jika Anda ingin melaporkan tentang pelanggaran Perjanjian Pelanggan, memiliki
                pertanyaan atau membutuhkan bantuan, silahkan hubungi Bantuan Pelanggan di
                hrd.avisha@gmail.com
                </p>
                </Container> 
            </Fragment>
        );   
    }
};

export default TermConPlain;