// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
                        // Import React
// import { Link }                                             from 'react-router-dom';
// Styling
import { Container, Row, Col }                              from 'reactstrap';   
// import '../Css/Footer.css';                         


class PrivacyPolicy extends React.Component {
    render() {                                     
        return (          
            <Fragment>                        
                <Container>
                    <Row>
                        <Col>Kebijakan Privasi</Col>
                    </Row>
                </Container>
            </Fragment>
        );   
    }
};

export default PrivacyPolicy;