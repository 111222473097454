// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  

// Styling
import { Row, Col, Table,
         Card, CardHeader, CardBody }                       from 'reactstrap';   
import * as config                                          from '../../Helpers/Config';
import * as api                                             from '../../Helpers/Api';
import * as method                                          from '../../Helpers/Method';
import * as authHelperMethods                               from '../../Helpers/AuthHelperMethods';
         
class SalaryUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listPartTimeFeeTransaction:[],
            onProgressTransaction:0,
            totalIncomeAMonth:0,
            paymentSuccesfullInThisWeek:0,
            totalPartTimeThisMonth:0
        };
    }

    componentDidMount = async () =>{
        await this.getAllAssignmentPartTime();
        await this.getTrackIncomeTransaction();
    }

    getAllAssignmentPartTime = async (e) => {
        const newData = {
            key_api: config.KEY_API,
          };
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.getPartTimeFeeTransactions(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({listPartTimeFeeTransaction:data.dataPartTimeTransaction})
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    getTrackIncomeTransaction = async (e) => {
        const newData = {
            key_api: config.KEY_API,
          };
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.getTrackIncomeTransaction(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
                const {waiting_payment_this_week, total_salary_this_month, total_part_time_this_month, payment_succesfull_in_this_week}=data.dataTrackIncomePartTime
                this.setState({
                                onProgressTransaction:waiting_payment_this_week,
                                totalIncomeAMonth:total_salary_this_month,
                                paymentSuccesfullInThisWeek:payment_succesfull_in_this_week,
                                totalPartTimeThisMonth:total_part_time_this_month
                })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    render() { 
        const { 
                listPartTimeFeeTransaction, 
                totalPartTimeThisMonth, 
                paymentSuccesfullInThisWeek, 
                onProgressTransaction, 
                totalIncomeAMonth
            }= this.state                                    
        return (          
            <Fragment> 
                <Col className="pl-0 pr-0">
                    <Card>
                        <CardHeader className="font-weight-bolder">Salary Status </CardHeader>
                        <CardBody>
                            <Table hover responsive>
                                <tbody>
                                    <tr>
                                        <td className="font-weight-bold">On Process</td>
                                        <td>{onProgressTransaction === 0 ? "-":method.formatRupiah(onProgressTransaction)}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Cut off this week</td>
                                        <td>{paymentSuccesfullInThisWeek === 0 ? "-":method.formatRupiah(paymentSuccesfullInThisWeek)}</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Total Part time job of a month</td>
                                        <td>{totalPartTimeThisMonth} Jobs</td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Total Salary Part time of a month</td>
                                        <td>{method.formatRupiah(totalIncomeAMonth)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                {listPartTimeFeeTransaction.map((transaction)=> {
                    return(
                        <Col className="p-2 section-message border-bottom">
                            <Row>
                                <Col lg="4">
                                    <p className="mb-0 font-weight-bolder text-12 my-auto">
                                    {transaction.job_sub_category} in {transaction.job_category}
                                    </p>
                                    <p className="mb-0 font-weight-light text-11 text-justify my-auto">
                                    {transaction.company_name}
                                    </p>
                                    <p className="font-weight-light text-10 text-justify my-auto">
                                    {transaction.work_type}
                                    </p>
                                </Col>
                                <Col lg="3" className="my-auto"><p className="my-auto">{transaction.income_fee==null ? "Rp0":method.formatRupiah(transaction.income_fee)}</p></Col>
                                <Col lg="3" className="my-auto"><p className="my-auto">{transaction.payment_status}</p></Col>
                                <Col lg="2" className="my-auto"><p className="text-post-time my-auto">{transaction.part_time_transaction_date}</p></Col>
                            </Row>
                        </Col>
                    );
                })}
            </Fragment>
        );   
    }
};

export default SalaryUser;