// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  

// Styling
import * as config                                          from '../../Helpers/Config';
import * as api                                             from '../../Helpers/Api';      
import * as authHelperMethods                               from '../../Helpers/AuthHelperMethods';
import * as method                                          from '../../Helpers/Method';

import { Container, Row, Col, UncontrolledCollapse, 
         Label, Button }                                    from 'reactstrap';   
import noData                                               from '../../Asset/Icon/no-data.svg';
import { ToastContainer, toast }                            from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoCompany                                          from '../../Asset/Logo/logo-company.png'

class JobOffering extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            listFullTime:[],
            listPartTime:[],
            successNotif:'',
        };
    }

    componentDidMount = async () =>{
        await this.getJobOfferings();
    }

    notify(){
        const { successNotif } = this.state;
        toast( successNotif )
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    getJobOfferings = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.allJobOffering(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({
                listFullTime:data.listFullTimeJobOffering,
                listPartTime:data.listPartTimeJobOffering
            })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
      }

      updateResponseJobOffering = async (idCandidate, response) => {
        const newData = {
            key_api: config.KEY_API,
            employee_response:response,
          };
          try{
            const res = await api.updateResponseJobOffering(idCandidate,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
                this.getJobOfferings();
                if(response==="ACCEPT"){
                    await this.setState({successNotif:"Berhasil menerima tawaran pekerjaan !"});
                    this.notify();
                } else {
                    await this.setState({successNotif:"Berhasil menolak tawaran pekerjaan !"});
                    this.notify();
                }        
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    render() 
    {
        return (          
            <Fragment>
                <ToastContainer />
                <Container>
                    <Row>
                        {/* Job Offering Full Time */}
                        <Col xs="11" lg="6" className="mx-auto mb-4 mb-0-xl">
                            <p className="mb-0 text-center text-14 text-white font-weight-bolder bg-blue rounded-top pt-3 pb-3">Full Time</p>
                            {
                                this.state.listFullTime.length === 0 ?
                                    <center>
                                        <img src={noData} className="mx-auto mb-2" style={{marginBottom:'-50px', minWidth:'60px', maxWidth:'350px'}}/>
                                        <Label className="d-block text-16">Data Empty</Label>
                                    </center>
                                : 
                                this.state.listFullTime.map((fullTimeJob)=>{
                                    return(
                                        <div>
                                        {fullTimeJob.employee_response==null ?
                                            <Col id="togglerFulltime" className="p-2 section-message border-bottom">
                                                <Row>
                                                    <Col lg="1">
                                                        <img src={fullTimeJob.company_logo_image!=null ? config.URL_IMAGE+"company_logo/"+fullTimeJob.company_logo_image:logoCompany} style={{zIndex:"1"}} className="rounded-circle" alt="" width="35px" height="35px"/>
                                                    </Col>
                                                    <Col lg="7">
                                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                                        Full Time <Label className="mb-0 font-weight-lighter">for {fullTimeJob.job_sub_category} in {fullTimeJob.job_category}</Label>
                                                        </p>
                                                        {/* <p className="font-weight-light text-11 text-justify my-auto">
                                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                                        </p> */}
                                                        <UncontrolledCollapse toggler="#togglerFulltime">
                                                            <hr/>
                                                            <p className="mb-1 font-weight-bolder">{fullTimeJob.company_name}</p>
                                                            <p className="mb-1">{fullTimeJob.job_sub_category}</p>
                                                            <p className="mb-1 font-weight-lighter">{fullTimeJob.job_location==null? "-":fullTimeJob.job_location}</p>
                                                            <p className="mb-0 font-weight-lighter">{fullTimeJob.work_type}</p>
                                                            <p className="mb-1">{method.formatRupiah(fullTimeJob.min_salary)} {"-"} {method.formatRupiah(fullTimeJob.max_salary)}/month</p>
                                                            <p className="text-10 text-green">{fullTimeJob.duration_after_posting} days ago - <span className="text-black">{fullTimeJob.total_applicant==null ? 0:fullTimeJob.total_applicant} Applicants</span></p>
                                                            <Col className="p-0">
                                                                <Row>
                                                                    <Col><Button className="btn-blue btn-block" onClick={()=> this.updateResponseJobOffering(fullTimeJob.id_candidate_employee, "DECLINE")}>Decline</Button></Col>
                                                                    <Col><Button className="btn-blue btn-block" onClick={()=> this.updateResponseJobOffering(fullTimeJob.id_candidate_employee, "ACCEPT")}>Accept</Button></Col>
                                                                </Row>
                                                            </Col>
                                                            <hr/>
                                                            <Col className="p-0">
                                                                <Row>
                                                                    <Col>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Slot</p>
                                                                            <p className="text-12 muli-font">{fullTimeJob.quota} People</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Category</p>
                                                                            <p className="text-12 muli-font">{fullTimeJob.job_category}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">PIC Name</p>
                                                                            <p className="text-12 muli-font">{fullTimeJob.pic_name}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Reserve</p>
                                                                            <p className="text-12 muli-font">{fullTimeJob.total_reserve==null ? "0":fullTimeJob.total_reserve} People</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Period</p>
                                                                            <p className=" text-12 muli-font">
                                                                                {method.formatDateDay(fullTimeJob.posting_time)} - {method.formatDateDay(fullTimeJob.deadline_job_vacancy)} 
                                                                                <br/>
                                                                                08:00 - 17:00 WIB
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="mb-0 text-12 font-weight-bold muli-font">PIC Phone Number</p>
                                                                            <p className="text-12 muli-font">{fullTimeJob.pic_phone_number}</p>
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                                <div>
                                                                    <p className="mb-0 text-12 font-weight-bold muli-font">Message From PIC</p>
                                                                    <p className="text-12 muli-font">{fullTimeJob.description}</p>
                                                                </div>
                                                            </Col>
                                                            <hr/>
                                                            <Col className="p-0">
                                                                <div>
                                                                    <p className="mb-0 text-12 font-weight-bold">Job Description</p>
                                                                    <p className="text-12 muli-font text-justify job-desc">
                                                                        {fullTimeJob.job_description}</p>
                                                                </div>
                                                            </Col>
                                                        </UncontrolledCollapse>
                                                    </Col>
                                                    <Col lg="3">
                                                        <p className="mb-0 text-post-time">{method.formatDateDay(fullTimeJob.job_offering_date)}</p>
                                                        <p className="text-center font-weight-light">-</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            :
                                            <Col className="p-2 section-message border-bottom">
                                                <Row>
                                                    <Col lg="1" className="my-auto">
                                                        <img src={fullTimeJob.company_logo_image!=null ? config.URL_IMAGE+"company_logo/"+fullTimeJob.company_logo_image:logoCompany} style={{zIndex:"1"}} className="rounded-circle" alt="" width="35px" height="35px"/>
                                                    </Col>
                                                    <Col lg="7">
                                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                                        Full Time <Label className="mb-0 font-weight-lighter">for {fullTimeJob.job_sub_category} in {fullTimeJob.job_category}</Label>
                                                        </p>
                                                        <p className="font-weight-light text-11 text-justify my-auto">
                                                            Hi, Aku Hamba Saya We are from {fullTimeJob.company_name}. After we review your Resume, see more
                                                        </p>
                                                    </Col>
                                                    <Col lg="3" className="my-auto">
                                                        <p className="mb-0 text-post-time">{method.formatDateDay(fullTimeJob.job_offering_date)}</p>
                                                        <p className="text-center font-weight-light">{fullTimeJob.employee_response==1 ? "Accept":"Decline"}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                        </div>
                                    );
                                })
                            }
                        </Col>
                        {/* End Job Offering Full Time */}
                        
                        {/* Job Offering Part Time */}
                        {/* <Col xs="11" lg="6" className="box-shadow mx-auto pl-0 pr-0"> */}
                        <Col xs="11" lg="6" className="mx-auto">
                            <p className="mb-0 text-center text-14 text-white font-weight-bolder bg-blue rounded-top pt-3 pb-3">Part Time</p>
                            {
                                this.state.listPartTime.length===0 ?
                                    <center>
                                        <img src={noData} className="mx-auto mb-2" style={{marginBottom:'-50px', minWidth:'60px', maxWidth:'350px'}}/>
                                        <Label className="d-block text-16">Data Empty</Label>
                                    </center>
                                : 
                                this.state.listPartTime.map((partTimeJob)=>{
                                    let shift = []
                                    if(partTimeJob.absen !== undefined){
                                        shift =  partTimeJob.absen.split(",");
                                    }
                                    return(
                                        <div>
                                            {partTimeJob.employee_response==null ?
                                                <Col  id="togglerParttime" className="p-2 section-message border-bottom">
                                                    <Row>
                                                        <Col lg="1">
                                                            <img src={partTimeJob.company_logo_image!=null ? config.URL_IMAGE+"company_logo/"+partTimeJob.company_logo_image:logoCompany} style={{zIndex:"1"}} className="rounded-circle" alt="" width="35px" height="35px"/>
                                                        </Col>
                                                        <Col lg="7">
                                                            <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                                            <p className="mb-0 font-weight-bolder text-12 my-auto">
                                                            Part Time <Label className="mb-0 font-weight-lighter">for {partTimeJob.job_sub_category} in {partTimeJob.job_category}</Label>
                                                            </p>
                                                            {/* <p className="font-weight-light text-11 text-justify my-auto">
                                                                Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                                            </p> */}
                                                            <UncontrolledCollapse toggler="#togglerParttime">
                                                                <hr/>
                                                                <p className="mb-1 font-weight-bolder">{partTimeJob.company_name}</p>
                                                                <p className="mb-1">{partTimeJob.job_sub_category}</p>
                                                                <p className="mb-1 font-weight-lighter">{partTimeJob.company_location}</p>
                                                                <p className="mb-0 font-weight-lighter">{partTimeJob.work_type}</p>
                                                                <p className="mb-1">{method.formatRupiah(partTimeJob.part_time_salary)}/day</p>
                                                                <p className="text-10 text-green">{partTimeJob.duration_after_posting} days ago - <span className="text-black">{partTimeJob.total_applicant==null ? 0:partTimeJob.total_applicant} Applicants</span></p>
                                                                <Col className="p-0">
                                                                    <Row>
                                                                        <Col><Button className="btn-blue btn-block" onClick={()=> this.updateResponseJobOffering(partTimeJob.id_candidate_employee, "DECLINE")}>Decline</Button></Col>
                                                                        <Col><Button className="btn-blue btn-block" onClick={()=> this.updateResponseJobOffering(partTimeJob.id_candidate_employee, "ACCEPT")}>Accept</Button></Col>
                                                                    </Row>
                                                                </Col>
                                                                <hr/>
                                                                <Col className="p-0">
                                                                    <Row>
                                                                        <Col>
                                                                            <div>
                                                                                <p className="mb-0 text-12 font-weight-bold muli-font">Slot</p>
                                                                                <p className="text-12 muli-font">{partTimeJob.quota} People</p>
                                                                            </div>
                                                                            <div>
                                                                                <p className="mb-0 text-12 font-weight-bold muli-font">Category</p>
                                                                                <p className="text-12 muli-font">{partTimeJob.job_category}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col>
                                                                            <div>
                                                                                <p className="mb-0 text-12 font-weight-bold muli-font">Reserve</p>
                                                                                <p className="text-12 muli-font">{partTimeJob.total_reserve==null ? "0":partTimeJob.total_reserve} People</p>
                                                                            </div>
                                                                            <div>
                                                                                <p className="mb-0 text-12 font-weight-bold muli-font">Period Mulai & Akhir</p>
                                                                                <p className=" text-12 muli-font">
                                                                                    {partTimeJob.start_part_time_date} - {partTimeJob.finish_part_time_date}
                                                                                    <br/>
                                                                                    {/* 08:00 - 17:00 WIB */}
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p className="font-weight-bolder mb-1 mt-2">Shift Kerja Part-time</p>
                                                                                {shift.map((it)=>
                                                                                   <li key={it}>{it}</li>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <hr/>
                                                                <Col className="p-0">
                                                                    <div>
                                                                        <p className="mb-0 text-12 font-weight-bold">Job Description</p>
                                                                        <p className="text-12 muli-font text-justify job-desc">
                                                                            {partTimeJob.job_description}</p>
                                                                    </div>
                                                                    {/* <div>
                                                                        <p className="mb-0 text-12 font-weight-bold">
                                                                            What will you do:
                                                                        </p>
                                                                        <p className="mb-0 text-12 muli-font text-justify job-required">
                                                                            - Lorem ipsum dolor sit amet<br/>
                                                                            - Lorem ipsum dolor sit amet<br/>
                                                                            - Lorem ipsum dolor sit amet<br/>
                                                                            - Lorem ipsum dolor sit amet<br/>
                                                                            - Lorem ipsum dolor sit amet<br/>
                                                                        </p>
                                                                    </div> */}
                                                                </Col>
                                                            </UncontrolledCollapse>
                                                        </Col>
                                                        <Col lg="3">
                                                            <p className="mb-0 text-post-time">{partTimeJob.job_offering_date}</p>
                                                            {/* <p className="text-center font-weight-light">Accept</p> */}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                :
                                                <Col className="p-2 section-message border-bottom">
                                                    <Row>
                                                        <Col lg="1" className="my-auto">Photo</Col>
                                                        <Col lg="7">
                                                            <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                                            <p className="mb-0 font-weight-bolder text-12 my-auto">
                                                                Part Time <Label className="mb-0 font-weight-lighter">for {partTimeJob.job_sub_category} in {partTimeJob.job_category}</Label>
                                                            </p>
                                                            <p className="font-weight-light text-11 text-justify my-auto">
                                                                Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                                            </p>
                                                        </Col>
                                                        <Col lg="3" className="my-auto">
                                                            <p className="mb-0 text-post-time">{method.formatDateDay(partTimeJob.posting_time)}</p>
                                                            <p className="text-center font-weight-light">{partTimeJob.employee_response==1 ? "Accept":"Decline"}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </div>
                                    );
                                })
                            }
                            {/* <Col className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="1" className="my-auto">Photo</Col>
                                    <Col lg="7">
                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                            Part Time <Label className="mb-0 font-weight-lighter">for House Keeping Public Area</Label>
                                        </p>
                                        <p className="font-weight-light text-11 text-justify my-auto">
                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                        </p>
                                    </Col>
                                    <Col lg="3" className="my-auto">
                                        <p className="mb-0 text-post-time">12 March 2020</p>
                                        <p className="text-center font-weight-light">Decline</p>
                                    </Col>
                                </Row>
                            </Col> */}

                            {/* <Col className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="1" className="my-auto">Photo</Col>
                                    <Col lg="7">
                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                        Part Time <Label className="mb-0 font-weight-lighter">for House Keeping Public Area</Label>
                                        </p>
                                        <p className="font-weight-light text-11 text-justify my-auto">
                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                        </p>
                                    </Col>
                                    <Col lg="3" className="my-auto">
                                        <p className="mb-0 text-post-time">12 March 2020</p>
                                        <p className="text-center font-weight-light">Accept</p>    
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="1" className="my-auto">Photo</Col>
                                    <Col lg="7">
                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                        Part Time <Label className="mb-0 font-weight-lighter">for House Keeping Public Area</Label>
                                        </p>
                                        <p className="font-weight-light text-11 text-justify my-auto">
                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                        </p>
                                    </Col>
                                    <Col lg="3" className="my-auto">
                                        <p className="mb-0 text-post-time">12 March 2020</p>
                                        <p className="text-center font-weight-light">Accept</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="1" className="my-auto">Photo</Col>
                                    <Col lg="7">
                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                        Part Time <Label className="mb-0 font-weight-lighter">for House Keeping Public Area</Label>
                                        </p>
                                        <p className="font-weight-light text-11 text-justify my-auto">
                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                        </p>
                                    </Col>
                                    <Col lg="3" className="my-auto">
                                        <p className="mb-0 text-post-time">12 March 2020</p>
                                        <p className="text-center font-weight-light">Decline</p>
                                    </Col>
                                </Row>
                            </Col> */}

                            {/* <Col id="togglerParttime" className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="1">Photo</Col>
                                    <Col lg="7">
                                        <p className="mb-0 text-9 text-mute font-weight-light">Offering</p>
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                        Part Time <Label className="mb-0 font-weight-lighter">for House Keeping Public Area</Label>
                                        </p>
                                        <p className="font-weight-light text-11 text-justify my-auto">
                                            Hi, Aku Hamba Saya We are from PT. Avisha Citra Mandiri. After we review your Resume, see more
                                        </p>
                                        <UncontrolledCollapse toggler="#togglerParttime">
                                            <hr/>
                                            <p className="mb-1 font-weight-bolder">Grand Hyatt</p>
                                            <p className="mb-1">House Keeping</p>
                                            <p className="mb-1 font-weight-lighter">Jakarta</p>
                                            <p className="mb-0 font-weight-lighter">Part Time</p>
                                            <p className="mb-1">Rp.150.000 {"-"} Rp.250.000/day</p>
                                            <p className="text-10 text-green">10 days ago - <span className="text-black">10 Applicants</span></p>
                                            <Col className="p-0">
                                                <Row>
                                                    <Col><Button className="btn-blue btn-block">Decline</Button></Col>
                                                    <Col><Button className="btn-blue btn-block">Accept</Button></Col>
                                                </Row>
                                            </Col>
                                            <hr/>
                                            <Col className="p-0">
                                                <Row>
                                                    <Col>
                                                        <div>
                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Slot</p>
                                                            <p className="text-12 muli-font">10 People</p>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Category</p>
                                                            <p className="text-12 muli-font">Security</p>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Reserve</p>
                                                            <p className="text-12 muli-font">30 People</p>
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 text-12 font-weight-bold muli-font">Period</p>
                                                            <p className=" text-12 muli-font">
                                                                31 March - 03 April 2020 
                                                                <br/>
                                                                08:00 - 17:00 WIB
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <hr/>
                                            <Col className="p-0">
                                                <div>
                                                    <p className="mb-0 text-12 font-weight-bold">Job Description</p>
                                                    <p className="text-12 muli-font text-justify job-desc">
                                                        Lorem ipsum diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                                        aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                                                        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
                                                        vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum
                                                        dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui
                                                        blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
                                                        Lorem ipsum diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                                        aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                                                        ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
                                                        vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum
                                                        dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui
                                                        b</p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 text-12 font-weight-bold">
                                                        What will you do:
                                                    </p>
                                                    <p className="mb-0 text-12 muli-font text-justify job-required">
                                                        - Lorem ipsum dolor sit amet<br/>
                                                        - Lorem ipsum dolor sit amet<br/>
                                                        - Lorem ipsum dolor sit amet<br/>
                                                        - Lorem ipsum dolor sit amet<br/>
                                                        - Lorem ipsum dolor sit amet<br/>
                                                    </p>
                                                </div>
                                            </Col>
                                        </UncontrolledCollapse>
                                    </Col>
                                    <Col lg="3">
                                        <p className="mb-0 text-post-time">12 March 2020</p>
                                        <p className="text-center font-weight-light">Accept</p>
                                    </Col>
                                </Row>
                            </Col> */}

                        </Col>
                        {/* End Job Offering Part Time */}

                    </Row>
                </Container>  
            </Fragment>
        );   
    }
};

export default JobOffering;