// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';


// import { Link }                                             from 'react-router-dom';
import bgCompany                                            from '../Asset/Img/banner.svg'
import logoCompany                                          from '../Asset/Logo/logo-company.png'

// Styling
import { Container, Row, Col, Button, Form, 
         FormGroup, Label, Input, InputGroupText,
         InputGroupAddon,  Modal, ModalBody,
         InputGroup}                                        from 'reactstrap';   

import '../Css/Main.css';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import Select                                               from 'react-select';

class ProfileCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:"",
            companyName:"",
            emailCompany:"",
            telpCompany:"",
            companyCountry:"",
            companyTaxIdNumber:null,
            companyAddress:"",
            postalCode:"",
            companyLogoImage:"",
            companyLogoImageFile:null,
            bannerImage:"",
            bannerImageFile:null,
            companyDescription:"",
            companySize:"",
            companyWebsite:"",
            password:"",
            dataProvincies:[],
            dataRegencies:[],
            dataSubdistricts:[],
            dataVillages:[],
            idVillage:0,
            idProvince:0, 
            idRegency:0,
            idSubistrict:0,
            idIndustryType:0,
            industryTypeName:"",
            provincyName:"",
            regencyName:"",
            subDistrictName:"",
            villageName:"",
            dataIndustryTypes:[],
            dataCompanySize:[
                                {
                                    value:"1-50 Pegawai",
                                    label:"1-50 Pegawai"
                                },
                                {
                                    value:"51-200 Pegawai",
                                    label:"51-200 Pegawai"
                                },
                                {
                                    value:"201-500 Pegawai",
                                    label:"201-500 Pegawai"
                                },
                                {
                                    value:"501-1000 Pegawai",
                                    label:"501-1000 Pegawai"
                                },
                                {
                                    value:"1001-2000 Pegawai",
                                    label:"1001-2000 Pegawai"
                                },
                                {
                                    value:"2001-5000 Pegawai",
                                    label:"2001-5000 Pegawai"
                                }
            ],
            modalSaveCompanyProfile: false,
            modalPasswordCompanyProfile: false
        };
        this.toggleModalSaveCompanyProfile = this.toggleModalSaveCompanyProfile.bind(this);
        this.toggleModalPinCompanyProfile = this.toggleModalPinCompanyProfile.bind(this);
    }

    componentDidMount= async () => {
        await this.getId();
        await this.getDataClient();
        await this.getProvincies();
        await this.getIndustryTypes();
    }

    handleChangePhotoProfile=(event) =>{
        this.setState({
                        companyLogoImageFile:event.target.files[0],
                        companyLogoImage:URL.createObjectURL(event.target.files[0])
        })
    }

    handleChangePhotoBannerImage=(event) =>{
        this.setState({
                        bannerImageFile:event.target.files[0],
                        bannerImage:URL.createObjectURL(event.target.files[0])
        })
    }

    getIndustryTypes = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getIndustryTypes({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataIndustryTypes: data.map((k) => ({ value: k.id, label: k.industry_type })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getProvincies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getProvincies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataProvincies: data.map((k) => ({ value: k.id, label: k.province_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChoseeVillage = async (element) => {
        this.setState({idVillage:element.value});
    }

    handleChoseeCompanySize = async (element) => {
        this.setState({companySize:element.value});
    }
    
    handleChoseeProvincy = async (element) => {
        this.setState({idProvince:element.value});
        this.getRegency(element.value);
    }

    getRegency = async (id)=>{
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getRegencies(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({ dataRegencies: data.map((k) => ({ value: k.id, label: k.regency_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    
    handleChoseeRegency = async (element) => {
        this.setState({idRegency:element.value});
        this.getSubdistrict(element.value)
    }

    getSubdistrict = async (id) => {
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getSubdistrict(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataSubdistricts: data.map((k) => ({ value: k.id, label: k.sub_district_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChoseeSubdistrict = async (element) => {
        const id = element.value;
        this.setState({idSubistrict:element.value});
        this.getVillages(element.value);
    }

    getVillages = async(id)=>{
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getVillages(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataVillages: data.map((k) => ({ value: k.id, label: k.village_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { id_province, id_regency, id_sub_district, id_village, id_industry_type, village_name, sub_district_name, regency_name, province_name, company_name, company_telephone_number, company_email, company_address, postal_code, company_country, 
                company_tax_id_number, company_logo_image, banner_image, company_website, company_size, company_description } = res.data.data;
            if((id_village!=0) || (id_village!=null)){
                await this.getVillages(id_sub_district);
                await this.getSubdistrict(id_regency);
                await this.getRegency(id_province);
            }
            await this.setState({
                idIndustryType:id_industry_type,
                idVillage:id_village,
                idProvince:id_province, 
                idRegency:id_regency,
                idSubistrict:id_sub_district,
                provincyName:province_name,
                regencyName:regency_name,
                subDistrictName:sub_district_name,
                villageName:village_name,    
                companyName:company_name,
                emailCompany:company_email,
                telpCompany:company_telephone_number,
                companyCountry:company_country,
                companyAddress:company_address,
                postalCode:postal_code,
                companyLogoImage:company_logo_image!=null ? config.URL_IMAGE+"company_logo/"+company_logo_image:logoCompany,
                bannerImage:banner_image!=null ? config.URL_IMAGE+"company_logo/"+banner_image:bgCompany,
                companyDescription:company_description,
                companySize:company_size,
                companyWebsite:company_website,
                companyTaxIdNumber:company_tax_id_number
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    
    toggleModalSaveCompanyProfile() {
        this.setState(prevState => ({
          modalSaveCompanyProfile: !prevState.modalSaveCompanyProfile
        }));
    }

    toggleModalPinCompanyProfile() {
        this.setState(prevState => ({
          modalPinCompanyProfile: !prevState.modalPinCompanyProfile
        }));
    }
    
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChosee = async (element) => {
        this.setState({idIndustryType:element.value});
    }

    handleSave = async () => {
        const {                 
                id,
                companyName,
                emailCompany,
                telpCompany,
                companyCountry,
                companyAddress,
                postalCode,
                bannerImageFile,
                companyLogoImageFile,
                companyDescription,
                companySize,
                companyWebsite,
                password,
                idVillage,
                idIndustryType,
                companyTaxIdNumber
            } = this.state;
            console.log(this.state)
        let newData = new FormData()
        newData.append('_methode', 'PUT')  
        newData.append('key_api', config.KEY_API)
        newData.append('id_client_company', id)
        newData.append('company_name', companyName)
        newData.append('company_telephone_number', telpCompany)
        newData.append('company_email',emailCompany)
        newData.append('company_address',companyAddress)
        newData.append('postal_code',postalCode)
        newData.append('company_country',companyCountry)
        newData.append('company_website',companyWebsite)
        newData.append('id_village',idVillage)
        newData.append('company_size', companySize)
        newData.append('id_industry_type', idIndustryType)
        newData.append('company_description', companyDescription)
        newData.append('password', password)
        newData.append('company_tax_id_number', companyTaxIdNumber==""?null:companyTaxIdNumber)
        newData.append('company_logo_image', companyLogoImageFile)
        newData.append('banner_image', bannerImageFile)
        try{
            const res = await api.updateCompanyProfile({
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                data: newData
            }, id)
            const { error } = res.data;
            if (error === false) {
                window.location.href = config.WEB_URL + `DashboardClient`;
            } else {
                alert("Password Salah");
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {         
        const {
                companyName,
                emailCompany,
                telpCompany,
                companyCountry,
                companyAddress,
                postalCode,
                companyLogoImage,
                bannerImage,
                companyDescription,
                companySize,
                companyWebsite,
                dataProvincies,
                dataRegencies,
                dataSubdistricts,
                dataVillages,
                dataIndustryTypes,
                dataCompanySize,
                industryTypeName,
                idIndustryType,
                provincyName,
                regencyName,
                subDistrictName,
                villageName,    
                idVillage,
                idProvince, 
                idRegency,
                idSubistrict,
                companyTaxIdNumber
        } = this.state;        
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Company Profile</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col className="bg-white rounded-lg pb-4 pl-0 pr-0">
                                <Form>
                                    <img src={bannerImage} name="backgroundCompany" id="backgroundCompany" className="rounded-lg mb-5 img-fluid" width="100%"/>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <center>
                                                        <img src={companyLogoImage} name="logoCompany" id="logoCompany" className="border rounded-lg p-2 mb-3" width="200" height="150"/>
                                                        <Label className="d-block font-weight-bold text-14 mb-0">{companyName}</Label>
                                                        <Label className="d-block text-success text-14">{emailCompany}</Label>
                                                    </center>
                                                    <Col lg="5" className="mx-auto">
                                                        <Button onClick={()=>document.getElementById('inputChangePhotoProfile').click()} className="btn-block btn-blue-normal">Change Photo
                                                            <Input className="d-none" id="inputChangePhotoProfile" type="file" onChange={this.handleChangePhotoProfile} />
                                                        </Button>
                                                        <Button onClick={()=>document.getElementById('inputChangeBackground').click()} className="btn-block btn-blue-normal">Change Background
                                                            <Input className="d-none" id="inputChangeBackground" type="file" onChange={this.handleChangePhotoBannerImage}/>
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Name Company</Label>
                                                    <Input type="text" onChange={this.handleChange} value={companyName} name="companyName" id="namecompany" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Company Country</Label>
                                                    <Input type="text" placeholder="Masukkan negara perusahaan" onChange={this.handleChange} value={companyCountry} name="companyCountry" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">NPWP</Label>
                                                    <Input type="number" placeholder="Masukkan NPWP perusahaan" onChange={this.handleChange} value={companyTaxIdNumber} name="companyTaxIdNumber" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Industry Type</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        value={dataIndustryTypes.filter(option => option.value === idIndustryType)}
                                                        options={dataIndustryTypes}
                                                        onChange={this.handleChosee.bind(this)}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Number of Employees</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        value={dataCompanySize.filter(option => option.value === companySize)}
                                                        options={dataCompanySize}
                                                        onChange={this.handleChoseeCompanySize.bind(this)}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Provinsi</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        placeholder="Pilih Provinsi"
                                                        value={dataProvincies.filter(option => option.value === idProvince)}
                                                        options={dataProvincies}
                                                        onChange={this.handleChoseeProvincy.bind(this)}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Kabupaten</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        placeholder="Pilih Kabupaten"
                                                        value={dataRegencies.filter(option => option.value === idRegency)}
                                                        options={dataRegencies}
                                                        onChange={this.handleChoseeRegency.bind(this)}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Kecamatan</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        placeholder="Pilih Kecamatan"
                                                        options={dataSubdistricts}
                                                        value={dataSubdistricts.filter(option => option.value === idSubistrict)}
                                                        onChange={this.handleChoseeSubdistrict.bind(this)}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Kelurahan</Label>
                                                    <Select
                                                        name="idVillage"
                                                        placeholder="Pilih Kelurahan"
                                                        value={dataVillages.filter(option => option.value === idVillage)}
                                                        onChange={this.handleChoseeVillage.bind(this)}
                                                        options={dataVillages}
                                                    />                                                                        
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Address Company</Label>
                                                    <Input type="textarea"  placeholder="Masukkan alamat" onChange={this.handleChange} value={companyAddress} name="companyAddress" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Postal Code</Label>
                                                    <Input type="number" placeholder="Masukkan kode pos" onChange={this.handleChange} value={postalCode} name="postalCode" />
                                                </FormGroup>
                                                {/* <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Establish Company</Label>
                                                    <Input type="date" name="establishCompany" id="establishcompany" className="" placeholder="datetime placeholder" onChange=""/>
                                                </FormGroup> */}
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Telp Company</Label>
                                                    <Input type="number" onChange={this.handleChange} value={telpCompany} name="telpCompany" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Email</Label>
                                                    <Input type="email" placeholder="Masukkan email perusahaan" onChange={this.handleChange} value={emailCompany} name="emailCompany" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">Web URL</Label>
                                                    <Input type="text" placeholder="Masukkan website perusahaan" onChange={this.handleChange} value={companyWebsite} name="companyWebsite" />
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label className="font-weight-bold">About Company</Label>
                                                    <Input type="textarea" placeholder="Masukkan deskripisi perusahaan" onChange={this.handleChange} value={companyDescription} name="companyDescription" />
                                                </FormGroup>
                                                <Button className="btn-block btn-blue-normal" onChange="" onClick={this.toggleModalSaveCompanyProfile}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                        </Col>
                    </Container>
                    <Container>
                        <Modal isOpen={this.state.modalSaveCompanyProfile} toggle={this.toggleModalSaveCompanyProfile} backdrop="static">
                            <ModalBody className="p-5 text-center">
                                <Label className="d-block font-weight-bold text-20">Are you sure change data company? </Label>
                                <Col className="mt-5">
                                    <Row>
                                        <Col><Button className="btn-block btn-warning rounded-lg" onClick={this.toggleModalSaveCompanyProfile}>Cancel</Button></Col>
                                        <Col><Button className="btn-block btn-blue-normal rounded-lg" onClick={this.toggleModalPinCompanyProfile}>Yes, sure</Button></Col>
                                    </Row>
                                </Col>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.modalPinCompanyProfile} toggle={this.toggleModalPinCompanyProfile} backdrop="static">
                            <ModalBody className="p-5 text-center">
                                <Label className="d-block font-weight-bold text-20">Confirmation Change Data</Label>
                                <Form className="pt-5 pb-5">
                                    <Label className="d-block text-16">Password</Label>
                                    <Input type="password" name="password" onChange={this.handleChange}/>
                                    <Col className="mt-4 pl-0 pr-0">
                                        <Button className="btn-block btn-blue-normal rounded-lg" onClick={this.handleSave}>Confirm</Button>
                                    </Col>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default ProfileCompany;