import React               from 'react';

import { Link }            from 'react-router-dom';

import LogoAvisha          from '../../Asset/Logo/avisha-logo-full.svg';

import {
  Collapse, Button,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Label
}                         from 'reactstrap';


class Header extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: false
      };
      this.toggle = this.toggle.bind(this);
  
  // Responsive navbar with toggle
      this.toggleNav = this.toggleNav.bind(this);
      this.state = {
        isOpen: false
      };
    }
  
    toggle() {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }
  
    toggleNav() {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    onClickCompany () {
      window.location.href = `/FormSignupClient/`;
    }

    onLoginUser () {
      window.location.href = `/FormLoginUser/`;
    }

    onSignupUser () {
      window.location.href = `/FormSignupUser/`;
    }

    render() {
      return (
        <div>
          <Navbar light expand="xl" className="bg-blue p-0">
              {/* <NavbarBrand href='/'><img src={logo} id="logo"/></NavbarBrand> */}
              <NavbarBrand href='/' className="bg-white p-3 shadow-sm" style={{borderRadius:'0 0 20px 0'}}><img src={LogoAvisha} alt="Logo Avisha" hspace="10" Width="130px"/></NavbarBrand>
                <NavbarToggler onClick={this.toggleNav}/>
                  <Collapse navbar isOpen={this.state.isOpen} className="p-2">
                    <Nav className="ml-auto" navbar>
                      <div>
                        <NavItem tag={Link} to="/FormSignUpClient" className="pr-lg-3 mb-2 d-none d-md-block d-lg-block d-xl-block">
                          <Button className="btn-1" style={{width:'200px'}}>Company</Button>
                        </NavItem>
                        <NavItem tag={Link} to="/FormSignUpClient" className="col-3 pr-lg-3 mb-2 d-md-none d-lg-none d-xl-none text-center">
                          <Label className="text-white my-auto">Company</Label>
                        </NavItem>
                      </div>
                      <div>
                        <NavItem tag={Link} to="/FormSignUpUser" className="pr-lg-3 mb-2 d-none d-md-block d-lg-block d-xl-block">
                          <Button className="btn-2" style={{width:'120px'}}>Sign Up</Button>
                        </NavItem>
                        <NavItem tag={Link} to="/FormSignUpUser" className="col-3 pr-lg-3 mb-2 d-md-none d-lg-none d-xl-none text-center">
                          <Label className="text-white my-auto">Sign Up</Label>
                        </NavItem>
                      </div>
                      <div>
                        <NavItem tag={Link} to="/FormLoginUser" className="pr-lg-3 mb-2 d-none d-md-block d-lg-block d-xl-block">
                          <Button className="btn-3" style={{width:'120px'}}>Sign In</Button>
                        </NavItem>
                        <NavItem tag={Link} to="/FormLoginUser" className="col-3 pr-lg-3 mb-2 d-md-none d-lg-none d-xl-none text-center">
                          <Label className="text-white my-auto">Sign In</Label>
                        </NavItem>
                      </div>
                    </Nav>
              </Collapse>
          </Navbar>
        </div>
      );
    }
  }

export default Header;