// id employee
export function fixId(id) {
    if(id){
        const str = "" + id;
        const pad = "800000000000000";
        const ans = pad.substring(0, pad.length - str.length) + str;
        return ans;
    }
}

// id job
export function fixIdJob(id) {
    if(id){
        const str = "" + id;
        const pad = "00000";
        const ans = pad.substring(0, pad.length - str.length) + str;
        return ans;
    }
}

export function capitalizeText(text) {
    if(text){
        const lowerCase = text.toLowerCase();
        const capitalize = lowerCase[0].toUpperCase()+lowerCase.slice(1).toLowerCase();
        return capitalize;
    }
}

export function formatRupiah(angka) {
    let hasil = '';
    if (angka == 0) {
        hasil += 'Rp. 0';
    } else {
        let rupiah = '';
        if(angka){
            const angkarev = angka.toString().split('').reverse().join('');
            for (let i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
            hasil += 'Rp. ' + rupiah.split('', rupiah.length - 1).reverse().join('');    
        }
    }
    return hasil;
}

export const formatRp = (angka, prefix) => {
    angka = '' + angka;
    angka = angka.split('.');
    var number_string = angka[0].replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
        let separator = (sisa)? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return `${((prefix == undefined)? rupiah : ((rupiah)? 'Rp. ' + rupiah : ''))}${(angka[1])? `,${angka[1]}` : ''}`;
};

export function formatMoney(angka) {
    let rupiah = '';
    if(angka){
        const angkarev = angka.toString().split('').reverse().join('');
        for (let i = 0; i < angkarev.length; i++)
            if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
        return rupiah.split('', rupiah.length - 1).reverse().join('');
    }
}

export function formatDate(date) { //salah format
    let newFormat = new Date(date);
    const days = 7;
    var res = newFormat.setTime(newFormat.getTime() + (days * 24 * 60 * 60 * 1000));
    newFormat = new Date(res);
    var hours = newFormat.getHours();

    // minutes part from the timestamp
    var minutes = newFormat.getMinutes();

    // seconds part from the timestamp
    // var seconds = newFormat.getSeconds();

    // will display time in 10:30:23 format
    // var formattedTime = newFormat + '-' + hours + ':' + minutes + ':' + seconds;

    const monthNames = [
        "Januari", "Februari", "Maret",
        "April", "May", "Juni", "Juli",
        "Augustus", "September", "Oktober",
        "November", "Desember"
    ];

    const day = newFormat.getDate();
    const monthIndex = newFormat.getMonth();
    const year = newFormat.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + hours + ':' + minutes;
}

export function formatDateDay(date) { //format yg benar
    let newFormat = new Date(date);
    var res = newFormat.setTime(newFormat.getTime() + (60 * 60 * 1000));
    newFormat = new Date(res);

    const monthNames = [
        "Januari", "Februari", "Maret",
        "April", "Mei", "Juni", "Juli",
        "Agustus", "September", "Oktober",
        "November", "Desember"
    ];

    const day = newFormat.getDate();
    const monthIndex = newFormat.getMonth();
    const year = newFormat.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export function formatDateTime(date) {
    let newFormat = new Date(date);
    let hours = newFormat.getHours();

    // minutes part from the timestamp
    let minutes = newFormat.getMinutes();
    if(hours.toString().length == 1) {
        hours = '0'+hours;
    }
    if(minutes.toString().length == 1) {
        minutes = '0'+minutes;
    }

    return hours + ':' + minutes;
}

export function formatDateAndTimeForPublishJob(date) {
    let newFormat = new Date(date);
    const monthNames = [
        "Januari", "Februari", "Maret",
        "April", "Mei", "Juni", "Juli",
        "Agustus", "September", "Oktober",
        "November", "Desember"
    ];


    let day = newFormat.getDate();
    let month = newFormat.getMonth();
    let year = newFormat.getFullYear();
    let hours = newFormat.getHours();

    // minutes part from the timestamp
    let minutes = newFormat.getMinutes();
    if(day.toString().length == 1) {
            day = '0'+day;
    }   
    if(hours.toString().length == 1) {
        hours = '0'+hours;
    }
    if(minutes.toString().length == 1) {
        minutes = '0'+minutes;
    }
    return day+"-"+monthNames[month]+"-"+year+ " " +hours + ':' + minutes;
}

export function formatDateAndTime(date) {
    let newFormat = new Date(date);
    const monthNames = [
        "Januari", "Februari", "Maret",
        "April", "Mei", "Juni", "Juli",
        "Agustus", "September", "Oktober",
        "November", "Desember"
    ];


    let day = newFormat.getDate();
    let month = newFormat.getMonth();
    let year = newFormat.getFullYear();
    let hours = newFormat.getHours();

    // minutes part from the timestamp
    let minutes = newFormat.getMinutes();
    if(day.toString().length == 1) {
            day = '0'+day;
    }   
    if(hours.toString().length == 1) {
        hours = '0'+hours;
    }
    if(minutes.toString().length == 1) {
        minutes = '0'+minutes;
    }
    return day+"-"+monthNames[month]+"-"+year+ " " +hours + ':' + minutes;
}

export function formatDecimal(value, exp) {
    if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export function sumOfPropertyValue(array, value) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
        sum += array[i].value;
    }

    return sum;
};

export function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

// export function loop(limit, toDo) {
//     const list=[];
//         for(var j=0; j<limit; j++){
//             list.push("star");
//         }
//     console.log("hasil",toDo)
//     // return list;
// }