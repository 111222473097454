// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import NavUser                                              from './ComponentUser/NavUser';

import { Link } from 'react-router-dom'

// Styling
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Label,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class EducationUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listEducationHistory: [],
      educationStage: '',
      institutionName: '',
      graduateYear: '',
      score: '',
      major: '',
    }
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        const {
          education_stage,
          institution_name,
          graduate_year,
          score,
          major,
        } = data.dataEducation[0]
        this.setState({
          educationStage: education_stage,
          institutionName: institution_name,
          graduateYear: graduate_year,
          score: score,
          major: major,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handleEditEducation() {
    document.getElementById('ValueLevelEducation').style.display = 'none'
    document.getElementById('LevelEducation').style.display = 'block'
    document.getElementById('ValueNameOfInstitution').style.display = 'none'
    document.getElementById('NameOfInstitution').style.display = 'block'
    document.getElementById('ValueMajorEducation').style.display = 'none'
    document.getElementById('majorEducation').style.display = 'block'
    document.getElementById('ValueDateOfGraduate').style.display = 'none'
    document.getElementById('DateOfGraduate').style.display = 'block'
    // document.getElementById('ValueCertificated').style.display='none';
    // document.getElementById('certificated').style.display='block';
    document.getElementById('ValueScoreGPA').style.display = 'none'
    document.getElementById('scoreGPA').style.display = 'block'
    document.getElementById('btnEditEducationUser').style.display = 'none'
    document.getElementById('btnCancelEditEducationUser').style.display =
      'block'
    document.getElementById('saveChangeEducationUser').style.display = 'block'
  }

  handleCancelEducation() {
    document.getElementById('ValueLevelEducation').style.display = 'block'
    document.getElementById('LevelEducation').style.display = 'none'
    document.getElementById('ValueNameOfInstitution').style.display = 'block'
    document.getElementById('NameOfInstitution').style.display = 'none'
    document.getElementById('ValueMajorEducation').style.display = 'block'
    document.getElementById('majorEducation').style.display = 'none'
    document.getElementById('ValueDateOfGraduate').style.display = 'block'
    document.getElementById('DateOfGraduate').style.display = 'none'
    // document.getElementById('ValueCertificated').style.display='block';
    // document.getElementById('certificated').style.display='none';
    document.getElementById('ValueScoreGPA').style.display = 'block'
    document.getElementById('scoreGPA').style.display = 'none'
    document.getElementById('btnEditEducationUser').style.display = 'block'
    document.getElementById('btnCancelEditEducationUser').style.display = 'none'
    document.getElementById('saveChangeEducationUser').style.display = 'none'
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  notify() {
    toast('Berhasil update data !')
  }

  saveEducation = async () => {
    const {
      educationStage,
      institutionName,
      graduateYear,
      score,
      major,
    } = this.state
    const idEmployee = authHelperMethods.getIdEmployee()
    const newData = {
      key_api: config.KEY_API,
      education_stage: educationStage,
      institution_name: institutionName,
      graduate_year: graduateYear,
      score: score,
      major: major,
    }
    try {
      const res = await api.saveEducation(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status } = res
      if (status === 200) {
        this.getDataEmployee()
        this.handleCancelEducation()
        this.notify()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  render() {
    const {
      educationStage,
      institutionName,
      graduateYear,
      score,
      major,
    } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    EDUCATION
                  </Label>
                </Col>
                <Col id="btnEditEducationUser" className="text-right text-blue">
                  <Link to="#" onClick={this.handleEditEducation}>
                    <Label className="text-16 mb-0">Edit</Label>
                  </Link>
                </Col>
                <Col
                  id="btnCancelEditEducationUser"
                  className="text-right text-blue"
                  style={{ display: 'none' }}
                >
                  <Link to="#" onClick={this.handleCancelEducation}>
                    <Label className="text-16 mb-0">Cancel</Label>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {/* {this.state.listEducationHistory.map((education)=>{ */}
              {/* return( */}
              <div>
                <Col className="border-top pt-2 pb-2">
                  <Row>
                    <Col
                      xs="6"
                      sm="5"
                      md="5"
                      lg="5"
                      className="pl-0 pr-0 my-auto"
                    >
                      <p className="font-weight-bold mb-0">Last Education </p>
                    </Col>
                    <Col className="p-0">
                      <p id="ValueLevelEducation" className="text-justify mb-0">
                        {educationStage}
                      </p>
                      <Input
                        type="text"
                        id="LevelEducation"
                        name="educationStage"
                        value={educationStage}
                        onChange={this.handleChange}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="border-top pt-2 pb-2">
                  <Row>
                    <Col
                      xs="6"
                      sm="5"
                      md="5"
                      lg="5"
                      className="pl-0 pr-0 my-auto"
                    >
                      <p className="font-weight-bold mb-0">Name of School</p>
                    </Col>
                    <Col className="p-0">
                      <p
                        id="ValueNameOfInstitution"
                        className="text-justify mb-0"
                      >
                        {institutionName}
                      </p>
                      <Input
                        type="text"
                        id="NameOfInstitution"
                        name="institutionName"
                        onChange={this.handleChange}
                        value={institutionName}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="border-top pt-2 pb-2">
                  <Row>
                    <Col
                      xs="6"
                      sm="5"
                      md="5"
                      lg="5"
                      className="pl-0 pr-0 my-auto"
                    >
                      <p className="font-weight-bold mb-0">Major</p>
                    </Col>
                    <Col className="p-0">
                      <p id="ValueMajorEducation" className="text-justify mb-0">
                        {major}
                      </p>
                      <Input
                        type="text"
                        id="majorEducation"
                        name="major"
                        onChange={this.handleChange}
                        value={major}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="border-top pt-2 pb-2">
                  <Row>
                    <Col
                      xs="6"
                      sm="5"
                      md="5"
                      lg="5"
                      className="pl-0 pr-0 my-auto"
                    >
                      <p className="font-weight-bold mb-0">Graduate Year</p>
                    </Col>
                    <Col className="p-0">
                      <p id="ValueDateOfGraduate" className="text-justify mb-0">
                        {graduateYear}
                      </p>
                      <Input
                        type="number"
                        id="DateOfGraduate"
                        name="graduateYear"
                        onChange={this.handleChange}
                        value={graduateYear}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* <Col className="border-top pt-2 pb-2">
                                        <Row>
                                            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
                                                <p className="font-weight-bold mb-0">Certificated</p>
                                            </Col>
                                            <Col className="p-0">
                                                <p id="ValueCertificated" className="text-justify mb-0">{"-"}</p>
                                                <Input type="text" id="certificated" name="certificated" onChange="" style={{display:'none'}}/>
                                            </Col>
                                        </Row>
                                    </Col> */}
                <Col className="border-top pt-2 pb-2">
                  <Row>
                    <Col
                      xs="6"
                      sm="5"
                      md="5"
                      lg="5"
                      className="pl-0 pr-0 my-auto"
                    >
                      <p className="font-weight-bold mb-0">Score GPA</p>
                    </Col>
                    <Col className="p-0">
                      <p id="ValueScoreGPA" className="text-justify mb-0">
                        {score}
                      </p>
                      <Input
                        type="number"
                        id="scoreGPA"
                        name="score"
                        onChange={this.handleChange}
                        value={score}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                </Col>
              </div>
              {/* ); */}
              {/* })} */}
              <Col
                className="pl-0 pr-0"
                id="saveChangeEducationUser"
                style={{ display: 'none' }}
              >
                <hr />
                <Button
                  className="btn-block btn-blue-normal"
                  onClick={this.saveEducation}
                >
                  Save Change
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

export default EducationUser
