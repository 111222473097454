// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import Geocode from "react-geocode";
// import { Link }                                             from 'react-router-dom';
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import * as authHelperMethods from '../Helpers/AuthHelperMethods';

// import Camera                                               from 'react-html5-camera-photo';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './ImagePreview';
import logoAvisha from '../Asset/Logo/avisha-logo.svg';
// import { Map, GoogleApiWrapper, Marker  }                   from 'google-maps-react';
// import { GoogleComponent }                                  from 'react-google-location' 
// Styling
import { Container, Row, Col, Modal, ModalBody, Card, CardHeader, CardBody, Button, Label, Spinner } from 'reactstrap';

class AttendanceUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: '-',
            longitude: '-',
            address: null,
            arriveImageFile: null,
            finishImageFile: null,
            dataAbsency: {},
            isTakePhoto: false,
            modalLoading: false
        };
        this.getLocation = this.getLocation.bind(this);
        this.getCoordinates = this.getCoordinates.bind(this);
        this.reverseCoordinates = this.reverseCoordinates.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    componentDidMount = async () => {
        await this.getDataAbsency();
    }

    toggleLoading = () => {
        this.setState(prevState => ({
            modalLoading: !prevState.modalLoading
        }));
    }

    getDataAbsency() {
        const data = localStorage.getItem("dataAbsency");
        this.setState({ dataAbsency: JSON.parse(data) });
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoordinates, this.getErrorLocation);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    getCoordinates(position) {
        this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
        this.reverseCoordinates();
        // this.Geocode.fromLatLng();
    }

    getErrorLocation(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                alert("Please active/allow geolocation your device")
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.")
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.")
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.")
                break;
            default:
                alert("An unknown error occurred.")

        }
    }

    reverseCoordinates() {
        fetch("https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.latitude},${this.state.longitude}&sensor=false&key=AIzaSyDJhc-CbYr-lt-6tK3E3mAi8yIlYNSNlXo")
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => alert(error))
    }

    handleTakePhoto = async (dataURI) => {
        this.toggleLoading();
        const { absence } = this.state.dataAbsency;
        this.getLocation();
        const fileArrive = await this.setToFileImage(dataURI);
        if (absence === "ARRIVE") {
            this.toggleLoading();
            await this.setState({ arriveImageFile: fileArrive, isTakePhoto: true });
        } else {
            this.toggleLoading();
            await this.setState({ finishImageFile: fileArrive, isTakePhoto: true });
        }
    }

    savePhotoAbsency = async () => {
        const { report, dataAbsency, arriveImageFile, finishImageFile, latitude, longitude } = this.state;
        var workLocationCoordinate = {
            lat: latitude,
            lng: longitude
        };
        // const myApiKey = "AIzaSyC1MyEN2K3RNT6BgeRqgfOZe18dB5oPfFM";
        // fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + latitude + ',' + longitude + '&key=' + myApiKey)
        //         .then((response) => response.json())
        //         .then((responseJson) => {
        //             console.log('ADDRESS GEOCODE is BACK!! => ' + JSON.stringify(responseJson));
        // })
        let newData = new FormData()
        newData.append('key_api', config.KEY_API);
        newData.append('id_candidate', dataAbsency['id_candidate']);
        newData.append('absence', dataAbsency['absence']);
        newData.append('report', report);
        newData.append('start_absence_image', arriveImageFile);
        newData.append('finish_absence_image', finishImageFile);
        if (dataAbsency['absence'] === "ARRIVE") {
            newData.append('start_location', JSON.stringify(workLocationCoordinate));
        } else {
            newData.append('finish_location', JSON.stringify(workLocationCoordinate));
        }
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            this.toggleLoading();
            const res = await api.absencePartTimeMyJob(idEmployee, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: newData
            })
            const { status } = res;
            if (status === 200) {
                this.toggleLoading();
                window.location.href = config.WEB_URL + "ListJobUser";
            }
        } catch (err) {
            this.toggleLoading();
            alert("Error, Please check your connection or status then try refresh your page");
        }
    }

    retakePhoto = async () => {
        this.setState({ isTakePhoto: false, dataUri: null, latitude: "-", longitude: "-" });
    }

    setToFileImage(dataURI) {
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let blob = new File([ab], 'absency_image.png', { type: mimeString });
        return blob;
    }

    handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        // console.log('takePhoto');
    }

    handleCameraError(error) {
        console.log('handleCameraError', error);
        this.setState({ noticeError: 'Sorry, attendance feature is error. You can attendance with Avisha Mobile App' });
    }

    handleCameraStart(stream) {
        // console.log('handleCameraStart');
    }

    handleCameraStop() {
        // console.log('handleCameraStop');
    }

    render() {
        const { dataUri, isTakePhoto } = this.state;
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey("AIzaSyDJhc-CbYr-lt-6tK3E3mAi8yIlYNSNlXo");
        // set response language. Defaults to english.
        Geocode.setLanguage("en");
        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("id");
        // Enable or disable logs. Its optional.
        Geocode.enableDebug();
        // Get address from latidude & longitude.
        Geocode.fromLatLng(this.state.latitude, this.state.longitude).then(
            response => {
                const address = response.results[0].formatted_address;
                console.log(address);
            },
            error => {
                console.error(error);
            }
        );
        return (
            <Fragment>
                {/* <HeaderUser/> */}
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Attendance User</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Row>
                            <Col md="8" lg="9" className="mx-auto">
                                <Card>
                                    <CardHeader>Please allow permission appers for actived attendance</CardHeader>
                                    <CardBody>
                                        <div>
                                            {
                                                (dataUri)
                                                    ? <ImagePreview dataUri={dataUri}
                                                    />
                                                    :
                                                    <Camera
                                                        onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
                                                        onTakePhotoAnimationDone={(dataUri) => { this.handleTakePhotoAnimationDone(dataUri); }}
                                                        onCameraError={(error) => { this.handleCameraError(error) }}
                                                        idealResolution={{ width: 640, height: 480 }}
                                                        idealFacingMode={FACING_MODES.USER}
                                                        imageType={IMAGE_TYPES.JPG}
                                                        imageCompression={0.95}
                                                        isMaxResolution={true}
                                                        isImageMirror={true}
                                                        isSilentMode={false}
                                                        isDisplayStartCameraError={true}
                                                        isFullscreen={false}
                                                        sizeFactor={0.5}
                                                        onCameraStart={(stream) => { this.handleCameraStart(stream); }}
                                                        onCameraStop={() => { this.handleCameraStop(); }}
                                                    />
                                            }
                                        </div>
                                        <hr />
                                        <p className="font-weight-light text-14 text-center"><Label className="font-weight-bold">Location</Label> <br /> Latitude: {this.state.latitude} Longitude: {this.state.longitude}</p>

                                        <hr />
                                        {
                                            isTakePhoto === true ?
                                                <div>
                                                    <Col className="ml-0 mr-0 text-center mb-2">
                                                        <Button onClick={this.retakePhoto} className="btn-block btn-warning text-white">
                                                            Retake Photo
                                                </Button>
                                                    </Col>

                                                    <Col className="ml-0 mr-0 text-center">
                                                        <Button onClick={this.savePhotoAbsency} className="btn-block btn-success text-white">
                                                            Save Photo
                                                </Button>
                                                    </Col>
                                                </div>
                                                : null
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal isOpen={this.state.modalLoading} toggle={this.toggleLoading} backdrop="static">
                    <ModalBody className="text-center p-5">
                        <Spinner color="warning" style={{ width: '8rem', height: '8rem', borderWidth: '7px' }} />
                        <img src={logoAvisha} alt="logo-google" width="60px" className="position-absolute" style={{ margin: '30px 0 0 -95px' }} />
                        <Label className="d-block mt-3">Please Wait</Label>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
};

export default AttendanceUser;