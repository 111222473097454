import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import {
         Container, Row, Col, FormGroup, InputGroup,
         Label, Button, InputGroupAddon, InputGroupText,
         Input, Card, CardBody, CardFooter, Form,
         Modal, ModalBody, CardHeader
        }                                                   from 'reactstrap';   
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import * as method                                          from '../Helpers/Method';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faTimesCircle }                                    from '@fortawesome/free-solid-svg-icons';
import NumberFormat                                         from 'react-number-format';

class SimulationPartTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCount:false,
            quota:0,
            salary: 0,
            subTotalFee:0,
            totalPaid: 0,
            hasilManagementCost: 0,
            hasilPPN: 0,
            hasilPPH: 0,
            perPack: 0,
            partTimeFinanceBalance: 0,
            nominalBalance:0,
            nominalBalanceInString: 'Choose Nominal',
            modalOpen: false,
            isShowWarningSimulation: false,
            paket: {},
            nominalKurang: 0
            // managementCostPersenValue:0,
            // ppnPersenValue:0,
            // pphPersenValue:0,
            // totalPaidRupiah:"",
            // managementCost:0,
            // ppn:"",
            // pph:"",
            // dataVariable:[],
        };
    }

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getDataClient();
        // await this.getVariableFormula();
        await this.getPaketTerpilih();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getPaketTerpilih = async () => {
        const paket = await JSON.parse(localStorage.getItem('packagePartTimeChoosed'));
        this.setState({paket});
    };

    // getVariableFormula = async () => {
    //     const newData = {key_api:config.KEY_API};
    //     try{
    //         const res = await api.getVariableFormula(
    //             {params:{
    //                 data:JSON.stringify(newData)
    //             }
    //         })
    //         const { data } = res.data;
    //         this.setState({
    //             dataVariable:data,
    //         });
    //     } catch(err){
    //         console.log("Error Response :",err)
    //     }
    // }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataClient:data,
                companyName:data.company_name,
                partTimeFinanceBalance:data.part_time_finance_balance===null?0:data.part_time_finance_balance,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value , isCount:false});
    }

    hitung = async () => {
        const { salary, quota, paket } = this.state;
        const gaji = parseInt(salary);
        const kuota = parseInt(quota);
        if (gaji === 0) {
            this.setState({ isShowWarningSimulation: true, simulationWarning: "Salary Cannot be null" });
        } else if (kuota === 0) {
            this.setState({ isShowWarningSimulation: true, simulationWarning: "Quota Cannot be null" });
        } else if (gaji < 10000) {
            this.setState({ isShowWarningSimulation: true, simulationWarning: "salary is too low" });
        } else {
            const hasilManagementCost = gaji * (parseInt(paket.management_cost)/100);
            const subTotalFee = gaji + hasilManagementCost;
            const hasilPPN = hasilManagementCost * (parseInt(paket.ppn)/100);
            const hasilPPH = hasilManagementCost * (parseInt(paket.pph) / 100);
            const perPack = (subTotalFee + hasilPPN) - hasilPPH;
            const totalPaid = perPack * kuota;
            await this.setState({
                hasilManagementCost,
                subTotalFee,
                hasilPPN,
                hasilPPH,
                perPack,
                totalPaid,
                isShowWarningSimulation:false,
                isCount: true,
                // totalPaidRupiah:method.formatRupiah(total),
            });
        }
    }
    
    count = async () => {
        const { salary, quota, dataVariable} = this.state;
        if(salary===0){
            this.setState({isShowWarningSimulation:true, simulationWarning:"Salary Cannot be null"});
        } else if (quota ===0){
            this.setState({isShowWarningSimulation:true, simulationWarning:"Quota Cannot be null"});
        } else if (salary < 10000){
            this.setState({isShowWarningSimulation:true, simulationWarning:"salary is too low"});
        } else {
            let managementCost = "";
            let subtotalFee="";
            let ppn="";
            let pph="";
            let i;
            for(i=0;i<dataVariable.length;i++){
                if(dataVariable[i]['variable_name'] === "Management Gaji"){
                    managementCost = (dataVariable[i]['value']/100) * salary;
                    this.setState({managementCostPersenValue:dataVariable[i]['value']});
                }
                if(dataVariable[i]['variable_name'] === "PPN"){
                    this.setState({ppnPersenValue:dataVariable[i]['value']});
                    ppn = (dataVariable[i]['value']/100) * managementCost;
                }
                if(dataVariable[i]['variable_name'] === "PPH"){
                    this.setState({pphPersenValue:dataVariable[i]['value']});
                    pph = (dataVariable[i]['value']/100) * managementCost;
                }

            }
            subtotalFee = parseInt(salary) + parseInt(managementCost);
            const total = ((subtotalFee + ppn) - pph) * quota;

            await this.setState({
                isShowWarningSimulation:false,
                isCount:true, totalPaid:total, 
                totalPaidRupiah:method.formatRupiah(total),
                managementCost:method.formatRupiah(managementCost),
                subtotalFee:method.formatRupiah(subtotalFee),
                ppn:method.formatRupiah(ppn),
                pph:method.formatRupiah(pph),
            });
        }
    }

    
    topUpCredit = () => {
        window.location.href = config.WEB_URL+"Buy";
    }

    closeModal = () => {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }));                
    }

    continue = () => {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }));                
    }

    onReset= async () =>{
        this.setState({
            isCount:false,
            quota:0,
            salary:0,
            totalPaid:0,
            totalPaidRupiah:"",
            partTimeFinanceBalance:0,
            modalOpen: false,
        })
    }

    topUp = () => {
        const { nominalBalance, paket, totalPaid, salary, quota } = this.state;
        if (nominalBalance < totalPaid) {
            this.setState({nominalKurang: 1});
        } else {
            this.setState({nominalKurang: 0});
            paket.total_payment = nominalBalance;
            paket.salary = salary;
            paket.quota = quota;
            localStorage.setItem('packagePartTimeChoosed', JSON.stringify(paket));
            window.location.href = config.WEB_URL + `PaymentMethodPartTime`;
        }
    }

    topUpSaldo = () => {
        const {nominalBalance} = this.state;
        const newData = {
            "category":"Top Up Saldo",
            "total_payment":nominalBalance
        }
        localStorage.setItem("packageChoosed",JSON.stringify(newData));
        window.location.href = config.WEB_URL + `PaymentMethodPartTime`;
    }

    handleChangeNumberFormat = (formattedValue) => {
        this.setState({ nominalBalance: formattedValue.value });
    }

    render() {
        const {totalPaid, isCount, quota, salary, modalOpen, nominalBalance, simulationWarning, isShowWarningSimulation, paket} = this.state;
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Simulation Part Time Job</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col md="8" className="mx-auto">
                            <Card>
                                <CardHeader>
                                    <h5 className="font-weight-bold">{paket.package_name}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Label className="d-block font-weight-bold text-center mb-3">Simulation Hiring Part Time Job</Label>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-16 font-weight-bold">Salary</Label>
                                                    <InputGroup>
                                                        <Input onChange={ this.handleChange } name="salary" value={salary} type="number"/>
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText className="text-14">/ day</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-2">
                                                    <Label className="text-16 font-weight-bold">Quantity</Label>
                                                    <InputGroup>
                                                    <Input onChange={ this.handleChange } name="quota" value={quota} type="number" className="input-box-white"/>
                                                        <InputGroupAddon addonType="append">
                                                        <InputGroupText className="text-14">people</InputGroupText>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {(isShowWarningSimulation === true)?<p><small style={{color:"red"}}>{simulationWarning}</small></p> : null
                                        }
                                    </Form>
                                    <hr/>
                                    {(isCount === true)? this.hasilHitung() : null}
                                </CardBody>
                                {isCount === false ? this.tombolResult() : null}
                            </Card>  
                        </Col>
                    </Container>  
                    {this.modalTopUpHTML(modalOpen, nominalBalance)}
                </div>
            </Fragment>
        );
    }

    modalTopUpHTML = (modalOpen, nominalBalance) => {
        const {nominalKurang} = this.state;
        return (<Modal isOpen={modalOpen} toggle={this.closeModal} backdrop="static">
            <ModalBody className="p-7">
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeModal} />
                <Label className="font-weight-bold">Nominal Credit</Label>
                <Row>
                    <Col>
                        <NumberFormat onValueChange={(formattedValue) => this.handleChangeNumberFormat(formattedValue)} id="nominalBalance" name="nominalBalance" value={nominalBalance} className="input-box-white" thousandSeparator={true} prefix={'Rp. '} />
                        {(nominalKurang === 1)? this.teksError() : null}
                    </Col>
                    <Col md="5" lg="5" className="my-auto">
                        <Button disabled={((nominalBalance === "") || (nominalBalance === 0)) ? true : false} className="btn-block btn-warning text-white" onClick={() => this.topUp()}>Top Up</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>);
    }

    teksError = () => <small id="teksError" class="text-danger">*Nominal tidak cukup</small>

    tombolResult = () => <CardFooter tag={Button} className="btn-block btn-blue-normal text-16" onClick={this.hitung}>Result</CardFooter>

    hasilHitung = () => {
        const {totalPaid, hasilManagementCost, subTotalFee, hasilPPN, hasilPPH, perPack, paket} = this.state;
        return (<Form>
            <Col className="pl-0 pr-0 mb-3">
                <Row>
                    <Col className="text-left"><Label className="d-block font-weight-bold text-16 mb-0">Management Cost</Label></Col>
                    <Col className="text-right"><Label className="d-block text-16 mb-0">{method.formatRupiah(hasilManagementCost)}</Label></Col>
                </Row>
                <p style={{ color: "grey" }}>Salary Part Time x {paket.management_cost} %</p>
            </Col>
            <Col className="pl-0 pr-0 mb-3">
                <Row>
                    <Col className="text-left"><Label className="d-block font-weight-bold text-16 mb-0">Subtotal fees</Label></Col>
                    <Col className="text-right"><Label className="d-block text-16 mb-0">{method.formatRupiah(subTotalFee)}</Label></Col>
                </Row>
                <p style={{ color: "grey" }}>Salary Part Time + Management Cost</p>
            </Col>
            <Col className="pl-0 pr-0 mb-3">
                <Row>
                    <Col className="text-left"><Label className="d-block font-weight-bold text-16 mb-0">PPN</Label></Col>
                    <Col className="text-right"><Label className="d-block text-16 mb-0">{method.formatRupiah(hasilPPN)}</Label></Col>
                </Row>
                <p style={{ color: "grey" }}>Management Cost x {paket.ppn} %</p>
            </Col>
            <Col className="pl-0 pr-0 mb-3 ">
                <Row>
                    <Col className="text-left"><Label className="d-block font-weight-bold text-16 mb-0">PPH</Label></Col>
                    <Col className="text-right"><Label className="d-block text-16 mb-0">{method.formatRupiah(hasilPPH)}</Label></Col>
                </Row>
                <p style={{ color: "grey" }}>Management Cost x {paket.pph} %</p>
            </Col>
            <Col className="bg-info my-auto rounded-lg mb-3 text-white p-3">
                <Row>
                    <Col className="text-left"><Label className="text-16 font-weight-bold mb-0">Grand total</Label></Col>
                    <Col className="text-right"><Label className="text-16 mb-0">{method.formatRupiah(totalPaid)}</Label></Col>
                </Row>
            </Col>
            <hr />
            <Col className="pl-0 pr-0 mb-3 ">
                <Row>
                    <Col className="text-left"><Label className="d-block font-weight-bold text-16 mb-0">Cost Per Pack</Label></Col>
                    <Col className="text-right"><Label className="d-block text-16 mb-0">{method.formatRupiah(perPack)}</Label></Col>
                </Row>
            </Col>
            <hr />
            <Col className="pl-0 pr-0 mt-4">
                <Row>
                    <Col><Button className="btn-block btn-blue-normal text-16" onClick={this.continue}>Top Up Credit Part Time</Button></Col>
                    <Col><Button className="btn-block btn-warning text-16" onClick={this.onReset}>Reset</Button></Col>
                </Row>
            </Col>
        </Form>);
    }
}

export default SimulationPartTime;