// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';

import { Link }                                             from 'react-router-dom';

// Styling
import { Container, Row, Col, Label, Button, Table, 
         Card, CardBody, Modal, ModalBody }                 from 'reactstrap';   

import { FontAwesomeIcon }                          from '@fortawesome/react-fontawesome'
import { faTimesCircle }                            from '@fortawesome/free-solid-svg-icons'

import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from '../Helpers/Method';

class RecentTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:0,
            partTimeBalance:"",
            fullTimeBalance:"",
            findTalentBalance:"",
            expiredDateBalance:"",
            spesificPackageName:"",
            dataAllPackageClient:[],
            historyClientQuotaPackage:[],
            modalDetailTransaction: false
        };
        this.toggleDetailTransaction = this.toggleDetailTransaction.bind(this);
    }

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getDataClient();
        await this.getAllPackageClient();
    }

    closeDetailTransaction = () => {
        this.setState(prevState => ({
            modalDetailTransaction: !prevState.modalDetailTransaction
        }));      
    }

    toggleDetailTransaction = async (key) => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getAllPackageClient(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const {data} = res.data;
            await this.setState({spesificPackageName:data[key].package_name, historyClientQuotaPackage:data[key].history_client_quota_package});
            this.setState(prevState => ({
                modalDetailTransaction: !prevState.modalDetailTransaction
            }));      
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getAllPackageClient = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getAllPackageClient(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const {data} = res.data;
            this.setState({dataAllPackageClient:data});
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }
      
    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })

            const { part_time_finance_balance, full_time_quota, talent_search_quota, expired_date_quota } = res.data.data;
            await this.setState({
                partTimeBalance:((part_time_finance_balance===null) || (part_time_finance_balance===0)) ? "0":method.formatMoney(part_time_finance_balance),
                fullTimeBalance:full_time_quota===null?0:full_time_quota,
                findTalentBalance:talent_search_quota===null?0:talent_search_quota,
                expiredDateBalance:expired_date_quota===null?"-":method.formatDate(expired_date_quota)
            });

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {        
        const {
            dataAllPackageClient, historyClientQuotaPackage, spesificPackageName
        } = this.state;                   
        return (          
            <Fragment>  
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Recent Transactions</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col className="bg-white pt-3 pb-3 rounded-lg">
                            <Row>
                                {/* <Col className="text-left"><Label>Recent Transaction</Label></Col> */}
                                <Col className="mb-3"><Link to="/all-recent-transaction" className="text-blue text-decoration-none">See All</Link></Col>
                            </Row>
                            {/* <hr className="mt-0"/> */}
                            {
                                dataAllPackageClient.length === 0 ? 
                                    <Col>
                                        <h5 className="font-weight-bolder text-center text-blue">No Transaction, yet.</h5>
                                    </Col>
                                :
                                <div>
                                    {dataAllPackageClient.map((item, key) =>
                                        <Card className="mb-4">
                                            <CardBody>
                                                <Row>
                                                    {
                                                        item.is_active === 1 ?
                                                            <Col xs="2" sm="2" md="2" lg="2" className="my-auto text-center">Active</Col>
                                                        : item.is_active===null ?
                                                            <Col xs="2" sm="2" md="2" lg="2" className="my-auto text-center">Not Used</Col>
                                                        : 
                                                            <Col xs="2" sm="2" md="2" lg="2" className="my-auto text-center">Unactived</Col>
                                                    }
                                                    <Col>
                                                        {/* <Label className="d-block font-weight-bold">{item.transaction_category}</Label>
                                                        <Label className="d-block font-weight-bold">{method.formatRupiah(item.total_payment)}</Label> */}
                                                        <Row>
                                                            <Col><Label className="font-weight-bold text-18">Job Posting</Label></Col>
                                                            <Col><Label className="font-weight-bold text-18">Find Talent</Label></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><Label className="text-20">{item.full_time_quota}</Label></Col>
                                                            <Col><Label className="text-20">{item.talent_search_quota}</Label></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><Label className="d-block mb-0">Active : {method.formatDateDay(item.created_at)}</Label></Col>
                                                            <Col><Label className="d-block mb-0">Expired Date : {method.formatDateDay(item.expired_date_quota)}</Label></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="3" className="my-auto">
                                                        <Button tag={Link} to="/Buy" className="btn-block btn-success">Re-buy</Button>
                                                        <Button onClick ={()=>this.toggleDetailTransaction(key)} className="btn-block btn-warning">Detail</Button>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                </div>
                            }
                        </Col>
                    </Container>
                </div>
                <Modal isOpen={this.state.modalDetailTransaction} toggle={this.closeDetailTransaction} backdrop="static">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeDetailTransaction}/>
                    <ModalBody>
                        {
                            historyClientQuotaPackage===null?
                                <p className="text-center text-16">Not Found History</p>
                            : 
                            <Table hover striped responsive>
                                <thead>
                                    <tr className="text-center text-16">
                                        <th>No</th>
                                        <th>Date Transaction </th>
                                        <th>Name Packaged</th>
                                        <th>Credit Job Post</th>
                                        <th>Credit Find Talent</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center text-14">
                                    {historyClientQuotaPackage.map((item, index) => {
                                        return (
                                                <tr>
                                                    <th scope="row">{index+1}</th>
                                                    <td>{method.formatDateDay(item.created_at)}</td>
                                                    <td>{spesificPackageName}</td>
                                                    <td>{item.id_job === null ? "-1":"-"}</td>
                                                    <td>{item.id_employee === null ? "-1":"-"}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        }   
                    </ModalBody>
                </Modal>
            </Fragment>
        );   
    }
};

export default RecentTransaction;