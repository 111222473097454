import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Container, Row, Col, Label,
        Card, CardHeader, CardBody }                        from 'reactstrap';   
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from '../Helpers/Method';
         
class AllRecentTransaction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataAllPaymentHistory:[],
        };
    }

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getAllPaymentHistory();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getAllPaymentHistory = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getAllPaymentHistory(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const {data} = res.data;
            this.setState({dataAllPaymentHistory:data});
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {                        
        const { dataAllPaymentHistory } = this.state;             
        return (          
            <Fragment>  
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">All Recent Transactions</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader className="text-18 font-weight-bold">History Purchase Packaged</CardHeader>
                                    <CardBody style={{height:'450px'}}>
                                        {
                                            dataAllPaymentHistory.length === 0 ? 
                                                <Col>
                                                    <h5 className="font-weight-bolder text-center text-blue">No Transaction, yet.</h5>
                                                </Col>
                                            :
                                            <div>
                                                {dataAllPaymentHistory.map((item) =>
                                                    ((item.status_payment==="Payment Success") && (item.transaction_category==="Buy Package")) ? 
                                                        <Col className="border rounded p-3 mb-3">
                                                            <Row>
                                                                <Col className="text-left my-auto"><Label className="d-block text-14 font-weight-bold mb-0">{item.package_name}</Label></Col>
                                                                <Col className="text-right my-auto"><Label className="d-block text-14 mb-0">{method.formatDateDay(item.updated_at)}</Label></Col>
                                                            </Row>
                                                        </Col>
                                                    :null
                                                )}
                                            </div>
                                        }
                                    </CardBody>
                                </Card> 
                            </Col>
                            <Col>
                                <Card>
                                    <CardHeader className="text-18 font-weight-bold">History Top Up Saldo</CardHeader>
                                    <CardBody style={{height:'450px'}}>
                                        {
                                            dataAllPaymentHistory.length === 0 ? 
                                                <Col>
                                                    <h5 className="font-weight-bolder text-center text-blue">No Transaction, yet.</h5>
                                                </Col>
                                            :
                                            <div>
                                                {dataAllPaymentHistory.map((item) =>
                                                    ((item.status_payment==="Payment Success") && (item.transaction_category==="Top Up Saldo")) ? 
                                                        <Col className="border rounded p-3 mb-3">
                                                            <Row>
                                                                <Col className="text-left my-auto"><Label className="d-block text-14 font-weight-bold mb-0">{item.transaction_category}</Label></Col>
                                                                <Col className="text-right my-auto"><Label className="d-block text-14 mb-0">{method.formatRupiah(item.total_payment)}</Label></Col>
                                                                <Col className="text-right my-auto"><Label className="d-block text-14 mb-0">{method.formatDateDay(item.updated_at)}</Label></Col>
                                                            </Row>
                                                        </Col>
                                                    :null
                                                )}
                                            </div>
                                        }
                                    </CardBody>
                                </Card> 
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default AllRecentTransaction;