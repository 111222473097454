// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
import HeaderUser from './ComponentUser/HeaderUser'

// Styling
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import '../Css/Main.css'

class EditpProfileUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Fragment>
        <HeaderUser />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Profile</h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="mt-4">
            <Col className="bg-white rounded-lg pt-4 pb-4 pl-0 pr-0">
              <Container className="pl-0 pr-0">
                <Card>
                  <CardHeader></CardHeader>
                  <CardBody></CardBody>
                </Card>
              </Container>
            </Col>
          </Container>
        </div>
      </Fragment>
    )
  }
}

export default EditpProfileUser
