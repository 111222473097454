// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from '../Client/ComponentClient/HeaderClient';

// Styling
import { Container, Row, Col, Button, Table}                from 'reactstrap';   
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import * as method                                          from '../Helpers/Method';
import ReactPaginate                                        from 'react-paginate';

class ApprovalJobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:0,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount:0,
            listJob:[],
        };
    }

    componentDidMount= async () => {
        localStorage.removeItem("idJob");
        await this.getId();
        await this.getJobs();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getJobs = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getJobs(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const { data } = res.data;
            let i;
            let newObject = [];
            for(i=0;i<data.length;i++){
                if(data[i]['total_candidate'] > 0){
                    newObject.push(data[i])
                }
            }
            await this.setState({
                listJob:newObject.slice(this.state.offset, this.state.offset + this.state.perPage),
                pageCount: newObject.length / this.state.perPage
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    detailJob = (idItem) =>{
        localStorage.setItem('idJob', idItem);
        window.location.href = config.WEB_URL + `DetailApprovalJobs`;
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
    
        this.setState({
          currentPage: selectedPage,
          offset: offset
        }, () => {
          this.getJobs()
        });
    };
    
    render() {         
        const { listJob, pageCount } = this.state;                           
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey vh-100">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Approval Jobs</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col className="bg-white rounded-lg overflow-scroll pt-4" style={{height:'530px', overflow:'scroll'}}>
                            { listJob.length===0?
                                <p className="my-auto font-weight-bold"> <center> No Candidate</center></p>
                            : 
                            <div>
                                <Table hover striped responsive>
                                    <thead>
                                        <tr className="text-center text-16">
                                        <th>No</th>
                                        <th>Id Pekerjaan </th>
                                        <th>Posting Job Time </th>
                                        <th>Catagory Job</th>
                                        <th>Type Job</th>
                                        <th>Total Candidate</th>
                                        <th>Periode</th>
                                        <th>Shift</th>

                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center text-14">
                                        {listJob.map((item, index) => {
                                            let shift = []
                                            if(item.absen !== undefined){
                                                shift =  item.absen.split(",");
                                            }
                                            return (
                                                <tr>
                                                <th scope="row">{index+1}</th>
                                                <td>{method.fixIdJob(item.id)}</td>
                                                <td>{method.formatDateDay(item.posting_time)}</td>
                                                <td>{item.job_category}</td>
                                                <td><label style={{backgroundColor:item.work_type==="Part-Time"?"yellow":"green"}}>{item.work_type}</label></td>
                                                <td>{item.total_candidate} Employee</td>
                                                <td>{item.work_type === "Part-Time" ? item.start_part_time_date+" s/d " +item.finish_part_time_date : "-"}</td>
                                                <td> 
                                                    { shift.map((it)=>
                                                        <><li key={it}>{it}</li><br /></>
                                                    )}
                                                </td>

                                                <td><Button className="btn-block btn-success" onClick={()=>this.detailJob(item.id)}>Detail</Button></td>
                                                </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="float-right">
                                    <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    />
                                </div>
                            </div>
                            }
                        </Col>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default ApprovalJobs;