import * as config from '../Helpers/Config';

export function getIdClient() {
    const idEncrypt = localStorage.getItem("idClient");
    if (localStorage.getItem("idClient")) {
        const arrPath = idEncrypt.split('_');
        if ((decryptKey(arrPath[0]) === config.KEY_ENCRYPT) && (decryptKey(arrPath[2]) === config.KEY_ENCRYPT)) {
            let id = "";
            for (let i = 0; i < arrPath[1].length; i++) {
                let b = arrPath[1].charCodeAt(i) //ord(value[i]);
                let a = b ^ 123; // <-- must be same number used to encode the character
                id = id + String.fromCharCode(a);
            }
            return id;
        } else {
            localStorage.clear();
            window.location.href = config.WEB_URL;
        }
    } else {
        localStorage.clear();
        window.location.href = config.WEB_URL;
    }
}

export function getIdEmployee() {
    const idEncrypt = localStorage.getItem("idEmployee");
    if (localStorage.getItem("idEmployee")) {
        const arrPath = idEncrypt.split('_');
        if ((decryptKey(arrPath[0]) === config.KEY_ENCRYPT) && (decryptKey(arrPath[2]) === config.KEY_ENCRYPT)) {
            let id = "";
            for (let i = 0; i < arrPath[1].length; i++) {
                let b = arrPath[1].charCodeAt(i) //ord(value[i]);
                let a = b ^ 123; // <-- must be same number used to encode the character
                id = id + String.fromCharCode(a);
            }
            return id;
        } else {
            localStorage.clear();
            window.location.href = config.WEB_URL;
        }
    } else {
        localStorage.clear();
        window.location.href = config.WEB_URL;
    }
}

export function logOut() {
    localStorage.clear();
}

export function checkIsLogin() {
    if (localStorage.getItem('isLoginClient')) {
        console.log("login")
    } else {
        window.location.href = config.WEB_URL;
    }
}

export function encryptKey(value) {
    const str = value.toString();
    var encoded = "";
    for (var i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 10;
        encoded = encoded + String.fromCharCode(b);
    }
    return encoded;

}

function decryptKey(value) {
    let key = "";
    for (let i = 0; i < value.length; i++) {
        let b = value.charCodeAt(i) //ord(value[i]);
        let a = b ^ 10; // <-- must be same number used to encode the character
        key = key + String.fromCharCode(a);
    }
    return key;

}


export function encryptId(value, key) {
    const str = value.toString();
    var encoded = key + "_";
    for (var i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 123; // bitwise XOR with any number, e.g. 123
        encoded = encoded + String.fromCharCode(b);
    }
    return encoded + "_" + key;
}


export function formatDate(date) {
    var today = new Date(date);
    const monthNames = [
        "Januari", "Februari", "Maret",
        "April", "May", "Juni", "Juli",
        "Augustus", "September", "Oktober",
        "November", "Desember"
    ];

    var h = today.getHours();
    var m = today.getMinutes();
    // var s = today.getSeconds();
    // m = checkTime(m);
    // s = checkTime(s);
    return today.getDate() + " " + monthNames[today.getMonth()] + " " + today.getFullYear() + " - " + h + ":" + m;
}