// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderUser                                           from './ComponentUser/HeaderUser';
import JobOffering                                          from './ComponentUser/JobOffering';

import classnames                                           from 'classnames';

import Wallet                                               from '../Asset/Icon/wallet.svg';

// Styling
import { Container, Row, Col, TabContent, TabPane, 
         Nav, NavItem, NavLink }      from 'reactstrap';   
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';      
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as method                                          from '../Helpers/Method';      
// import { not } from 'glamor';
         
class MesssageUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            financeBalance:0,
            listInboxMessage:[],
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    componentDidMount = async () =>{
        await this.getDataEmployee();
        await this.getInboxMessages();
    }

    getDataEmployee = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.profileEmployee(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({
                financeBalance:data.dataProfile.finance_balance
            })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
      }

      getInboxMessages = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.allEmployeeInbox(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({
                listInboxMessage:data.listEmployeeInbox
            })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
      }


    render() {                                     
        return (          
            <Fragment>  
                <HeaderUser/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Job Offering</h5>
                                </Col>
                                <Col className="my-auto text-right">
                                    <img src={Wallet} alt="wallet-avisha" width="30px" className="mr-2" />
                                    <p className="d-inline-flex font-weight-bolder mb-0">Balance : {this.state.financeBalance==null ? "Rp.0" :method.formatRupiah(this.state.financeBalance)}</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '0' })}
                                        onClick={(e) => { this.toggle('0'); }} style={{borderRadius:'12px 12px 0 0'}}><p className="mb-0 font-weight-bold">Job Offering</p> 
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab} className="border-left border-right border-bottom text-12">
                            {/* ------- Tab Message --------*/}
                            <TabPane tabId="0" className="bg-white box-shadow p-3" style={{minHeight:'470px', borderRadius:'0 0 0 0'}}>
                                <JobOffering/>
                            </TabPane>
                            {/* ------- End Tab Message --------*/}
                        </TabContent>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default MesssageUser;