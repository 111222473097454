import React from 'react'

import { NavLink, Link } from 'react-router-dom'

import logoAvishaWhite from '../../Asset/Logo/logo-avisha-white.svg'

import {
  Container,
  Col,
  Label,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faHistory,
  faList,
  faBriefcase,
  faBell,
  faUserCircle,
  faUser,
  faCog,
  faQuestion,
  faSignOutAlt,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import * as config from '../../Helpers/Config'
import * as api from '../../Helpers/Api'
import * as authHelperMethods from '../../Helpers/AuthHelperMethods'
import * as method from '../../Helpers/Method'
class HeaderUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      employeeName: '',
      listInboxMessage: [],
    }
    this.toggle = this.toggle.bind(this)

    // Responsive navbar with toggle
    this.toggleNav = this.toggleNav.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount = async () => {
    await this.getInboxMessages()
    await this.getDataEmployee()
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleNav() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        this.setState({ employeeName: data.dataProfile.full_name })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  logOut = () => {
    localStorage.clear()
    window.location.href = config.WEB_URL
  }

  getInboxMessages = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.allEmployeeInbox(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        await this.setState({
          listInboxMessage: data.listEmployeeInbox,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  render() {
    const { listInboxMessage } = this.state
    return (
      <div className="bg-blue">
        <Container className="pl-0 pr-0">
          <Navbar color="#000" light expand="lg">
            <NavbarBrand href="/">
              <img
                src={logoAvishaWhite}
                alt="logo avisha no color"
                width="120px"
                className="mb-1"
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNav} />
            <Collapse navbar isOpen={this.state.isOpen}>
              <Nav navbar className="text-14 ml-auto">
                <NavItem className="mr-lg-5 my-auto">
                  <NavLink
                    to="/DashboardUser"
                    activeClassName={'active'}
                    exact={true}
                    activeStyle={{
                      fontWeight: 'bolder',
                      borderBottom: '2px solid #fff',
                    }}
                    style={{ color: '#ffffff' }}
                  >
                    <FontAwesomeIcon icon={faHome} />{' '}
                    <Label className="mb-2 mb-lg-0 mb-xl-0">Dashboard</Label>
                  </NavLink>
                </NavItem>
                <NavItem className="mr-lg-5 my-auto">
                  <NavLink
                    to="/HistoryUser"
                    activeClassName={'active'}
                    activeStyle={{
                      fontWeight: 'bolder',
                      borderBottom: '2px solid #fff',
                    }}
                    style={{ color: '#ffffff' }}
                  >
                    <FontAwesomeIcon icon={faHistory} />{' '}
                    <Label className="mb-2 mb-lg-0 mb-xl-0">History</Label>
                  </NavLink>
                </NavItem>
                <NavItem className="mr-lg-5 my-auto">
                  <NavLink
                    to="/ListJobUser"
                    activeClassName={'active'}
                    activeStyle={{
                      fontWeight: 'bolder',
                      borderBottom: '2px solid #fff',
                    }}
                    style={{ color: '#ffffff' }}
                  >
                    <FontAwesomeIcon icon={faBriefcase} className="" />{' '}
                    <Label className="mb-2 mb-lg-0 mb-xl-0">My Job</Label>
                  </NavLink>
                </NavItem>
                <NavItem className="mr-lg-4 my-auto">
                  <NavLink
                    to="/job-offer"
                    activeClassName={'active'}
                    activeStyle={{
                      fontWeight: 'bolder',
                      borderBottom: '2px solid #fff',
                    }}
                    style={{ color: '#ffffff' }}
                  >
                    <FontAwesomeIcon icon={faList} className="" />{' '}
                    <Label className="mb-2 mb-lg-0 mb-xl-0">Job Offer</Label>
                  </NavLink>
                </NavItem>
                <div className="border-left mt-2 mb-2 mr-4 d-none d-lg-block d-xl-block"></div>
                <div className="d-flex">
                  <NavItem className="mr-3 my-auto">
                    {/* <NavLink to="/NotifClient"  activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#D0D2D3'}}> */}
                    <UncontrolledDropdown setActiveFromChild>
                      <DropdownToggle className="bg-white">
                        <FontAwesomeIcon icon={faBell} className="text-blue" />{' '}
                        {listInboxMessage === undefined ||
                        listInboxMessage.length === 0 ? null : (
                          <div
                            className="text-danger position-absolute"
                            style={{ margin: '-30px 0 0 8px' }}
                          >
                            &#9864;
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu right className="mt-2">
                        <div
                          style={{
                            width: '300px',
                            height: '300px',
                            overflowY: 'scroll',
                          }}
                        >
                          <DropdownItem header>
                            <p className="font-weight-bold text-center mb-0">
                              Notifications
                            </p>
                          </DropdownItem>
                          <DropdownItem divider className="mt-0" />
                          {listInboxMessage === undefined ||
                          listInboxMessage.length === 0
                            ? null
                            : listInboxMessage.map((item) => (
                                <div key={item.key}>
                                  <DropdownItem
                                    tag={Link}
                                    to="/notification-user"
                                    className="text-wrap text-justify"
                                  >
                                    {item.is_read === null ? (
                                      <div>
                                        <b className="text-10 d-inline-block bold">
                                          {method.formatDateDay(
                                            item.created_at,
                                          )}
                                        </b>
                                        <br />
                                        <b className="text-10 d-inline-block bold">
                                          {item.title}
                                        </b>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="text-10 d-inline-block">
                                          {method.formatDateDay(
                                            item.created_at,
                                          )}
                                        </p>
                                        <br />
                                        <p className="text-10 d-inline-block">
                                          {item.title}
                                        </p>
                                      </div>
                                    )}
                                    <hr />
                                  </DropdownItem>
                                </div>
                              ))}
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    {/* </NavLink> */}
                  </NavItem>
                  <NavItem>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        nav
                        className="bg-white rounded-pill pl-4 pr-4 d-inline-flex"
                      >
                        <Row>
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            className="text-30 my-auto mr-2"
                          />{' '}
                          <div
                            className="my-auto text-12 mr-2"
                            style={{ maxWidth: '100px' }}
                          >
                            <p className="mb-0 my-auto font-weight-bold nameUser text-capitalize">
                              {this.state.employeeName}
                            </p>
                          </div>
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="my-auto"
                          />
                        </Row>
                      </DropdownToggle>
                      <DropdownMenu className="mx-auto">
                        <Link to="/ProfileUser">
                          <DropdownItem className="text-14">
                            <Row>
                              <Col xs="1">
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="text-secondary"
                                />{' '}
                              </Col>
                              <Col>Profile</Col>
                            </Row>
                          </DropdownItem>
                        </Link>
                        {/* <Link to="#">
                                <DropdownItem className="text-14">
                                  <Row>
                                    <Col xs="1">
                                      <FontAwesomeIcon icon={faCog} className="text-secondary"/>{' '}
                                    </Col>
                                    <Col>
                                      Setting
                                    </Col>
                                  </Row>
                                </DropdownItem>
                              </Link> */}
                        {/* <Link to="#">
                                <DropdownItem className="text-14">
                                  <Row>
                                    <Col xs="1">
                                      <FontAwesomeIcon icon={faQuestion} className="text-secondary"/>{' '}
                                    </Col>
                                    <Col>
                                      Help
                                    </Col>
                                  </Row>
                                </DropdownItem>
                              </Link> */}
                        <DropdownItem divider className="w-75 mx-auto" />
                        <Link to="/">
                          <DropdownItem
                            onClick={this.logOut}
                            className="text-14"
                          >
                            <Row>
                              <Col xs="1">
                                <FontAwesomeIcon
                                  icon={faSignOutAlt}
                                  className="text-secondary"
                                />{' '}
                              </Col>
                              <Col>Sign Out</Col>
                            </Row>
                          </DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>
                </div>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    )
  }
}

export default HeaderUser
