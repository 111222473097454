// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
import HeaderClient from './ComponentClient/HeaderClient'

import { Link } from 'react-router-dom'
// Styling
import {
  Container,
  Row,
  Col,
  Card,
  CardFooter,
  CardBody,
  Label,
  CardHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { faTimesCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import * as config from '../Helpers/Config'
import * as api from '../Helpers/Api'
import * as method from '../Helpers/Method'
import * as authHelperMethods from '../Helpers/AuthHelperMethods'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalPayment: '',
      packageName: '',
      dataBankTypes: [],
      category: '',
      id: 0,
      modalPaymentGateway: false,
      dataPayment: {},
      customer_email: '',
      customer_name: '',
      customer_phone: '',
    }
  }

  componentDidMount = async () => {
    await this.getBankTypes()
    await this.getPackageChoose()
    await this.getId()
  }

  getId = () => {
    const id = authHelperMethods.getIdClient()
    this.setState({ id: id })
  }

  getPackageChoose = async () => {
    const { total_payment, package_name, category } = JSON.parse(
      localStorage.getItem('packageChoosed'),
    )
    if (category === 'Buy Package') {
      this.setState({ packageName: package_name })
    }
    this.setState({
      totalPayment: method.formatRupiah(total_payment),
      category: category,
    })
  }

  getBankTypes = async () => {
    try {
      const newData = {
        key_api: config.KEY_API,
      }
      const res = await api.getBankTypes({
        params: { data: JSON.stringify(newData) },
      })
      const { data } = res.data
      this.setState({ dataBankTypes: data })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  choosePayment = async (item) => {
    const newData = JSON.parse(localStorage.getItem('packageChoosed'))
    newData.id_bank_type = item['id']
    newData.bank_type = item['bank_name']
    newData.code = item['code']
    newData.id_bank_type = item['id']
    newData.key_api = config.KEY_API
    newData.id_client = this.state.id
    newData.bank_account_number = item['bank_account_number']
    this.setState({ dataPayment: newData })
    try {
      if (item.is_payment_gateway == 0) {
        const res = await api.createTransaction({
          params: {
            data: JSON.stringify(newData),
          },
        })
        const { status, data } = res
        if (status === 200) {
          localStorage.setItem('id_transaction', data.data.id_transaction)
          localStorage.removeItem('packageChoosed')
          window.location.href = `${config.WEB_URL}WaitingPayment`
        } else if (status === 404) {
          alert('Koneksi Error')
        }
      } else {
        localStorage.setItem('id_bank', newData.id_bank_type)
        localStorage.setItem('code', newData.code)
        this.toggleModalPaymentGateway()
      }
    } catch (err) {
      alert('Koneksi Error')
      console.log('Error Response :', err)
    }
  }

  toggleModalPaymentGateway = async () => {
    this.setState((prevState) => ({
      modalPaymentGateway: !prevState.modalPaymentGateway,
    }))
  }

  prosesPaymentGateway = async () => {
    const {
      dataPayment,
      customer_email,
      customer_name,
      customer_phone,
    } = this.state
    dataPayment.paymentGateway = {
      bank: dataPayment.code, //'DANA',
      gross_amount: dataPayment.total_payment,
      customer_email,
      customer_name,
      customer_phone,
      expired: 2,
      transaction_details: [
        {
          price: dataPayment.total_payment,
          quantity: 1,
          name: dataPayment.package_name,
        },
      ],
    }
    const paymentGateway = await api.newPaymentGateway({
      params: {
        data: JSON.stringify(dataPayment),
      },
    })
    const { data } = paymentGateway
    if (data.status === true) {
      localStorage.setItem('id_transaction', data.id_transaction)
      localStorage.removeItem('packageChoosed')
      window.location.href = `${config.WEB_URL}WaitingPaymentGateway`
    } else {
      this.setState({ error: data.remarks })
    }
  }

  render() {
    const { totalPayment, packageName, category } = this.state
    return (
      <Fragment>
        <HeaderClient />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">
                    Choose Payment Method
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="mt-4 text-14">
            <Row>
              <Col>
                <Card className="mb-4">
                  <CardBody style={{ minHeight: '400px' }}>
                    <Label className="d-block text-20 font-weight-bold">
                      {' '}
                      Transfer Bank
                    </Label>
                    <hr />
                    {this.bankHTML()}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader className="text-16 font-weight-bold">
                    Summary Purchase
                  </CardHeader>
                  <CardBody>
                    {category === 'Buy Package' ? (
                      <Row className="mb-4">
                        <Col className="text-left">
                          <Label className="d-block font-weight-bold">
                            Item
                          </Label>
                          <Label className="d-block">{packageName}</Label>
                        </Col>

                        <Col className="text-left">
                          <Label className="d-block font-weight-bold">
                            Price
                          </Label>
                          <Label className="d-block">{totalPayment}</Label>
                        </Col>

                        <Col className="text-right">
                          <Label className="d-block font-weight-bold">
                            Qyt
                          </Label>
                          <Label className="d-block">{'1'}</Label>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mb-4">
                        <Col className="text-left">
                          <Label className="d-block font-weight-bold">
                            Total Top Up
                          </Label>
                        </Col>

                        <Col className="text-left">
                          <Label className="d-block">{totalPayment}</Label>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col className="text-left">
                        <Label className="d-block font-weight-bold">Tax </Label>
                      </Col>
                      <Col className="text-right">
                        <Label className="d-block">{'-'}</Label>
                      </Col>
                    </Row>
                    <hr />
                    <Label className="d-block font-weight-bold text-16">
                      Total Pembayaran{' '}
                    </Label>
                    <Label className="d-block text-14">{totalPayment}</Label>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {this.modalPaymentGatewayHTML()}
      </Fragment>
    )
  }

  bankHTML = () => {
    const { dataBankTypes } = this.state
    return dataBankTypes.map((item) => (
      <Col
        className="box-shadow p-2 mb-3"
        onClick={() => this.choosePayment(item)}
      >
        <Row>
          <Col>
            <img
              src={config.URL_IMAGE + 'logo_bank/' + item.bank_logo}
              width="60px"
              className="ml-4"
              alt={item.bank_name}
            />
          </Col>
          <Col className="my-auto text-right">
            <FontAwesomeIcon icon={faChevronRight} className="text-24" />
          </Col>
        </Row>
      </Col>
    ))
  }

  handleInput = (e) => this.setState({ [e.target.id]: e.target.value })

  modalPaymentGatewayHTML = () => {
    // console.log(dataPayment)
    const {
      modalPaymentGateway,
      dataPayment,
      customer_email,
      customer_name,
      customer_phone,
      error,
    } = this.state
    return (
      <Modal
        isOpen={modalPaymentGateway}
        toggle={this.toggleModalPaymentGateway}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalPaymentGateway}
        />
        <ModalHeader>
          <h5 className="font-weight-bold">Akun {dataPayment.bank_type}</h5>
        </ModalHeader>
        <ModalBody>
          {!error ? null : this.errorHTML(error)}
          <div className="row mb-1">
            <div className="col-4">Email Akun</div>
            <div className="col">
              <input
                type="email"
                onChange={this.handleInput}
                className="form-control form-control-sm"
                id="customer_email"
                value={customer_email}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-4">Nama Akun</div>
            <div className="col">
              <input
                type="text"
                onChange={this.handleInput}
                className="form-control form-control-sm"
                id="customer_name"
                value={customer_name}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-4">Nomor Telepon Akun</div>
            <div className="col">
              <input
                type="number"
                onChange={this.handleInput}
                className="form-control form-control-sm"
                id="customer_phone"
                value={customer_phone}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={this.prosesPaymentGateway}
          >
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  errorHTML = (error) => (
    <div class="alert alert-danger" role="alert">
      {error}
    </div>
  )
}

export default PaymentMethod
