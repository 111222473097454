import React, { Fragment }      from 'react';
import HeaderClient             from './ComponentClient/HeaderClient';
import imgProfile               from '../Asset/Icon/img-profile.png'

import {    Nav, NavLink,
            NavItem, TabContent, TabPane, 
            Container, Row, Col,
            Label, Button,
            Card, CardBody,
            Modal, ModalBody
        }                       from 'reactstrap';
import classnames               from 'classnames';
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import * as authHelperMethods   from '../Helpers/AuthHelperMethods';
import * as api                 from '../Helpers/Api';
import * as config              from '../Helpers/Config';
import { faTimesCircle }        from '@fortawesome/free-solid-svg-icons';
import * as method              from '../Helpers/Method';
import { Multiselect }          from 'multiselect-react-dropdown';
import Select                   from 'react-select';

class FindTalent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            searchString: '',
            id: 0,//id client
            idEmployee: 0,
            urlCv: "",
            selected: "",
            location:"all",
            education:"all",
            filterEducation:  {
                label:"SMU/SMA",
                value:`SMU/SMA`,
            },
            dataClient: {},
            filterIdJobCategory:[],
            dataJobCategories: [],
            listRegency:[],
            dataEmployee: [],
            dataEmployeeArchived:[],
            dataEmployeeNotArchived:[],
            detailDataEmployee: {},
            dataEducation : [
                {
                    label:"SMU/SMA",
                    value:`SMU/SMA`,
                },
                {
                    label:"Diploma 1",
                    value:"Diploma 1",
                },
                {
                    label:"Diploma 2",
                    value:"Diploma 2",
                },
                {
                    label:"Diploma 3",
                    value:"Diploma 3",
                },
                {
                    label:"Diploma 4",
                    value:"Diploma 4",
                },
                {
                    label:"Sarjana 1",
                    value:"Sarjana 1",
                },
                {
                    label:"Sarjana 2",
                    value:"Sarjana 2",
                },
                {
                    label:"Sarjana 3",
                    value:"Sarjana 3",
                },
            ],
            modalGetResume: false,
            modalProfileCandidate: false,
            isSearchCategory: false,
            jobCategories: []
        };
    }

    componentDidMount = async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getDataClient();
        await this.getJobCategories();
        await this.getAllRegency();
    }

    getAllRegency= async (e) => {
        try {
            const res = await api.getAllRegencies({
                params: { data: JSON.stringify({ key_api: config.KEY_API }) }
            });
            const {status, data} = res;
            if (status === 200) this.setState({
                listRegency: data.dataAllRegencies.map((k) => ({
                    value: k.id,
                    label: k.regency_name
                }))
            })
        } catch(err){
            //   console.log("Error Response :",err)
        }
    }

    getJobCategories = async () => {
        try {
            const res = await api.getJobCategories({
                params: { data: JSON.stringify({ key_api: config.KEY_API }) }
            });
            const { data } = res.data;
            this.setState({
                dataJobCategories: data.map((k) => ({
                    id: k.id,
                    name: `${k.job_category}-${k.job_sub_category}`
                }))
            });
        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = { key_api: config.KEY_API };
        try {
            const res = await api.getDataClient(id,
                {
                    params: {
                        data: JSON.stringify(newData)
                    }
                })
            const { data } = res.data;
            this.setState({
                dataClient: data,
            });
        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    handleRemoveJobCategory = async (e) => {
        await this.getTalentEmployee();
        if (this.state.filterIdJobCategory.length === 0) {
            let filteredEmployee = [];
            this.state.dataEmployee.map((item) =>
                (item['list_id_job_category'] != null) ?
                    e.map((k) => (
                        JSON.parse(item['list_id_job_category']).includes(k.id.toString()) === true) ? filteredEmployee.push(item) : null
                    ) : null
            );
            this.setState({ dataEmployee: filteredEmployee});
        }
    }

    handleSelectJobCategory = async (e) => {
        let jobCategories = [];
        e.forEach(category => jobCategories.push(category.id));
        this.setState({jobCategories});
        await this.getTalentEmployee();
        if (this.state.filterIdJobCategory.length === 0) {
            let filteredEmployee = [];
            this.state.dataEmployee.map((item) => 
                (item['list_id_job_category'] != null) ? 
                    e.map((k) =>
                        (JSON.parse(item['list_id_job_category']).includes(k.id.toString()) === true) ?
                            filteredEmployee.push(item)
                        :null
                    )
                : null        
            )
            const result = filteredEmployee.reduce((unique, o) => {
                if(!unique.some(obj => obj.id === o.id)) {
                  unique.push(o);
                }
                return unique;
            },[]);
            this.setState({
                dataEmployee: result,
                isSearchCategory: true
            });
        }
    }

    getTalentEmployee = async () => {
        const { id } = this.state;
        console.log("getTalentEmployee");
        console.log(id);

        try {
            const newData = {key_api: config.KEY_API}
            const res = await api.getTalentEmployee({params: {data: JSON.stringify(newData)}}, id)
            const { data } = res.data;
            this.setState({ dataEmployee: data.sort((a, b) => (b.total_parttime > a.total_parttime) ? 1 : -1) });

        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    invite = (item) => {
        const newData = { id_employee: item['id'], full_name: item['full_name'] }
        localStorage.setItem("dataEmployee", JSON.stringify(newData));
        window.location.href = config.WEB_URL + "find-talent-candidate/choose-job";
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id });
    }

    getModalResume = async (item) => {
        this.setState({ idEmployee: item.id });
        this.setState(prevState => ({
            modalGetResume: !prevState.modalGetResume
        }));
    }

    getResume = async () => {
        const { id, idEmployee } = this.state;
        const newData = { key_api: config.KEY_API, id_employee: idEmployee }
        try {
            const res = await api.getResume(id,
                {
                    params: {
                        data: JSON.stringify(newData)
                    }
                });
            const { status } = res;
            if (status === 200) {
                window.location.href = config.WEB_URL + "find-talent";
                this.setState(prevState => ({
                    modalGetResume: !prevState.modalGetResume
                }));
            }
        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    closeModalGetResume = async () => {
        this.setState(prevState => ({
            modalGetResume: !prevState.modalGetResume
        }));
    }

    toggleProfileCandidate = async (item) => {
        this.setState({ detailDataEmployee: item });
        this.setState(prevState => ({
            modalProfileCandidate: !prevState.modalProfileCandidate
        }));
    }

    closeProfileCandidate = () => {
        this.setState(prevState => ({
            modalProfileCandidate: !prevState.modalProfileCandidate
        }));
    }

    handleChoose = async (element) => {
        await this.setState({location:element.label});
    }

    // handleChooseEducation = async (element) => {
    //     // await this.getTalentEmployee();
    //     const {dataEmployee} = this.state;
    //     const newDataEmployee = [];
    //     dataEmployee.map((item) =>
    //         item.education_stage === element.label ?
    //             newDataEmployee.push(item) : null
    //     )
    //     this.setState({dataEmployee:newDataEmployee});
    // }

    handleChooseEducation = async (element) => {
        this.setState({filterEducation: {
            value: element.value,
            label: element.label
        }})
        await this.getTalentEmployee();
        // const {dataEmployee} = this.state;
        // const newDataEmployee = [];
        // dataEmployee.map((item) =>
        //     item.education_stage === element.label ?
        //         newDataEmployee.push(item) : null
        // );
        // const result = newDataEmployee.reduce((unique, o) => {
        //     if (!unique.some(obj => obj.id === o.id)) {
        //         unique.push(o);
        //     }
        //     return unique;
        // }, []);
        // console.log("hasil filter");
        // console.log(result)
        // this.setState({
        //     dataEmployee: result,
        //     isSearchCategory: true
        // });
    }

    isSearchCategoryHTML = (listRegency, dataEducation) => <div>
        <Col className="mb-3">
            <Label className="d-block text-14 font-weight-bolder">Location</Label>
            <Select name="form-field-name" options={listRegency} onChange={this.handleChoose.bind(this)} />
        </Col>
        <Col className="mb-3">
            <Label className="d-block text-14 font-weight-bolder">Edukasi</Label>
            <Select name="form-field-name" value={this.state.filterEducation} options={dataEducation} onChange={this.handleChooseEducation.bind(this)} />
        </Col>
    </div>;

    notFoundHTML = <p className="my-auto font-weight-bold"> <center> Data Not Found</center></p>;

    employeArchivedHTML = dataEmployeeArchived => dataEmployeeArchived.map(item => <Row className="mb-4">
        <Col lg="3" className="text-center">
            <img src={item.profile_image === null ? imgProfile : config.URL_IMAGE_EMPLOYEE + "image_profile_employee/" + item.profile_image} alt="" className="rounded-circle" width="130px" height="130px" />
        </Col>
        <Col>
            <p className="text-16 font-weight-bolder text-capitalize">{method.fixId(item.id)}</p>
            <Row>
                <Col>
                    <p className="mb-1 text-14">{item.phone_number}</p>
                    <p className="mb-1 text-14">{item.gender}</p>
                    <p className="mb-1 text-14">{(item.education_stage == null)? '-' : `${item.education_stage}-${item.major}`}</p>
                    <p className="mb-1 text-14">{(item.regency_name === null)? '-' : item.regency_name}</p>
                </Col>
                <Col><Button className="btn-block btn-info mb-1" onClick={() => this.toggleProfileCandidate(item)} >See Profile</Button></Col>
                <Col><Button onClick={() => this.invite(item)} className="btn-block btn-blue-normal">Invite</Button></Col>
            </Row>
        </Col>
    </Row>);

    employeNotArchivedHTML = dataEmployeeNotArchived => dataEmployeeNotArchived.map((item) => <Row className="mb-4">
        <Col lg="3" className="text-center">
            <img src={(item.profile_image === null)? imgProfile : config.URL_IMAGE_EMPLOYEE + "image_profile_employee/" + item.profile_image} alt="" className="rounded-circle" width="130px" height="130px" />
        </Col>
        <Col>
            <p className="text-16 font-weight-bolder text-capitalize">{method.fixId(item.id)}</p>
            <Row>
                <Col>
                    <p className="mb-1 text-14">{(item.education_stage == null)? '-' : `${item.education_stage}-${item.major}`}</p>
                    <p className="mb-1 text-14">{item.regency_name}</p>
                </Col>
                <Col sm="6" md="5" lg="4">
                    <Button className="btn-block btn-info mb-1" onClick={() => this.toggleProfileCandidate(item)}>See Profile</Button>
                </Col>
                <Col sm="6" md="5" lg="4">
                    <Button onClick={() => this.getModalResume(item)} className="btn-block btn-success">Get Resume</Button>
                </Col>
            </Row>
        </Col>
    </Row>);

    modalResumeHTML = (modalGetResume, dataClient) => <Modal isOpen={modalGetResume} toggle={this.closeModalGetResume} backdrop="static">
        <ModalBody>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeModalGetResume} />
            <h5 className="text-center mb-5">Set Schedule Interview</h5>
            <Row>
                <Col lg="10">
                    <Label className="font-weight-bold d-block">Balance Talent Search</Label>
                </Col>
                <Col lg="2">
                    <Label className="mb-1">{dataClient.talent_search_quota}</Label>
                </Col>
            </Row>
            <Row>
                <Col lg="10">
                </Col>
                <Col lg="2">
                    <Label style={{ color: 'red' }} className="mb-1">-1</Label>
                </Col>
            </Row>
            <Row>
                <Col lg="10">
                    <Label className="font-weight-bold d-block">Update Balance Talent Search</Label>
                </Col>
                <Col lg="2">
                    <Label className="mb-1">{dataClient.talent_search_quota - 1}</Label>
                </Col>
            </Row>
            <hr />
            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.getResume}>Get Resume</Button></Col>
        </ModalBody>
    </Modal>

    modalProfilHTML = detailDataEmployee => <Modal isOpen={this.state.modalProfileCandidate} backdrop="static">
        <ModalBody className="text-center">
            <div style={{ height: '430px', overflowX: 'scroll' }} className="mb-4">
                <Col className="border-bottom pl-0 pr-0">
                    <Col>
                        <Row>
                            <Col lg="auto" className="text-center">
                                <img src={detailDataEmployee.profile_image === null ? imgProfile : config.URL_IMAGE_EMPLOYEE + "image_profile_employee/" + detailDataEmployee.profile_image} alt="" className="border border-info rounded-circle p-1 mb-4" width="120px" />
                            </Col>
                            <Col className="text-14 my-auto">
                                <p className="font-weight-bold mb-0 text-left">Id : {method.fixId(detailDataEmployee.id)}</p>
                                <p className="font-weight-bold mb-0 text-left">Name : {detailDataEmployee.is_archived === 1 ? detailDataEmployee.full_name : "-"}</p>
                                <p className="font-weight-bold mb-0 text-left">Email : {detailDataEmployee.is_archived === 1 ? detailDataEmployee.email : "-"}</p>
                                <p className="font-weight-bold mb-0 text-left">No Hp : {detailDataEmployee.is_archived === 1 ? detailDataEmployee.phone_number : "-"}</p>
                                <Row>
                                    <Col lg="auto">
                                        <Label className="text-green mb-0"></Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <Col className="text-14 mt-3">
                    <h5 className="font-weight-bolder mb-0 text-left">Personal Info</h5>
                    <hr />
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Birth Date</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.birth_date === null ? "-" : method.formatDateDay(detailDataEmployee.birth_date)}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Birth Place</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.birth_place}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Gender</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.gender}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Id Card</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.id_card_number}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Religion</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.religion}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Body Height</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.height === null ? "-" : detailDataEmployee.height + " cm"}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Body Weight</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.weight === null ? "-" : detailDataEmployee.weight + " Kg"}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Id Card Address</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.is_archived === 1 ? detailDataEmployee.id_card_address : "-"}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Domicile Address</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.is_archived === 1 ? detailDataEmployee.domicile_address : "-"}</p></Col>
                    </Row>
                    <hr className="mt-1" />
                </Col>
                <Col className="text-14 mt-3">
                    <h5 className="font-weight-bolder mb-0 text-left">Education</h5>
                    <hr />
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Last Education</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.education_stage === null ? "-" : detailDataEmployee.education_stage}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Name of Institution</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.institution_name === null ? "-" : detailDataEmployee.institution_name}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Major</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.major === null ? "-" : detailDataEmployee.major}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Graduate Year</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.graduate_year === null ? "-" : detailDataEmployee.graduate_year}</p></Col>
                    </Row>
                    <Row>
                        <Col><p className="text-12 font-weight-bold text-left">Score GPA</p></Col>
                        <Col><p className="text-12 text-right">{detailDataEmployee.score === null ? "-" : detailDataEmployee.score}</p></Col>
                    </Row>
                    <hr className="mt-1" />
                </Col>
                <Col className="text-14 mt-3">
                    <h5 className="font-weight-bolder mb-0 text-left">Expertise</h5>
                    <hr />
                    {
                        ((detailDataEmployee.skills === null) || (detailDataEmployee.skills === undefined)) ? "-"
                            :
                            detailDataEmployee.skills.map((item) => {
                                return (
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold text-left">{item.skill_name}</p></Col>
                                        <Col className="text-right">
                                            {/* <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/> */}
                                            {item.skill_rate}
                                        </Col>
                                    </Row>
                                );
                            }
                            )
                    }
                    <hr className="mt-1" />
                </Col>
                <Col className="border-bottom text-14 mt-3">
                    <h5 className="font-weight-bolder mb-0 text-left">Experience</h5>
                    <hr />
                    {
                        ((detailDataEmployee.work_histories === null) || (detailDataEmployee.work_histories === undefined)) ? "-"
                            :
                            detailDataEmployee.work_histories.map((item) => {
                                return (
                                    <Row>
                                        <Col md="4"><p className="text-12 font-weight-bold">{item.position}</p></Col>
                                        <Col md="5"><p className="text-12 text-justify">{item.company_name}</p></Col>
                                        <Col md="3"><p className="text-12 text-right">{item.working_period}</p></Col>
                                    </Row>
                                );
                            }
                            )
                    }
                </Col>
            </div>
            <Col md="5" className="mx-auto">
                <Button onClick={() => this.closeProfileCandidate()} className="btn-warning btn-block ">Close</Button>
            </Col>
        </ModalBody>
    </Modal>

    filterEmployee = () => {
        const { dataEmployee, location, jobCategories, filterEducation } = this.state;
        const dataEmployeeArchived = [];
        const dataEmployeeNotArchived = [];
        console.log(filterEducation.label)
        console.log(jobCategories)

        dataEmployee.forEach(item => {
            if(filterEducation.label === item.education_stage){
                const list_id_job_category = JSON.parse(item.list_id_job_category);

                let adaJob = 0;
                jobCategories.forEach(element => {
                    if (list_id_job_category.includes(`${element}`)) adaJob += 1;
                });
                if (adaJob > 0) {
                    if ((item.is_archived === 1)) {
                        console.log("archived")
                        if (location === 'all') {
                            dataEmployeeArchived.push(item)
                        } else {
                            if (item.regency_name === location) {
                                dataEmployeeArchived.push(item)
                            }
                        }
                    } else {
                        console.log("no archived")
                        if (location === 'all') {
                            dataEmployeeNotArchived.push(item)
                        } else {
                            if (item.regency_name === location) {
                                dataEmployeeNotArchived.push(item)
                            }
                        }
                    }
                }
            }
           
        });
        return {dataEmployeeArchived, dataEmployeeNotArchived};
    }

    render() {
        const { isSearchCategory, dataEducation, listRegency, dataJobCategories, modalGetResume, dataClient, detailDataEmployee} = this.state;
        const {dataEmployeeArchived, dataEmployeeNotArchived} = this.filterEmployee();
        return (<Fragment>
            <HeaderClient />
            <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Find Talent</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <Container className="pt-4">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="4">
                                        <Col className="bg-white rounded box-shadow mb-3 p-3">
                                            <Col className="mb-3">
                                                <Label className="d-block text-14 font-weight-bolder">Search</Label>
                                                <Multiselect options={dataJobCategories} selectedValues={this.state.selectedValue} onSelect={(e)=>this.handleSelectJobCategory(e)} onRemove={(e)=>this.handleRemoveJobCategory(e)} displayValue="name" placeholder="Search job categories"/>
                                            </Col>
                                            {(isSearchCategory === true) ? this.isSearchCategoryHTML(listRegency, dataEducation) : null}
                                        </Col>
                                    </Col>
                                    <Col lg="8">
                                        <Nav tabs>
                                            <Fragment>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === '0' })} onClick={(e) => { this.toggle('0'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Available</p> 
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={(e) => { this.toggle('1'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Get Resume</p> 
                                                    </NavLink>
                                                </NavItem>
                                            </Fragment>
                                        </Nav>
                                        <Col className="box-shadow p-3 mb-3">
                                            <TabContent activeTab={this.state.activeTab} className="text-12">
                                                <TabPane tabId="0" className="bg-white">
                                                    {(dataEmployeeArchived.length > 0)? this.employeArchivedHTML(dataEmployeeArchived) : this.notFoundHTML}
                                                </TabPane>
                                                <TabPane tabId="1" className="bg-white">
                                                    {(dataEmployeeNotArchived.length > 0)? this.employeNotArchivedHTML(dataEmployeeNotArchived) : this.notFoundHTML}
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                    {this.modalResumeHTML(modalGetResume, dataClient)}
                    {this.modalProfilHTML(detailDataEmployee)}
                </div>
            </div>
        </Fragment>);
    }
}

export default FindTalent;