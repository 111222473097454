// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderUser                                           from './ComponentUser/HeaderUser';

import classnames from 'classnames';
import noData                                       from '../Asset/Icon/no-data.svg';

import Wallet                                               from '../Asset/Icon/wallet.svg';

// Styling
import { Container, Row, Col, TabContent, TabPane, 
         Label, Nav, NavItem, NavLink, Button, Card, CardBody, 
    Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';      
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as method from '../Helpers/Method';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
         
class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            financeBalance: 0,
            listInboxMessage: [],
            histories: [],
            modalDetailHistory: false,
            detailHistory: null,
            totalWd: 0,
            transactions: [],
            idWdTransactions: [],
            modalWD: false
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount = async () =>{
        await this.getDataEmployee();
        await this.getInboxMessages();
        await this.getTransactions();
        await this.getHistories();
    }

    getTransactions = async () => {
        const idEmployee = authHelperMethods.getIdEmployee();
        const res = await api.getTransactions(idEmployee, {
            params: { data: JSON.stringify({key_api: config.KEY_API}) }
        });
        this.setState({transactions: res.data.transactions});
    }

    getHistories = async () => {
        const newData = { key_api: config.KEY_API };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.getWithdrawHistories(idEmployee, {
                params: { data: JSON.stringify(newData) }
            });
            const { data } = res;
            this.setState({
                histories: data.withdraws
            });
            console.log(this.state);
        } catch (error) {
            
        }
    }

    getDataEmployee = async (e) => {
        const newData = { key_api: config.KEY_API };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.profileEmployee(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({
                    financeBalance: data.dataProfile.total_salary_this_month
                })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    getInboxMessages = async (e) => {
        const newData = { key_api: config.KEY_API };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.allEmployeeInbox(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({
                    listInboxMessage: data.listEmployeeInbox
                })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }
    
    dataKosongHTML = <center>
        <img src={noData} width="350px" className="mx-auto" style={{marginBottom:'-50px'}} alt="Data Kosong"/>
        <Label className="d-block text-16">Data Empty</Label>
    </center>

    draftJobHTML = dataDraftJob => {
        return dataDraftJob.map((item) => <Col className="box-shadow p-3 mb-3">
            <Row>
                <Col>
                    <p className="text-16 font-weight-bolder text-capitalize">{item.job_category} ({item.work_type})</p>
                    <Row>
                        <Col>
                            <p className="mb-1 text-14">{item.quota} People</p>
                            <p className="mb-1 text-14">{item.work_location}</p>
                            <p className="mb-0 text-14">{item.job_position}</p>
                        </Col>
                        <Col>
                            <p className="mb-1 text-14">{method.formatRupiah(item.min_salary)} - {method.formatRupiah(item.max_salary)}</p>
                            <p className="mb-1 text-14">{item.min_experience_year} Year Experience</p>
                            <p className="mb-0 text-14">{item.work_type === "Part-Time" ? "Work Time " + method.formatDateAndTime(item.start_part_time_date) + " s/d " + method.formatDateTime(item.finish_part_time_date) : "Deadline " + method.formatDateDay(item.deadline_job_vacancy)}</p>
                            <p className="mb-0 text-14">Waktu Posting {item.posting_time}</p>
                        </Col>
                        <Col>
                            <Button className="btn-block btn-info text-white" onClick={() => this.editDraft(item)}>Edit</Button>
                            <Button className="btn-block btn-warning text-white" onClick={() => this.toggleDetailFullTime(item)}>Detail</Button>
                            <Button className="btn-block btn-success text-white" onClick={() => this.togglePublishJob(item)}>Publish Job</Button>
                            <Button className="btn-block btn-danger text-white" onClick={() => this.toggleDeleteFullTimeOrDraft(item, "Draft")}>Delete</Button>
                        </Col>
                    </Row>
                    {(item.is_invite_candidate === 1) ? <p className="mb-0 text-14" style={{ color: "green" }}>Publish otomatis pada {method.formatDateAndTimeForPublishJob(item.inviting_candidate_date)}</p> : null}
                </Col>
            </Row>
        </Col>);
    }

    status = status => {
        let hasil = '';
        if (status === 0) {
            hasil = 'Pending';
        } else if (status === 1) {
            hasil = 'Success';
        } else {
            hasil = 'Failed';
        }
        return hasil;
    }

    toggleModalDetailHistory = () => {
        this.setState(prevState => ({
            modalDetailHistory: !prevState.modalDetailHistory,
        }));
    }
    
    isiDetailHistory = detailHistory => {
        const { history, details } = detailHistory;
        return (<div>
            <table cellPadding={4}>
                <tr>
                    <th>Status</th>
                    <td>: {this.status(history.status)}</td>
                </tr>
                <tr>
                    <th>Created At</th>
                    <td>: {history.created_at}</td>
                </tr>
            </table>
            <div className='table-responsive mt-2'>
                <table className='table table-hover table-bordered' style={{width: '100%'}}>
                    <thead className='thead-dark'>
                        <tr className='text-center'>
                            <th>No</th>
                            <th>Client</th>
                            <th>Job</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(details.length > 0) ? this.historyDetails(details) : <tr>
                            <td colSpan={4} className='text-center'>~ Empty~</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>);
    }

    historyDetails = details => details.map((detail, i) => <tr>
        <td className='text-center'>{i + 1}</td>
        <td>{detail.company_name}</td>
        <td>{detail.job_category} - {detail.job_sub_category}</td>
        <td className='text-right'>{detail.nominal}</td>
    </tr>);

    modalDetailHistoryHTML = () => {
        const { modalDetailHistory, detailHistory } = this.state;
        return (<Modal isOpen={modalDetailHistory} toggle={this.toggleModalDetailHistory} backdrop="static">
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalDetailHistory} />
            <ModalBody>
                {(detailHistory)? this.isiDetailHistory(detailHistory) : null}
            </ModalBody>
        </Modal>);
    }

    detailHistory = async (history) => {
        const res = await api.getWithdrawDetails(history.id, {
            params: {data: JSON.stringify({key_api: config.KEY_API}) }
        });
        const { details } = res.data;
        this.setState(prevState => ({
            modalDetailHistory: !prevState.modalDetailHistory,
            detailHistory: {history, details}
        }));
    }

    historiesHTML = histories => histories.map(history => <Col className="box-shadow p-3 mb-3">
        <Row>
            <Col>
                <table className='text-14' cellPadding={4}>
                    <tr>
                        <th>Amount</th>
                        <td>: {history.jumlah}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>: {this.status(history.status)}</td>
                    </tr>
                    <tr>
                        <th>Created At</th>
                        <td>: {history.created_at}</td>
                    </tr>
                    {(!history.confirm_at)? null : <tr>
                        <th>Confirm At</th>
                        <td>: {history.confirm_at}</td>
                    </tr>}
                </table>
            </Col>
            <Col className="col-3">
                <Button className="btn-block btn-warning text-white" onClick={() => this.detailHistory(history)}>Detail</Button>
            </Col>
        </Row>
    </Col>);


    render() {
        const { histories } = this.state;
        return (<Fragment>
            <HeaderUser />
            <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Withdraw</h5>
                            </Col>
                            <Col className="my-auto text-right">
                                <img src={Wallet} alt="wallet-avisha" width="30px" className="mr-2" />
                                <p className="d-inline-flex font-weight-bolder mb-0">Balance : {this.state.financeBalance == null ? "Rp.0" : method.formatRupiah(this.state.financeBalance)}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div fluid className="pb-5 muli-font bg-body-grey">
                    <Container className="pt-4">
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '0' })}
                                            onClick={(e) => { this.toggle('0'); }} style={{ borderRadius: '12px 12px 0 0' }}><p className="mb-0 font-weight-bold text-14">Withdraw</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={(e) => { this.toggle('1'); }} style={{ borderRadius: '12px 12px 0 0' }}><p className="mb-0 font-weight-bold text-14">Histories</p>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} className="border-left border-right border-bottom text-12">
                                    <TabPane tabId="0" className="bg-white p-3" style={{ minHeight: '470px' }}>{this.withdrawBaruHTML()}
                                    </TabPane>
                                    <TabPane tabId="1" className="bg-white p-3" style={{ minHeight: '470px' }}>
                                        {(histories.length > 0) ? this.historiesHTML(histories) : this.dataKosongHTML}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </div>
            {this.modalDetailHistoryHTML()}
            {this.modalWDHTML()}
        </Fragment>);
    }

    cekAll = e => {
        let total = 0;
        let isCekAll = e.target.checked;
        let idWdTransactions = [];
        document.querySelectorAll('.cek').forEach(cek => {
            cek.checked = isCekAll;
            if (isCekAll == true) {
                total += parseInt(cek.dataset.nominal);
                idWdTransactions.push({
                    id: cek.value,
                    nominal: cek.dataset.nominal
                });
            }
        });
        this.setState({
            totalWd: (isCekAll == true) ? this.state.totalWd + total : 0,
            idWdTransactions
        });
    }

    cek = e => {
        let { totalWd } = this.state;
        let checked = e.target.checked;
        const nominal = parseInt(e.target.dataset.nominal);
        const total = (!checked) ? totalWd - nominal : totalWd + nominal;
        let idWdTransactions = [];
        document.querySelectorAll('.cek').forEach(cek => {
            if (cek.checked) idWdTransactions.push({
                id: cek.value,
                nominal: cek.dataset.nominal
            });
        });
        this.setState({ idWdTransactions, totalWd: total });
    }

    body = transactions => transactions.map(transaction => <tr>
        <td className='text-center'>
            <input type='checkbox' className='cek' onChange={this.cek} data-nominal={transaction.income_fee} value={transaction.id} />
        </td>
        <td>{transaction.company_name}</td>
        <td>{transaction.job_category} - {transaction.job_sub_category}</td>
        <td className='text-right'>{transaction.income_fee}</td>
    </tr>);

    withdrawBaruHTML = () => {
        const { totalWd, transactions, idWdTransactions } = this.state;
        return (<div>
            <div className="row mb-2">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-3"><label htmlFor="total" className='form-label font-weight-bold text-14'>Total</label></div>
                        <div className="col"><input type="number" disabled className='form-control form-control-sm' id='total' value={totalWd} /></div>
                    </div>
                </div>
            </div>
            <button class="btn btn-success btn-sm" onClick={this.toggleModalWD} disabled={(idWdTransactions.length > 0)? false : true}>
                <FontAwesomeIcon icon={faDollarSign}/> Withdraw
            </button>
            <div className="table-responsive mt-2">
                <table className="table table-hover table-bordered" id="tableWithdraw">
                    <thead className="thead-dark">
                        <tr className="text-center">
                            <th style={{width: '5%'}}><input type="checkbox" onChange={this.cekAll}/></th>
                            <th>Client</th>
                            <th>Job</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(transactions.length > 0) ? this.body(transactions) : <tr>
                            <td colSpan={4} className="text-center">~ Empty Data ~</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>);
    }

    toggleModalWD = () => {
        this.setState(prevState => ({
            modalWD: !prevState.modalWD,
        }));
    }

    modalWDHTML = () => {
        const { modalWD } = this.state;
        return (<Modal isOpen={modalWD} toggle={this.toggleModalWD} backdrop="static">
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalWD} />
            <ModalHeader>
                <h5 className='font-weight-bold'>Withdraw</h5>
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to withdraw your balance?</p>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-primary' onClick={this.wd}>Confirm</button>
            </ModalFooter>
        </Modal>);
    }

    wd = async () => {
        const { idWdTransactions } = this.state;
        const idEmployee = authHelperMethods.getIdEmployee();
        const res = await api.withdraw({
            params: {
                data: JSON.stringify({
                    key_api: config.KEY_API,
                    idWdTransactions,
                    idEmployee
                })
            }
        });
        const { status } = res;
        if (status == 200) window.location.reload();
    }
};

export default Withdraw;