import React, { Fragment }              from 'react';
import HeaderClient                     from './ComponentClient/HeaderClient';
import * as authHelperMethods           from  '../Helpers/AuthHelperMethods';
import classnames                       from 'classnames';
import * as api                         from '../Helpers/Api'
import * as config                      from '../Helpers/Config'
import {FontAwesomeIcon}                from '@fortawesome/react-fontawesome'
import {faTimesCircle}                  from '@fortawesome/free-solid-svg-icons'
import {
    Container, Row, Col, Nav, NavItem,
    NavLink, Modal, ModalHeader, ModalBody,
    ModalFooter,
}                                       from 'reactstrap'

class PaymentHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:                     0,
            activeTab:              0,
            jabatans:               [],
            leaves:                 [],
            modalAddPosition:       false,
            modalEditPosition:      false,
            modalDeletePosition:    false,
            modalAddLeave:          false,
            modalEditLeave:         false,
            modalDeleteLeave:       false,
            selectedPosition:       null,
            selectedLeave:          null,
            idPosition:             '',
            namaPosition:           '',
            idLeave:                '',
            namaLeave:              '',
            alert:                  false,
            alertWarna:             '',
            alertTeks:              ''
        };
        this.dataParam = {params: {data: JSON.stringify({ key_api: config.KEY_API })}};
    };

    generateParam = param => {
        param.key_api = config.KEY_API;
        return {params: {data: JSON.stringify(param)}};
    }

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getAllPositions();
        await this.getAllLeaves();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient()
        this.setState({ id: id })
    }

    ubahNilai = e => {
        const data = {};
        data[e.target.id] = e.target.value;
        this.setState(data)
    }

    resetNilai = () => this.setState({
        idPosition:     '',
        namaPosition:   '',
        idLeave:        '',
        namaLeave:      '',
    })

    getAllPositions = async() => {
        const {id} = this.state;
        const res = await api.getAllPositions(id, this.dataParam)
        const {data} = res.data;
        this.setState({jabatans: data});
    }

    getAllLeaves = async() => {
        const {id} = this.state;
        const res = await api.getAllLeaves(id, this.dataParam)
        const {data} = res.data;
        this.setState({leaves: data});
    }

    toggle = tab => {
        const {activeTab} = this.state
        if (activeTab !== tab) this.setState({ activeTab: tab })
    }

    toggleModalAddPosition = async() => {
        await this.setState((prevState) => ({modalAddPosition: !prevState.modalAddPosition}));
        const {modalAddPosition} = this.state;
        if(!modalAddPosition) this.resetNilai();
    }

    toggleModalEditPosition = async(data = null) => {
        await this.setState((prevState) => ({modalEditPosition: !prevState.modalEditPosition}));
        const {modalEditPosition} = this.state;
        if(!modalEditPosition) {
            this.resetNilai();
        } else {
            await this.setState({
                idPosition: data.id,
                namaPosition: data.jabatan
            });
        }
    }

    toggleModalDeletePosition = async(data = null) => {
        await this.setState((prevState) => ({modalDeletePosition: !prevState.modalDeletePosition}));
        const {modalDeletePosition} = this.state;
        if(!modalDeletePosition) {
            this.resetNilai();
        } else {
            await this.setState({idPosition: data.id});
        }
    }

    toggleModalAddLeave = async() => {
        await this.setState((prevState) => ({modalAddLeave: !prevState.modalAddLeave}));
        const {modalAddLeave} = this.state;
        if(!modalAddLeave) this.resetNilai();
    }

    toggleModalEditLeave = async(data = null) => {
        await this.setState((prevState) => ({modalEditLeave: !prevState.modalEditLeave}));
        const {modalEditLeave} = this.state;
        if(!modalEditLeave) {
            this.resetNilai();
        } else {
            await this.setState({
                idLeave: data.id,
                namaLeave: data.cuti_khusus
            });
        }
    }

    toggleModalDeleteLeave = async(data = null) => {
        await this.setState((prevState) => ({modalDeleteLeave: !prevState.modalDeleteLeave}));
        const {modalDeleteLeave} = this.state;
        if(!modalDeleteLeave) {
            this.resetNilai();
        } else {
            await this.setState({idLeave: data.id});
        }
    }

    toggleAlert = () => this.setState((prevState) => ({alert: !prevState.alert}));

    renderAlert = res => {
        let state;
        if(res.data.error) {
            state = {
                alert: 1,
                alertWarna: 'danger',
                alertTeks: res.data.message
            };
        } else {
            state = {
                alert: 2,
                alertWarna: 'success',
                alertTeks: res.data.message
            };
        }
        this.setState(state);
    }

    addPosition = async() => {
        const {id, namaPosition} = this.state;
        const param = this.generateParam({namaPosition, id_client_company: id});
        const res = await api.addPosition(param);
        this.renderAlert(res);
        await this.getAllPositions();
        this.toggleModalAddPosition();
    }

    editPosition = async() => {
        const {idPosition, namaPosition} = this.state;
        const param = this.generateParam({idPosition, namaPosition});
        const res = await api.editPosition(param);
        this.renderAlert(res);
        await this.getAllPositions();
        this.toggleModalEditPosition();
    }
    
    deletePosition = async() => {
        const {idPosition} = this.state;
        const param = this.generateParam({idPosition});
        const res = await api.deletePosition(param);
        this.renderAlert(res);
        await this.getAllPositions();
        this.toggleModalDeletePosition();
    }

    addLeave = async() => {
        const {id, namaLeave} = this.state;
        const param = this.generateParam({namaLeave, id_client_company: id});
        const res = await api.addLeave(param);
        this.renderAlert(res);
        await this.getAllLeaves();
        this.toggleModalAddLeave();
    }

    editLeave = async() => {
        const {idLeave, namaLeave} = this.state;
        const param = this.generateParam({idLeave, namaLeave});
        const res = await api.editLeave(param);
        this.renderAlert(res);
        await this.getAllLeaves();
        this.toggleModalEditLeave();
    }
    
    deleteLeave = async() => {
        const {idLeave} = this.state;
        const param = this.generateParam({idLeave});
        const res = await api.deleteLeave(param);
        this.renderAlert(res);
        await this.getAllLeaves();
        this.toggleModalDeleteLeave();
    }

    modalAddPositionHTML = () => {
        const {modalAddPosition, namaPosition} = this.state;
        return this.formHTML({
            isOpen: modalAddPosition,
            toggle: this.toggleModalAddPosition,
            title: 'Add Position',
            idForm: 'namaPosition',
            label: "Position's Name",
            value: namaPosition,
            onClick: this.addPosition
        });
    }

    modalEditPositionHTML = () => {
        const {modalEditPosition, namaPosition} = this.state;
        return this.formHTML({
            isOpen: modalEditPosition,
            toggle: this.toggleModalEditPosition,
            title: 'Edit Position',
            idForm: 'namaPosition',
            label: "Position's Name",
            value: namaPosition,
            onClick: this.editPosition
        });
    }

    modalDeletePositionHTML = () => {
        const {modalDeletePosition, namaPosition} = this.state;
        return this.deleteHTML({
            isOpen: modalDeletePosition,
            toggle: this.toggleModalDeletePosition,
            title: 'Delete Position',
            label: `Do you really want to delete ${namaPosition}?`,
            onClick: this.deletePosition
        });
    }

    modalAddLeaveHTML = () => {
        const {modalAddLeave, namaLeave} = this.state;
        return this.formHTML({
            isOpen: modalAddLeave,
            toggle: this.toggleModalAddLeave,
            title: 'Add Leave',
            idForm: 'namaLeave',
            label: "Leave's Name",
            value: namaLeave,
            onClick: this.addLeave
        });
    }

    modalEditLeaveHTML = () => {
        const {modalEditLeave, namaLeave} = this.state;
        return this.formHTML({
            isOpen: modalEditLeave,
            toggle: this.toggleModalEditLeave,
            title: 'Edit Leave',
            idForm: 'namaLeave',
            label: "Leave's Name",
            value: namaLeave,
            onClick: this.editLeave
        });
    }

    modalDeleteLeaveHTML = () => {
        const {modalDeleteLeave, namaLeave} = this.state;
        return this.deleteHTML({
            isOpen: modalDeleteLeave,
            toggle: this.toggleModalDeleteLeave,
            title: 'Delete Leave',
            label: `Do you really want to delete ${namaLeave}?`,
            onClick: this.deleteLeave
        });
    }

    alertHTML = () => {
        const {alert, alertWarna, alertTeks} = this.state;
        return <div className={`alert alert-${alertWarna} alert-dismissible fade show ${(alert == 1)? '' : 'my-2 mx-2'}`} role="alert">
            {alertTeks}<button onClick={this.toggleAlert} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>;
    }

    formHTML = ({isOpen, toggle, title, idForm, label, value, onClick}) => {
        const {alert} = this.state;
        return <Modal isOpen={isOpen} toggle={toggle}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={toggle}/>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {(!alert)? null : ((alert == 2)? null : this.alertHTML())}
                <label htmlFor={idForm}>{label}</label>
                <input id={idForm} className="form-control form-control-sm" value={value} onChange={this.ubahNilai}/>
            </ModalBody>
            <ModalFooter><button className="btn btn-primary" onClick={onClick}>Confirm</button></ModalFooter>
        </Modal>;
    }

    deleteHTML = ({isOpen, toggle, title, label, onClick}) => {
        const {alert} = this.state;
        return <Modal isOpen={isOpen} toggle={toggle}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={toggle}/>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {(!alert)? null : ((alert == 2)? null : this.alertHTML())}
                {label}
            </ModalBody>
            <ModalFooter><button className="btn btn-primary" onClick={onClick}>Confirm</button></ModalFooter>
        </Modal>;
    }

    tabelJabatanHTML = jabatans => <div>
        <button className="btn btn-primary btn-sm mb-2 mt-2" onClick={this.toggleModalAddPosition}>Add Position</button>
        <div className="table-responsive">
            <table className="table table-hover table-bordered table-sm" id="tableUtama" style={{ width: '100%' }}>
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th style={{ width: '5%' }}>No</th>
                        <th>Position Name</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {(jabatans.length == 0)? this.trKosong(3) : (jabatans.map((jabatan, i) => <tr key={i}>
                        <td style={{textAlign: 'center'}}>{i+1}</td>
                        <td>{jabatan.jabatan}</td>
                        <td style={{textAlign: 'center'}}>
                            <button type="button" className="btn btn-warning btn-sm" onClick={() => this.toggleModalEditPosition(jabatan)}>Edit</button>&nbsp;
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.toggleModalDeletePosition(jabatan)}>Delete</button>
                        </td>
                    </tr>))}
                </tbody>
            </table>
        </div>
    </div>;

    tabelLeaveHTML = leaves => <div>
        <button className="btn btn-primary btn-sm mb-2 mt-2" onClick={e => this.toggleModalAddLeave('add')}>Add leave</button>
        <div className="table-responsive">
            <table className="table table-hover table-bordered table-sm" id="tableUtama" style={{ width: '100%' }}>
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th style={{ width: '5%' }}>No</th>
                        <th>Leave Name</th>
                        <th style={{ width: '15%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {(leaves.length == 0)? this.trKosong(3) : (leaves.map((leave, i) => <tr key={i}>
                        <td style={{textAlign: 'center'}}>{i+1}</td>
                        <td>{leave.cuti_khusus}</td>
                        <td style={{textAlign: 'center'}}>
                            <button type="button" className="btn btn-warning btn-sm" onClick={() => this.toggleModalEditLeave(leave)}>Edit</button>&nbsp;
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => this.toggleModalDeleteLeave(leave)}>Delete</button>
                        </td>
                    </tr>))}
                </tbody>
            </table>
        </div>
    </div>;

    trKosong = colspan => <tr><td colSpan={colspan} className="text-center text-secondary">~ Data not found ~</td></tr>;

    renderTab = (activeTab) => {
        const {jabatans, leaves} = this.state;
        let html;
        if (activeTab == 0) {
            html = this.tabelJabatanHTML(jabatans);
        } else if (activeTab == 1) {
            html = this.tabelLeaveHTML(leaves);
        } else {
            html = null;
        }
        return html;
    }

    bodyHTML = activeTab => <div>
        <Nav tabs>
            <Fragment>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 0 })} onClick={(e) => {this.toggle(0)}} style={{ borderRadius: '10px 10px 0 0' }}>
                        <p className="mb-0 font-weight-bold text-14">Positions</p>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 1 })} onClick={(e) => {this.toggle(1)}} style={{ borderRadius: '10px 10px 0 0' }}>
                        <p className="mb-0 font-weight-bold text-14">Leaves</p>
                    </NavLink>
                </NavItem>
            </Fragment>
        </Nav>
        <div>{this.renderTab(activeTab)}</div>
    </div>;

    render() {
        const {activeTab, alert} = this.state;
        return <Fragment>                        
            <HeaderClient/>
            <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Master</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="container mt-4">
                        <div className="card">
                            {(!alert)? null : ((alert == 1)? null : this.alertHTML())}
                            <div className="card-body">{this.bodyHTML(activeTab)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {this.modalAddPositionHTML()}
            {this.modalEditPositionHTML()}
            {this.modalDeletePositionHTML()}
            {this.modalAddLeaveHTML()}
            {this.modalEditLeaveHTML()}
            {this.modalDeleteLeaveHTML()}
        </Fragment>;
    }
};

export default PaymentHistory;