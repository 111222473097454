import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import NumberFormat                                         from 'react-number-format';
import * as method                                          from '../Helpers/Method';
// import { DownloadTableExcel }                               from 'react-export-table-to-excel';
import DatePicker                                           from 'react-datepicker';
import { registerLocale, setDefaultLocale }                 from 'react-datepicker';
import id                                                   from 'date-fns/locale/id';
import makeAnimated from 'react-select/animated';
import {FontAwesomeIcon}                                    from '@fortawesome/react-fontawesome'
import {faTimesCircle}                                      from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import '../Css/style.css';
import {
    Container, Row, Col, Nav, NavItem,
    NavLink, Modal, ModalHeader, ModalBody,
    ModalFooter,
}                                                           from 'reactstrap'
import { DownloadTableExcel }                               from 'react-export-table-to-excel';
import XLSX from 'xlsx';
import { ToastContainer, toast }                            from 'react-toastify';

class Employee extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        id:             0,
        dataLemburs:    [],
        modalAksi:      false,
        pilihans:        [
            {
                value: 1,
                label: 'Approve'
            },
            {
                value: 2,
                label: 'Reject'
            }
        ],
        idLembur:       0,
        keputusan:      0,
        file: null
    }

    componentDidMount = async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getAllLembur();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({id});
    }

    _generateParam = (param = {}) => {
        param.key_api = config.KEY_API;
        return {params: {data: JSON.stringify(param)}};
    }

    _generateStatus = status => {
        let hasil;
        if(status == 0) {
            hasil = <span className="text-warning">New</span>;
        } else if(status == 1) {
            hasil = <span className="text-success">Approved</span>;
        } else if(status == 2) {
            hasil = <span className="text-danger">Rejected</span>;
        }
        return hasil;
    }

    getAllLembur = async() => {
        const {id} = this.state;
        const res = await api.getAllEmployeeClient(this._generateParam({id_client: id}));
        this.setState({dataLemburs: res.data.employees});
    }

    toggleModalAksi = idLembur => {
        this.setState((prevState) => ({modalAksi: !prevState.modalAksi}));
        this.setState({idLembur});
    }

    action = async() => {
        const {idLembur, keputusan} = this.state;
        if(keputusan == 0) {
            alert('Required!');
        } else {
            const res = await api.updateLembur(this._generateParam({id: idLembur, keputusan}));
            const {error, message} = res.data;
            if(error) {
                alert(message);
            } else {
                window.location.reload();
            }
        }
    }

    tableRef = React.createRef();

    sekarang = () => {
        const today = new Date();
        const Y = today.getFullYear();
        const m = ''+(today.getMonth()+1);
        const d = ''+today.getDate();
        return `${Y}-${m}-${d}`;
    }

     handleChangeXLS = (event) => {
        const file = event.target.files[0];
        console.log(file);
        this.setState({file:file  }, () => {
            console.log(this.state.file, 'file');
            console.log("done here")

        });
    };
    
     handleMapExcel = (excelData) => {
        excelData.map(async (it, index) => {

            if(index > 0){
                const {id} = this.state;

                const payload = {
                    "id_client_company" : id,
                    'full_name' : it[0],
                    'email' : it[1],
                    'phone_number'  : it[2],  
                    'id_card_number'  : it[3],     
                    'domicile_address'   : it[4],     
                    'id_card_address'  : it[5],   
                    'gender'  : it[6],    
                    'birth_place'  : it[7],   
                    'birth_date' : it[8],     
                    'religion'  : it[9], 
                }
                console.log(payload);
                const res =  await api.addEmployeeClient(this._generateParam(payload));
                console.log(res.data.message);
                //toast("messages");
                alert(it[1] + ", " + res.data.message)
            }
        })
     }
     handleFileXLS = () => {
        console.log("handleFileXLS");
        console.log(this.state.file);
        if (!this.state.file) return;
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.handleMapExcel(excelData);
        
        };
        reader.readAsArrayBuffer(this.state.file);

      };
    
      toggleModalAksi = idLembur => {
        this.setState((prevState) => ({modalAksi: !prevState.modalAksi}));
        this.setState({idLembur});
    }

    bodyHTML = () => <div>
        <DownloadTableExcel
            filename={`Izin ${this.sekarang()}`}
            sheet="Izin"
            currentTableRef={this.tableRef.current}
        >
            <button className="btn btn-success btn-sm mb-2">Export</button>
        </DownloadTableExcel>

        <button onClick={this.toggleModalAksi} className="btn btn-info btn-sm mb-2 ml-2">Import</button>

        <div className="table-responsive">
            <table className="table table-hover table-bordered table-sm" ref={this.tableRef}>
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>NIK</th>
                        <th>Status Pekerjaan</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{this.tbodyHTML()}</tbody>
            </table>
        </div>
    </div>;

    tbodyHTML = () => {
        const {dataLemburs} = this.state;
        return dataLemburs.map(({id, full_name, email, phone_number, status, id_card_number}, i) => <tr key={i}>
            <td className="text-center">{method.fixId(id)}</td>
            <td className="text-left">{full_name}</td>
            <td className="text-center">{phone_number}</td>
            <td className="text-center">{email}</td>
            <td className="text-center">{id_card_number}</td>
            <td className="text-center">{ status === 1 ? "Full-Time" : "Part-Time" }  </td>
        </tr>);
    }

    handleChangePilihan = (e, u) => this.setState({[u.name]: e.value});

    modalAksiHTML = () => {
        const {modalAksi, pilihans} = this.state;
        return <Modal isOpen={modalAksi} toggle={this.toggleModalAksi}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalAksi}/>
            <ModalHeader>Form Bulk Employee</ModalHeader> 
            <ModalBody>
                {/* <button onClick={"location.href='https://docs.google.com/spreadsheets/d/1_lB7mG-V8mbiODpRpp2WexQSTLWZ0YL1/edit?usp=share_link&ouid=111857011944198933635&rtpof=true&sd=true'"} className="btn btn-info btn-sm mb-2 ml-2">Download Template</button> */}
                <button className="btn btn-primary btn-sm mb-2 ml-2">
                <a href="https://docs.google.com/spreadsheets/d/1_lB7mG-V8mbiODpRpp2WexQSTLWZ0YL1/edit?usp=share_link&ouid=111857011944198933635&rtpof=true&sd=true" className='btn-info' target="_blank" rel="noopener noreferrer">Download Template</a>
                </button>
                <input type="file" className='ml-2' onChange={this.handleChangeXLS} />
            </ModalBody>
            <ModalFooter><button className="btn btn-primary" onClick={this.handleFileXLS}>Confirm</button></ModalFooter>
        </Modal>;
    }

    render() {
        return <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Employee</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="container mt-4">
                        <div className="card">
                            <div className="card-body">{this.bodyHTML()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {this.modalAksiHTML()}
        </Fragment>;
    }
}

export default Employee;