import React               from 'react';

import { Link }            from 'react-router-dom';

import logoAvishaWhite     from '../../Asset/Logo/logo-avisha-white.svg';

import {
  Collapse, Label,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Button
}                          from 'reactstrap';


class FrontHeaderUser extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: false
      };
      this.toggle = this.toggle.bind(this);
  
  // Responsive navbar with toggle
      this.toggleNav = this.toggleNav.bind(this);
      this.state = {
        isOpen: false
      };
    }
  
    toggle() {
      this.setState(prevState => ({
        modal: !prevState.modal
      }));
    }
  
    toggleNav() {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    render() {
        return (
    <div className="bg-blue">
      <Navbar color="#000" light expand="xl">
        <Container>
          <NavbarBrand href='/'><img src={logoAvishaWhite} alt="logo avisha no color" width="130px"/></NavbarBrand>
          <NavbarToggler onClick={this.toggleNav}/>
            <Collapse navbar isOpen={this.state.isOpen}>
              <Nav className="ml-auto" navbar>
                <NavItem tag={Link} to="/FormSignUpClient" className="pr-lg-3 mb-2 my-auto">
                  <Label className="text-white text-14 my-auto">Company</Label>
                </NavItem>
                <NavItem className="pr-lg-3 mb-2 my-auto">
                  <Label className="text-white mr-2 my-auto">|</Label> <Label className="text-white text-14 my-auto font-weight-bolder">Jobseekers</Label>
                </NavItem>
                <NavItem tag={Link} to="/FormSignUpUser" className="pr-lg-3 mb-2 my-auto">
                  <Button className="btn-2" style={{width:'120px'}}>Sign Up</Button>
                </NavItem>
                <NavItem tag={Link} to="/FormLoginUser" className="mb-2 my-auto">
                  <Button className="btn-3" style={{width:'120px'}}>Sign In</Button>
                </NavItem>
              </Nav>
            </Collapse>
        </Container>
      </Navbar>
    </div>
  );

    }
}

export default FrontHeaderUser;