import React, { Fragment }                                  from 'react';  
import { Container, Row, Col }                              from 'reactstrap';   


class TermCon extends React.Component {
    render() {                                     
        return (          
            <Fragment>                        
                <Container>
                    <Row>
                        <Col>Syarat dan Ketentuan di Avisha</Col>
                    </Row>
                </Container>
            </Fragment>
        );   
    }
};

export default TermCon;