import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Container, Row, Col, FormGroup,
         Label, Button, 
         Input, Card, CardBody, 
         Modal, ModalBody}                                  from 'reactstrap';   
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome'
import { faTimesCircle }                                    from '@fortawesome/free-solid-svg-icons'
import makeAnimated from 'react-select/animated';

class CreateFullTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName:"",
            workType:"Full-Time",
            minSalary:0,
            maxSalary:0,
            skillNeeded:"", 
            minExperienceYear:0,
            jobDescription:"",
            minimumQualification:"",
            quota:0,
            workAddress:"",
            deadlineJobVacancy:"",
            idCategory:0,
            idJobPosition:0,
            idJobSpecialization:0,
            lastEducation:"",
            idAcademicMajor:0,
            idVillage:0,
            activeTab: '0',
            id:0,
            modalCreateFullTime: false,
            isSalaryHide:false,
            dataSpecializations:[],
            dataCategories:[],
            dataJobPosition:[],
            dataStudies:[],
            dataProvincies:[],
            dataRegencies:[],
            dataSubdistricts:[],
            dataVillages:[],
            dataClient:[],
            dataLastEducation:[
                {
                    'value':"SMU/SMA",
                    'label':"SMU/SMA"
                },
                {
                    'value':"Diploma 1",
                    'label':"Diploma 1"
                }, 
                {
                    'value':"Diploma 2",
                    'label':"Diploma 2"
                },
                {
                    'value':"Diploma 3",
                    'label':"Diploma 3"
                }, 
                {
                    'value':"Diploma 4",
                    'label':"Diploma 4"
                },
                {
                    'value':"Sarjana 1",
                    'label':"Sarjana 1"
                }, 
                {
                    'value':"Sarjana 2",
                    'label':"Sarjana 2"
                },
                {
                    'value':"Sarjana 3",
                    'label':"Sarjana 3"
                }                                
            ],
            shift_in: '08:00',
            shift_out: '17:00',
            absen_locations: [],
            absen_types: [],
            locations: [],
            types: []
        };
    }

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getJobSpecializations();
        await this.getJobCategories();
        await this.getJobPosition();
        await this.getProvincies();
        await this.getStudies();
        await this.getDataClient();
        await this.getLocations();
        await this.getTypes();
    }

    handleCheckBox = () =>{
        const { isSalaryHide } = this.state;
        this.setState({isSalaryHide:!isSalaryHide});
    }
    
    getProvincies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getProvincies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataProvincies: data.map((k) => ({ value: k.id, label: k.province_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getTypes = async() => {
        const {id} = this.state;
        const res = await api.getAbsenTypes(id, this.dataParam);
        const {data} = res.data;
        const types = [];
        data.forEach(type => {
            types.push({
                value: type.absen_type_id,
                label: type.type_name
            });
        });
        this.setState({types});
    };

    getLocations = async() => {
        const {id} = this.state;
        const res = await api.getAbsenLocations(id, this.dataParam);
        const {data} = res.data;
        const locations = [];
        data.forEach(location => {
            locations.push({
                value: location.absen_location_id,
                label: location.location_name
            });
        });
        this.setState({locations});
    };

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataClient:data,
                companyName:data.company_name,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getStudies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getStudies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataStudies: data.map((k) => ({ value: k.id, label: k.academic_major_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobPosition = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobPosition({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataJobPosition: data.map((k) => ({ value: k.id, label: k.position_type })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }


    getJobCategories = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobCategories({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataCategories: data.map((k) => ({ value: k.id, label: k.job_category + "-" +k.job_sub_category })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobSpecializations = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobSpecializations({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataSpecializations: data.map((k) => ({ value: k.id, label: k.job_specialization_name + "-" +k.detail })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    createJobFullTime = () => {
        window.location.href = config.WEB_URL + `job/full-time/create`;
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChoseeCategory = async (element) => {
        this.setState({idCategory:element.value});
    }

    handleChooseTypes = async (types) => {
        const datas = [];
        types.forEach(type => datas.push(type.value));
        this.setState({absen_types: datas});
    }

    handleChooseLocations = async (locations) => {
        const datas = [];
        locations.forEach(location => datas.push(location.value));
        this.setState({absen_locations: datas});
    }

    handleChoseeAcademiMajor = async (element) => {
        this.setState({idAcademicMajor:element.value});
    }

    handleChoseeJobPosition = async (element) => {
        this.setState({idJobPosition:element.value});
    }

    handleChoseeLastEducation = async (element) => {
        this.setState({lastEducation:element.label});
    }

    handleChoseeSpecialization = async (element) => {
        this.setState({idJobSpecialization:element.value});
    }

    handleChoseeVillage = async (element) => {
        this.setState({idVillage:element.value});
    }

    handleChoseeProvincy = async (element) => {
        const id = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getRegencies(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({ dataRegencies: data.map((k) => ({ value: k.id, label: k.regency_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChoseeRegency = async (element) => {
        const id  = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getSubdistrict(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataSubdistricts: data.map((k) => ({ value: k.id, label: k.sub_district_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    handleChoseeSubdistrict = async (element) => {
        const id = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getVillages(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataVillages: data.map((k) => ({ value: k.id, label: k.village_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    closeModal = () => {
        this.setState(prevState => ({
            modalCreateFullTime: !prevState.modalCreateFullTime
        }));            
    }    

    errorDataNotComplate = () => {
        alert("Data Mandatory Lengkap");
    }    
    
    submit = () => {
        const { idCategory, idJobPosition, idJobSpecialization, minSalary, maxSalary, lastEducation,
                idAcademicMajor, minExperienceYear, skillNeeded, jobDescription, minimumQualification,
                deadlineJobVacancy, idVillage, workAddress, quota } = this.state;

        if((idCategory===0) || (minSalary===0) || (maxSalary===0) || idJobPosition===0 || (idJobSpecialization===0) 
        || (lastEducation==="") || (minExperienceYear===0) || (jobDescription==="") || (quota===0) || (idVillage===0) || (workAddress==="")){
            this.errorDataNotComplate();
        } else {
            this.setState(prevState => ({
                modalCreateFullTime: !prevState.modalCreateFullTime
            }));                
        }
    }
    createJob = async () => {
        const { id, workType, idCategory, idJobPosition, idJobSpecialization, minSalary, maxSalary, lastEducation,
            idAcademicMajor, minExperienceYear, skillNeeded, jobDescription, minimumQualification,
            deadlineJobVacancy, idVillage, workAddress, quota, isSalaryHide, absen_locations, absen_types, shift_in, shift_out } = this.state;
        const newData = {
            key_api:config.KEY_API,
            id_client_company:id,
            work_type:workType,
            id_job_position:idJobPosition,
            id_job_specialization:idJobSpecialization,
            id_job_category:idCategory,
            id_academic_major:idAcademicMajor,
            id_village:idVillage,
            work_address:workAddress,
            min_salary:minSalary,
            max_salary:maxSalary,
            last_education:lastEducation,
            min_experience_year:minExperienceYear,
            skill_needed:skillNeeded,
            job_description:jobDescription,
            minimum_qualification:minimumQualification,
            quota:quota,
            deadline_job_vacancy:deadlineJobVacancy,
            is_hide_nominal_salary: (isSalaryHide===true)? 1 : 0,
            shift_in,
            shift_out,
            absen_locations,
            absen_types
        }
        try{
            const res = await api.createJobFulltime({
                params: {
                    data: JSON.stringify(newData)
                }
            });
            const { status } = res;
            if (status === 200) {
                window.location.href = config.WEB_URL + `job`;
                this.setState(prevState => ({
                    modalCreateFullTime: !prevState.modalCreateFullTime
                }));            

            }
        } catch(err){
            console.log("Error Response :",err)
        }    

    }

    animatedComponents = makeAnimated()

    render() {
        const { 
                minSalary,
                maxSalary,
                skillNeeded, 
                minExperienceYear,
                jobDescription,
                minimumQualification,
                quota,
                workAddress,
                deadlineJobVacancy,
                dataSpecializations,
                dataCategories,
                dataJobPosition,
                dataStudies,
                dataClient,
                dataLastEducation,
                dataProvincies,
                dataRegencies,
                dataSubdistricts,
                dataVillages,
                modalCreateFullTime,
                isSalaryHide,
                shift_in,
                shift_out,
                types,
                locations,
            } = this.state;
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Create Job Full Time</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div fluid className="pb-5 muli-font bg-body-grey">
                        <Container className="pt-5">
                            <Card>
                                <CardBody>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Nama Perusahaan</Label>
                                        <Input disabled type="text" value={dataClient.company_name} className="input-box-grey"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Tipe Pekerjaan</Label>
                                        <Input disabled type="text" value="Full Time" className="input-box-grey"/><br/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress">* Kategori Pekerjaan</Label>
                                        <Select
                                                name="idCategory"
                                                onChange={this.handleChoseeCategory.bind(this)}
                                                options={dataCategories}
                                        />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress">* Jabatan Pekerjaan</Label>
                                        <Select
                                                name="idJobPosition"
                                                onChange={this.handleChoseeJobPosition.bind(this)}
                                                options={dataJobPosition}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress">* Spesialisasi Pekerjaan</Label>
                                        <Select
                                                name="idJobSpecialization"
                                                onChange={this.handleChoseeSpecialization.bind(this)}
                                                options={dataSpecializations}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Gaji</Label>
                                        <Row>
                                            <Col xs="12" sm="12" md="4">
                                                <Label className="text-14 mb-1">Minimum Gaji</Label>
                                                <Input onChange={ this.handleChange } name="minSalary" value={minSalary} type="number" className="input-box-white"/><br/>
                                            </Col>
                                            <Col xs="12" sm="12" md="4">
                                                <Label className="text-14 mb-1">Maksimum Gaji</Label>
                                                <Input onChange={ this.handleChange } name="maxSalary" value={maxSalary} type="number"  className="input-box-white"/><br/>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" className="my-auto">
                                                <Input onChange={ this.handleCheckBox } type="checkbox" checked={isSalaryHide} id="isSalaryHide" name="isSalaryHide"/>
                                                <Label className="text-16 my-auto">Confidential Salary/Rates</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress"> * Pendidikan Terakhir</Label>
                                        <Select
                                                name="lastEducation"
                                                onChange={this.handleChoseeLastEducation.bind(this)}
                                                options={dataLastEducation}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress">Bidang Studi</Label>
                                        <Select
                                                name="idAcademicMajor"
                                                onChange={this.handleChoseeAcademiMajor.bind(this)}
                                                options={dataStudies}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Pengalaman</Label>
                                        <Row>
                                            <Col lg="1">
                                                <Input type="number" onChange={ this.handleChange } name="minExperienceYear" value={minExperienceYear} className="input-box-white"/><br/>
                                            </Col>
                                            <Col lg="3">
                                                <p> Tahun</p>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Skill</Label>
                                        <Input placeholder="Masukkan skill..." type="text" onChange={ this.handleChange } name="skillNeeded" value={skillNeeded} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Deskripsi Pekerjaan</Label>
                                        <Input placeholder="Masukkan deskripsi pekerjaan..." type="textarea" onChange={ this.handleChange } name="jobDescription" value={jobDescription} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Minimum Qualification</Label>
                                        <Input placeholder="Masukkan minimum kualifikasi..." type="textarea" onChange={ this.handleChange } name="minimumQualification" value={minimumQualification} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Quota</Label>
                                        <Row>
                                            <Col lg="1">
                                                <Input type="number" onChange={ this.handleChange } name="quota" value={quota} className="input-box-white"/><br/>
                                            </Col>
                                            <Col lg="3">
                                                <p> Orang</p>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Deadline</Label>
                                        <Input type="date" onChange={ this.handleChange } name="deadlineJobVacancy" value={deadlineJobVacancy} className="input-box-white"/><br/>
                                    </FormGroup><hr/>
                                    <p>*Attendance</p>
                                    <div className="row">
                                        <div className="col-md">
                                            <FormGroup className="mb-0">
                                                <Label className="text-14 mb-1">Shift In</Label>
                                                <Input type="time" onChange={ this.handleChange } name="shift_in" value={shift_in} className="form-control"/><br/>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md">
                                            <FormGroup className="mb-0">
                                                <Label className="text-14 mb-1">Shift Out</Label>
                                                <Input type="time" onChange={ this.handleChange } name="shift_out" value={shift_out} className="form-control"/><br/>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Types</Label>
                                        <Select
                                            name="absen_types"
                                            closeMenuOnSelect={false}
                                            components={this.animatedComponents}
                                            isMulti
                                            options={types}
                                            onChange={ this.handleChooseTypes.bind(this) }
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Locations</Label>
                                        <Select
                                            name="absen_locations"
                                            closeMenuOnSelect={false}
                                            components={this.animatedComponents}
                                            isMulti
                                            options={locations}
                                            onChange={ this.handleChooseLocations.bind(this) }
                                        />
                                    </FormGroup>
                                    <hr/>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Alamat</Label>
                                        <Row>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Provinsi"
                                                    options={dataProvincies}
                                                    onChange={this.handleChoseeProvincy.bind(this)}
                                                />                                                                        
                                            </Col>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Kabupaten"
                                                    options={dataRegencies}
                                                    onChange={this.handleChoseeRegency.bind(this)}
                                                />                                                                        
                                            </Col>
                                        </Row>
                                        <p></p>
                                        <Row>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Kecamatan"
                                                    options={dataSubdistricts}
                                                    onChange={this.handleChoseeSubdistrict.bind(this)}
                                                />                                                                        
                                            </Col>
                                            <Col lg="6">
                                                <Select
                                                    name="idVillage"
                                                    placeholder="Pilih Kelurahan"
                                                    onChange={this.handleChoseeVillage.bind(this)}
                                                    options={dataVillages}
                                                />                                                                        
                                            </Col>
                                        </Row><br/>
                                        <textarea placeholder="Masukan Alamat" onChange={ this.handleChange } name="workAddress" className="form-control input-box-white">{workAddress}</textarea>
                                    </FormGroup>
                                    <small style={{color:'red'}} className="text-red">Dengan awalan simbol * wajib diisi (Mandatory).</small>
                                    <hr/>
                                    <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.submit}>Create Job</Button></Col>
                                </CardBody>
                            </Card>  
                        </Container> 
                        <Modal isOpen={modalCreateFullTime} toggle={this.closeModal} backdrop="static">
                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeModal}/>
                            <ModalBody>
                                <h5 className="text-center mb-5">Create Job</h5>
                                <p> Are you sure you want to create this job ?</p>
                                <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.createJob}>Create</Button></Col>
                            </ModalBody>
                        </Modal> 
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CreateFullTime;