import React, { Fragment }      from 'react';
import HeaderClient from './ComponentClient/HeaderClient';
import {    Nav, NavLink,
            NavItem, TabContent, TabPane, 
            Container, Row, Col,
            Label, Button,
            Card, CardBody,
            Modal, ModalBody, ModalHeader, ModalFooter
        }                       from 'reactstrap';
import imgProfile from '../Asset/Icon/img-profile.png';
import classnames               from 'classnames';
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import * as authHelperMethods   from '../Helpers/AuthHelperMethods';
import * as api                 from '../Helpers/Api';
import * as config              from '../Helpers/Config';
import { faTimesCircle }        from '@fortawesome/free-solid-svg-icons';
import * as method              from '../Helpers/Method';
import { Multiselect }          from 'multiselect-react-dropdown';
import Select                   from 'react-select';
import { tr } from 'date-fns/locale';

class FindTalent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            educations: [],
            locations: [],
            jobCategories: [],
            tab: 0,
            archiveds: [],
            notArchiveds: [],
            archivedsTampil: [],
            notArchivedsTampil: [],
            filteredJobCategories: false,
            lokasi: '',
            pendidikan: '',
            employeeDetail: null,
            modalProfil: false
        };
    }

    componentDidMount = async () => {
        authHelperMethods.checkIsLogin();
        this.getId();
        await this.getJobCategories();
        this.educations();
        await this.getAllRegency();
    }

    toggleProfileCandidate = async(idEmployee) => {
        const res = await api.getProfilEmployee(idEmployee, this.param);
        const { employee } = res.data;
        await this.setState({employeeDetail: employee});
        this.toggleModalProfil();
    }

    toggleModalProfil = () => {
        this.setState(prevState => ({
            modalProfil: !prevState.modalProfil
        }));
    }

    param = {params: {data: JSON.stringify({ key_api: config.KEY_API })}};

    getJobCategories = async () => {
        const res = await api.getJobCategoriesNew(this.param);
        const { jobCategories } = res.data;
        this.setState({ jobCategories });
    }

    getAllRegency= async (e) => {
        const res = await api.getAllRegenciesNew(this.param);
        const { locations } = res.data;
        this.setState({ locations });
    }

    filter = () => {
        const {archiveds, notArchiveds } = this.state;
        this.setState({
            archivedsTampil: this.aksiFilter(archiveds),
            notArchivedsTampil: this.aksiFilter(notArchiveds)
        });
    }

    aksiFilter = datas => {
        const { lokasi, pendidikan} = this.state;
        let hasil = [];
        if (!lokasi && !pendidikan) {
            hasil = datas
        } else {
            for (let data of datas) {
                if (lokasi && !pendidikan) {
                    if (data.regency_name == lokasi) hasil.push(data);
                } else if (!lokasi && pendidikan) {
                    if (data.education_stage == pendidikan) hasil.push(data);
                } else {
                    if (data.regency_name == lokasi && data.education_stage == pendidikan) hasil.push(data);
                }
            }
        }
        return hasil;
    }

    filterLokasi = async(e) => {
        await this.setState({ lokasi: e.value });
        this.filter();
    }

    filterPendidikan = async(e) => {
        await this.setState({ pendidikan: e.value });
        this.filter();
    }

    educations = () => {
        let educations = [];
        const pendidikans = ['SMU/SMA', 'Diploma 1', 'Diploma 2', 'Diploma 3', 'Diploma 4', 'Sarjana 1', 'Sarjana 2', 'Sarjana 3'];
        pendidikans.forEach(pendidikan => educations.push({label: pendidikan, value: pendidikan}));
        this.setState({educations})
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id });
    }

    ambilDataEmployee = async(id, jobs) => {
        const res = await api.getTalentEmployeeNew({
            params: {
                data: JSON.stringify({
                    key_api: config.KEY_API,
                    id_client: id,
                    jobs
                })
            }
        });
        const { archiveds, notArchiveds } = res.data;
        this.setState({
            archivedsTampil: archiveds,
            notArchivedsTampil: notArchiveds,
            archiveds,
            notArchiveds
        });
    }

    handleSelectJobCategory = async (event) => {
        const { id } = this.state;
        const jobs = event.map(e => e.id);
        await this.ambilDataEmployee(id, jobs);
        this.setState({filteredJobCategories: true});
    }

    handleRemoveJobCategory = async(event) => {
        const { id } = this.state;
        const jobs = event.map(e => e.id);
        if (jobs.length > 0) {
            await this.ambilDataEmployee(id, jobs);
        } else {
            this.setState({
                archivedsTampil: [],
                notArchivedsTampil: [],
                archiveds: [],
                notArchiveds: [],
                filteredJobCategories: false,
                pendidikan: '',
                lokasi: ''
            });
        }
    }

    changeTab = e => this.setState({ tab: parseInt(e.target.dataset.tab) });

    filterPanelHTML = () => {
        const { educations, locations, jobCategories, filteredJobCategories } = this.state;
        return (<div className="card">
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="jobCategories">Job Categories</label>
                    <Multiselect onSelect={(e) => this.handleSelectJobCategory(e)} onRemove={(e) => this.handleRemoveJobCategory(e)} id="jobCategories" options={jobCategories} displayValue="name" placeholder="Search job categories" />
                </div>
                {(!filteredJobCategories) ? null : <div>
                    <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <Select options={locations} onChange={this.filterLokasi} id="location" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="education">Education</label>
                        <Select options={educations} onChange={this.filterPendidikan} id="education" />
                    </div>
                </div>}
            </div>
        </div>);
    }

    notFoundHTML = <p className="my-auto font-weight-bold text-center">Data Not Found</p>;

    employeeArchivedHTML = () => {
        const { archivedsTampil } = this.state;
        return ((archivedsTampil.length == 0) ? this.notFoundHTML : (archivedsTampil.map(archived => <Row className="mb-4">
            <Col lg="3" className="text-center">
                <img src={archived.profile_image === null ? imgProfile : config.URL_IMAGE_EMPLOYEE + "image_profile_employee/" + archived.profile_image} alt="" className="rounded-circle" width="130px" height="130px" />
            </Col>
            <Col>
                <p className="text-16 font-weight-bolder text-capitalize">{method.fixId(archived.id)}</p>
                <Row>
                    <Col>
                        <p className="mb-1 text-14">{archived.phone_number}</p>
                        <p className="mb-1 text-14">{archived.gender}</p>
                        <p className="mb-1 text-14">{(!archived.education_stage) ? '-' : `${archived.education_stage}-${archived.major}`}</p>
                        <p className="mb-1 text-14">{(!archived.regency_name) ? '-' : archived.regency_name}</p>
                    </Col>
                    <Col><Button className="btn-block btn-info mb-1" onClick={() => this.toggleProfileCandidate(archived.id)} >See Profile</Button></Col>
                    <Col><Button onClick={() => this.invite(archived)} className="btn-block btn-blue-normal">Invite</Button></Col>
                </Row>
            </Col>
        </Row>)));
    }

    employeeNotArchivedHTML = () => {
        const { notArchivedsTampil } = this.state;
        return ((notArchivedsTampil.length == 0) ? this.notFoundHTML : (notArchivedsTampil.map(notArchived => <Row className="mb-4">
            <Col lg="3" className="text-center">
                <img src={(notArchived.profile_image === null) ? imgProfile : config.URL_IMAGE_EMPLOYEE + "image_profile_employee/" + notArchived.profile_image} alt="" className="rounded-circle" width="130px" height="130px" />
            </Col>
            <Col>
                <p className="text-16 font-weight-bolder text-capitalize">{method.fixId(notArchived.id)}</p>
                <Row>
                    <Col>
                        <p className="mb-1 text-14">{(notArchived.education_stage == null) ? '-' : `${notArchived.education_stage}-${notArchived.major}`}</p>
                        <p className="mb-1 text-14">{notArchived.regency_name}</p>
                    </Col>
                    <Col sm="6" md="5" lg="4">
                        <Button className="btn-block btn-info mb-1" onClick={() => this.toggleProfileCandidate(notArchived.id)}>See Profile</Button>
                    </Col>
                    <Col sm="6" md="5" lg="4">
                        <Button onClick={() => this.getModalResume(notArchived)} className="btn-block btn-success">Get Resume</Button>
                    </Col>
                </Row>
            </Col>
        </Row>)));
    }

    contentHTML = () => {
        const { tab } = this.state;
        return (<div>
            <Nav tabs>
                <Fragment>
                    <NavItem><NavLink onClick={this.changeTab} data-tab="0" className={(tab == 0) ? 'active' : ''}>
                        <p className="mb-0 font-weight-bold text-14" data-tab="0">Available</p>
                    </NavLink></NavItem>
                    <NavItem><NavLink onClick={this.changeTab} data-tab="1" className={(tab == 1) ? 'active' : ''}>
                        <p className="mb-0 font-weight-bold text-14" data-tab="1">Get Resume</p>
                    </NavLink></NavItem>
                </Fragment>
            </Nav>
            <Col className="box-shadow p-3 mb-3">
                <TabContent activeTab={tab} className="text-12">
                    <TabPane tabId={0} className="bg-white">{ this.employeeArchivedHTML() }</TabPane>
                    <TabPane tabId={1} className="bg-white">{ this.employeeNotArchivedHTML() }</TabPane>
                </TabContent>
            </Col>
        </div>);
    }

    modalProfilHTML = () => {
        const { modalProfil, employeeDetail } = this.state;
        if (employeeDetail) {
            const { profile, skills, workHistories } = employeeDetail;
            console.log(profile);
            return (<Modal isOpen={modalProfil} toggle={this.toggleModalProfil} backdrop="static">
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalProfil} />
                <ModalHeader>
                    <h5 className='font-weight-bold'>Profile</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-4">
                            <img src={(!profile.profile_image) ? imgProfile : `${config.URL_IMAGE_EMPLOYEE}image_profile_employee/${profile.profile_image}`} alt="" className="border border-info rounded-circle p-1 mb-4" width="120px" height="120px" />
                        </div>
                        <div className="col-md" style={{fontSize: '14px'}}>
                            <table>
                                <tr>
                                    <th>ID Employee</th>
                                    <td>: {method.fixId(profile.id)}</td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <td>: {profile.full_name}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>: {profile.email}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number</th>
                                    <td>: {profile.phone_number}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <hr /><h5 className="font-weight-bold">Personal Info</h5>
                    <table className="text-12">
                        <tr>
                            <th>Birth Date</th>
                            <td>: {profile.birth_date}</td>
                        </tr>
                        <tr>
                            <th>Birt Place</th>
                            <td>: {profile.birth_place}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>: {profile.gender}</td>
                        </tr>
                        <tr>
                            <th>ID Card</th>
                            <td>: {profile.id_card_number}</td>
                        </tr>
                        <tr>
                            <th>Religion</th>
                            <td>: {profile.religion}</td>
                        </tr>
                        <tr>
                            <th>Body Height</th>
                            <td>: {profile.height}</td>
                        </tr>
                        <tr>
                            <th>Body Weight</th>
                            <td>: {profile.weight}</td>
                        </tr>
                        <tr>
                            <th>ID Card Address</th>
                            <td>: {profile.id_card_address}</td>
                        </tr>
                        <tr>
                            <th>Domicile Address</th>
                            <td>: {profile.domicile_address}</td>
                        </tr>
                    </table>
                    <hr /><h5 className="font-weight-bold">Education</h5>
                    <table className="text-12">
                        <tr>
                            <th>Last Education</th>
                            <td>: {profile.education_stage}</td>
                        </tr>
                        <tr>
                            <th>Name of Institution</th>
                            <td>: {profile.institution_name}</td>
                        </tr>
                        <tr>
                            <th>Major</th>
                            <td>: {profile.major}</td>
                        </tr>
                        <tr>
                            <th>Graduate Year</th>
                            <td>: {profile.graduate_year}</td>
                        </tr>
                        <tr>
                            <th>Score GPA</th>
                            <td>: {profile.score}</td>
                        </tr>
                    </table>
                    <hr /><h5 className="font-weight-bold">Expertise</h5>
                    <div className="table-responsive text-12">
                        <table className="table table-hover table-bordered">
                            <thead className="thead-dark text-center">
                                <tr>
                                    <th>Name</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(skills.length == 0) ? <tr>
                                    <td colSpan={2} className="text-center">~ Data Empty ~</td>
                                </tr> : (skills.map(skill => <tr>
                                    <td>{skill.skill_name}</td>
                                    <td className="text-center">{skill.skill_rate}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                    <hr /><h5 className="font-weight-bold">Experience</h5>
                    <div className="table-responsive text-12">
                        <table className="table table-hover table-bordered">
                            <thead className="thead-dark text-center">
                                <tr>
                                    <th>Position</th>
                                    <th>Company</th>
                                    <th>Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(workHistories.length == 0) ? <tr>
                                    <td colSpan={3} className="text-center">~ Data Empty ~</td>
                                </tr> : (workHistories.map(workHistory => <tr>
                                    <td>{workHistory.position}</td>
                                    <td>{workHistory.company_name}</td>
                                    <td>{workHistory.working_period}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={this.toggleModalProfil}>Close</button>
                </ModalFooter>
            </Modal>);
        }
    }

    render() {
        return (<div>
            <HeaderClient />
            <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Find Talent</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="card mt-4 container">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">{this.filterPanelHTML()}</div>
                            <div className="col-md">{this.contentHTML()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {this.modalProfilHTML()}
        </div>);
    }
}

export default FindTalent;