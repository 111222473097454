import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Container, Row, Col, FormGroup,
         Label, Button, 
         Input, Card, CardBody, 
         Modal, ModalBody}                                  from 'reactstrap';   
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select, { components }                               from 'react-select';
import NumberFormat                                         from 'react-number-format';
import * as method                                          from '../Helpers/Method';
import DatePicker                                           from 'react-datepicker';
import { registerLocale, setDefaultLocale }                 from 'react-datepicker';
import id                                                   from 'date-fns/locale/id';
import makeAnimated from 'react-select/animated';
import "react-datepicker/dist/react-datepicker.css";
import '../Css/style.css';

class CreatePartTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName:'',
            workType:"Part-Time",
            skillNeeded:'', 
            minExperienceYear:0,
            jobDescription:'',
            minimumQualification:'',
            quota:0,
            jobName: '',
            salary:0,
            partTimeSalary:0, 
            workAddress:'',
            start:'',
            end:'',
            gender:'',
            picPhoneNumber: '',
            picName:'',
            idCategory:0,
            idJobPosition:0,
            idJobSpecialization:0,
            lastEducation:'',
            idAcademicMajor:0,
            idVillage:0,
            activeTab: '0',
            id:0,
            modalOpenApproveCandidates: false,
            isSalaryHide:false,
            dataSpecializations:[],
            dataCategories:[],
            dataJobPosition:[],
            dataStudies:[],
            dataProvincies:[],
            dataRegencies:[],
            dataSubdistricts:[],
            dataVillages:[],
            dataClient: [],
            postingTimeStart: '',
            dataGender:[
                {
                    'value':"Laki - laki",
                    'label':"Laki - laki"
                },
                {
                    'value':"Perempuan",
                    'label':"Perempuan"
                },
                {
                    'value':"Laki - laki dan Perempuan",
                    'label':"Laki - laki dan Perempuan"
                }
            ],
            dataLastEducation:[
                {
                    'value':"SMU/SMA",
                    'label':"SMU/SMA"
                },
                {
                    'value':"Diploma 1",
                    'label':"Diploma 1"
                }, 
                {
                    'value':"Diploma 2",
                    'label':"Diploma 2"
                },
                {
                    'value':"Diploma 3",
                    'label':"Diploma 3"
                }, 
                {
                    'value':"Diploma 4",
                    'label':"Diploma 4"
                },
                {
                    'value':"Sarjana 1",
                    'label':"Sarjana 1"
                }, 
                {
                    'value':"Sarjana 2",
                    'label':"Sarjana 2"
                },
                {
                    'value':"Sarjana 3",
                    'label':"Sarjana 3"
                }                                
            ],
            shift_in: '08:00',
            shift_out: '17:00',
            absen_locations: [],
            absen_types: [],
            locations: [],
            types: [],
            shifts: [{
                before_in: 60,
                after_in: 60,
                shift_in: '08:00',
                before_out: 60,
                after_out: 60,
                shift_out: '17:00',
                quota: 1
            }]
        };
    }

    componentDidMount= async () => {
        await this.getId();
        await this.getJobSpecializations();
        await this.getJobCategories();
        await this.getJobPosition();
        await this.getProvincies();
        await this.getStudies();
        await this.getDataClient();
        await this.getLocations();
        await this.getTypes();
        registerLocale('id', id)
    }

    handleCheckBox = () =>{
        const { isSalaryHide } = this.state;
        this.setState({isSalaryHide:!isSalaryHide});
    }

    getProvincies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getProvincies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataProvincies: data.map((k) => ({ value: k.id, label: k.province_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getTypes = async() => {
        const {id} = this.state;
        const res = await api.getAbsenTypes(id, this.dataParam);
        const {data} = res.data;
        const types = [];
        data.forEach(type => {
            types.push({
                value: type.absen_type_id,
                label: type.type_name
            });
        });
        this.setState({types});
    };

    getLocations = async() => {
        const {id} = this.state;
        const res = await api.getAbsenLocations(id, this.dataParam);
        const {data} = res.data;
        const locations = [];
        data.forEach(location => {
            locations.push({
                value: location.absen_location_id,
                label: location.location_name
            });
        });
        this.setState({locations});
    };

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataClient:data,
                companyName:data.company_name,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getStudies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getStudies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataStudies: data.map((k) => ({ value: k.id, label: k.academic_major_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobPosition = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobPosition({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataJobPosition: data.map((k) => ({ value: k.id, label: k.position_type })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }


    getJobCategories = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobCategories({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataCategories: data.map((k) => ({ value: k.id, label: k.job_category + "-" +k.job_sub_category })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobSpecializations = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobSpecializations({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataSpecializations: data.map((k) => ({ value: k.id, label: k.job_specialization_name + "-" +k.detail })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeNumberFormat = (formattedValue) => {
        this.setState({ partTimeSalary: formattedValue.value });
    }

    handleChoseeGender = async (element) => {
        this.setState({gender:element.value});
    }

    handleChoseeCategory = async (element) => {
        this.setState({idCategory:element.value});
    }

    handleChoseeAcademiMajor = async (element) => {
        this.setState({idAcademicMajor:element.value});
    }

    handleChoseeJobPosition = async (element) => {
        this.setState({idJobPosition:element.value});
    }

    handleChoseeLastEducation = async (element) => {
        this.setState({lastEducation:element.label});
    }

    handleChoseeSpecialization = async (element) => {
        this.setState({idJobSpecialization:element.value});
    }

    handleChoseeVillage = async (element) => {
        this.setState({idVillage:element.value});
    }

    handleChoseeProvincy = async (element) => {
        const id = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getRegencies(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({ dataRegencies: data.map((k) => ({ value: k.id, label: k.regency_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    multiValueRemove = (props) => {
        if (props.data.isFixed) {
          return null;
        }
        return <components.MultiValueRemove {...props} />;
      };

    handleChoseeRegency = async (element) => {
        const id  = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getSubdistrict(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataSubdistricts: data.map((k) => ({ value: k.id, label: k.sub_district_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    handleChoseeSubdistrict = async (element) => {
        const id = element.value;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getVillages(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({idVillage:0})
            this.setState({dataVillages: data.map((k) => ({ value: k.id, label: k.village_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    closeModal = () => {
        this.setState(prevState => ({
            modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
        }));            
    }    

    errorDataNotComplate = () => {
        alert("Fields Required");
    }    
    
    submit = () => {
        const { idCategory, idJobPosition, idJobSpecialization, lastEducation, partTimeSalary, minExperienceYear, jobDescription, gender, idVillage, workAddress, quota, picName, picPhoneNumber, postingTimeStart, start, end, shifts, absen_locations, absen_types } = this.state;
        const kosong = [];
        shifts.forEach(shift => {
            Object.keys(shift).forEach(key => {
                if(!shift[key]) kosong.push(false);
            });
        });
        if((idCategory===0) || (idJobPosition===0) || (idJobSpecialization===0) || (quota===0) || (gender==="") || (picName==="") || (picPhoneNumber==="")|| (partTimeSalary==="") || (partTimeSalary===0) || (lastEducation==="") || (minExperienceYear===0) || (jobDescription==="") || (idVillage===0) || (workAddress==="") || postingTimeStart == '' || start == '' || end == '' || kosong.length > 0 || absen_locations.length == 0 || absen_types.length == 0) {
            this.errorDataNotComplate();
        } else {
            this.setState(prevState => ({
                modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
            }));                
        }
    }

    createJob = async () => {
        const { id, workType, idCategory, idJobPosition, idJobSpecialization, lastEducation,
            idAcademicMajor, gender, minExperienceYear, skillNeeded, jobDescription, minimumQualification,
            partTimeDate, idVillage, workAddress, quota, partTimeSalary, isSalaryHide, picName, picPhoneNumber, postingTimeStart, absen_locations, absen_types, shift_in, shift_out, start, end, shifts, jobName } = this.state;
        let newData = {
            key_api: config.KEY_API,
            id_client_company: id,
            postingTimeStart,
            datas: []
        };
        newData.datas.push({
            id_client_company: id,
            work_type: workType,
            id_job_position: idJobPosition,
            id_job_specialization: idJobSpecialization,
            id_job_category: idCategory,
            id_academic_major: idAcademicMajor,
            id_village: idVillage,
            work_address: workAddress,
            salary: partTimeSalary,
            last_education: lastEducation,
            min_experience_year: minExperienceYear,
            skill_needed: skillNeeded,
            job_description: jobDescription,
            minimum_qualification: minimumQualification,
            quota: quota,
            part_time_date: partTimeDate,
            is_hide_nominal_salary: (isSalaryHide === true) ? 1 : 0,
            gender: gender,
            pic_name: picName,
            pic_phone_number: picPhoneNumber,
            shift_in,
            shift_out,
            absen_locations,
            absen_types,
            start,
            end,
            shifts,
            job_name: jobName
        });
        try{
            const res = await api.createJobParttime({
                params: {
                    data: JSON.stringify(newData)
                }
            });
            const { status } = res.data;
            if (status === 200) {
                window.location.href = config.WEB_URL + `job`;
                this.setState(prevState => ({
                    modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
                }));            

            }
        } catch(err){
            console.log("Error Response :",err)
        }    
    }

    handleChooseTypes = async (types) => {
        const datas = [];
        if(types) types.forEach(type => datas.push(type.value));
        this.setState({absen_types: datas});
    }

    handleChooseLocations = async (locations) => {
        const datas = [];
        if(locations) locations.forEach(location => datas.push(location.value));
        this.setState({absen_locations: datas});
    }

    handleChangePostTime = e => {
        console.log(e)
        const { jmlPosting } = this.state;
        this.setState({ postingTimeStart: e });
    }

    addShift = () => {
        const {shifts} = this.state;
        shifts.push({
            before_in: 60,
            after_in: 60,
            shift_in: '',
            before_out: 60,
            after_out: 60,
            shift_out: '',
            quota: ''
        });
        this.setState(shifts);
    }

    deleteShift = index => {
        const {shifts} = this.state;
        delete shifts[index];
        this.setState(shifts);
    }

    handleChangeShift = (param, index, nilai) => {
        const {shifts} = this.state;
        shifts[index][param] = nilai;
        this.setState({shifts});
    }

    shiftingHTML = (index, {before_in, after_in, shift_in, before_out, after_out, shift_out, quota}) => <tr key={index}>
        <td><input type="time" className="form-control form-control-sm" value={shift_in} onChange={e => this.handleChangeShift('shift_in', index, e.target.value)}/></td>
        <td><input type="time" className="form-control form-control-sm" value={shift_out} onChange={e => this.handleChangeShift('shift_out', index, e.target.value)}/></td>
        <td><input type="number" className="form-control form-control-sm" value={quota} onChange={e => this.handleChangeShift('quota', index, e.target.value)}/></td>
        <td className="text-center">{(index == 0)? null : <button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteShift(index)}>Delete</button>}</td>
    </tr>;

    render() {
        const { 
                partTimeSalary,
                skillNeeded, 
                picPhoneNumber, 
                picName,
                minExperienceYear,
                jobDescription,
                jobName,
                minimumQualification,
                quota,
                workAddress,
                dataSpecializations,
                dataCategories,
                dataJobPosition,
                dataStudies,
                dataClient,
                dataLastEducation,
                dataProvincies,
                dataRegencies,
                dataSubdistricts,
                dataVillages,
                dataGender,
                modalOpenApproveCandidates, 
                workType,
                isSalaryHide,
                jmlPosting,
                postingTimeStart,
                types,
                locations,
                shifts,
                start,
                end
            } = this.state;
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Create Job Part Time</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div fluid className="pb-5 muli-font bg-body-grey">
                        <Container className="pt-5">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1">Company's Name</Label></div>
                                                <div className="col"><Input disabled type="text" value={dataClient.company_name} className="form-control form-control-sm"/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1">Job Type</Label></div>
                                                <div className="col"><Input disabled type="text" value={workType} className="form-control form-control-sm"/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1" htmlFor="jobName">Job Name</Label></div>
                                                <div className="col"><Input name="jobName" id="jobName" onChange={ this.handleChange } type="text" value={jobName} className="form-control form-control-sm"/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="idCategory" className="text-14 mb-1">*Job Category</Label></div>
                                                <div className="col"><Select name="idCategory" id="idCategory" onChange={this.handleChoseeCategory.bind(this)} options={dataCategories}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="idJobPosition" className="text-14 mb-1">*Job Title</Label></div>
                                                <div className="col"><Select name="idJobPosition" id="idJobPosition" onChange={this.handleChoseeJobPosition.bind(this)} options={dataJobPosition}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="idJobSpecialization" className="text-14 mb-1">*Job Specialization</Label></div>
                                                <div className="col"><Select name="idJobSpecialization" id="idJobSpecialization" onChange={this.handleChoseeSpecialization.bind(this)} options={dataSpecializations}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="lastEducation" className="text-14 mb-1">*Last Education</Label></div>
                                                <div className="col"><Select name="lastEducation" id="lastEducation" onChange={this.handleChoseeLastEducation.bind(this)} options={dataLastEducation}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="gender" className="text-14 mb-1">*Gender</Label></div>
                                                <div className="col"><Select name="gender" id="gender" onChange={this.handleChoseeGender.bind(this)} options={dataGender}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label htmlFor="idAcademicMajor" className="text-14 mb-1">Study</Label></div>
                                                <div className="col"><Select name="idAcademicMajor" id="idAcademicMajor" onChange={this.handleChoseeAcademiMajor.bind(this)} options={dataStudies}/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14" htmlFor="minExperienceYear">*Experience</Label></div>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <Input type="number" onChange={ this.handleChange } name="minExperienceYear" id="minExperienceYear" value={minExperienceYear} className="form-control form-control-sm"/>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary btn-sm" disabled>year</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14" htmlFor="skillNeeded">Skill</Label></div>
                                                <div className="col"><textarea placeholder="Skill..." className="form-control" onChange={ this.handleChange } id="skillNeeded" name="skillNeeded">{skillNeeded}</textarea></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1" htmlFor="jobDescription">*Job Description</Label></div>
                                                <div className="col"><Input placeholder="Job description..." type="textarea" onChange={ this.handleChange } id="jobDescription" name="jobDescription" value={jobDescription} className="form-control form-control-sm"/></div>
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1" htmlFor="quota">*Quota</Label></div>
                                                <div className="col">
                                                    <div className="input-group">
                                                        <Input type="number" onChange={ this.handleChange } name="quota" id="quota" value={quota} className="form-control form-control-sm"/>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary btn-sm" disabled>person</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="row mb-1">
                                                <div className="col-4"><Label className="text-14 mb-1" htmlFor="minimumQualification">Minimum Qualification</Label></div>
                                                <div className="col"><Input placeholder="Minimum qualification..." type="textarea" onChange={ this.handleChange } id="minimumQualification" name="minimumQualification" value={minimumQualification} className="form-control form-control-sm"/></div>
                                            </div>
                                            <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="picName">*PIC Name</Label></div>
                                                    <div className="col"><Input placeholder="PIC Name..." type="text" onChange={ this.handleChange } name="picName" id="picName" value={picName} className="form-control form-control-sm"/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="picPhoneNumber">*PIC Phone Number</Label></div>
                                                    <div className="col"><Input placeholder="PIC Phone Number..." type="number" onChange={ this.handleChange } name="picPhoneNumber" id="picPhoneNumber" value={picPhoneNumber} className="form-control form-control-sm"/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="partTimeSalary">*Salary</Label></div>
                                                    <div className="col"><NumberFormat onValueChange={ (formattedValue)=>this.handleChangeNumberFormat(formattedValue) } name="partTimeSalary" id="partTimeSalary" value={partTimeSalary} className="form-control form-control-sm" thousandSeparator={true} prefix={'Rp. '} /></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"></div>
                                                    <div className="col">
                                                        <div className="form-check">
                                                            <input onChange={ this.handleCheckBox } className="form-check-input" type="checkbox" checked={isSalaryHide} id="isSalaryHide" name="isSalaryHide"/>
                                                            <label className="form-check-label text-12" htmlFor="isSalaryHide">Confidential Salary / Rates</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="province">*Province</Label></div>
                                                    <div className="col"><Select id="province" name="form-field-name" options={dataProvincies} onChange={this.handleChoseeProvincy.bind(this)}/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="city">*City</Label></div>
                                                    <div className="col"><Select name="form-field-name" id="city" options={dataRegencies} onChange={this.handleChoseeRegency.bind(this)}/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="district">*District</Label></div>
                                                    <div className="col"><Select name="form-field-name" id="district" options={dataSubdistricts} onChange={this.handleChoseeSubdistrict.bind(this)}/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="village">*Village</Label></div>
                                                    <div className="col"><Select name="idVillage" id="village" onChange={this.handleChoseeVillage.bind(this)} options={dataVillages}/></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="address">*Address</Label></div>
                                                    <div className="col"><textarea placeholder="Address..." onChange={ this.handleChange } id="address" name="workAddress" className="form-control form-control-sm">{workAddress}</textarea></div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="start">*Job Period</Label></div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col"><input type="date" className="form-control form-control-sm" id="start" name="start" value={start} onChange={this.handleChange} /></div>
                                                            <div className="col"><input type="date" className="form-control form-control-sm" id="end" name="end" value={end} onChange={this.handleChange} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4"><Label className="text-14 mb-1" htmlFor="startPosting">*Start Posting</Label></div>
                                                    <div className="col customDatePickerWidth">
                                                        <DatePicker style={{width: '100% !important'}} className="form-control form-control-sm" id="startPosting" selected={postingTimeStart} onChange={this.handleChangePostTime} minDate={new Date()} locale="id" showTimeSelect timeFormat="p" timeIntervals={15} dateFormat="Pp"/>
                                                    </div>
                                                </div>
                                        </div>
                                    </div><hr/>
                                    <p className="font-weight-bold">*Attendance</p>
                                    <div className="row">
                                        <div className="col-md">
                                            <Label className="text-14 mb-1">Types</Label>
                                            <Select
                                                name="absen_types"
                                                closeMenuOnSelect={false}
                                                components={this.animatedComponents}
                                                isMulti
                                                options={types}
                                                onChange={ this.handleChooseTypes.bind(this) }
                                            />
                                        </div>
                                        <div className="col-md">
                                            <Label className="text-14 mb-1">Locations</Label>
                                            <Select
                                                name="absen_locations"
                                                closeMenuOnSelect={false}
                                                components={this.animatedComponents}
                                                isMulti
                                                options={locations}
                                                onChange={ this.handleChooseLocations.bind(this) }
                                            />
                                        </div>
                                    </div><hr/>
                                    <p className="font-weight-bold">*Shifting</p>
                                    <button className="btn btn-primary btn-sm mb-2" onClick={this.addShift}>Add Shift</button>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered table-sm" style={{width: '100%'}}>
                                            <thead className="thead-dark">
                                                <tr className="text-center">
                                                    <th>Shift In</th>
                                                    <th>Shift Out</th>
                                                    <th style={{width: '15%'}}>Quota</th>
                                                    <th style={{width: '5%'}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shifts.map((shift, i) => this.shiftingHTML(i, shift))}
                                            </tbody>
                                        </table>
                                    </div><hr/>
                                    <small>
                                        <p style={{color:'red'}} className="text-red">Prefix with symbol * is required (Mandatory).</p>
                                    </small><hr/>
                                    <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.submit}>Next</Button></Col>
                                </CardBody>
                            </Card>  
                        </Container> 
                        <Modal isOpen={modalOpenApproveCandidates} toggle={this.closeModal}>
                            <ModalBody>
                                <h5 className="text-center mb-5">Create Job Part Time</h5>
                                <p> Are you sure you want to create this job ?</p>
                                <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.createJob}>Create</Button></Col>
                            </ModalBody>
                        </Modal> 
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default CreatePartTime;