// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import HeaderUser                                           from './ComponentUser/HeaderUser';

import { Link } from 'react-router-dom'

// Styling
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'
import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class EmergencyContact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emergencyContactRelationship: '',
      emergencyContactName: '',
      emergencyContactPhoneNumber: '',
      emergencyContactAddress: '',
      emergencyContactRelationshipDiff: '',
      emergencyContactNameDiff: '',
      emergencyContactPhoneNumberDiff: '',
      emergencyContactAddressDiff: '',
    }
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        const {
          family_relationship,
          family_name,
          family_phone_number,
          family_domicile_address,
          family_name_diff_house,
          family_phone_number_diff_house,
          family_relationship_diff_house,
          family_domicile_address_diff_house,
        } = data.dataProfile
        this.setState({
          emergencyContactName: family_name,
          emergencyContactPhoneNumber: family_phone_number,
          emergencyContactAddress: family_domicile_address,
          emergencyContactRelationship: family_relationship,
          emergencyContactRelationshipDiff: family_relationship_diff_house,
          emergencyContactNameDiff: family_name_diff_house,
          emergencyContactPhoneNumberDiff: family_phone_number_diff_house,
          emergencyContactAddressDiff: family_domicile_address_diff_house,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleEditEmergencyContact() {
    document.getElementById('ValueNameEmergencyContact').style.display = 'none'
    document.getElementById('NameEmergencyContact').style.display = 'block'
    document.getElementById('ValueNameEmergencyContact2').style.display = 'none'
    document.getElementById('NameEmergencyContact2').style.display = 'block'
    document.getElementById('ValuePhoneEmergencyContact').style.display = 'none'
    document.getElementById('PhoneEmergencyContact').style.display = 'block'
    document.getElementById('ValuePhoneEmergencyContact2').style.display =
      'none'
    document.getElementById('PhoneEmergencyContact2').style.display = 'block'
    document.getElementById('ValueAddressEmergencyContact').style.display =
      'none'
    document.getElementById('AddressEmergencyContact').style.display = 'block'
    document.getElementById('ValueAddressEmergencyContact2').style.display =
      'none'
    document.getElementById('AddressEmergencyContact2').style.display = 'block'
    document.getElementById('ValueRelationEmergencyContact').style.display =
      'none'
    document.getElementById('RelationEmergencyContact').style.display = 'block'
    document.getElementById('ValueRelationEmergencyContact2').style.display =
      'none'
    document.getElementById('RelationEmergencyContact2').style.display = 'block'
    document.getElementById('btnEditEmergencyContact').style.display = 'none'
    document.getElementById('btnCancelEmergencyContact').style.display = 'block'
    document.getElementById('saveChangeEmergencyContact').style.display =
      'block'
  }

  handleCancelEmergencyContact() {
    document.getElementById('ValueNameEmergencyContact').style.display = 'block'
    document.getElementById('NameEmergencyContact').style.display = 'none'
    document.getElementById('ValueNameEmergencyContact2').style.display =
      'block'
    document.getElementById('NameEmergencyContact2').style.display = 'none'
    document.getElementById('ValuePhoneEmergencyContact').style.display =
      'block'
    document.getElementById('PhoneEmergencyContact').style.display = 'none'
    document.getElementById('ValuePhoneEmergencyContact2').style.display =
      'block'
    document.getElementById('PhoneEmergencyContact2').style.display = 'none'
    document.getElementById('ValueAddressEmergencyContact').style.display =
      'block'
    document.getElementById('AddressEmergencyContact').style.display = 'none'
    document.getElementById('ValueAddressEmergencyContact2').style.display =
      'block'
    document.getElementById('AddressEmergencyContact2').style.display = 'none'
    document.getElementById('ValueRelationEmergencyContact').style.display =
      'block'
    document.getElementById('RelationEmergencyContact').style.display = 'none'
    document.getElementById('ValueRelationEmergencyContact2').style.display =
      'block'
    document.getElementById('RelationEmergencyContact2').style.display = 'none'
    document.getElementById('btnEditEmergencyContact').style.display = 'block'
    document.getElementById('btnCancelEmergencyContact').style.display = 'none'
    document.getElementById('saveChangeEmergencyContact').style.display = 'none'
  }

  notify() {
    toast('Berhasil update data !')
  }

  handleSaveEmergencyContact = async () => {
    const {
      emergencyContactName,
      emergencyContactPhoneNumber,
      emergencyContactAddress,
      emergencyContactRelationship,
      emergencyContactRelationshipDiff,
      emergencyContactNameDiff,
      emergencyContactPhoneNumberDiff,
      emergencyContactAddressDiff,
    } = this.state
    const idEmployee = authHelperMethods.getIdEmployee()
    const newData = {
      key_api: config.KEY_API,
      family_name: emergencyContactName,
      family_phone_number: emergencyContactPhoneNumber,
      family_domicile_address: emergencyContactAddress,
      family_relationship: emergencyContactRelationship,
      family_relationship_diff_house: emergencyContactRelationshipDiff,
      family_name_diff_house: emergencyContactNameDiff,
      family_phone_number_diff_house: emergencyContactPhoneNumberDiff,
      family_domicile_address_diff_house: emergencyContactAddressDiff,
    }
    try {
      const res = await api.saveEmergencyContact(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status } = res
      if (status === 200) {
        this.getDataEmployee()
        this.handleCancelEmergencyContact()
        this.notify()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  render() {
    const {
      emergencyContactName,
      emergencyContactPhoneNumber,
      emergencyContactAddress,
      emergencyContactRelationship,
      emergencyContactRelationshipDiff,
      emergencyContactNameDiff,
      emergencyContactPhoneNumberDiff,
      emergencyContactAddressDiff,
    } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    EMERGENCY CONTACT
                  </Label>
                </Col>
                <Col
                  id="btnEditEmergencyContact"
                  className="text-right text-blue"
                >
                  <Link to="#" onClick={this.handleEditEmergencyContact}>
                    <Label className="text-16 mb-0">Edit</Label>
                  </Link>{' '}
                </Col>
                <Col
                  id="btnCancelEmergencyContact"
                  className="text-right text-blue"
                  style={{ display: 'none' }}
                >
                  <Link to="#" onClick={this.handleCancelEmergencyContact}>
                    <Label className="text-16 mb-0">Cancel</Label>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="text-14">
              {/* Emergency Contact One Domicile */}
              <Label className="d-block font-weight-bold text-16">
                Emergency Contact One Domicile
              </Label>
              <hr />
              <Col className="pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Full Name</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueNameEmergencyContact" className="mb-0">
                      {emergencyContactName}
                    </p>
                    <Input
                      type="text"
                      id="NameEmergencyContact"
                      name="emergencyContactName"
                      onChange={this.handleChange}
                      value={this.state.emergencyContactName || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Phone Number</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValuePhoneEmergencyContact" className="mb-0">
                      {emergencyContactPhoneNumber}
                    </p>
                    <Input
                      type="number"
                      id="PhoneEmergencyContact"
                      name="emergencyContactPhoneNumber"
                      onChange={this.handleChange}
                      value={emergencyContactPhoneNumber || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Address Domicile</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueAddressEmergencyContact" className="mb-0">
                      {emergencyContactAddress}
                    </p>
                    <Input
                      type="textarea"
                      id="AddressEmergencyContact"
                      name="emergencyContactAddress"
                      onChange={this.handleChange}
                      value={emergencyContactAddress || ''}
                      style={{
                        height: '100px',
                        resize: 'none',
                        display: 'none',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 mb-5">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Relationship</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueRelationEmergencyContact" className="mb-0">
                      {emergencyContactRelationship}
                    </p>
                    <Input
                      type="text"
                      id="RelationEmergencyContact"
                      name="emergencyContactRelationship"
                      onChange={this.handleChange}
                      value={emergencyContactRelationship || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              {/* End Emergency Contact One Domicile */}
              {/* Emergency Contact Other Domicile */}
              <Label className="d-block font-weight-bold text-16">
                Emergency Contact Other Domicile
              </Label>
              <hr />
              <Col className="pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Full Name</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueNameEmergencyContact2" className="mb-0">
                      {emergencyContactNameDiff}
                    </p>
                    <Input
                      type="text"
                      id="NameEmergencyContact2"
                      name="emergencyContactNameDiff"
                      onChange={this.handleChange}
                      value={emergencyContactNameDiff || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Phone Number</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValuePhoneEmergencyContact2" className="mb-0">
                      {emergencyContactPhoneNumberDiff}
                    </p>
                    <Input
                      type="number"
                      id="PhoneEmergencyContact2"
                      name="emergencyContactPhoneNumberDiff"
                      onChange={this.handleChange}
                      value={emergencyContactPhoneNumberDiff || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Address Domicile</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueAddressEmergencyContact2" className="mb-0">
                      {emergencyContactAddressDiff}
                    </p>
                    <Input
                      type="textarea"
                      id="AddressEmergencyContact2"
                      name="emergencyContactAddressDiff"
                      onChange={this.handleChange}
                      value={emergencyContactAddressDiff || ''}
                      style={{
                        height: '100px',
                        resize: 'none',
                        display: 'none',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0">
                    <p className="font-weight-bold mb-0">Relationship</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueRelationEmergencyContact2" className="mb-0">
                      {emergencyContactRelationshipDiff}
                    </p>
                    <Input
                      type="text"
                      id="RelationEmergencyContact2"
                      name="emergencyContactRelationshipDiff"
                      onChange={this.handleChange}
                      value={emergencyContactRelationshipDiff || ''}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              {/* End Emergency Contact Other Domicile */}

              <Col
                className="pl-0 pr-0"
                id="saveChangeEmergencyContact"
                style={{ display: 'none' }}
              >
                <hr />
                <Button
                  className="btn-block btn-blue-normal"
                  onClick={this.handleSaveEmergencyContact}
                >
                  Save Change
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

export default EmergencyContact
