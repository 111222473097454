import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import classnames                                           from 'classnames';
import { Container, Row, Col, TabContent, TabPane, 
         Label, Nav, NavItem, NavLink, Button, 
         Input, Card, CardHeader, CardBody, 
         Modal, ModalBody }                                 from 'reactstrap';  
import Select                                               from 'react-select'; 
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import * as method                                          from '../Helpers/Method';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faTimesCircle }                                    from '@fortawesome/free-solid-svg-icons';
import DateTimePicker                                       from 'react-datetime-picker';

class ChooseJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            idClient:0,
            idJob:0,
            idEmployee:0,
            spesificName:"",
            interview_date:"",
            picName:"",
            picPhoneNumber:"",
            description:"",
            searchString:'',
            location:'all',
            dataJobPartTime:[],
            dataJobFullTime:[],
            listRegency:[],
            dataClient:{},
            modalSetInterviewFullTime:false,
            isSortingSlot:false,
            isSortingPeriod:false,
            isSortingMaxSalary:false,
            isSortingMinSalary:false,
        };
    }

    componentDidMount= async () => {
        await this.getId();
        await this.getDataEmployee();
        await this.getDataClient();
        await this.getAllRegency();
    }
    
    getDataClient = async () => {
        const { idClient } = this.state;    
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(idClient,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataClient:data,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ idClient: id});
    }

    getDataEmployee = async () => {
        const { id_employee, full_name } = JSON.parse(localStorage.getItem('dataEmployee'));
        this.setState({idEmployee:id_employee, spesificName:full_name});
        await this.getJobFulltime(id_employee);
        await this.getJobParttime(id_employee);
    }

    getJobFulltime = async (id_employee) => {
        const { idClient } = this.state;
        const newData = {key_api:config.KEY_API, work_type:"Full-Time", id_employee:id_employee};
        try{
            const res = await api.getJobByIdEmployee(idClient,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const { data } = res.data;
            this.setState({
                dataJobFullTime:data,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobParttime = async (id_employee) => {
        const { idClient } = this.state;
        const newData = {key_api:config.KEY_API, work_type:"Part-Time", id_employee:id_employee};
        try{
            const res = await api.getJobByIdEmployee(idClient,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const { data } = res.data;
            this.setState({
                dataJobPartTime:data,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeSearch = (e) => {
        this.setState({ searchString : e.currentTarget.value });
    }

    handleChangeDateInterview = (e) => {
        this.setState({ 
                        interview_date : e, 
                    });
    }

    closeModalSetInterview = ()=>{
        this.setState(prevState => ({
            modalSetInterviewFullTime: !prevState.modalSetInterviewFullTime
        }));            
    }

    chooseJobFulltime = async (idJob) => {
        this.setState({idJob:idJob});
        this.setState(prevState => ({
            modalSetInterviewFullTime: !prevState.modalSetInterviewFullTime
        }));            
    }
        
    inviteCandidateToFullTime = async () => {
        const {idJob, idEmployee, idClient, interview_date, picName, picPhoneNumber, description} = this.state;
        const newData = {
                            key_api:config.KEY_API, id_job:idJob, 
                            id_employee:idEmployee, 
                            interview_date:interview_date,
                            pic_name:picName,
                            pic_phone_number:picPhoneNumber,
                            description:description
                        }
        try{
            const res = await api.inviteCandidateToFullTime(idClient,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const { status } = res;
            if(status===200){
                localStorage.removeItem("dataEmployee")
                window.location.href = config.WEB_URL+"find-talent";
                this.setState(prevState => ({
                    modalSetInterviewFullTime: !prevState.modalSetInterviewFullTime
                }));            
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    chooseJobParttime = (idJob) => {
        this.setState({idJob:idJob});
        this.setState(prevState => ({
            modalPartTime: !prevState.modalPartTime
        }));            
    }

    closeModalPartTime = () => {
        this.setState(prevState => ({
            modalPartTime: !prevState.modalPartTime
        }));            
    }

    inviteCandidateToPartTime = async () => {
        const {idJob, idEmployee, idClient} = this.state;
        const newData = {key_api:config.KEY_API,id_job:idJob, id_employee:idEmployee, id_client:idClient}
        try{
            const res = await api.inviteCandidateToPartTime(idClient,
                {params:{
                    data:JSON.stringify(newData)
                }
            });
            const { status } = res;
            if(status===200){
                localStorage.removeItem("dataEmployee")
                window.location.href = config.WEB_URL+"find-talent";
                this.setState(prevState => ({
                    modalPartTime: !prevState.modalPartTime
                }));            
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
              activeTab: tab
            });
        }
        const {id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

        if(tab==0){
            this.getJobFulltime(id_employee)
        }else if(tab==1){
            this.getJobParttime(id_employee)
        }
    }

    getAllRegency= async (e) => {
        const newData = {
            key_api: config.KEY_API,
          };
          try{
            const res = await api.getAllRegencies({
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({listRegency:data.dataAllRegencies.map((k) => ({ value: k.id, label: k.regency_name })) })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    handleChoose = async (element) => {
        await this.setState({location:element.label});
        this.filterJobs(element.label);
    }

    filterJobs= async (value) =>{
        const {location, activeTab, idClient}= this.state
          try{
            const { id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

                const newData = {
                    key_api: config.KEY_API,
                    work_type:"Full-Time",
                    id_employee:id_employee
                };
                if(activeTab==1){
                    newData["work_type"]="Part-Time"
                }
                const res = await api.getJobByIdEmployee(idClient,{
                    params: {data: JSON.stringify(newData)}
                })
                const {status, data} = res;
                if(status === 200){
                    if(activeTab==0){
                        if(location=="all"){
                            this.setState({dataJobFullTime:data.data})
                        }else{
                            const listIdLoanLocation= [];
                                for(var i=0; i<data.data.length; i++){
                                    if(data.data[i].work_location === value){
                                        listIdLoanLocation.push(data.data[i].id)
                                    }
                                }
                            
                            const allListFilter = [...listIdLoanLocation]
                            var counts = {};
                            allListFilter.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
        
                            const finalListFilter= []
                            Object.entries(counts).map(([key,value])=>{
                                for(var i=0; i<data.data.length; i++){
                                    if(data.data[i].id == key){        
                                        finalListFilter.push(data.data[i])
                                    }
                                }
                                this.setState({dataJobFullTime:finalListFilter})
                            })
                            if(finalListFilter.length === 0){
                                this.setState({dataJobFullTime:finalListFilter})
                            }
                        }
                    }else if(activeTab==1){
                        if(location=="all"){
                            this.setState({dataJobPartTime:data.data})
                        }else{
                            const listIdLoanLocation= [];
                                for(var i=0; i<data.data.length; i++){
                                    if(data.data[i].work_location === value){
                                        listIdLoanLocation.push(data.data[i].id)
                                    }
                                }
                            
                            const allListFilter = [...listIdLoanLocation]
                            var counts = {};
                            allListFilter.forEach(function(x) { counts[x] = (counts[x] || 0)+1; });
        
                            const finalListFilter= []
                            Object.entries(counts).map(([key,value])=>{
                                for(var i=0; i<data.data.length; i++){
                                    if(data.data[i].id == key){        
                                        finalListFilter.push(data.data[i])
                                    }
                                }
                                this.setState({dataJobPartTime:finalListFilter})
                            })
                            if(finalListFilter.length === 0){
                                this.setState({dataJobPartTime:finalListFilter})
                            }
                        }
                    }
                }
            } catch(err){
                //   console.log("Error Response :",err)
            }
    }

    handleChangeSortPeriod = async (e) => {
        const { isSortingPeriod, activeTab } = this.state;
        const {id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

        if(e.target.checked === true){
            this.sortJob(e.target.value);
        } else {
            if(activeTab==0){
                this.getJobFulltime(id_employee)
            }else if(activeTab==1){
                this.getJobParttime(id_employee)
            }
        }
        this.setState({
            isSortingSlot:false,
            isSortingPeriod:!isSortingPeriod,
            isSortingMaxSalary:false,
            isSortingMinSalary:false,
        })
    }

    handleChangeSortSlot = async (e) => {
        const { isSortingSlot, activeTab } = this.state;
        const {id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

        if(e.target.checked === true){
            this.sortJob(e.target.value);
        } else {
            if(activeTab==0){
                this.getJobFulltime(id_employee)
            }else if(activeTab==1){
                this.getJobParttime(id_employee)
            }
        }
        this.setState({
            isSortingSlot:!isSortingSlot,
            isSortingPeriod:false,
            isSortingMaxSalary:false,
            isSortingMinSalary:false,
        });
    }

    handleChangeSortMax = async (e) => {
        const { isSortingMaxSalary, activeTab } = this.state;
        const {id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

        if(e.target.checked === true){
            this.sortJob(e.target.value);
        } else {
            if(activeTab==0){
                this.getJobFulltime(id_employee)
            }else if(activeTab==1){
                this.getJobParttime(id_employee)
            }
        }
        this.setState({
            isSortingSlot:false,
            isSortingPeriod:false,
            isSortingMaxSalary:!isSortingMaxSalary,
            isSortingMinSalary:false,
        })
    }

    handleChangeSortMin = async (e) => {
        const { isSortingMinSalary, activeTab } = this.state;
        const {id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));

        if(e.target.checked === true){
            this.sortJob(e.target.value);
        } else {
            if(activeTab==0){
                this.getJobFulltime(id_employee)
            }else if(activeTab==1){
                this.getJobParttime(id_employee)
            }
        }
        this.setState({
            isSortingSlot:false,
            isSortingPeriod:false,
            isSortingMaxSalary:false,
            isSortingMinSalary:!isSortingMinSalary,
        })
    }

    sortJob= async (category) => {
        const {idClient, activeTab} = this.state
        try{
            const { id_employee} = JSON.parse(localStorage.getItem('dataEmployee'));
            const newData = {
                key_api: config.KEY_API,
                work_type:"Full-Time",
                id_employee:id_employee
              };

            if(activeTab==1){
                newData["work_type"]="Part-Time"
            }    
            const res = await api.getJobByIdEmployee(idClient,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
                if(activeTab==0){
                    if(category=="Slot"){
                        const slotArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => a.quota > b.quota ? 1 : -1)
                                            .map((item) => 
                                                slotArray.push(item)
                                            );
                        this.setState({dataJobFullTime:slotArray})
                    } else if(category=="Periode"){
                        const periodArray = [];
                        [].concat(data.data)
                                            .sort( (a, b) => new Date(a.deadline_job_vacancy) - new Date(b.deadline_job_vacancy) )
                                            .map((item) => 
                                                periodArray.push(item)
                                            );
                        this.setState({dataJobFullTime:periodArray})
                    } else if(category=="Highest Salary"){
                        const maxSalaryArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => parseInt(b.min_salary) > parseInt(a.min_salary) ? 1 : -1)
                                            .map((item) => 
                                            maxSalaryArray.push(item)
                                            );
                        this.setState({dataJobFullTime:maxSalaryArray})
                    }else if(category=="Lowest Salary"){
                        const minSalaryArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => parseInt(a.min_salary) > parseInt(b.min_salary) ? 1 : -1)
                                            .map((item) => 
                                            minSalaryArray.push(item)
                                            );
                        this.setState({dataJobFullTime:minSalaryArray})
                    }
                }
                else if(activeTab==1){
                    if(category=="Slot"){
                        const slotArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => a.quota > b.quota ? 1 : -1)
                                            .map((item) => 
                                                slotArray.push(item)
                                            );
                        this.setState({dataJobPartTime:slotArray})
                    } else if(category=="Periode"){
                        const periodArray = [];
                        [].concat(data.data)
                                            .sort( (a, b) => new Date(a.part_time_date) - new Date(b.part_time_date) )
                                            .map((item) => 
                                                periodArray.push(item)
                                            );
                        this.setState({dataJobPartTime:periodArray})
                    } else if(category=="Highest Salary"){
                        const maxSalaryArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => parseInt(b.part_time_salary) > parseInt(a.part_time_salary) ? 1 : -1)
                                            .map((item) => 
                                            maxSalaryArray.push(item)
                                            );
                        this.setState({dataJobPartTime:maxSalaryArray})
                    }else if(category=="Lowest Salary"){
                        const minSalaryArray = [];
                        [].concat(data.data)
                                            .sort((a, b) => parseInt(a.part_time_salary) > parseInt(b.part_time_salary) ? 1 : -1)
                                            .map((item) => 
                                            minSalaryArray.push(item)
                                            );
                        this.setState({dataJobPartTime:minSalaryArray})
                    }
                }
                
            }
        } catch(err){
            //   console.log("Error Response :",err)
          }
    }


    render() {
        const { 
                spesificName, interview_date, dataJobFullTime, dataJobPartTime, modalSetInterviewFullTime, modalPartTime,
                picName, picPhoneNumber, description, searchString, activeTab, listRegency, isSortingSlot, isSortingPeriod, isSortingMaxSalary, isSortingMinSalary } = this.state;
            const lowercasedFilter = searchString.toLowerCase();
            let filteredDataFulltime=[];
            let filteredDataParttime=[];
            if(activeTab==0){
                filteredDataFulltime = dataJobFullTime.filter(item => {
                    return Object.keys(item).some(key =>
                    item['job_category'].toLowerCase().includes(lowercasedFilter)
                    );
                });
            }else if (activeTab==1){
                filteredDataParttime = dataJobPartTime.filter(item => {
                    return Object.keys(item).some(key =>
                    item['job_category'].toLowerCase().includes(lowercasedFilter)
                    );
                });
            }
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Choose Job</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div fluid className="pb-5 muli-font bg-body-grey">
                        <Container className="pt-5">
                            <Card>
                                <CardHeader><h6 className="font-weight-bolder my-auto">Choose Job</h6></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="3">
                                        <Col className="bg-white rounded box-shadow mb-3 p-3">
                                            <Col className="mb-3">
                                                <Label className="d-block text-14 font-weight-bolder">Search</Label>
                                                <Input 
                                                type="text" 
                                                id="search" 
                                                className="search input-box-grey text-12" 
                                                placeholder="search job name" 
                                                value={searchString}
                                                onChange={this.handleChangeSearch}/>
                                            </Col>
                                            <Col className="mb-3">
                                                <Label className="d-block text-14 font-weight-bolder">Location</Label>
                                                <Select
                                                            name="form-field-name"
                                                            options={listRegency}
                                                            onChange={this.handleChoose.bind(this)}/> 
                                            </Col>
                                            <Col className="mb-3">
                                                <Label className="d-block text-14 font-weight-bolder">Sort By:</Label>
                                                <div className="spacing-checkbox text-12">
                                                    <Input type="checkbox" id="Periode" checked={isSortingPeriod===false?false:true} className="checkboxLocation check-box" value="Periode" onChange={this.handleChangeSortPeriod}/> Periode<br/>
                                                    <Input type="checkbox" id="Slot" checked={isSortingSlot===false?false:true} className="checkboxLocation check-box" value="Slot" onChange={this.handleChangeSortSlot}/> Slot<br/>
                                                    <Input type="checkbox" id="Highest Salary" checked={isSortingMaxSalary===false?false:true} className="checkboxLocation check-box" value="Highest Salary" onChange={this.handleChangeSortMax}/> Highest Salary<br/>
                                                    <Input type="checkbox" id="Lowest Salary" checked={isSortingMinSalary===false?false:true} className="checkboxLocation check-box" value="Lowest Salary" onChange={this.handleChangeSortMin}/> Lowest Salary<br/>
                                                </div>
                                            </Col>
                                        </Col>
                                        </Col>
                                        <Col lg="9">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === '0' })}
                                                            onClick={(e) => { this.toggle('0'); }} style={{borderRadius:'12px 12px 0 0'}}><p className="mb-0 font-weight-bold text-14">Full Time Job</p> 
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === '1' })}
                                                            onClick={(e) => { this.toggle('1'); }} style={{borderRadius:'12px 12px 0 0'}}><p className="mb-0 font-weight-bold text-14">Part Time Job</p> 
                                                    </NavLink>
                                                </NavItem> */}
                                            </Nav>
                                            <TabContent activeTab={activeTab} className="border-left border-right border-bottom text-12">
                                            {/* ------- Tab Full time Talent --------*/}
                                                <TabPane tabId="0" className="bg-white p-3" style={{minHeight:'470px'}}>
                                                    {
                                                        filteredDataFulltime.length != 0 ?
                                                        filteredDataFulltime.map((item) => 
                                                            <Col className="box-shadow p-3 mb-3">
                                                                <Row>
                                                                    <Col>
                                                                        <p className="text-16 font-weight-bolder text-capitalize">{item.job_category}</p>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className="mb-1 text-14">{item.quota} People</p>
                                                                                <p className="mb-1 text-14">{item.work_location}</p>
                                                                                <p className="mb-0 text-14">{item.job_position}</p>
                                                                            </Col>
                                                                            <Col>
                                                                                <p className="mb-1 text-14">{method.formatRupiah(item.min_salary)} - {method.formatRupiah(item.max_salary)}</p>
                                                                                <p className="mb-1 text-14">{item.min_experience_year} Year Experience</p>
                                                                                <p className="mb-0 text-14">Deadline {method.formatDateDay(item.deadline_job_vacancy)}</p>
                                                                            </Col>
                                                                            <Col>
                                                                                <Button onClick={()=>this.chooseJobFulltime(item.id)} className="btn-block btn-warning">Invite to this Job</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    : <h3> <center> Data Not Found </center></h3>                                                
                                                    }
                                                </TabPane>
                                            {/* ------- End Tab Full time Talent --------*/}
                                            {/* ------- Tab Part Time Talent --------*/}
                                            <TabPane tabId="1" className="bg-white p-3" style={{minHeight:'470px'}}>
                                                    { filteredDataParttime.length != 0 ? 
                                                        filteredDataParttime.map((item) => 
                                                            <Col className="box-shadow p-3 mb-3">
                                                                <Row>
                                                                    <Col>
                                                                        <p className="text-16 font-weight-bolder text-capitalize">{item.job_category}</p>
                                                                        <Row>
                                                                            <Col>
                                                                                <p className="mb-1 text-14">{item.quota} People</p>
                                                                                <p className="mb-1 text-14">{item.work_location}</p>
                                                                                <p className="mb-0 text-14">{item.job_position}</p>
                                                                            </Col>
                                                                            <Col>
                                                                                <p className="mb-1 text-14">{method.formatRupiah(item.part_time_salary)}</p>
                                                                                <p className="mb-1 text-14">{item.min_experience_year} Year Experience</p>
                                                                                <p className="mb-0 text-14">Deadline {method.formatDateDay(item.part_time_date)}</p>
                                                                            </Col>
                                                                            <Col>
                                                                                <Button onClick={()=>this.chooseJobParttime(item.id)}className="btn-block btn-warning">Invite to this Job</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    : <h3> <center> Data Not Found </center></h3>                                                
                                                }
                                                </TabPane>
                                            {/* ------- End Tab Part Time Talent --------*/}
                                                </TabContent>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>  
                            </Container>  
                            <Modal  isOpen={modalSetInterviewFullTime} toggle={this.closeModalSetInterview} backdrop="static">
                                <ModalBody>
                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeModalSetInterview}/>
                                    <Row>
                                        <Col>
                                            <Label className="font-weight-bold d-block">Name of Candidate</Label>
                                            <Label className="d-block mb-3">{spesificName}</Label>

                                            <Label className="font-weight-bold d-block">Schedule Interview</Label>
                                            <Label className="d-block mb-3">{interview_date===""?"-":method.formatDateAndTime(interview_date)}</Label>
                                        </Col>
                                        <Col>
                                            <Label className="font-weight-bold d-block">Create Schedule Interview</Label>
                                            <DateTimePicker onChange={(e)=>this.handleChangeDateInterview(e)} value={interview_date} className="mb-3" name="interview_date"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label> PIC Name </Label>
                                        </Col>
                                        <Col>
                                            <Input name="picName" onChange={this.handleChange} placeholder="Masukkan nama pic " type="text"> </Input>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Label> PIC Phone Number </Label>
                                        </Col>
                                        <Col>
                                            <Input name="picPhoneNumber" onChange={this.handleChange} placeholder="Masukkan nomor hp pic" type="number"> </Input>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <Label> Keterangan </Label>
                                        </Col>
                                        <Col>
                                            <Input name="description" onChange={this.handleChange} placeholder="Masukkan keterangan terkait interview" type="textarea"> </Input>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Col lg="6" className="mx-auto"><Button disabled={(interview_date=="") ||(picName=="") || (picPhoneNumber=="") || (description=="") ? true : false} className="btn-block btn-warning" onClick={this.inviteCandidateToFullTime}>Invite</Button></Col>
                                </ModalBody>
                            </Modal>
                            <Modal  isOpen={modalPartTime} toggle={this.closeModalPartTime} backdrop="static">
                                <ModalBody className="p-5">
                                    <Label className="d-block text-center text-16 font-weight-bold mb-4"> Are you sure, you want to invite {spesificName} at this job ?</Label>
                                    <Col className="mx-auto mt-4">
                                        <Button className="btn-block btn-warning" onClick={this.inviteCandidateToPartTime}>Invite</Button>
                                        <Button className="btn-block btn-outline-danger" onClick={this.closeModalPartTime}>Cancel</Button>
                                    </Col>
                                </ModalBody>
                            </Modal>

                        </div>
                    </div>
            </Fragment>
        );
    }
}

export default ChooseJob;