// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderUser                                           from './ComponentUser/HeaderUser';
// import NavUser                                              from './ComponentUser/NavUser';
import Filter                                               from '../User/ComponentUser/Filter';
import SalaryUser                                           from '../User/ComponentUser/SalaryUser';
import HistoryJobUser                                       from '../User/ComponentUser/HistoryJobUser';
import classnames                                           from 'classnames';

// import { Link }                                             from 'react-router-dom';

import Wallet                                               from '../Asset/Icon/wallet.svg';

// Styling
import { Container, Row, Col, TabContent, TabPane, 
         Nav, NavItem, NavLink }                            from 'reactstrap';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from '../Helpers/Method';
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
         
class HistoryUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            financeBalance:0
            };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    componentDidMount = async () =>{
        await this.getDataEmployee();
    }

    getDataEmployee = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
        try{
            const res = await api.profileEmployee(idEmployee,{
                params: {data: JSON.stringify(newData)}
            })
            const {status, data} = res;
            if(status === 200){
                this.setState({
                financeBalance:data.dataProfile.total_salary_this_month
                })
            }
        } catch(err){
        //   console.log("Error Response :",err)
        }   
    }

    render() {  
        const {financeBalance}= this.state                                   
        return (          
            <Fragment>
                <HeaderUser/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder my-auto pt-2 pb-2">History</h5>
                                </Col>
                                <Col className="my-auto text-right">
                                    <img src={Wallet} alt="wallet-avisha" width="30px" className="mr-2" />
                                    <p className="d-inline-flex font-weight-bolder mb-0">Balance : {financeBalance==null ? "Rp.0":method.formatRupiah(financeBalance)}</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">                      
                        {/* <Row> */}
                            {/* <Col md="4" lg="3" className="pl-0 pr-0">
                                <Filter/>
                            </Col> */}
                            <Col md="12" lg="12">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '0' })}
                                            onClick={(e) => { this.toggle('0'); }} style={{borderRadius:'12px 12px 0 0'}}><p className="mb-0 font-weight-bold">Jobs</p>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={(e) => { this.toggle('1'); }} style={{borderRadius:'12px 12px 0 0'}}><p className="mb-0 font-weight-bold">Salary</p> 
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} className="border-left border-right border-bottom text-12">
                                    {/* ------- Tab Jobs --------*/}
                                    <TabPane tabId="0" className="bg-white p-3" style={{minHeight:'470px'}}> 
                                        <HistoryJobUser/>
                                    </TabPane>
                                    {/* ------- End Tab Parttime --------*/}

                                    {/* ------- Tab Salary --------*/}
                                    <TabPane tabId="1" className="bg-white p-3" style={{minHeight:'470px'}}>
                                        <SalaryUser/>
                                    </TabPane>
                                    {/* ------- End Tab Salary --------*/}
                                </TabContent>
                            </Col>
                        {/* </Row> */}
                    </Container>  
                </div>
            </Fragment>
        );   
    }
};

export default HistoryUser;