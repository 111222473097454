// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import HeaderUser                                           from './ComponentUser/HeaderUser';

import { Link } from 'react-router-dom'

// Styling
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  Label,
  InputGroupAddon,
  Card,
  CardHeader,
  Button,
  CardBody,
  InputGroupText,
} from 'reactstrap'

import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import * as method from '../../../Helpers/Method'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fullName: '',
      profileImage: null,
      domicileAddress: '',
      idCardAddress: '',
      birthDate: '',
      birthPlace: '',
      gender: '',
      religion: '',
      phoneNumber: '',
      email: '',
      height: '',
      weight: '',
      idCardNumber: '',
    }
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleGender = (element) => {
    this.setState({ gender: element.value })
  }

  handleReligion = async (element) => {
    this.setState({ religion: element.value })
  }

  notify() {
    toast('Berhasil update data !')
  }

  handleSave = async () => {
    const {
      fullName,
      birthPlace,
      birthDate,
      gender,
      religion,
      phoneNumber,
      email,
      height,
      weight,
      idCardNumber,
      idCardAddress,
      domicileAddress,
    } = this.state
    const idEmployee = authHelperMethods.getIdEmployee()
    const newData = {
      key_api: config.KEY_API,
      full_name: fullName,
      birth_place: birthPlace,
      birth_date: birthDate,
      gender: gender,
      religion: religion,
      phone_number: phoneNumber,
      email: email,
      height: height,
      weight: weight,
      id_card_number: idCardNumber,
      id_card_address: idCardAddress,
      domicile_address: domicileAddress,
    }
    try {
      const res = await api.savePersonalInformationsEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status } = res
      if (status === 200) {
        this.getDataEmployee()
        this.handleCancelPersonalInfo()
        this.notify()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        const {
          full_name,
          email,
          phone_number,
          id_card_address,
          domicile_address,
          birth_place,
          birth_date,
          gender,
          religion,
          id_card_number,
          height,
          weight,
        } = data.dataProfile
        this.setState({
          fullName: full_name,
          domicileAddress: domicile_address,
          idCardAddress: id_card_address,
          phoneNumber: phone_number,
          email: email,
          // profileImage:null,
          birthDate: birth_date,
          birthPlace: birth_place,
          gender: gender,
          religion: religion,
          height: height,
          weight: weight,
          idCardNumber: id_card_number,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handleEditPersonalInfo() {
    document.getElementById('ValueFullName').style.display = 'none'
    document.getElementById('EditFullName').style.display = 'block'
    document.getElementById('ValuePlaceOfBirth').style.display = 'none'
    document.getElementById('EditPlaceOfBirth').style.display = 'block'
    document.getElementById('ValueDateOfBirth').style.display = 'none'
    document.getElementById('dateOfBirth').style.display = 'block'
    document.getElementById('ValueGender').style.display = 'none'
    document.getElementById('EditGender').style.display = 'block'
    document.getElementById('ValueReligion').style.display = 'none'
    document.getElementById('EditReligion').style.display = 'block'
    document.getElementById('ValuePhoneNumber').style.display = 'none'
    document.getElementById('phoneNumber').style.display = 'block'
    document.getElementById('ValueEmail').style.display = 'none'
    document.getElementById('emailUser').style.display = 'block'
    document.getElementById('ValueHeight').style.display = 'none'
    document.getElementById('GroupHeightUser').style.display = 'flex'
    document.getElementById('ValueWeight').style.display = 'none'
    document.getElementById('GroupWeightUser').style.display = 'flex'
    document.getElementById('ValueIdCardNumber').style.display = 'none'
    document.getElementById('idCardNumber').style.display = 'block'
    document.getElementById('ValueIdCardAddress').style.display = 'none'
    document.getElementById('IdCardAddress').style.display = 'block'
    document.getElementById('ValueDomicile').style.display = 'none'
    document.getElementById('DomicileAddress').style.display = 'block'
    document.getElementById('btnEditPersonalInfo').style.display = 'none'
    document.getElementById('btnCancelEditPersonalInfo').style.display = 'block'
    document.getElementById('saveChangePersonalInfo').style.display = 'block'
  }

  handleCancelPersonalInfo() {
    document.getElementById('ValueFullName').style.display = 'block'
    document.getElementById('EditFullName').style.display = 'none'
    document.getElementById('ValuePlaceOfBirth').style.display = 'block'
    document.getElementById('EditPlaceOfBirth').style.display = 'none'
    document.getElementById('ValueDateOfBirth').style.display = 'block'
    document.getElementById('dateOfBirth').style.display = 'none'
    document.getElementById('ValueGender').style.display = 'block'
    document.getElementById('EditGender').style.display = 'none'
    document.getElementById('ValueReligion').style.display = 'block'
    document.getElementById('EditReligion').style.display = 'none'
    document.getElementById('ValuePhoneNumber').style.display = 'block'
    document.getElementById('phoneNumber').style.display = 'none'
    document.getElementById('ValueEmail').style.display = 'block'
    document.getElementById('emailUser').style.display = 'none'
    document.getElementById('ValueHeight').style.display = 'block'
    document.getElementById('GroupHeightUser').style.display = 'none'
    document.getElementById('ValueWeight').style.display = 'block'
    document.getElementById('GroupWeightUser').style.display = 'none'
    document.getElementById('ValueIdCardNumber').style.display = 'block'
    document.getElementById('idCardNumber').style.display = 'none'
    document.getElementById('ValueIdCardAddress').style.display = 'block'
    document.getElementById('IdCardAddress').style.display = 'none'
    document.getElementById('ValueDomicile').style.display = 'block'
    document.getElementById('DomicileAddress').style.display = 'none'
    document.getElementById('btnEditPersonalInfo').style.display = 'block'
    document.getElementById('btnCancelEditPersonalInfo').style.display = 'none'
    document.getElementById('saveChangePersonalInfo').style.display = 'none'
  }

  render() {
    const {
      fullName,
      domicileAddress,
      idCardAddress,
      birthDate,
      birthPlace,
      gender,
      religion,
      phoneNumber,
      email,
      height,
      weight,
      idCardNumber,
    } = this.state
    const genderOptions = [
      { value: 'Laki - laki', label: 'Laki - laki' },
      { value: 'Perempuan', label: 'Perempuan' },
    ]
    const religionOptions = [
      { value: 'Islam', label: 'Islam' },
      { value: 'Kristen Protestan', label: 'Kristen Protestan' },
      { value: 'Kristen Katolik', label: 'Kristen Katolik' },
      { value: 'Buddha', label: 'Buddha' },
      { value: 'Hindu', label: 'Hindu' },
    ]
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    PERSONAL INFORMATIONS
                  </Label>
                </Col>
                <Col id="btnEditPersonalInfo" className="text-right text-blue">
                  <Link to="#" onClick={this.handleEditPersonalInfo}>
                    <Label className="text-16 mb-0">Edit</Label>
                  </Link>
                </Col>
                <Col
                  id="btnCancelEditPersonalInfo"
                  className="text-right text-blue"
                  style={{ display: 'none' }}
                >
                  <Link to="#" onClick={this.handleCancelPersonalInfo}>
                    <Label className="text-16 mb-0">Cancel</Label>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="text-14">
              <Col className="pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">Full Name</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueFullName" className="mb-0">
                      {fullName}
                    </p>
                    <Input
                      type="text"
                      id="EditFullName"
                      name="fullName"
                      onChange={this.handleChange}
                      value={fullName}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold mb-0">Place of Birth</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValuePlaceOfBirth" className="mb-0">
                      {birthPlace === null ? '-' : birthPlace}
                    </p>
                    <Input
                      type="text"
                      id="EditPlaceOfBirth"
                      name="birthPlace"
                      onChange={this.handleChange}
                      value={birthPlace}
                      style={{ display: 'none' }}
                      placeholder="Tempat lahir"
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">Date of Birth</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueDateOfBirth" className="mb-0">
                      {birthDate === null
                        ? '-'
                        : method.formatDateDay(birthDate)}
                    </p>
                    <Input
                      type="date"
                      id="dateOfBirth"
                      name="birthDate"
                      onChange={this.handleChange}
                      value={birthDate === null ? '' : birthDate}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">Gender</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueGender" className="mb-0">
                      {gender === null ? '-' : gender}
                    </p>
                    <div id="EditGender" style={{ display: 'none' }}>
                      <Select
                        name="placeOfBirth"
                        value={genderOptions.filter(
                          (option) => option.label === gender,
                        )}
                        options={genderOptions}
                        style={{ display: 'none' }}
                        onChange={this.handleGender.bind(this)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold mb-0">Religion</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueReligion" className="mb-0">
                      {religion === null ? '-' : religion}
                    </p>
                    <div id="EditReligion" style={{ display: 'none' }}>
                      <Select
                        name="placeOfBirth"
                        value={religionOptions.filter(
                          (option) => option.label === religion,
                        )}
                        onChange={this.handleReligion.bind(this)}
                        options={religionOptions}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">Phone Number</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValuePhoneNumber" className="mb-0">
                      {phoneNumber}
                    </p>
                    <Input
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={this.handleChange}
                      value={phoneNumber}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">E-mail</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueEmail" className="mb-0">
                      {email}
                    </p>
                    <Input
                      type="email"
                      id="emailUser"
                      name="email"
                      onChange={this.handleChange}
                      value={email}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold mb-0">Body Height</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueHeight" className="mb-0">
                      {height === null ? '-' : height + ' cm'}
                    </p>
                    <InputGroup
                      id="GroupHeightUser"
                      style={{ display: 'none' }}
                    >
                      <Input
                        type="number"
                        id="heightUser"
                        name="height"
                        onChange={this.handleChange}
                        value={height}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="text-14">CM</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold  mb-0">Body Weight</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueWeight" className="mb-0">
                      {weight === null ? '-' : weight + ' kg'}
                    </p>
                    <InputGroup
                      id="GroupWeightUser"
                      style={{ display: 'none' }}
                    >
                      <Input
                        type="number"
                        id="weightUser"
                        name="weight"
                        onChange={this.handleChange}
                        value={weight}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="text-14">KG</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col xs="6" sm="5" md="5" lg="5" className="p-0 my-auto">
                    <p className="font-weight-bold mb-0">
                      Number ID Card (E-KTP)
                    </p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueIdCardNumber" className="mb-0">
                      {idCardNumber === null ? '-' : idCardNumber}
                    </p>
                    <Input
                      type="number"
                      id="idCardNumber"
                      name="idCardNumber"
                      onChange={this.handleChange}
                      value={idCardNumber}
                      style={{ display: 'none' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2 pb-2">
                <Row>
                  <Col
                    xs="6"
                    sm="5"
                    md="5"
                    lg="5"
                    className="pl-0 pr-0 my-auto"
                  >
                    <p className="font-weight-bold mb-0">Address on ID Card</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueIdCardAddress" className="text-justify mb-0">
                      {idCardAddress === null ? '-' : idCardAddress}
                    </p>
                    <Input
                      type="textarea"
                      id="IdCardAddress"
                      name="idCardAddress"
                      onChange={this.handleChange}
                      value={idCardAddress}
                      style={{
                        height: '100px',
                        resize: 'none',
                        display: 'none',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="border-top pt-2">
                <Row>
                  <Col
                    xs="6"
                    sm="5"
                    md="5"
                    lg="5"
                    className="pl-0 pr-0 my-auto"
                  >
                    <p className="font-weight-bold mb-0">Address Domicile</p>
                  </Col>
                  <Col className="p-0">
                    <p id="ValueDomicile" className="text-justify mb-0">
                      {domicileAddress === null ? '-' : domicileAddress}
                    </p>
                    <Input
                      type="textarea"
                      id="DomicileAddress"
                      name="domicileAddress"
                      onChange={this.handleChange}
                      value={domicileAddress || ''}
                      style={{
                        height: '100px',
                        resize: 'none',
                        display: 'none',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                className="pl-0 pr-0"
                id="saveChangePersonalInfo"
                style={{ display: 'none' }}
              >
                <hr />
                <Button
                  className="btn-block btn-blue-normal"
                  onClick={this.handleSave}
                >
                  Save Change
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

export default PersonalInfo
