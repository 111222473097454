// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import FrontHeaderClient                                    from './ComponentClient/FrontHeaderClient';

import * as firebase                                        from 'firebase/app';
import 'firebase/auth';

import { auth, provider }                                   from '../Helpers/Firebase.js';

import { Link }                                             from 'react-router-dom';

import imgBanner1                                           from '../Asset/Img/img1.svg';
import logoAvisha                                           from '../Asset/Logo/avisha-logo.svg';

// Styling
import { Container, Row, Col, Label, Modal, Spinner,
         Button, Form, FormGroup, Input, ModalBody }        from 'reactstrap';

import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faTimesCircle }                                    from '@fortawesome/free-solid-svg-icons';

import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import { ToastContainer, toast }                            from 'react-toastify';
import * as helpersApiCms                                   from '../Helpers/HelpersApiCms';

import 'react-toastify/dist/ReactToastify.css';

class FormLoginClient extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email:'',
            password:'',
            warning:'',
            warningChangePassword:'',
            confirmationPassword:'',
            newPassword:'',
            emailToChangePassword:'',
            idClient:0,
            modalFP: false,
            isWarningForgotPassword:false,
            isWarningChangePassword:false,
            modalLoading: false,
        };
        this.toggleModalFP = this.toggleModalFP.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    toggleLoading = () => {
        this.setState(prevState => ({
            modalLoading: !prevState.modalLoading
        }));            
    }

    toggleCloseModalChangePassword = () => {
        this.setState(prevState => ({
            modalChangePassword: !prevState.modalChangePassword
        }));            
    }
    
    toggleModalFP = () => {
        this.setState(prevState => ({
            modalFP: !prevState.modalFP
        }));            
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    LoginGoogleClient = async (e) => {
        await auth.signInWithPopup(provider)
        .then((res) => {
            const { email } = res.user;
            this.loginWithGoogle(email);
        });
    }

    loginWithGoogleClient = async (email) => {
        const newData = { email_google:email, key_api:config.KEY_API}
        try{
            const res =  await api.loginGoogle({
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                params: {
                    key_api: config.KEY_API,
                    data: JSON.stringify(newData)
                  }      
            })
            const { status, data } = res;
            if (status===200){
                if(data.message === "Berhasil login"){
                    localStorage.setItem("isLoginClient", true);
                    localStorage.setItem("idClient", authHelperMethods.encryptId(data.data.id, authHelperMethods.encryptKey(config.KEY_ENCRYPT)));
                    window.location.href = config.WEB_URL + `DashboardClient`;
                } else {
                    alert(data.message);
                }
            } else {
                alert("Server Error");
            }
        } catch(err){
            document.getElementById("googleBorrowerInvalid").style.display="block"
        }
    }

    handleLoginClient = async (e) => {
        e.preventDefault();
        const {email, password} = this.state;
        if((email === '') || (password === '') ){
            alert('Email atau password belum diisi');
        } else {
            const newData = {
                key_api:config.KEY_API,
                email:email,
                password:password,
            }
            try{
                this.toggleLoading();
                const res = await api.loginClient({
                    params: {
                        data: JSON.stringify(newData)
                    }
                })
                const { status, data } = res;
                if (status === 200) {
                    localStorage.setItem("isLoginClient", true);
                    localStorage.setItem("idClient", authHelperMethods.encryptId(data.data.id, authHelperMethods.encryptKey(config.KEY_ENCRYPT)));
                    this.toggleLoading();
                    window.location.href = config.WEB_URL + `DashboardClient`;
                } else if (status === 404) {
                    this.toggleLoading();
                    alert('Email atau Password salah');
                }
            } catch(err){
                this.toggleLoading();
                alert('Email atau Password salah');
                console.log("Error Response :",err)
            }    
        }
    }

    checkEmail = async (e) =>{
        e.preventDefault();
        const {emailToChangePassword}= this.state;
        if(emailToChangePassword===""){
            this.setState({
                            isWarningForgotPassword:true,
                            warning:"Email address is required !"
            })
        } else {
            this.setState(prevState => ({
                modalLoading: !prevState.modalLoading
            }));   
            const newData = {
                key_api:config.KEY_API,
                email:emailToChangePassword,
            }
            try{
                const res = await helpersApiCms.confirmationEmailToForgetPasswordClient({
                    params: {data: JSON.stringify(newData)}
                })
                const { status, data } = res;
                if (status === 200) {
                    if(data.message ==="Email Exist"){
                        this.setState(prevState => ({
                            modalLoading: !prevState.modalLoading
                        }));            
                        this.setState(prevState => ({
                            modalFP: !prevState.modalFP
                        }));            
                        this.setState(prevState => ({
                            modalChangePassword: !prevState.modalChangePassword
                        }));            
                        this.setState({
                            isWarningForgotPassword:false,
                        })
                    } else {
                        this.setState({
                            isWarningForgotPassword:true,
                            warning:"Email not found !"
                        })
                    }
                } else {
                    this.setState({
                        isWarningForgotPassword:true,
                        warning:"Email not found !"
                    })
                }
            } catch(err){
                this.setState({
                    isWarningForgotPassword:true,
                    warning:"Email not found !"
                })
            }    
        }
    }

    changePassword = async (e) => {
        e.preventDefault();
        const {idClient, newPassword, confirmationPassword} = this.state;
        if(newPassword===""){
            this.setState({
                warningChangePassword:"New password is required !",
                isWarningChangePassword:true
            });
        } else if (confirmationPassword===""){
            this.setState({
                warningChangePassword:"Confirmation password is required !",
                isWarningChangePassword:true
            });
        } else if (confirmationPassword != newPassword){
            this.setState({
                warningChangePassword:"Confirm Password does not match !",
                isWarningChangePassword:true
            });
        } else {
            const newData = {
                key_api:config.KEY_API,
                password:newPassword,
            }
            try{
                const res = await api.changePasswordClientBeforeLogin(idClient,{
                    params: {data: JSON.stringify(newData)}
                })
                const { status, data } = res;
                if (status === 200) {
                    this.setState(prevState => ({
                        modalChangePassword: !prevState.modalChangePassword
                    }));            
                    this.notify();
                } 
            } catch(err){
                alert('Error Failed');
                console.log("Error Response :",err)
            }    
        }
    }

    notify(){
        toast( "Change password is successful !" )
    }

    render() {               
        const {
            isWarningForgotPassword, 
            warning, 
            emailToChangePassword,
            modalChangePassword,
            modalFP
        } = this.state;
        return (          
            <Fragment>    
                <ToastContainer />
                <FrontHeaderClient/>
                <div className="bg-body-grey">
                    <Container className="pt-5 pb-5">
                        <Row>
                            <Col md="8" lg="6" className="mx-auto my-auto">
                                <Col lg="10" className="bg-white box-shadow p-4">
                                    <h5 className="text-center font-weight-bolder">Sign In for Company</h5><br/>
                                    <Form onSubmit={this.handleLoginClient}>
                                        <FormGroup className="mb-0">
                                            <Label className="text-14">Email</Label>
                                            <Input onChange={this.handleChange} type="email" name="email" id="EmailUser" className="input-box-grey"/><br/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="text-14">Password</Label>
                                            <Input onChange={this.handleChange} type="password" name="password" id="PasswordUser" className="input-box-grey"/><br/>
                                        </FormGroup>
                                        <Col className="pl-0 pr-0">
                                            <Button onSubmit={this.handleLoginClient} disabled={this.state.email === "" || this.state.password === ""} className="btn-blue btn-block">SIGN IN</Button>
                                            {/* <p className="text-center m-0 p-2 separator">or</p> */}
                                            {/* <Button className="btn-grey btn-block" onClick={this.loginWithGoogleClient}>SIGN IN WITH GOOGLE <img src={logoGoogle}  className="float-right" alt="logo-google" width="18px"/></Button><br/> */}
                                            <p className="text-9 text-mute text-center">
                                                By signing in to your account, you agree to indeed’s <u>term of service</u> and
                                                consent to our <u>cookie policy</u> and <u>privacy policy</u>
                                            </p>
                                            <p className="text-10 text-center text-mute mb-0">Not a member? <Link to="/FormSignupClient"><Label className="text-blue mb-0">Create an account</Label></Link></p>
                                            <Link to="#" onClick={this.toggleModalFP}><p className="text-10 text-center text-mute">Forgot Password?</p></Link>
                                        </Col>
                                    </Form> 
                                </Col>
                            </Col>
                            <Col className="d-none d-lg-block my-auto">
                                <img src={imgBanner1} alt="banner-avisha"/>
                            </Col>
                        </Row>
                    </Container>
                    <Modal isOpen={modalFP} toggle={this.toggleModalFP} backdrop="static">
                        <ModalBody>
                            <Link to="#" onClick={this.toggleModalFP}><FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle"/></Link>
                            <h5 className="muli-font">Forget Password</h5>
                            <hr/>
                            <Label className="d-block text-16 text-center">If you forget or miss the password, you can reset it. We will send link reset password to your email</Label>
                            <div className="pt-2 pl-5 pr-5 pb-4">
                                <Form onSubmit={this.checkEmail}>
                                    <Label className="d-block text-16 text-center">Email</Label>
                                    <Input type="email" id="emailuser" name="emailToChangePassword" value={emailToChangePassword} className="mb-3" onChange={this.handleChange}/>
                                    <Col className="pl-0 pr-0"><Button className="btn-block btn-blue-normal" onSubmit={this.checkEmail}>Reset Password</Button></Col>
                                </Form>
                                    {
                                        isWarningForgotPassword === true ?
                                            <Label className="d-block text-12 text-center text-danger mt-3">Failed. <br/> {warning}</Label>
                                        :null
                                    }
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modalChangePassword} toggle={this.toggleCloseModalChangePassword} backdrop="static">
                        <ModalBody>
                            <Link to="#" onClick={this.toggleCloseModalChangePassword}><FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle"/></Link>
                            <h5 className="muli-font">Konfirmasi Email</h5>
                            <hr/>
                            <Form onSubmit={this.changePassword}>
                                <center><p>Silahkan cek email anda untuk konfirmasi email</p></center>
                            </Form>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.modalLoading} toggle={this.toggleLoading} backdrop="static">
                        <ModalBody className="text-center p-5">
                           <Spinner color="warning" style={{ width: '8rem', height: '8rem', borderWidth:'7px' }}/>
                           <img src={logoAvisha}  alt="logo-google" width="60px" className="position-absolute" style={{margin:'30px 0 0 -95px'}}/>
                           <Label className="d-block mt-3">Please Wait</Label> 
                        </ModalBody>
                    </Modal>
                </div>
            </Fragment>
        );   
    }
};

export default FormLoginClient;