// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';

// Styling
import { Container, Row, Col, Card, CardHeader, 
         CardBody, Button, Label }       from 'reactstrap';   
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from  '../Helpers/Method';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';

class BuyPackaged extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id: 0,
            dataPackageClient: [],
            dataPackageClientPartTime: []
        };
    }


    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getPaymentHistory();
        await this.getPackageClient();
        await this.getPackageClientPartTime();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
      }

    getPaymentHistory = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getPaymentHistory(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const {data} = res.data;
            if(data){
                window.location.href = config.WEB_URL+"DashboardClient";
            } 
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getPackageClient = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getPackageClient({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataPackageClient: data });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getPackageClientPartTime = async () => {
        try {
            const newData = ({
                key_api: config.KEY_API,
                id_client: this.state.id
            });
            const res = await api.getPackageClientPartTime({
                params: { data: JSON.stringify(newData) }
            });
            this.setState({ dataPackageClientPartTime: res.data.data });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    simulationCreditPartTime = item => {
        item.id_package = item.id;
        localStorage.setItem('packagePartTimeChoosed',JSON.stringify(item));
        window.location.href = config.WEB_URL + `simulation-credit-part-time`;
    }


    buy = (item)=>{
        const newData = {
            "category":"Buy Package",
            "id_package": item['id'],
            "total_payment":item['price'],
            "package_name":item['package_name']
        }
        localStorage.setItem("packageChoosed",JSON.stringify(newData));
        window.location.href = config.WEB_URL + `PaymentMethod`;
    }

    render() {      
        const { dataPackageClient, dataPackageClientPartTime } = this.state;                               
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Buy Package & Top up Credit</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4 text-14">
                        {/* <Col className="mb-4">
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader className="font-weight-bold text-16">Top Up Credit</CardHeader>
                                        <CardBody>
                                            <Label className="font-weight-bold">Simulation Credit Part Time</Label>
                                            <Col  md="3" lg="2" className="my-auto pl-0 pr-0">
                                                <Button className="btn-block btn-info text-white mb-4 " onClick={()=>this.simulationCreditPartTime()}>Simulation</Button>
                                            </Col>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col> */}
                        <Col className="mb-4">
                            <hr></hr>
                            <h5>Part Time</h5>
                            <div className="row">
                                {(dataPackageClientPartTime.length > 0)? dataPackageClientPartTime.map(item => this.paketPartTimeComponent(item)) : this.kosongHTML}
                            </div>
                        </Col>
                        <Col className="mb-4">
                            <hr></hr>
                            <h5>Full Time</h5>
                            <div className="row">
                                {(dataPackageClient.length > 0)? dataPackageClient.map(item => this.paketFullTimeComponent(item)) : this.kosongHTML}
                            </div>
                        </Col>
                    </Container>
                </div>
            </Fragment>
        );   
    }

    kosongHTML = <Col>~ Data Kosong ~</Col>

    paketFullTimeComponent = item => <div className="col-md-3 mt-2">
        <Card>
            <CardHeader className="font-weight-bold text-center text-20">{item.package_name}</CardHeader>
            <CardBody>
                <Col className="pl-0 pr-0 pb-3 border-bottom text-center d-inline-block ">
                    <p className="mb-0">{item.package_category}</p>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="5">
                            <Label className="my-auto pt-2 pb-2">Job Talent Search</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.talent_Search_quota}</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="5">
                            <Label className="my-auto pt-2 pb-2">Job Posting</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.posting_quota}</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="6">
                            <Label className="my-auto pt-2 pb-2">Price (Rp)</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{method.formatMoney(item.price)}</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14 mb-3">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="6">
                            <Label className="my-auto pt-2 pb-2">Expired Packaged</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.expired_duration}</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="pl-0 pr-0">
                    <Button className="btn-block" onClick={() => this.buy(item)} style={{ backgroundColor: '#165C7D' }}>Buy</Button>
                </Col>
            </CardBody>
        </Card>
    </div>;

    paketPartTimeComponent = item => <div className="col-md-3 mt-2">
          <Card>
            <CardHeader className="font-weight-bold text-center text-20">{item.package_name}</CardHeader>
            <CardBody>
                <Col className="pl-0 pr-0 pb-3 border-bottom text-center d-inline-block ">
                    <p className="mb-0">{item.package_category}</p>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="5">
                            <Label className="my-auto pt-2 pb-2">PPN</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.ppn}%</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="5">
                            <Label className="my-auto pt-2 pb-2">PPH</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.pph}%</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom pl-0 pr-0 text-14">
                    <Row>
                        <Col xs="4" sm="3" md="6" lg="6">
                            <Label className="my-auto pt-2 pb-2">Manajement Cost</Label>
                        </Col>
                        <Col className="text-right">
                            <Label className="my-auto pt-2 pb-2">{item.management_cost}%</Label>
                        </Col>
                    </Row>
                </Col>
                <Col className="pl-0 pr-0">
                    <Button className="btn-block" onClick={() => this.simulationCreditPartTime(item)} style={{ backgroundColor: '#165C7D' }}>Buy</Button>
                </Col>
            </CardBody>
        </Card>  
    </div>;
};

export default BuyPackaged;