import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Container, Row, Col, Card, CardBody, Label,
         UncontrolledCollapse }                             from 'reactstrap'; 
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';      
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as method                                          from '../Helpers/Method';      
import ReactPaginate                                        from 'react-paginate';
class NotifClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listInboxMessage:[],
            offset: 0,
            perPage: 10,
            currentPage: 0
        };
    }

    componentDidMount = async () =>{
        await this.getInboxMessages();
    }

    getInboxMessages = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idClient = authHelperMethods.getIdClient();
          try{
            const res = await api.getNotification(idClient,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            
            if(status === 200){
              this.setState({
                listInboxMessage:data.data.slice(this.state.offset, this.state.offset + this.state.perPage)
            })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getInboxMessages()
        });

    };

    render() {
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey vh-100">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Notification</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="muli-font bg-body-grey">
                        <Container className="mt-4">
                            <Card className="rounded-lg">
                                <CardBody>
                                {this.state.listInboxMessage.map((notif)=>{
                                    return(
                                        <Col id={"toggler"+notif.id} className="p-2 section-message border-bottom" onClick={this.handleOpenMessage}>
                                            <Row>
                                                <Col md="10">
                                                    <p className="mb-0 text-9 text-mute font-weight-light">Info</p>
                                                    <p className="mb-0 font-weight-bolder text-12 my-auto">
                                                        {notif.notif_category==null ? "":notif.notif_category} 
                                                    </p>
                                                    <p className="font-weight-light text-11 text-justify my-auto">
                                                        {notif.title}
                                                    </p>
                                                    <UncontrolledCollapse toggler={"#toggler"+notif.id}>
                                                        <hr/>
                                                        <p className="text-14">{notif.description}</p>
                                                    </UncontrolledCollapse>
                                                </Col>
                                                <Col md="2"><p className="text-post-time">{method.formatDateDay(notif.created_at)}</p></Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                                </CardBody>
                            </Card>
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}/>
                        </Container> 
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default NotifClient;