// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import { Link }                                             from 'react-router-dom';
import imgBanner1                                           from '../../Asset/Img/img1.svg';
import gPlayAvisha                                          from '../../Asset/Icon/google-play.png';
import { Container, Row, Col, Button }                      from 'reactstrap';   
// import '../Css/Footer.css';                         


class Banners extends React.Component {

    openUser (){
        window.location.href = '/FormLoginUser'
    }

    openCompany (){
        window.location.href = '/FormLoginClient'
    }
    render() {                                     
        return (          
            <Fragment>                        
                <Container fluid className="bg-blue p-5">
                    <div className="bg-logo-avisha"></div>
                    <Container className="m-0">
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6" className="bg-normal font-weight-bolder rounded p-4">
                                <h2 className="text-white font-weight-bolder mb-0">I am <br/> looking <br/> for working</h2>
                                <p className="text-white font-weight-light">create a job seeker account</p>
                                {/* <Link to="/FormLoginUser"></Link><br/> */}
                                <Button className="btn-findJob" onClick={this.openUser}>START LOOKING FOR JOB</Button>
                                <p className="text-12 text-white font-weight-light mt-3">
                                    already have an account? <Link to="/FormLoginUser" className="text-decoration-none text-white"><u>Log in</u></Link>
                                </p>
                                <p className="text-12 text-white font-weight-light mt-3">
                                    by creating an account you agree to our <u>term and condition</u><br/>
                                    our <u>privacy policy</u> and our <u>end user license agreement</u>
                                </p>
                            </Col>
                            <Col>
                                <img src={imgBanner1} alt="banner-avisha"/>
                                {/* <img src={gPlayAvisha} alt="avisha-mobile-app" width="100px"/> */}
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="bg-company-banner p-5">
                    <Container className="m-2 mt-lg-4 mb-lg-4">
                            <Col xs="12" sm="8" md="6" lg="5" xl="4" className="bg-white font-weight-bolder rounded  box-shadow p-5 my-auto">
                                <h2 className="text-blue font-weight-bolder mb-0">I am looking <br/> to hire</h2>
                                <p className="text-blue font-weight-light">create a job recruiter account</p>
                                {/* <Link to="/FormLoginClient">MENDAFTAR UNTUK MENCARI TALENTA</Link><br/> */}
                                <Col xs="12" sm="6" md="6" lg="8" className="p-0"><Button className="btn-createJob btn-block" onClick={this.openCompany}>START HIRE</Button></Col>
                            </Col>
                    </Container>
                </Container>
            </Fragment>
        );   
    }
};

export default Banners;