// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';

import { Link }                                             from 'react-router-dom';
import imgProfile                                           from '../Asset/Icon/img-profile.png'

// Styling
import { Container, Row, Col, Card, CardHeader, 
         CardBody, Button, Label, Input, 
         UncontrolledDropdown, Dropdown, 
         DropdownToggle, DropdownMenu, DropdownItem }       from 'reactstrap';  

import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagram }                                       from '@fortawesome/free-brands-svg-icons';

import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from  '../Helpers/Method';

class DetailCandidatesFulltime extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }

    render() {      
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Detail Candidates Part time</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4 text-14">
                        <div className="bg-white view-candidates p-5">
                            <Col>
                                <Row>
                                    <Col xs="3"><img src={imgProfile} alt=""/></Col>
                                    <Col className="my-auto">
                                        <h1 className="font-weight-bolder muli-font mb-0">{"Afdhal Yaned"}</h1>
                                        <h4 className="font-weight-bold muli-font">{"Marketing & Sales"}</h4>
                                        <Label className="d-block text-16">ID : {"202020"}</Label>
                                    </Col>
                                </Row>
                                <hr/>
                            </Col>
                            <Col>
                                <Row>
                                    <Col md="5">
                                        <div name="bio-candidates" className="mb-3">
                                            <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info text-right pr-4" style={{borderRadius:'0 20px 20px 0'}}><Label className="d-block font-weight-bold text-18 mb-0">Contact</Label></Col>
                                            <div className="pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Email"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"mail@example.com"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Phone Number"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"+62 81180901011"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Social Media"}</Label></Col>
                                                    <Col className="text-right">
                                                        <Label className="d-block text-16 mb-0">{"Afdhal Yaned"} <FontAwesomeIcon icon={faFacebookSquare} className="text-20 text-primary"/></Label>
                                                        <Label className="d-block text-16 mb-0">{"Afdhal Yaned"} <FontAwesomeIcon icon={faTwitterSquare} className="text-20 text-primary"/></Label>
                                                        <Label className="d-block text-16">{"Afdhal Yaned"} <FontAwesomeIcon icon={faInstagram} className="text-20 text-primary"/></Label>
                                                        
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label className="d-block font-weight-bold text-16 mb-0">{"Domicile Address"}</Label>
                                                        <Label className="d-block text-16 text-justify">{"Perum. Jakarta Selatan RT.01 / RW.02"}</Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label className="d-block font-weight-bold text-16 mb-0">{"ID Card Address"}</Label>
                                                        <Label className="d-block text-16 mb-0 text-justify">{"Perum. Jakarta Selatan RT.01 / RW.02"}</Label>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info text-right pr-4" style={{borderRadius:'0 20px 20px 0'}}><Label className="d-block font-weight-bold text-18 mb-0">Biodata</Label></Col>
                                            <div className="pt-2 pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Place of Birth"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Jakarta Pusat"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Date of Birth"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"20 Juni 1990"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Gender"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Male"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Status"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Single"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Weight"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"60 kg"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Height"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"170 cm"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Age"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"21 years"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"ID Card Number"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"21212121XXX"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Location Job"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Jakarta, Bandung, Banten"}</Label></Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    <Col>
                                        <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info pl-4" style={{borderRadius:'20px 0 0 20px'}}><Label className="d-block font-weight-bold text-18 mb-0">Education</Label></Col>
                                            <div className="pt-2 pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Level"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"High School"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Name of School"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"SMAN 3 Jakarta"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Major"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Sains"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Graduate"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"24 July 2016"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Score/GPA"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16 mb-0">{"24.0"}</Label></Col>
                                                </Row>
                                            </div>
                                        <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info pl-4" style={{borderRadius:'20px 0 0 20px'}}><Label className="d-block font-weight-bold text-18 mb-0">Skill</Label></Col>
                                            <div className="pt-2 pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Skill 1"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"High School"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Skill 2"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"SMAN 3 Jakarta"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Skill 3"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Sains"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Skill 4"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16 mb-0">{"Sains"}</Label></Col>
                                                </Row>
                                            </div>
                                        <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info pl-4" style={{borderRadius:'20px 0 0 20px'}}><Label className="d-block font-weight-bold text-18 mb-0">Experience</Label></Col>
                                            <div className="pt-2 pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Title Experience 1"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"High School"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Title Experience 2"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"SMAN 3 Jakarta"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Title Experience 3"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">{"Sains"}</Label></Col>
                                                </Row>
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Title Experience 4"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16 mb-0">{"Sains"}</Label></Col>
                                                </Row>
                                            </div>
                                        <Col className="border-top border-right border-bottom p-2 bg-secondary bg-info pl-4" style={{borderRadius:'20px 0 0 20px'}}><Label className="d-block font-weight-bold text-18 mb-0">Salary</Label></Col>
                                            <div className="pt-2 pl-4 pr-4">
                                                <Row>
                                                    <Col><Label className="d-block font-weight-bold text-16">{"Rate"}</Label></Col>
                                                    <Col className="text-right"><Label className="d-block text-16">Rp. {"4.000.000 - 6.000.000"}</Label></Col>
                                                </Row>
                                            </div>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col lg="6" className="mx-auto mt-5">
                                <Link to="#"><Button className="btn-block btn-success mb-3">Download CV</Button></Link>
                                <Link to="/FindTalent"><Button className="btn-block btn-blue-normal">Back to Find Candidates</Button></Link>
                            </Col>
                        </div>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default DetailCandidatesFulltime;