import React, { Fragment, useRef, useCallback, createRef } from 'react'
import HeaderClient from './ComponentClient/HeaderClient'
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import * as authHelperMethods from '../Helpers/AuthHelperMethods'
import * as api from '../Helpers/Api'
import * as config from '../Helpers/Config'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {
  LoadScript,
  GoogleMap,
  Polygon,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api'
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete'
import './Css/style.css'

class Absen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      locations: [],
      types: [],
      activeTab: 0,
      modalAddType: false,
      modalAddLocation: false,
      type_name: '',
      type_desc: '',
      mandatory_location: false,
      idType: 0,
      modalEditType: false,
      modalDeletetype: false,
      idLocation: 0,
      location_name: '',
      location_desc: '',
      center: { lat: -6.229644395527812, lng: 106.99621219521482 },
      defaultPath: [
        { lat: -6.235766402982342, lng: 106.9841958988281 },
        { lat: -6.2357185823942896, lng: 107.00857181435545 },
        { lat: -6.2172406974399035, lng: 107.00960178261717 },
        { lat: -6.217435685045188, lng: 106.98453922158201 },
      ],
      path: [
        { lat: -6.235766402982342, lng: 106.9841958988281 },
        { lat: -6.2357185823942896, lng: 107.00857181435545 },
        { lat: -6.2172406974399035, lng: 107.00960178261717 },
        { lat: -6.217435685045188, lng: 106.98453922158201 },
      ],
      modalEditLocation: false,
      modalDeleteLocation: false,
      mapStyle: {
        width: '100%',
        height: '400px',
      },
      inputStyles: {
        boxSizing: `border-box`,
        border: `1px solid transparent`,
        width: `240px`,
        height: `32px`,
        padding: `0 12px`,
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        fontSize: `14px`,
        outline: `none`,
        textOverflow: `ellipses`,
        position: 'absolute',
        left: '50%',
        marginLeft: '-120px',
        bounds: null,
      },
      lokasis: [],
    }
    this.polygonRef = createRef(null)
    this.listenersRef = createRef([])
    this.searchBox = ''
  }

  componentDidMount = async () => {
    authHelperMethods.checkIsLogin()
    await this.getId()
    await this.getTypes()
    await this.getLocations()
  }

  getId = () => {
    const id = authHelperMethods.getIdClient()
    this.setState({ id })
  }

  dataParam = {
    params: {
      data: JSON.stringify({ key_api: config.KEY_API }),
    },
  }

  updateViewPort = (viewport) => this.setState(viewport)

  getTypes = async () => {
    const { id } = this.state
    const res = await api.getAbsenTypes(id, this.dataParam)
    const { data } = res.data
    this.setState({ types: data })
  }

  getLocations = async () => {
    const { id } = this.state
    const res = await api.getAbsenLocations(id, this.dataParam)
    const { data } = res.data
    this.setState({ locations: data })
  }

  prosesAddType = async () => {
    const { type_name, type_desc, mandatory_location, id } = this.state
    if (!type_name || !type_desc) {
      alert('All field required')
    } else {
      const dataParam = {
        params: {
          data: JSON.stringify({
            clients_company_id: id,
            type_name,
            type_desc,
            mandatory_location,
          }),
        },
      }
      const res = await api.addAbsenType(dataParam)
      await this.getTypes()
      this.toggleModalAddType()
    }
  }

  prosesEditType = async () => {
    const { type_name, type_desc, mandatory_location, idType } = this.state
    if (!type_name || !type_desc) {
      alert('All field required')
    } else {
      const dataParam = {
        params: {
          data: JSON.stringify({
            absen_type_id: idType,
            type_name,
            type_desc,
            mandatory_location,
          }),
        },
      }
      const res = await api.editAbsenType(dataParam)
      await this.getTypes()
      this.toggleModalEditType('')
    }
  }

  prosesEditLocation = async () => {
    const {
      location_name,
      location_desc,
      center,
      path,
      idLocation,
    } = this.state
    if (!location_name || !location_desc) {
      alert('All field required')
    } else {
      const dataParam = {
        params: {
          data: JSON.stringify({
            absen_location_id: idLocation,
            location_name,
            location_desc,
            center,
            path,
          }),
        },
      }
      const res = await api.editAbsenLocation(dataParam)
      await this.getLocations()
      this.toggleModalEditLocation('')
    }
  }

  prosesAddLocation = async () => {
    const { location_name, location_desc, center, path, id } = this.state
    if (!location_name || !location_desc) {
      alert('All field required')
    } else {
      const dataParam = {
        params: {
          data: JSON.stringify({
            location_name,
            location_desc,
            center,
            path,
            id_client: id,
          }),
        },
      }
      const res = await api.addAbsenLocation(dataParam)
      await this.getLocations()
      this.toggleModalAddLocation()
    }
  }

  prosesDeleteType = async () => {
    const { idType } = this.state
    const dataParam = {
      params: { data: JSON.stringify({ absen_type_id: idType }) },
    }
    const res = await api.deleteAbsenType(dataParam)
    await this.getTypes()
    this.toggleModalDeleteType('')
  }

  prosesDeleteLocation = async () => {
    const { idLocation } = this.state
    const dataParam = {
      params: { data: JSON.stringify({ absen_location_id: idLocation }) },
    }
    const res = await api.deleteAbsenLocation(dataParam)
    await this.getLocations()
    this.toggleModalDeleteLocation('')
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab })
  }

  ubahNilai = (e) => {
    const data = {}
    data[e.target.id] =
      e.target.type == 'checkbox' ? e.target.checked : e.target.value
    this.setState(data)
  }

  kosongHTML = (col) => (
    <tr>
      <td colSpan={col} className="text-center text-secondary">
        ~ Data Empty ~
      </td>
    </tr>
  )

  renderTrBodyLocation = () => {
    const { locations } = this.state
    let html
    if (locations.length == 0) {
      html = this.kosongHTML(4)
    } else {
      html = locations.map((location, i) => (
        <tr key={i}>
          <td className="text-center">{i + 1}</td>
          <td>{location.location_name}</td>
          <td>{location.location_desc}</td>
          <td className="text-center">
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                this.toggleModalEditLocation(location.absen_location_id)
              }
            >
              Edit
            </button>
            &nbsp;
            <button
              className="btn btn-danger btn-sm"
              onClick={() =>
                this.toggleModalDeleteLocation(location.absen_location_id)
              }
            >
              Delete
            </button>
          </td>
        </tr>
      ))
    }
    return html
  }

  tableLocationHTML = () => (
    <div>
      <button
        className="btn btn-primary btn-sm mb-2 mt-2"
        onClick={this.toggleModalAddLocation}
      >
        Add Location
      </button>
      <div className="table-responsive">
        <table
          className="table table-hover table-bordered"
          id="tableUtama"
          style={{ width: '100%' }}
        >
          <thead className="thead-dark">
            <tr className="text-center">
              <th style={{ width: '10%' }}>No</th>
              <th>Location Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.renderTrBodyLocation()}</tbody>
        </table>
      </div>
    </div>
  )

  renderTrBodyType = () => {
    const { types } = this.state
    let html
    if (types.length == 0) {
      html = this.kosongHTML(5)
    } else {
      html = types.map((type, i) => (
        <tr key={i}>
          <td className="text-center">{i + 1}</td>
          <td>{type.type_name}</td>
          <td>{type.type_desc}</td>
          <td className="text-center">
            {type.mandatory_location == 1 ? 'Yes' : 'No'}
          </td>
          <td className="text-center">
            <button
              className="btn btn-warning btn-sm"
              onClick={() => this.toggleModalEditType(type.absen_type_id)}
            >
              Edit
            </button>
            &nbsp;
            <button
              className="btn btn-danger btn-sm"
              onClick={() => this.toggleModalDeleteType(type.absen_type_id)}
            >
              Delete
            </button>
          </td>
        </tr>
      ))
    }
    return html
  }

  handleClose = (modal) => {
    let data = {}
    data[modal] = false
    this.setState(data)
  }

  toggleModalAddType = () => {
    this.setState({
      type_name: '',
      type_desc: '',
      mandatory_location: false,
    })
    this.setState((prevState) => ({ modalAddType: !prevState.modalAddType }))
  }

  toggleModalEditType = async (id) => {
    if (!Number.isInteger(id)) {
      this.setState({
        idType: 0,
        type_name: '',
        type_desc: '',
        mandatory_location: false,
      })
    } else {
      const dataParam = { params: { data: JSON.stringify({ id }) } }
      const res = await api.getSingleType(dataParam)
      const {
        absen_type_id,
        mandatory_location,
        type_desc,
        type_name,
      } = res.data.data
      this.setState({
        idType: absen_type_id,
        type_name: type_name,
        type_desc: type_desc,
        mandatory_location: mandatory_location == 1 ? true : false,
      })
    }
    this.setState((prevState) => ({ modalEditType: !prevState.modalEditType }))
  }

  toggleModalEditLocation = async (id) => {
    if (!Number.isInteger(id)) {
      this.setState({
        idLocation: 0,
        location_name: '',
        location_desc: '',
      })
    } else {
      const dataParam = { params: { data: JSON.stringify({ id }) } }
      const res = await api.getSingleLocation(dataParam)
      const {
        absen_location_id,
        location_desc,
        location_name,
        latitude,
        longitude,
        polygon,
      } = res.data.data
      const polygonJadi = []
      polygon.forEach((poly) => {
        polygonJadi.push({
          lat: parseFloat(poly.lat),
          lng: parseFloat(poly.lng),
        })
      })
      const dataState = {
        idLocation: absen_location_id,
        location_name: location_name,
        location_desc: location_desc,
        path: polygonJadi,
        center: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      }
      this.setState(dataState)
    }
    this.setState((prevState) => ({
      modalEditLocation: !prevState.modalEditLocation,
    }))
  }

  toggleModalDeleteType = async (id) => {
    if (!Number.isInteger(id)) {
      this.setState({
        idType: 0,
        type_name: '',
      })
    } else {
      const dataParam = { params: { data: JSON.stringify({ id }) } }
      const res = await api.getSingleType(dataParam)
      const { absen_type_id, type_name } = res.data.data
      this.setState({
        idType: absen_type_id,
        type_name: type_name,
      })
    }
    this.setState((prevState) => ({
      modalDeleteType: !prevState.modalDeleteType,
    }))
  }

  toggleModalDeleteLocation = async (id) => {
    if (!Number.isInteger(id)) {
      this.setState({
        idLocation: 0,
        location_name: '',
      })
    } else {
      const dataParam = { params: { data: JSON.stringify({ id }) } }
      const res = await api.getSingleLocation(dataParam)
      const { absen_location_id, location_name } = res.data.data
      this.setState({
        idLocation: absen_location_id,
        location_name: location_name,
      })
    }
    this.setState((prevState) => ({
      modalDeleteLocation: !prevState.modalDeleteLocation,
    }))
  }

  toggleModalAddLocation = () => {
    const { defaultPath } = this.state
    this.setState({
      location_name: '',
      location_desc: '',
      path: defaultPath,
    })
    this.setState((prevState) => ({
      modalAddLocation: !prevState.modalAddLocation,
    }))
  }

  onEdit = () => {
    if (this.polygonRef.current) {
      const nextPath = this.polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      this.setState({
        center: nextPath[0],
        path: nextPath,
      })
    }
  }

  onLoad = (polygon) => (this.polygonRef.current = polygon)

  onUnmount = () => {
    this.listenersRef.current.forEach((lis) => lis.remove())
    this.polygonRef.current = null
  }

  editMarker = (e) => {
    const posisi = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }
    this.setState({ center: posisi })
  }

  onLoadSearch = (ref) => (this.searchBox = ref)

  onPlacesChanged = () => {
    const { center, path, defaultPath } = this.state
    const pathJadi = []
    if (this.searchBox.getPlaces().length > 0) {
      const lokasiPertama = this.searchBox.getPlaces()[0].geometry.location
      const latLng = {
        lat: lokasiPertama.lat(),
        lng: lokasiPertama.lng(),
      }
      path.forEach((loc) => {
        const jarakLat = center.lat - loc.lat
        const jarakLng = center.lng - loc.lng
        pathJadi.push({
          lat: latLng.lat + jarakLat,
          lng: latLng.lng + jarakLng,
          // lat: (latLng.lat*loc.lat)/center.lat,
          // lng: (latLng.lng*loc.lng)/center.lng
        })
      })
      this.setState({
        center: latLng,
        path: pathJadi,
      })
      const lokasis = []
      this.searchBox
        .getPlaces()
        .forEach(({ place_id, formatted_address, geometry: { location } }) => {
          lokasis.push({
            place_id,
            formatted_address,
            latLng: {
              lat: location.lat(),
              lng: location.lng(),
            },
          })
          // <li key={place_id}>
          //     {formatted_address}
          //     {" at "}
          //     ({location.lat()}, {location.lng()})
          // </li>
        })
      console.log(lokasis)
      this.setState({ lokasis })
    }
  }

  modalAddLocationHTML = () => {
    const {
      modalAddLocation,
      mapStyle,
      path,
      inputStyles,
      center,
      location_name,
      location_desc,
      bounds,
      lokasis,
    } = this.state
    const position = [this.state.lat, this.state.lng]
    return (
      <Modal
        isOpen={modalAddLocation}
        toggle={this.toggleModalAddLocation}
        size="xl"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalAddLocation}
        />
        <ModalHeader>Add Location</ModalHeader>
        <ModalBody>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="location_name">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control form-control-sm"
                id="location_name"
                value={location_name}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              <label htmlFor="location_desc">Description</label>
            </div>
            <div className="col">
              <textarea
                id="location_desc"
                className="form-control"
                value={location_desc}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCNiCtogEjSsnnVGI5rb09MqZavEuPopEw"
            language="en"
            region="us"
            libraries={['places']}
          >
            <GoogleMap
              mapContainerClassName="App-map"
              mapContainerStyle={mapStyle}
              center={center}
              zoom={12}
              version="weekly"
              on
            >
              <Polygon
                editable
                draggable
                path={path}
                onMouseUp={this.onEdit}
                onDragEnd={this.onEdit}
                onLoad={this.onLoad}
              />
              <StandaloneSearchBox
                onLoad={this.onLoadSearch}
                onPlacesChanged={this.onPlacesChanged}
              >
                <input
                  type="text"
                  placeholder="Search a place"
                  style={inputStyles}
                  id="pac-input"
                  className=" form-control"
                />
              </StandaloneSearchBox>
            </GoogleMap>
          </LoadScript>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.prosesAddLocation}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  modalEditTypeHTML = () => {
    const {
      modalEditType,
      type_name,
      type_desc,
      mandatory_location,
    } = this.state
    return (
      <Modal isOpen={modalEditType} toggle={this.toggleModalEditType}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalEditType}
        />
        <ModalHeader>
          <h5>Edit Type</h5>
        </ModalHeader>
        <ModalBody>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="type_name">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control form-control-sm"
                id="type_name"
                value={type_name}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="type_desc">Description</label>
            </div>
            <div className="col">
              <textarea
                id="type_desc"
                className="form-control"
                onChange={this.ubahNilai}
              >
                {type_desc}
              </textarea>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-3"></div>
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mandatory_location"
                  checked={mandatory_location}
                  onChange={this.ubahNilai}
                />
                <label
                  className="form-check-label"
                  htmlFor="mandatory_location"
                >
                  Mandatory
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.prosesEditType}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  modalEditLocationHTML = () => {
    const {
      modalEditLocation,
      location_name,
      location_desc,
      center,
      path,
      mapStyle,
      inputStyles,
    } = this.state
    return (
      <Modal
        isOpen={modalEditLocation}
        toggle={this.toggleModalEditLocation}
        size="xl"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalEditLocation}
        />
        <ModalHeader>Edit Location</ModalHeader>
        <ModalBody>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="location_name">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control form-control-sm"
                id="location_name"
                value={location_name}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              <label htmlFor="location_desc">Description</label>
            </div>
            <div className="col">
              <textarea
                id="location_desc"
                className="form-control"
                value={location_desc}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyCNiCtogEjSsnnVGI5rb09MqZavEuPopEw"
            language="en"
            region="us"
            libraries={['places']}
          >
            <GoogleMap
              mapContainerClassName="App-map"
              mapContainerStyle={mapStyle}
              center={center}
              zoom={12}
              version="weekly"
              on
            >
              <Polygon
                editable
                draggable
                path={path}
                onMouseUp={this.onEdit}
                onDragEnd={this.onEdit}
                onLoad={this.onLoad}
              />
              <StandaloneSearchBox
                onLoad={this.onLoadSearch}
                onPlacesChanged={this.onPlacesChanged}
              >
                <input
                  type="text"
                  placeholder="Search a place"
                  style={inputStyles}
                  id="pac-input"
                  className="controls"
                />
              </StandaloneSearchBox>
            </GoogleMap>
          </LoadScript>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.prosesEditLocation}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  modalDeleteLocationHTML = () => {
    const { modalDeleteLocation, location_name } = this.state
    return (
      <Modal
        isOpen={modalDeleteLocation}
        toggle={this.toggleModalDeleteLocation}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalDeleteLocation}
        />
        <ModalHeader>
          <h5>Delete Location</h5>
        </ModalHeader>
        <ModalBody>
          <p>Do you really want to delete {location_name}?</p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={this.prosesDeleteLocation}
          >
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  modalDeleteTypeHTML = () => {
    const { modalDeleteType, type_name } = this.state
    return (
      <Modal isOpen={modalDeleteType} toggle={this.toggleModalDeleteType}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalDeleteType}
        />
        <ModalHeader>
          <h5>Delete Type</h5>
        </ModalHeader>
        <ModalBody>
          <p>Do you really want to delete {type_name}?</p>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.prosesDeleteType}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  modalAddTypeHTML = () => {
    const {
      modalAddType,
      type_name,
      type_desc,
      mandatory_location,
    } = this.state
    return (
      <Modal isOpen={modalAddType} toggle={this.toggleModalAddType}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleModalAddType}
        />
        <ModalHeader>
          <h5>Add Type</h5>
        </ModalHeader>
        <ModalBody>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="type_name">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control form-control-sm"
                id="type_name"
                value={type_name}
                onChange={this.ubahNilai}
              />
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-3">
              <label htmlFor="type_desc">Description</label>
            </div>
            <div className="col">
              <textarea
                id="type_desc"
                className="form-control"
                onChange={this.ubahNilai}
              >
                {type_desc}
              </textarea>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-3"></div>
            <div className="col">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="mandatory_location"
                  checked={mandatory_location}
                  onChange={this.ubahNilai}
                />
                <label
                  className="form-check-label"
                  htmlFor="mandatory_location"
                >
                  Mandatory
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={this.prosesAddType}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  tableTypeHTML = () => (
    <div>
      <button
        className="btn btn-primary btn-sm mb-2 mt-2"
        onClick={this.toggleModalAddType}
      >
        Add Type
      </button>
      <div className="table-responsive">
        <table
          className="table table-hover table-bordered"
          id="tableUtama"
          style={{ width: '100%' }}
        >
          <thead className="thead-dark">
            <tr className="text-center">
              <th style={{ width: '10%' }}>No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Mandatory Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.renderTrBodyType()}</tbody>
        </table>
      </div>
    </div>
  )

  renderTab = (activeTab) => {
    let html
    if (activeTab == 0) {
      html = this.tableTypeHTML()
    } else if (activeTab == 1) {
      html = this.tableLocationHTML()
    } else if (activeTab == 2) {
      html = null
    }
    return html
  }

  navHTML = () => {
    const { activeTab } = this.state
    return (
      <div>
        <Nav tabs>
          <Fragment>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 0 })}
                onClick={(e) => {
                  this.toggle(0)
                }}
                style={{ borderRadius: '10px 10px 0 0' }}
              >
                <p className="mb-0 font-weight-bold text-14">Attendance Type</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={(e) => {
                  this.toggle(1)
                }}
                style={{ borderRadius: '10px 10px 0 0' }}
              >
                <p className="mb-0 font-weight-bold text-14">Locations</p>
              </NavLink>
            </NavItem>
          </Fragment>
        </Nav>
        <div id="tempatTab">{this.renderTab(activeTab)}</div>
      </div>
    )
  }

  render() {
    const { center, zoom, path } = this.state
    return (
      <Fragment>
        <HeaderClient />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">
                    Attendance
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pb-5 muli-font bg-body-grey">
            <div className="container mt-4">
              <div className="card">
                <div className="card-body">{this.navHTML()}</div>
              </div>
            </div>
          </div>
        </div>
        {this.modalAddTypeHTML()}
        {this.modalEditTypeHTML()}
        {this.modalDeleteTypeHTML()}
        {this.modalEditTypeHTML()}
        {this.modalDeleteTypeHTML()}
        {this.modalAddLocationHTML()}
        {this.modalEditLocationHTML()}
        {this.modalDeleteLocationHTML()}
      </Fragment>
    )
  }
}

export default Absen
