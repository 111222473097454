// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import FrontHeaderUser                                      from './ComponentUser/FrontHeaderUser';
import Termcon                                              from './ComponentUser/Term&ConPlain';

import * as firebase                                        from 'firebase/app';
import 'firebase/auth';

import { auth, provider }                                   from '../Helpers/Firebase.js';

import { Link }                                             from 'react-router-dom';

import imgBanner1                                           from '../Asset/Img/img1.svg';
import logoGoogle                                           from '../Asset/Logo/Google.svg';

// Styling
import { Container, Row, Col, Label, Button, Form, 
         FormGroup, Input, Modal, ModalBody }               from 'reactstrap';   
// import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome'
// import { faGoogle, faFacebook }                             from '@fortawesome/free-brands-svg-icons'
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
// import { filter}                                            from 'lodash';
import { ToastContainer, toast }                            from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FormSignupUser extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            fullname:'',
            email:'',
            phoneNumber:'',
            password:'',
            checkPassword:'',
            listEmail:[],
            listPhoneNumber:[],
            checked: false,
            aggrementCheckBox: false,
            validate: {
                email:'',
                phoneNumber: '',
                fullName:'',
                password:'',
                confirmPassword:''
            },
            warningError:"",
            isComplateData:false,
            modalAgreement:false,
        }
    }

    componentDidMount = async () =>{
        await this.getAllEmployees();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    registerUserGoogle = async (e) => {
        await auth.signInWithPopup(provider)
        .then((res) => {
            const { email} = res.user;
            this.setState({email:email});
            // localStorage.setItem("email", email);
            // window.location.href = config.WEB_URL + `DashboardUser`;
        });
    }

    validateEmail = async (value) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if(value===""){
            validate.email = 'has-danger';
            this.setState({isComplateData:false});
        } else {
            if (emailRex.test(value)) {
                this.setState({isComplateData:true});
                validate.email = 'has-success';
            } else {
                this.setState({isComplateData:false});
                validate.email = 'has-danger';
            }
        }
        this.setState({ validate })
    }

    validateFullName(name) {
        const { validate } = this.state
        if (name==="") {
            this.setState({isComplateData:false});
            validate.fullName = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.fullName = 'has-success'
        }
        this.setState({ validate })
    }

    validateHP = async (phone) => {
        const HPRex = /^.{10,}$/;
        const { validate } = this.state
        if(phone===""){
            validate.phoneNumber = 'has-danger';
            this.setState({isComplateData:false});
        } else{
            if (HPRex.test(phone)) {
                validate.phoneNumber = 'has-success';
                this.setState({isComplateData:true});    
            } else {
                validate.phoneNumber = 'has-danger';
                this.setState({isComplateData:false});
            }
        }
        this.setState({ validate })
    }

    validatePassword(password) {
        const { validate } = this.state
        if (password==="") {
            this.setState({isComplateData:false});
            validate.password = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.password = 'has-success'
        }
        this.setState({ validate })

    }

    validateConfirmPassword(password) {
        const { validate, checkPassword } = this.state
        if (password==="") {
            this.setState({isComplateData:false});
            validate.confirmPassword = 'has-danger';
        } else if(checkPassword === password){
            this.setState({isComplateData:true});
            validate.confirmPassword = 'has-success';
        }else {
            this.setState({isComplateData:false});
            validate.confirmPassword = 'has-danger';
        }
        this.setState({ validate })

    }

    getAllEmployees = async (e) => {
        const newData = {
            key_api: config.KEY_API
          };
          const {email, phoneNumber} = this.state
          try{
            const res = await api.allEmployees({
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              const allEmailEmployees = [];
              const allPhoneNumberEmployees = [];

              data.dataAllEmployees.map((item1) => allEmailEmployees.push(item1.email))
              data.dataAllEmployees.map((item2) => allPhoneNumberEmployees.push(item2.phone_number))
              this.setState({
                  listEmail:allEmailEmployees,
                  listPhoneNumber:allPhoneNumberEmployees
                })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    notify(){
        const { warningError } = this.state;
        toast( warningError )
    }

    toggleModalAgreement = async () => {
        const {listEmail, listPhoneNumber, isComplateData, fullname, email, phoneNumber, password, checkPassword} = this.state;
        await this.validateFullName(fullname);
        await this.validateEmail(email);
        await this.validateHP(phoneNumber);
        await this.validatePassword(password);
        await this.validateConfirmPassword(checkPassword);
        if(this.state.isComplateData===true){
            if(listEmail.includes(email)===true){
                await this.setState({warningError:"Email sudah pernah digunakan !"});
                this.notify();
            } else if (listPhoneNumber.includes(phoneNumber) === true){
                await this.setState({warningError:"Nomor Handphone sudah pernah digunakan !"});
                this.notify();
            } else {
                this.setState(prevState => ({
                    modalAgreement: !prevState.modalAgreement
                }));
            }
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {fullname, email, phoneNumber, password} = this.state;
        const newData = {
            key_api: config.KEY_API,
            full_name:fullname,
            email:email,
            phone_number:phoneNumber,
            password: password
        };
        try {
            const res = await api.registerEmployee({
                params: { data: JSON.stringify(newData) }
            });
            const { status, data } = res;
            if (status === 200) {
                window.location.href = config.WEB_URL + `FormLoginUser`;
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    onChangeCheckBox(e) {
        this.setState({ aggrementCheckBox: e.target.checked});
    }
    
    render() {                                
        const {aggrementCheckBox, fullname, email}= this.state;     
        return (          
            <Fragment>          
                <ToastContainer />
                <FrontHeaderUser/>
                <div className="bg-body-grey">
                    <Container className="pb-5">
                        <Row>
                            <Col className="d-none d-lg-block my-auto">
                                <img src={imgBanner1} alt="banner-avisha"/>
                            </Col>
                            <Col md="8" lg="6" className="mx-auto my-5">
                                <Col lg="10" className="bg-white box-shadow p-4 my-5">
                                    <h5 className="text-center font-weight-bolder">Sign Up Jobseeker</h5><br/>
                                    <Form onSubmit={this.toggleModalAgreement}>
                                        <FormGroup className="mb-0">
                                            <Label className="text-14">Full Name</Label>
                                            <Input type="text" name="fullname" 
                                                   id="EmailUser" className="input-box-grey" 
                                                   onChange={this.handleChange}
                                                   placeholder="Full Name"
                                                   value={fullname}
                                                   valid={ this.state.validate.fullName === 'has-success' }
                                                   invalid={ this.state.validate.fullName === 'has-danger' }
                                                   required
                                                /><br/>
                                        </FormGroup>
                                        <FormGroup className="mb-0">
                                            <Label className="text-14">Email</Label>
                                            <Input type="email" name="email" id="EmailUser" className="input-box-grey"
                                                    valid={ this.state.validate.email === 'has-success' }
                                                    value={email} placeholder="Email" invalid={ this.state.validate.email === 'has-danger' }
                                                    onChange={this.handleChange}
                                                    required/><br/>
                                        </FormGroup>
                                        <FormGroup className="mb-0">
                                            <Label className="text-14">Phone Number</Label>
                                            <Input type="number" name="phoneNumber" id="EmailUser" className="input-box-grey"
                                                    valid={ this.state.validate.phoneNumber === 'has-success' }
                                                    placeholder="Phone Number" invalid={ this.state.validate.phoneNumber === 'has-danger' }
                                                    onChange={this.handleChange}
                                                    required/><br/>
                                        </FormGroup>
                                        <FormGroup className="mb-0">
                                            <Label className="text-14">Password</Label>
                                            <Input type="password" name="password" id="PasswordUser" 
                                            className="input-box-grey" onChange={this.handleChange}
                                            placeholder="Min 6 characters" valid={ this.state.validate.password === 'has-success' }
                                            invalid={ this.state.validate.password === 'has-danger' }
                                            required/><br/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="text-14">Re-Password</Label>
                                            <Input type="password" name="checkPassword" id="PasswordUser" 
                                            className="input-box-grey" onChange={this.handleChange}
                                            placeholder="Re-Password" valid={ this.state.validate.confirmPassword === 'has-success' }
                                            invalid={ this.state.validate.confirmPassword === 'has-danger' }
                                            required/><br/>
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <Button className="btn-blue btn-block" onClick={this.toggleModalAgreement} disabled={ this.state.fullName === "" || this.state.phoneNumber === "" || this.state.password === "" }>SIGN UP</Button>
                                                {/* <Link to="/FormLoginUser"> <Button className="btn-blue btn-block" >SIGN UP</Button></Link> */}
                                                <p className="text-center m-0 p-2 separator">or</p>
                                                <Link><Button className="btn-grey btn-block" onClick={this.registerUserGoogle}>SIGN UP WITH GOOGLE <img src={logoGoogle}  className="float-right" alt="logo-google" width="18px"/></Button></Link><br/>
                                                <p className="text-9 text-mute text-center">
                                                    By signing in to your account, you agree to indeed’s <u>term of service</u> and
                                                    consent to our <u>cookie policy</u> and <u>privacy policy</u>
                                                </p>
                                                <p className="text-10 text-center text-mute mb-0">Already have an account? <Link to="/FormLoginUser"><Label className="text-blue mb-0">Sign In</Label></Link></p>
                                                <Link to="#"><p className="text-10 text-center text-mute">Forgot Password?</p></Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal isOpen={this.state.modalAgreement} toggle={this.toggleModalAgreement}>
                    <ModalBody>
                        <h3 className="mb-3">Terms and Conditions</h3>
                        <div className="rounded-lg mb-3 p-2" style={{height:'400px', overflowX:'scroll', backgroundColor:'#E8E8E8'}}>
                            <Termcon/>
                        </div>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" onChange={e => this.onChangeCheckBox(e)} required={"Please check this box if you want to proceed"}/>{' '}<Label className="text-16 font-weight-bold">I accept the Terms and Conditions</Label>
                            </Label>
                        </FormGroup>
                        <Button className="btn-block btn-blue-normal mb-3" disabled={aggrementCheckBox===false?true:false} id="submitSignUp" onClick={ this.handleSubmit }>Continue</Button>
                        <Button className="btn-block btn-warning" onClick={this.toggleModalAgreement}>Decline</Button>
                    </ModalBody>
                </Modal>
            </Fragment>
        );   
    }
};

export default FormSignupUser;