import React, { Fragment }            from 'react';
import Header                         from './Component/Header';
import Banners                        from './Component/Banners';
// import Carousel                       from '../Component/Carousel';
// import ContentLandingTop              from '../Component/ContentLandingTop';
// import ContentLandingBottom           from '../Component/ContentLandingBottom';
import Footer                         from './Component/Footer';

// import '../Css/Header.css';
// import '../Css/Reuse.css';

const Beranda = () => {
  return(
      <Fragment>
        <Header/>
        <Banners/>
        <Footer/>
      </Fragment>
    );
  };

export default Beranda;
