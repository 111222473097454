import React                                        from 'react';

import { Link }                                     from 'react-router-dom';

import { Container, Col, Label, Button,
         Input, InputGroup, InputGroupAddon }       from 'reactstrap'; 
           
import { FontAwesomeIcon }                          from '@fortawesome/react-fontawesome'
import { faSearch }                                 from '@fortawesome/free-solid-svg-icons'
         
class Filter extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    render() {
        return (
            <Container>
                <Col className="bg-white rounded mb-3 p-3">
                    <Col className="mb-3">
                        <Label className="d-block text-14 font-weight-bolder mb-1">Search</Label>
                        <InputGroup>
                            <Input type="text" id="search" className="search input-box-grey text-12" onChange=""/>
                            <InputGroupAddon addonType="append">
                                <Button id="btn-FindTalent" name="btn-find-talent" className="btn-blue" onChange=""><FontAwesomeIcon icon={faSearch} /></Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col className="mb-3">
                        <Label className="d-block text-14 font-weight-bolder mb-1">Location</Label>
                        <div className="spacing-checkbox text-12">
                        <Input type="checkbox" id="checkboxLocation" className="checkboxLocation" value=""/> Jakarta<br/>
                        <Input type="checkbox" id="checkboxLocation" className="checkboxLocation" value=""/> Depok <br/>
                        <Input type="checkbox" id="checkboxLocation" className="checkboxLocation" value=""/> Bogor
                        </div>
                    </Col>
                    <Col className="mb-3">
                        <Label className="d-block text-14 font-weight-bolder mb-1">Type of Employment</Label>
                        <div className="spacing-checkbox text-12">
                        <Input type="checkbox" id="checkboxLocation" className="checkboxLocation" value=""/> Full Time<br/>
                        <Input type="checkbox" id="checkboxLocation" className="checkboxLocation" value=""/> Part Time <br/>
                        </div>
                    </Col>
                    <Col>
                        <Label className="d-block text-14 font-weight-bolder mb-1">Salary Range</Label>
                        <Input type="range" id="checkboxLocation" className="checkboxLocation" defaultValue={0}/>
                    </Col>
                </Col>
                <Col className="bg-white rounded mb-3 p-3">
                    <Col className="mb-3">
                        <Label className="d-block text-14 font-weight-bolder mb-1">Sort By:</Label>
                        <div className="spacing-checkbox text-12">
                            <Input type="checkbox" id="checkboxLocation" className="checkboxLocation check-box" value=""/> Periode<br/>
                            <Input type="checkbox" id="checkboxLocation" className="checkboxLocation check-box" value=""/> Slot<br/>
                            <Input type="checkbox" id="checkboxLocation" className="checkboxLocation check-box" value=""/> Highest Salary<br/>
                            <Input type="checkbox" id="checkboxLocation" className="checkboxLocation check-box" value=""/> Lowest Salary<br/>
                        </div>
                    </Col>
                </Col>
            </Container>
        );
    }
}

export default Filter;