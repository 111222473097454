import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import {FontAwesomeIcon}                                    from '@fortawesome/react-fontawesome'
import {faTimesCircle}                                      from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import '../Css/style.css';
import {
    Container, Row, Col, Label, 
    Button, Input, Modal, ModalBody, 
    ModalHeader, ModalFooter
}                                                           from 'reactstrap';

class CreateFullTime extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        id:                     0,
        dataClient:             {},
        companyName:            '',
        workType:               'Full-Time',
        skillNeeded:            '', 
        minExperienceYear:      0,
        jobDescription:         '',
        minimumQualification:   '',
        quota:                  0,
        jobName:                '',
        minSalary:              0,
        maxSalary:              0,
        workAddress:            '',
        idCategory:             0,
        idJobPosition:          0,
        idPosition:             0,
        idJobSpecialization:    0,
        lastEducation:          '',
        idAcademicMajor:        0,
        isSalaryHide:           false,
        province:               0,
        city:                   0,
        district:               0,
        village:                0,
        deadline:               '',
        absen_locations:        [],
        absen_types:            [],
        shifts:                 [{
            before_in:  60,
            after_in:   60,
            shift_in:   '08:00',
            before_out: 60,
            after_out:  60,
            shift_out:  '17:00',
            quota:      1
        }],
        employees:              [],
        modalCreateJob:         false,
        dataSpecializations:    [],
        dataCategories:         [],
        dataJobPositions:       [],
        dataPositions:          [],
        dataStudies:            [],
        dataProvincies:         [],
        dataCities:             [],
        dataDistricts:          [],
        dataVillages:           [],
        dataLocations:          [],
        dataTypes:              [],
        dataEmployees:          [],
        alert:                  false,
        alertWarna:             '',
        alertTeks:              '',
        dataLastEducations:     [
            {
                'value':"SMU/SMA",
                'label':"SMU/SMA"
            },
            {
                'value':"Diploma 1",
                'label':"Diploma 1"
            }, 
            {
                'value':"Diploma 2",
                'label':"Diploma 2"
            },
            {
                'value':"Diploma 3",
                'label':"Diploma 3"
            }, 
            {
                'value':"Diploma 4",
                'label':"Diploma 4"
            },
            {
                'value':"Sarjana 1",
                'label':"Sarjana 1"
            }, 
            {
                'value':"Sarjana 2",
                'label':"Sarjana 2"
            },
            {
                'value':"Sarjana 3",
                'label':"Sarjana 3"
            }                                
        ],
    }

    componentDidMount = async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getDataClient();
        await this.getJobSpecializations();
        await this.getJobCategories();
        await this.getJobPositions();
        await this.getAllPositions();
        await this.getProvincies();
        await this.getStudies();
        await this.getLocations();
        await this.getTypes();
        await this.getAllEmployee();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({id});
    }

    _generateParam = (param = {}) => {
        param.key_api = config.KEY_API;
        return {params: {data: JSON.stringify(param)}};
    }

    getDataClient = async () => {
        const {id} = this.state;
        const res = await api.getDataClient(id, this._generateParam());
        const {data} = res.data;
        this.setState({
            dataClient: data,
            companyName: data.company_name,
        });
    }

    reset = () => this.setState({
        skillNeeded:            '', 
        minExperienceYear:      0,
        jobDescription:         '',
        minimumQualification:   '',
        quota:                  0,
        jobName:                '',
        minSalary:              0,
        maxSalary:              0,
        workAddress:            '',
        idCategory:             0,
        idJobPosition:          0,
        idPosition:             0,
        idJobSpecialization:    0,
        lastEducation:          '',
        idAcademicMajor:        0,
        isSalaryHide:           false,
        province:               0,
        city:                   0,
        district:               0,
        village:                0,
        deadline:               '',
        absen_locations:        [],
        absen_types:            [],
        shifts:                 [{
            before_in:  60,
            after_in:   60,
            shift_in:   '08:00',
            before_out: 60,
            after_out:  60,
            shift_out:  '17:00',
            quota:      1
        }],
        employees:              []
    });

    getJobSpecializations = async () => {
        const res = await api.getJobSpecializations(this._generateParam());
        const {data} = res.data;
        this.setState({
            dataSpecializations: data.map(({id, job_specialization_name, detail}) => ({
                value: id,
                label: `${job_specialization_name}-${detail}`
            }))
        });
    }

    getJobCategories = async () => {
        const res = await api.getJobCategories(this._generateParam());
        const {data} = res.data;
        this.setState({
            dataCategories: data.map(({id, job_category, job_sub_category}) => ({
                value: id,
                label: `${job_category}-${job_sub_category}`
            }))
        });
    }

    getJobPositions = async () => {
        const res = await api.getJobPosition(this._generateParam());
        const {data} = res.data;
        this.setState({
            dataJobPositions: data.map(({id, position_type}) => ({
                value: id,
                label: position_type
            }))
        });
    }

    getAllPositions = async() => {
        const {id} = this.state;
        const res = await api.getAllPositions(id, this._generateParam())
        const {data} = res.data;
        this.setState({
            dataPositions: data.map(({id, jabatan}) => ({
                value: id,
                label: jabatan
            }))
        });
    }

    getProvincies = async () => {
        const res = await api.getProvincies(this._generateParam());
        const {data} = res.data;
        this.setState({
            dataProvincies: data.map(({id, province_name}) => ({
                value: id,
                label: province_name
            }))
        });
    }

    getStudies = async () => {
        const res = await api.getStudies(this._generateParam());
        const {data}  = res.data;
        this.setState({
            dataStudies: data.map(({id, academic_major_name}) => ({
                value:  id,
                label:  academic_major_name
            }))
        });
    }

    getLocations = async() => {
        const {id} = this.state;
        const res = await api.getAbsenLocations(id, this._generateParam());
        const {data} = res.data;
        this.setState({
            dataLocations: data.map(location => ({
                value: location.absen_location_id,
                label: location.location_name
            }))
        });
    };

    getTypes = async() => {
        const {id} = this.state;
        const res = await api.getAbsenTypes(id, this._generateParam());
        const {data} = res.data;
        this.setState({
            dataTypes: data.map(({absen_type_id, type_name}) => ({
                value:  absen_type_id,
                label:  type_name
            }))
        });
    };

    getAllEmployee = async() => {
        const res = await api.getAllEmployee(this._generateParam());
        const {data} = res.data;
        this.setState({
            dataEmployees: data.map(({id, full_name}, index) => ({
                value:  id,
                label:  `${full_name} (${this.fixId(id)})`,
                index
            }))
        });
    }

    fixId = id => {
        if(id) {
            const str = ''+id;
            const pad = '800000000000000';
            const ans = pad.substring(0, pad.length - str.length) + str
            return ans;
        }
    }

    handleChange = e => this.setState({[e.target.name]: e.target.value});

    handleSelect = ({value}, e) => this.setState({[e.name]: value});

    handleChooseProvince = async({value}, e) => {
        this.setState({[e.name]: value});
        const res = await api.getRegencies(value, this._generateParam());
        const {data} = res.data;
        this.setState({
            city:       0,
            district:   0,
            village:    0,
            dataCities: data.map(({id, regency_name}) => ({
                value: id,
                label: regency_name
            }))
        });
    }

    handleChooseCity = async({value}, e) => {
        this.setState({[e.name]: value});
        const res = await api.getSubdistrict(value, this._generateParam());
        const {data} = res.data;
        this.setState({
            district:   0,
            village:    0,
            dataDistricts: data.map(({id, sub_district_name}) => ({
                value: id,
                label: sub_district_name
            }))
        });
    }

    handleChooseDistrict = async({value}, e) => {
        this.setState({[e.name]: value});
        const res = await api.getVillages(value, this._generateParam());
        const {data} = res.data;
        this.setState({
            village:        0,
            dataVillages:   data.map(({id, village_name}) => ({
                value: id,
                label: village_name
            }))
        });
    }

    handleChooseVillage = async({value}, e) => this.setState({[e.name]: value});

    handleChooseTypes = types => {
        const datas = [];
        if(types) types.forEach(type => datas.push(type.value));
        this.setState({absen_types: datas});
    }

    handleChooseLocations = locations => {
        const datas = [];
        if(locations) locations.forEach(location => datas.push(location.value));
        this.setState({absen_locations: datas});
    }

    handleChangeShift = (param, index, nilai) => {
        const {shifts} = this.state;
        shifts[index][param] = nilai;
        this.setState({shifts});
    }

    addShift = () => {
        const {shifts} = this.state;
        shifts.push({
            before_in:  60,
            after_in:   60,
            shift_in:   '',
            before_out: 60,
            after_out:  60,
            shift_out:  '',
            quota:      ''
        });
        this.setState(shifts);
    }

    deleteShift = index => {
        const {shifts} = this.state;
        delete shifts[index];
        this.setState(shifts);
    }

    addEmployee = () => {
        const {employees} = this.state;
        employees.push('');
        this.setState(employees);
    }

    deleteEmployee = index => {
        const {employees} = this.state;
        employees.splice(index, 1);
        this.setState(employees);
    }

    handleChangeEmployee = ({value}, index) => {
        const {employees} = this.state;
        employees[index] = value;
        this.setState(employees);
    }

    toggleAlert = () => this.setState((prevState) => ({alert: !prevState.alert}));

    shiftingHTML = (index, {shift_in, shift_out, quota}) => <tr key={index}>
        <td><input type="time" className="form-control form-control-sm" value={shift_in} onChange={e => this.handleChangeShift('shift_in', index, e.target.value)}/></td>
        <td><input type="time" className="form-control form-control-sm" value={shift_out} onChange={e => this.handleChangeShift('shift_out', index, e.target.value)}/></td>
        <td><input type="number" className="form-control form-control-sm" value={quota} onChange={e => this.handleChangeShift('quota', index, e.target.value)}/></td>
        <td className="text-center">{(index == 0)? null : <button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteShift(index)}>Delete</button>}</td>
    </tr>;

    employeesHTML = index => {
        const {dataEmployees} = this.state;
        return <tr key={index}>
            <td><Select name={`employee`} id={`employee`} options={dataEmployees} onChange={e => this.handleChangeEmployee(e, index)}/></td>
            <td className="text-center"><button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteEmployee(index)}>Delete</button></td>
        </tr>;
    }

    bodyUtamaHTML = () => {
        const {
            companyName, workType, dataSpecializations, dataCategories, dataJobPositions, dataStudies, dataProvincies, dataCities, 
            dataDistricts, dataVillages, dataLocations, dataTypes, dataLastEducations, jobName, skillNeeded, jobDescription, dataPositions,
            minExperienceYear, quota, minimumQualification, deadline, minSalary, maxSalary, isSalaryHide, workAddress, shifts, alert
        } = this.state;
        return <div>
            {(!alert)? null : ((alert == 1)? null : this.alertHTML())}
            <div className="row">
                <div className="col-md">
                    <div className="row mb-1">
                        <div className="col-4">Company's Name</div>
                        <div className="col"><input disabled className="form-control form-control-sm" value={companyName}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4">Job Type</div>
                        <div className="col"><input disabled className="form-control form-control-sm" value={workType}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="jobName">Job Name</Label></div>
                        <div className="col"><Input name="jobName" id="jobName" type="text" className="form-control form-control-sm" value={jobName} onChange={this.handleChange}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label htmlFor="idCategory" className="text-14 mb-1">*Job Category</Label></div>
                        <div className="col"><Select name="idCategory" id="idCategory" options={dataCategories} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label htmlFor="idJobPosition" className="text-14 mb-1">*Job Title</Label></div>
                        <div className="col"><Select name="idJobPosition" id="idJobPosition" options={dataJobPositions} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label htmlFor="idPosition" className="text-14 mb-1">*Job Position</Label></div>
                        <div className="col"><Select name="idPosition" id="idPosition" options={dataPositions} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label htmlFor="idJobSpecialization" className="text-14 mb-1">*Job Specialization</Label></div>
                        <div className="col"><Select name="idJobSpecialization" id="idJobSpecialization" options={dataSpecializations} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label htmlFor="lastEducation" className="text-14 mb-1">*Last Education</Label></div>
                        <div className="col"><Select name="lastEducation" id="lastEducation" options={dataLastEducations} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                    <div className="col-4"><Label htmlFor="idAcademicMajor" className="text-14 mb-1">Study</Label></div>
                        <div className="col"><Select name="idAcademicMajor" id="idAcademicMajor" options={dataStudies} onChange={this.handleSelect.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14" htmlFor="skillNeeded">Skill</Label></div>
                        <div className="col"><textarea placeholder="Skill..." className="form-control" id="skillNeeded" name="skillNeeded" onChange={this.handleChange}>{skillNeeded}</textarea></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="jobDescription">*Job Description</Label></div>
                        <div className="col"><textarea placeholder="Job description..." className="form-control" id="jobDescription" name="jobDescription" onChange={this.handleChange}>{jobDescription}</textarea></div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14" htmlFor="minExperienceYear">*Experience</Label></div>
                        <div className="col">
                            <div className="input-group">
                                <Input type="number" name="minExperienceYear" id="minExperienceYear" className="form-control form-control-sm" onChange={this.handleChange} value={minExperienceYear}/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary btn-sm" disabled>year</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="quota">*Quota</Label></div>
                        <div className="col">
                            <div className="input-group">
                                <Input type="number" name="quota" id="quota" className="form-control form-control-sm" onChange={this.handleChange} value={quota}/>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary btn-sm" disabled>person</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="minimumQualification">Minimum Qualification</Label></div>
                        <div className="col"><Input placeholder="Minimum qualification..." type="textarea" id="minimumQualification" name="minimumQualification" className="form-control form-control-sm" onChange={this.handleChange} value={minimumQualification}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="deadline">Deadline</Label></div>
                        <div className="col"><Input type="date" id="deadline" name="deadline" className="form-control form-control-sm" onChange={this.handleChange} value={deadline}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="minSalary">*Range Salary</Label></div>
                        <div className="col">
                            <div className="row">
                                <div className="col-md"><Input type="number" id="minSalary" name="minSalary" className="form-control form-control-sm" onChange={this.handleChange} value={minSalary}/></div>
                                <div className="col-md"><Input type="number" id="maxSalary" name="maxSalary" className="form-control form-control-sm" onChange={this.handleChange} value={maxSalary}/></div>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="isSalaryHide" name="isSalaryHide" value={isSalaryHide} onChange={this.handleChange}/>
                                <label className="form-check-label text-12" htmlFor="isSalaryHide">Confidential Salary / Rates</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="province">*Province</Label></div>
                        <div className="col"><Select id="province" name="province" options={dataProvincies} onChange={this.handleChooseProvince.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="city">*City</Label></div>
                        <div className="col"><Select name="city" id="city" options={dataCities} onChange={this.handleChooseCity.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="district">*District</Label></div>
                        <div className="col"><Select name="district" id="district" options={dataDistricts} onChange={this.handleChooseDistrict.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="village">*Village</Label></div>
                        <div className="col"><Select name="village" id="village" options={dataVillages} onChange={this.handleChooseVillage.bind(this)}/></div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-4"><Label className="text-14 mb-1" htmlFor="workAddress">*Address</Label></div>
                        <div className="col"><textarea placeholder="Address..." id="workAddress" name="workAddress" className="form-control form-control-sm" onChange={this.handleChange}>{workAddress}</textarea></div>
                    </div>
                </div>
            </div><hr/>
            <p className="font-weight-bold">*Attendance</p>
            <div className="row">
                <div className="col-md">
                    <Label className="text-14 mb-1">Types</Label>
                    <Select name="absen_types" closeMenuOnSelect={false} components={this.animatedComponents} isMulti options={dataTypes} onChange={this.handleChooseTypes.bind(this)}/>
                </div>
                <div className="col-md">
                    <Label className="text-14 mb-1">Locations</Label>
                    <Select name="absen_locations" id="absen_locations" closeMenuOnSelect={false} components={this.animatedComponents} isMulti options={dataLocations} onChange={ this.handleChooseLocations.bind(this) }/>
                </div>
            </div><hr/>
            {this.tabelShiftHTML()}
            {this.tabelEmployeeHTML()}
            <small>
                <p style={{color:'red'}} className="text-red">Prefix with symbol * is required (Mandatory).</p>
            </small><hr/>
            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.submit}>Next</Button></Col>
        </div>;
    }

    tabelShiftHTML = () => {
        const {shifts} = this.state;
        return <div>
            <p className="font-weight-bold">*Shifting</p>
            <button className="btn btn-primary btn-sm mb-2" onClick={this.addShift}>Add Shift</button>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-sm" style={{width: '100%'}}>
                    <thead className="thead-dark">
                        <tr className="text-center">
                            <th>Shift In</th>
                            <th>Shift Out</th>
                            <th style={{width: '15%'}}>Quota</th>
                            <th style={{width: '5%'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {shifts.map((shift, i) => this.shiftingHTML(i, shift))}
                    </tbody>
                </table>
            </div><hr/>
        </div>;
    }

    tabelEmployeeHTML = () => {
        const {employees} = this.state;
        return <div>
            <p className="font-weight-bold">Employee</p>
            <button className="btn btn-primary btn-sm mb-2" onClick={this.addEmployee}>Add Employee</button>
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-sm" style={{width: '100%'}}>
                    <thead className="thead-dark">
                        <tr className="text-center">
                            <th>Employee</th>
                            <th style={{width: '10%'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee, i) => this.employeesHTML(i))}
                    </tbody>
                </table>
            </div><hr/>
        </div>;
    }

    toggleModalCreateJob = () => this.setState((prevState) => ({modalCreateJob: !prevState.modalCreateJob}));

    submit = () => {
        const {
            skillNeeded, minExperienceYear, jobDescription, minimumQualification,
            quota, jobName, minSalary, maxSalary, workAddress,
            idCategory, idJobPosition, idPosition, idJobSpecialization, lastEducation,
            idAcademicMajor, province, city, district, village, shifts
        } = this.state;
        if(skillNeeded == '' || minExperienceYear == 0 || jobDescription == ''|| minimumQualification == '' || quota == 0 || jobName == '' || minSalary == 0 || maxSalary == 0 ||  workAddress == '' || idCategory == 0 || idJobPosition == 0 || idPosition == 0 || idJobSpecialization == 0 || lastEducation == '' || idAcademicMajor == 0 || province == 0 || city == 0 || district == 0 || village == 0 || shifts.length == 0) {
            alert("Please fill mandatory input!");
        } else {
            this.toggleModalCreateJob();
        }
    }

    createJob = async() => {
        const {
            skillNeeded, minExperienceYear, jobDescription, minimumQualification, companyName,
            quota, jobName, minSalary, maxSalary, workAddress, employees, id, workType, absen_types,
            idCategory, idJobPosition, idPosition, idJobSpecialization, lastEducation, absen_locations,
            idAcademicMajor, province, city, district, village, shifts, isSalaryHide, deadline
        } = this.state;
        const res = await api.addFulltimeJob(this._generateParam({
            idKlien: id, companyName, deadline, isSalaryHide, absen_locations, absen_types,
            skillNeeded, minExperienceYear, jobDescription, minimumQualification,
            quota, jobName, minSalary, maxSalary, workAddress, employees, workType,
            idCategory, idJobPosition, idPosition, idJobSpecialization, lastEducation,
            idAcademicMajor, province, city, district, village, shifts
        }));
        const {error, message} = res.data;
        if(!error) {
            this.setState({
                alert:      2,
                alertWarna: 'success',
                alertTeks:  message,
                modalCreateJob: false
            });
            window.location.reload();
        } else {
            this.setState({
                alert:      1,
                alertWarna: 'danger',
                alertTeks:  message
            });
        }
    }

    modalCreateFullTimeJobHTML = () => {
        const {modalCreateJob, alert} = this.state;
        return <Modal isOpen={modalCreateJob} toggle={this.toggleModalCreateJob}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalCreateJob}/>
            <ModalHeader>Create Full Time Job</ModalHeader>
            <ModalBody>
                {(!alert)? null : ((alert == 2)? null : this.alertHTML())}
                <p>Are you sure you want to create this job?</p>
            </ModalBody>
            <ModalFooter><button className="btn btn-primary" onClick={this.createJob}>Confirm</button></ModalFooter>
        </Modal>;
    }

    alertHTML = () => {
        const {alert, alertWarna, alertTeks} = this.state;
        return <div className={`alert alert-${alertWarna} alert-dismissible fade show ${(alert == 1)? '' : 'my-2 mx-2'}`} role="alert">
            {alertTeks}<button onClick={this.toggleAlert} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>;
    }

    render() {
        return <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Create Job Full Time</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="container mt-4">
                        <div className="card">
                            <div className="card-body">{this.bodyUtamaHTML()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {this.modalCreateFullTimeJobHTML()}
        </Fragment>;
    }
}

export default CreateFullTime;