import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Container, Row, Col, FormGroup,
         Label, Button, 
         Input, Card, CardBody, 
         Modal, ModalBody}                                  from 'reactstrap';   
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import NumberFormat                                         from 'react-number-format';
import DatePicker                                           from 'react-datepicker';
import { registerLocale }                 from  'react-datepicker';
import id                                                   from 'date-fns/locale/id';
import "react-datepicker/dist/react-datepicker.css";


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];
class EditDraftPartTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [options[0]],
            companyName:"",
            workType:"Part-Time",
            skillNeeded:"", 
            minExperienceYear:0,
            jobDescription:"",
            minimumQualification:"",
            picPhoneNumber:"", 
            picName:"",
            quota:0,
            partTimeSalary:0, 
            workAddress:"",
            workTimeStart:"",
            workTimeFinish:"",
            gender:"",
            idCategory:0,
            idJobPosition:0,
            idJobSpecialization:0,
            lastEducation:"",
            idAcademicMajor:0,
            idVillage:0,
            activeTab: '0',
            id:0,
            jobCategorySelected:"",
            jobPositionSelected:"",
            jobSpesializationSelected:"",
            academyMajorSelected:"",
            provinceSelected:"",
            locationSelected: [],
            typeSelected: [],
            regencySelected:"",
            subdistrictSelected:"",
            villageSelected:"",
            modalOpenApproveCandidates: false,
            isSalaryHide:false,
            dataDraftJob:{},
            dataSpecializations:[],
            dataCategories:[],
            dataJobPosition:[],
            dataStudies:[],
            dataProvincies:[],
            dataRegencies:[],
            dataSubdistricts:[],
            dataVillages:[],
            dataClient:[],
            dataGender:[
                            {
                                'value':"Laki - laki",
                                'label':"Laki - laki"
                            },
                            {
                                'value':"Perempuan",
                                'label':"Perempuan"
                            },
                            {
                                'value':"Laki - laki dan Perempuan",
                                'label':"Laki - laki dan Perempuan"
                            }
            ],
            dataLastEducation:[
                                {
                                    'value':"SMU/SMA",
                                    'label':"SMU/SMA"
                                },
                                {
                                    'value':"Diploma 1",
                                    'label':"Diploma 1"
                                }, 
                                {
                                    'value':"Diploma 2",
                                    'label':"Diploma 2"
                                },
                                {
                                    'value':"Diploma 3",
                                    'label':"Diploma 3"
                                }, 
                                {
                                    'value':"Diploma 4",
                                    'label':"Diploma 4"
                                },
                                {
                                    'value':"Sarjana 1",
                                    'label':"Sarjana 1"
                                }, 
                                {
                                    'value':"Sarjana 2",
                                    'label':"Sarjana 2"
                                },
                                {
                                    'value':"Sarjana 3",
                                    'label':"Sarjana 3"
                                }                                
            ],
            locations: [],
            absen_locations: [],
            absen_types: [],
            shifts: [{
                before_in: 60,
                after_in: 60,
                shift_in: '08:00',
                before_out: 60,
                after_out: 60,
                shift_out: '17:00',
                quota: 1
            }]
        };
    }


    
    handleChangeLocation = it => {
        this.setState({ locationSelected: it });
    };
    handleChangeType = it => {
        this.setState({ typeSelected: it });
    };


    componentDidMount= async () => {
        await this.getId();
        await this.getLocations();
        await this.getTypes();
        await this.getJobSpecializations();
        await this.getJobCategories();
        await this.getJobPosition();
        await this.getProvincies();
        await this.getStudies();
        await this.getDataClient();
        await this.getDetailDraftJob();
        registerLocale('id', id)
    }

    handleCheckBox = () =>{
        const { isSalaryHide } = this.state;
        this.setState({isSalaryHide:!isSalaryHide});
    }

    getProvincies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getProvincies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataProvincies: data.map((k) => ({ value: k.id, label: k.province_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    getTypes = async() => {
        const {id} = this.state;
        const res = await api.getAbsenTypes(id, this.dataParam);
        const {data} = res.data;
        const types = [];
        data.forEach(type => {
            types.push({
                value: type.absen_type_id,
                label: type.type_name
            });
        });
        this.setState({types});
    };

    getLocations = async() => {
        const {id} = this.state;
        const res = await api.getAbsenLocations(id, this.dataParam);
        const {data} = res.data;
        const locations = [];
        data.forEach(location => {
            locations.push({
                value: location.absen_location_id,
                label: location.location_name
            });
        });
        this.setState({locations});
    };

    getDetailDraftJob = async () => {
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDetailDraftJob(localStorage.getItem('idJob'),
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataDraftJob:data,
                skillNeeded:data['skill_needed'], 
                minExperienceYear:data['min_experience_year'], 
                jobDescription:data['job_description'], 
                minimumQualification:data['minimum_qualification'], 
                quota:data['quota'], 
                partTimeSalary:data['part_time_salary'], 
                workAddress:data['work_address'], 
                workTimeStart:new Date(data['start_part_time_date']), 
                workTimeFinish:new Date(data['finish_part_time_date']), 
                gender:data['gender'],
                jobCategorySelected:data['job_category'] + "-" +data['job_sub_category'],
                jobPositionSelected:data['job_position'],
                jobSpesializationSelected:data['job_specialization_name']+ "-" +data['detail'],
                academyMajorSelected:data['academic_major_name'],
                provinceSelected:data['province_name'],
                regencySelected:data['regency_name'],
                subdistrictSelected:data['sub_district_name'],
                villageSelected:data['village_name'],
                idCategory:data['id_job_category'],
                idJobPosition:data['id_job_position'],
                idJobSpecialization:data['id_job_specialization'],
                lastEducation:data['last_education'],
                idAcademicMajor:data['id_academic_major'],
                idVillage:data['id_village'],
                picName:data['pic_name'],
                picPhoneNumber:data['pic_phone_number'],
                shifts: this.mapShift(data['absen']),
                locationSelected: this.mapLocation(data['absen_location_id']),
                typeSelected: this.mapType(data['absen_type_job_id']),
            });
            await this.getRegencies(data['id_provincy']);
            await this.getSubdistrict(data['id_regency']);
            await this.getVillages(data['id_subdistrict']);
            
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    mapType (strType){
        let tmp = strType.split(","); 
        console.log(this.state.types);
        const type = tmp.map((it,index) => {
            const findById = this.state.types.find(map => map.value === parseInt(it));
            console.log(it)
            console.log(findById)
            return {
                    value : findById?.value,
                    label : findById?.label,
            }
        })
        return type;
    }
    mapLocation (strLocation){
        let locTmp = strLocation.split(","); 
        const loc = locTmp.map((it,index) => {
            const findById = this.state.locations.find(map => map.value === parseInt(it));
            console.log(findById)
            return {
                    value : findById?.value,
                    label : findById?.label,
            }
        })
        return loc;
    }
    mapShift (strShift){
        console.log('shift');
        let shiftTmp = strShift.split(","); 
        const shift = shiftTmp.map((it,index) => {
            const inOut = it.split(";");
            return {
                    before_in: 60,
                    after_in: 60,
                    shift_in: inOut[0],
                    before_out: 60,
                    after_out: 60,
                    shift_out: inOut[1],
                    quota: inOut[2]
            }
        })
        return shift;
    }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                dataClient:data,
                companyName:data.company_name,
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getStudies = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getStudies({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataStudies: data.map((k) => ({ value: k.id, label: k.academic_major_name })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobPosition = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobPosition({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataJobPosition: data.map((k) => ({ value: k.id, label: k.position_type })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }


    getJobCategories = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobCategories({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataCategories: data.map((k) => ({ value: k.id, label: k.job_category + "-" +k.job_sub_category })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getJobSpecializations = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API,
            })
            const res = await api.getJobSpecializations({
                params:{data:JSON.stringify(newData)}
            })
            const { data } = res.data;
            this.setState({ dataSpecializations: data.map((k) => ({ value: k.id, label: k.job_specialization_name + "-" +k.detail })) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }
    handleChooseTypes = async (types) => {
        const datas = [];
        if(types) types.forEach(type => datas.push(type.value));
        this.setState({absen_types: datas});
    }

    handleChooseLocations = async (locations) => {
        const datas = [];
        if(locations) locations.forEach(location => datas.push(location.value));
        this.setState({absen_locations: datas});
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeNumberFormat = (formattedValue) => {
        this.setState({ partTimeSalary: formattedValue.value });
    }

    handleChoseeGender = async (element) => {
        this.setState({gender:element.value});
    }

    handleChoseeCategory = async (element) => {
        this.setState({idCategory:element.value, jobCategorySelected:element.label});
    }

    handleChoseeAcademiMajor = async (element) => {
        this.setState({idAcademicMajor:element.value, academyMajorSelected:element.label});
    }

    handleChoseeJobPosition = async (element) => {
        this.setState({idJobPosition:element.value, jobPositionSelected:element.label});
    }

    handleChoseeLastEducation = async (element) => {
        this.setState({lastEducation:element.label});
    }

    handleChoseeSpecialization = async (element) => {
        this.setState({idJobSpecialization:element.value, jobSpesializationSelected:element.label});
    }

    handleChoseeVillage = async (element) => {
        this.setState({idVillage:element.value, villageSelected:element.label});
    }

    handleChangeDatetimeStart = (e) => {
        this.setState({ workTimeStart : e,
                        workTimeFinish: e,
                    });
    }

    handleChangeDatetimeFinish = (e) => {
        this.setState({ 
                        workTimeFinish : e ,
        });
    }

    handleChoseeProvincy(element){
        this.setState({provinceSelected:element.label, idVillage:0});
        this.getRegencies(element.value);
    }

    getRegencies = async (id)=>{
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getRegencies(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({ dataRegencies: data.map((k) => ({ value: k.id, label: k.regency_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    handleChoseeRegency = async (element) => {
        this.setState({regencySelected:element.label, idVillage:0});
        this.getSubdistrict(element.value);
    }

    getSubdistrict = async (id)=>{
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getSubdistrict(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({dataSubdistricts: data.map((k) => ({ value: k.id, label: k.sub_district_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }
    
    handleChoseeSubdistrict = async (element) => {
        this.setState({subdistrictSelected:element.label, idVillage:0});
        this.getVillages(element.value);
    }

    getVillages = async (id)=>{
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getVillages(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({dataVillages: data.map((k) => ({ value: k.id, label: k.village_name})) });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    closeModal = () => {
        this.setState(prevState => ({
            modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
        }));            
    }    

    errorDataNotComplate = () => {
        alert("Data Mandatory Tidak Lengkap");
    }    
    
    submit = () => {
        const { idCategory, idJobPosition, idJobSpecialization, lastEducation, partTimeSalary,
                minExperienceYear, jobDescription, gender, workTimeStart, workTimeFinish,
                idVillage, workAddress, quota, picName, picPhoneNumber, locationSelected, typeSelected  } = this.state;
        if((idCategory===0) || (idJobPosition===0) || (idJobSpecialization===0) || (quota===0) || (gender==="") || (partTimeSalary==="") || (partTimeSalary===0)
        || (lastEducation==="") || (minExperienceYear===0) || (jobDescription==="") || (picName==="") || (picPhoneNumber==="")|| (idVillage===0) || (workAddress==="") || (workTimeStart==="") || (workTimeFinish==="")
         || (locationSelected.length === 0) || (typeSelected.length === 0)
        ){
            this.errorDataNotComplate();
        } else {
            this.setState(prevState => ({
                modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
            }));                
        }
    }
    addShift = () => {
        const {shifts} = this.state;
        shifts.push({
            before_in: 60,
            after_in: 60,
            shift_in: '',
            before_out: 60,
            after_out: 60,
            shift_out: '',
            quota: ''
        });
        this.setState(shifts);
    }

    handleChangeShift = (param, index, nilai) => {
        const {shifts} = this.state;
        shifts[index][param] = nilai;
        this.setState({shifts});
    }

    deleteShift = index => {
        const {shifts} = this.state;
        delete shifts[index];
        this.setState(shifts);
    }

    shiftingHTML = (index, {before_in, after_in, shift_in, before_out, after_out, shift_out, quota}) => <tr key={index}>
        <td><input type="time" className="form-control form-control-sm" value={shift_in} onChange={e => this.handleChangeShift('shift_in', index, e.target.value)}/></td>
        <td><input type="time" className="form-control form-control-sm" value={shift_out} onChange={e => this.handleChangeShift('shift_out', index, e.target.value)}/></td>
        <td><input type="number" className="form-control form-control-sm" value={quota} onChange={e => this.handleChangeShift('quota', index, e.target.value)}/></td>
        <td className="text-center">{(index == 0)? null : <button type="button" className="btn btn-danger btn-sm" onClick={() => this.deleteShift(index)}>Delete</button>}</td>
    </tr>;



    editJob = async () => {
        const { id, workType, idCategory, idJobPosition, idJobSpecialization, lastEducation,
            idAcademicMajor, gender, minExperienceYear, skillNeeded, jobDescription, minimumQualification,
            picName, picPhoneNumber, partTimeDate, idVillage, workAddress, quota, partTimeSalary, isSalaryHide, workTimeStart, workTimeFinish, shifts, locationSelected, typeSelected } = this.state;
        const newData = {
            key_api:config.KEY_API,
            id_job:localStorage.getItem('idJob'),
            id_client_company:id,
            work_type:workType,
            id_job_position:idJobPosition,
            id_job_specialization:idJobSpecialization,
            id_job_category:idCategory,
            id_academic_major:idAcademicMajor,
            id_village:idVillage,
            work_address:workAddress,
            salary:partTimeSalary,
            last_education:lastEducation,
            min_experience_year:minExperienceYear,
            skill_needed:skillNeeded,
            job_description:jobDescription,
            minimum_qualification:minimumQualification,
            quota:quota,
            part_time_date:partTimeDate,
            is_hide_nominal_salary:isSalaryHide===true?1:0,
            gender:gender,
            start_part_time_date:workTimeStart,
            finish_part_time_date:workTimeFinish, 
            pic_name:picName, 
            pic_phone_number:picPhoneNumber,    
            shifts: shifts,
            locationSelected: locationSelected,
            typeSelected: typeSelected,
        }
        try{

            console.log(locationSelected)
            console.log(typeSelected)

       
            const res = await api.editDraftJob({
                params: {
                    data: JSON.stringify(newData)
                }
            })
            const { status } = res;
            if (status === 200) {
                localStorage.removeItem('idJob');
                window.location.href = config.WEB_URL + `job`;
                this.setState(prevState => ({
                    modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
                }));            

            }
        } catch(err){
            console.log("Error Response :",err)
        }    
    }

    render() {
        const { 
                partTimeSalary,
                picPhoneNumber, 
                picName,
                skillNeeded, 
                minExperienceYear,
                jobDescription,
                minimumQualification,
                quota,
                workAddress,
                workTimeStart, 
                workTimeFinish,
                dataSpecializations,
                dataCategories,
                dataJobPosition,
                dataStudies,
                jobCategorySelected,
                dataDraftJob,
                dataLastEducation,
                dataProvincies,
                dataRegencies,
                dataSubdistricts,
                dataVillages,
                dataGender,
                modalOpenApproveCandidates, 
                lastEducation,
                isSalaryHide,
                jobPositionSelected,
                jobSpesializationSelected,
                gender,
                academyMajorSelected,
                provinceSelected,
                regencySelected,
                subdistrictSelected,
                villageSelected,
                types,
                locations,
                locationSelected,
                typeSelected,

                shifts
            } = this.state;
        return (
            <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Edit Job Part Time</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div fluid className="pb-5 muli-font bg-body-grey">
                        <Container className="pt-5">
                            <Card>
                                <CardBody>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Nama Perusahaan</Label>
                                        <Input disabled type="text" value={dataDraftJob.company_name} className="input-box-grey"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Tipe Pekerjaan</Label>
                                        <Input disabled type="text" value={dataDraftJob.work_type} className="input-box-grey"/><br/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>* Kategori Pekerjaan</Label>
                                        <Select
                                                name="idCategory"
                                                value={dataCategories.filter(option => option.label === jobCategorySelected)}
                                                onChange={this.handleChoseeCategory.bind(this)}
                                                options={dataCategories}
                                        />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="kotaAddress">* Jabatan Pekerjaan</Label>
                                        <Select
                                                name="idJobPosition"
                                                onChange={this.handleChoseeJobPosition.bind(this)}
                                                value={dataJobPosition.filter(option => option.label === jobPositionSelected)}
                                                options={dataJobPosition}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>* Spesialisasi Pekerjaan</Label>
                                        <Select
                                                name="idJobSpecialization"
                                                value={dataSpecializations.filter(option => option.label === jobSpesializationSelected)}
                                                onChange={this.handleChoseeSpecialization.bind(this)}
                                                options={dataSpecializations}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Gaji</Label>
                                        <Row>
                                            <Col xs="12" sm="12" md="4">
                                                <NumberFormat onValueChange={ (formattedValue)=>this.handleChangeNumberFormat(formattedValue) } name="partTimeSalary" value={partTimeSalary} className="input-box-white" thousandSeparator={true} prefix={'Rp. '} />
                                            </Col>
                                            <Col xs="12" sm="12" md="4" className="my-auto">
                                                <Input onChange={ this.handleCheckBox } type="checkbox" checked={isSalaryHide} id="isSalaryHide" name="isSalaryHide"/>
                                                <Label className="text-16 my-auto">Confidential Salary/Rates</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label> * Pendidikan Terakhir</Label>
                                        <Select
                                                name="lastEducation"
                                                value={dataLastEducation.filter(option => option.label === lastEducation)}
                                                onChange={this.handleChoseeLastEducation.bind(this)}
                                                options={dataLastEducation}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label> * Jenis Kelamin</Label>
                                        <Select
                                                name="gender"
                                                value={dataGender.filter(option => option.label === gender)}
                                                onChange={this.handleChoseeGender.bind(this)}
                                                options={dataGender}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Bidang Studi</Label>
                                        <Select
                                                name="idAcademicMajor"
                                                value={dataStudies.filter(option => option.label === academyMajorSelected)}
                                                onChange={this.handleChoseeAcademiMajor.bind(this)}
                                                options={dataStudies}
                                            />                                                                        
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Pengalaman</Label>
                                        <Row>
                                            <Col lg="1">
                                                <Input type="number" onChange={ this.handleChange } name="minExperienceYear" value={minExperienceYear} className="input-box-white"/><br/>
                                            </Col>
                                            <Col lg="3">
                                                <p> Tahun</p>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Skill</Label>
                                        <Input placeholder="Masukkan skill..." type="text" onChange={ this.handleChange } name="skillNeeded" value={skillNeeded} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Deskripsi Pekerjaan</Label>
                                        <Input placeholder="Masukkan deskripsi pekerjaan..." type="textarea" onChange={ this.handleChange } name="jobDescription" value={jobDescription} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">Minimum Qualification</Label>
                                        <Input placeholder="Masukkan minimum kualifikasi..." type="textarea" onChange={ this.handleChange } name="minimumQualification" value={minimumQualification} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Quota</Label>
                                        <Row>
                                            <Col lg="1">
                                                <Input type="number" onChange={ this.handleChange } name="quota" value={quota} className="input-box-white"/><br/>
                                            </Col>
                                            <Col lg="3">
                                                <p> Orang</p>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Nama PIC</Label>
                                        <Input placeholder="Masukkan nama pic..." type="text" onChange={ this.handleChange } name="picName" value={picName} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Nomor Handphone PIC</Label>
                                        <Input placeholder="Masukkan nomor handphome pic..." type="number" onChange={ this.handleChange } name="picPhoneNumber" value={picPhoneNumber} className="input-box-white"/><br/>
                                    </FormGroup>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Tanggal Masuk kerja</Label>
                                        <Row>
                                            <Col xs="12" sm="12" md="4">
                                                <Label className="text-14 mb-1">Mulai :</Label> <br/>
                                                <DatePicker
                                                    selected={workTimeStart}
                                                    onChange={this.handleChangeDatetimeStart}
                                                    minDate={new Date()}
                                                    locale="id"
                                                    showTimeSelect
                                                    timeFormat="p"
                                                    timeIntervals={15}
                                                    dateFormat="P"
                                                />
                                            </Col>
                                            <Col xs="12" sm="12" md="4">
                                                <Label className="text-14 mb-1">Selesai :</Label> <br/>
                                                <DatePicker
                                                    selected={workTimeFinish}
                                                    onChange={this.handleChangeDatetimeFinish}
                                                    minDate={workTimeStart}
                                                    maxDate={workTimeStart}
                                                    locale="id"
                                                    showTimeSelect
                                                    timeFormat="p"
                                                    timeIntervals={15}
                                                    dateFormat="P"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <FormGroup className="mb-0">
                                        <Label className="text-14 mb-1">* Alamat</Label>
                                        <Row>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Provinsi"
                                                    options={dataProvincies}
                                                    value={dataProvincies.filter(option => option.label === provinceSelected)}
                                                    onChange={this.handleChoseeProvincy.bind(this)}
                                                />                                                                        
                                            </Col>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Kabupaten"
                                                    value={dataRegencies.filter(option => option.label === regencySelected)}
                                                    options={dataRegencies}
                                                    onChange={this.handleChoseeRegency.bind(this)}
                                                />                                                                        
                                            </Col>
                                        </Row>
                                        <p></p>
                                        <Row>
                                            <Col lg="6">
                                                <Select
                                                    name="form-field-name"
                                                    placeholder="Pilih Kecamatan"
                                                    value={dataSubdistricts.filter(option => option.label === subdistrictSelected)}
                                                    options={dataSubdistricts}
                                                    onChange={this.handleChoseeSubdistrict.bind(this)}
                                                />                                                                        
                                            </Col>
                                            <Col lg="6">
                                                <Select
                                                    placeholder="Pilih Kelurahan"
                                                    name="idVillage"
                                                    value={dataVillages.filter(option => option.label === villageSelected)}
                                                    onChange={this.handleChoseeVillage.bind(this)}
                                                    options={dataVillages}
                                                />                                                                        
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Input  placeholder="Masukkan Alamat" type="text" onChange={ this.handleChange } name="workAddress" value={workAddress} className="input-box-white"/><br/>
                                        <p className="font-weight-bold">*Attendance</p>
                                        <div className="row">
                                            <div className="col-md">
                                                <Label className="text-14 mb-1">Types</Label>
                                                <Select
                                                    name="typeSelected"
                                                    isMulti
                                                    value={this.state.typeSelected}
                                                    onChange={ this.handleChangeType } 
                                                    options={types}                     

                                                />
                                            </div>
                                            <div className="col-md">
                                                <Label className="text-14 mb-1">Locations</Label>
                                                <Select
                                                    name="locationSelected"
                                                    isMulti
                                                    value={this.state.locationSelected}
                                                    onChange={ this.handleChangeLocation }                      
                                                    options={locations}
                                                />
                                           
                                            </div>
                                        </div><hr/>
                                        <p className="font-weight-bold">*Shifting</p>
                                        <button className="btn btn-primary btn-sm mb-2" onClick={this.addShift}>Add Shift</button>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered table-sm" style={{width: '100%'}}>
                                                <thead className="thead-dark">
                                                    <tr className="text-center">
                                                        <th>Shift In</th>
                                                        <th>Shift Out</th>
                                                        <th style={{width: '15%'}}>Quota</th>
                                                        <th style={{width: '5%'}}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shifts.map((shift, i) => this.shiftingHTML(i, shift))}
                                                </tbody>
                                            </table>
                                        </div><hr/>
                                    </FormGroup>
                                    <small>
                                        <p style={{color:'red'}} className="text-red">Dengan awalan simbol * wajib diisi (Mandatory).</p>
                                    </small>
                                    <hr/>
                                    <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.submit}>Selanjutnya</Button></Col>
                                </CardBody>
                            </Card>  
                        </Container> 
                        <Modal isOpen={modalOpenApproveCandidates} toggle={this.closeModal} backdrop="static">
                            <ModalBody>
                                <h5 className="text-center mb-5">Update Job Part Time</h5>
                                <p> Are you sure you want to update this job ?</p>
                                <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.editJob}>Update</Button></Col>
                            </ModalBody>
                        </Modal> 
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default EditDraftPartTime;