import React                                                from 'react';
import { NavLink, Link }                                    from 'react-router-dom';
import * as authHelperMethods                               from  '../../Helpers/AuthHelperMethods';
import * as config                                          from '../../Helpers/Config';
import * as api                                             from '../../Helpers/Api';
import logoAvishaWhite                                      from '../../Asset/Logo/logo-avisha-white.svg';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import {
    Label, Collapse, DropdownItem, Row,
    Navbar, NavbarToggler, NavbarBrand, 
    Nav, NavItem, UncontrolledDropdown, 
    DropdownToggle, DropdownMenu
}                                                           from 'reactstrap';
import {
    faHome, faHistory, faSearch, faClipboard,
    faBell, faUserCircle, faUser, 
    faListAlt, faSignOutAlt, faServer,
    faCaretDown, faCheck, faCheckCircle
}                                                           from '@fortawesome/free-solid-svg-icons';

class HeaderClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            id:0,
            companyName:'',
            accountName:'',
            dataNotification:[]
        };
        this.toggle = this.toggle.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.state = {isOpen: false};
    }
  
    componentDidMount= async () => {
        await this.getId();
        await this.getDataClient();
        await this.getNotification();
    }

    toggle() {
        this.setState(prevState => ({modal: !prevState.modal}));
    }
  
    toggleNav() {
        this.setState({isOpen: !this.state.isOpen});
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }
    
    logOut = () => {
        localStorage.clear();
        window.location.href = config.WEB_URL;
    }

    getNotification = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try {
            const res = await api.getNotification(id, {params: {data:JSON.stringify(newData)}});
            const { data } = res.data;
            this.setState({dataNotification:data});
        } catch(err) {
            console.log("Error Response :",err)
        }
    }

    goToNotification() {
        window.location.href = config.WEB_URL+"notification-client";
    }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id, {params:{data:JSON.stringify(newData)}});
            const { account_name, company_name } = res.data.data
            this.setState({
                accountName:account_name,
                companyName:company_name
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    notifikasiHTML = dataNotification => {
        let html;
        if((dataNotification === undefined) || (dataNotification.length === 0)) {
            html = null;
        } else {
            html = dataNotification.map((item, i) => <DropdownItem key={i} className="text-wrap text-justify text-black border-bottom">
                <p onClick={this.goToNotification} className="text-10 d-inline-block mb-0 pt-3 pb-3">{item['description']}</p>
            </DropdownItem>);
        }
        return html;
    }

    render() {
        const { dataNotification, companyName} = this.state;
        return (<div className="bg-blue">
            <Navbar color="#000" light expand="lg">
                <NavbarBrand href='/'><img src={logoAvishaWhite} alt="logo avisha no color" width="120px" className="mb-1"/></NavbarBrand>
                <NavbarToggler onClick={this.toggleNav}/>
                <Collapse navbar isOpen={this.state.isOpen}>
                    <Nav navbar className="text-14 ml-auto">
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/DashboardClient" activeClassName={"active"} exact={true} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faHome}/>{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Dashboard</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/master" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faServer} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Master</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/job" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff', }} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faListAlt} />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Job Post</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/attendance" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faCheckCircle} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Attendance</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/list-attendance" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faClipboard} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">List Attendance</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/overtime" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faClipboard} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Overtime</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/leave" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faClipboard} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Leave</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/izin" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faClipboard} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Izin</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-5 my-auto">
                        <NavLink to="/find-talent" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faSearch} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Find Talent</Label>
                        </NavLink>
                    </NavItem>
                    <NavItem className="mr-lg-4 my-auto">
                        <NavLink to="/RecentTransaction" activeClassName={"active"} activeStyle={{ fontWeight:'bolder', borderBottom:'2px solid #fff'}} style={{color:'#ffffff'}}>
                            <FontAwesomeIcon icon={faHistory} className="" />{' '}
                            <Label className="mb-2 mb-lg-0 mb-xl-0">Recent Transaction</Label>
                        </NavLink>
                    </NavItem>
                    <div className="border-left mt-2 mb-2 mr-4 d-none d-lg-block d-xl-block"></div>
                    <div className="d-flex">
                        <NavItem className="mr-3 my-auto">
                            <UncontrolledDropdown setActiveFromChild>
                                <DropdownToggle className="bg-white">
                                    <FontAwesomeIcon icon={faBell} className="text-blue"/>{' '}
                                    <div className="text-danger position-absolute" style={{margin:'-30px 0 0 8px'}}>&#9864;</div>
                                </DropdownToggle>
                                <DropdownMenu right className="mt-2">
                                    <div style={{width:'300px'}}>
                                        <DropdownItem header><p className="font-weight-bold mb-0 position-static">Notification</p></DropdownItem>
                                        <div style={{height:'200px', overflow:'scroll'}}>
                                            <DropdownItem divider className="mt-0"/>
                                            {this.notifikasiHTML(dataNotification)}
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </NavItem>
                        <NavItem>
                            <UncontrolledDropdown>
                                <DropdownToggle nav className="bg-white rounded-pill pl-4 pr-4 d-inline-flex">
                                    <Row>
                                        <FontAwesomeIcon icon={ faUserCircle } className="text-30 my-auto mr-2"/>{' '}
                                            <div className="my-auto text-12 mr-2" style={{maxWidth:'100px'}}>
                                                <Label className="mb-0 my-auto text-12">{companyName}</Label>
                                            </div>
                                        <FontAwesomeIcon icon={ faCaretDown } className="my-auto"/>
                                    </Row>
                                </DropdownToggle>
                                <DropdownMenu style={{margin:'4px 0 0 5px'}}>
                                    <Link to="/ProfileClient">
                                        <DropdownItem className="text-14"><FontAwesomeIcon icon={faUser}/>{' '}Profile</DropdownItem>
                                    </Link>
                                    <Link to="/company-profile">
                                        <DropdownItem className="text-14"><FontAwesomeIcon icon={faCheck}/>{' '}Company Profile</DropdownItem>
                                    </Link>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.logOut} className="text-14"><FontAwesomeIcon icon={faSignOutAlt}/>{' '}Sign Out</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </NavItem>
                    </div>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>);
    }
}

export default HeaderClient;