// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import HeaderUser                                           from './ComponentUser/HeaderUser';

import { Link } from 'react-router-dom'

// Styling
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  Button,
  CardHeader,
  CardBody,
  Table,
  Label,
  Input,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class ExperienceUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companyName: '',
      bussinessField: '',
      position: '',
      workingPeriod: '',
      salary: '',
      companyAddress: '',
      companyTelephoneNumber: '',
      directSupervisorName: '',
      directSupervisorPosition: '',
      reasonForLeaving: '',
      jobDescription: '',
      warningError: '',
      listWorkHistory: [],
      modalAddEditExperience: false,
    }
    this.toggleAddEditExperience = this.toggleAddEditExperience.bind(this)
  }

  toggleAddEditExperience() {
    this.setState((prevState) => ({
      modalAddEditExperience: !prevState.modalAddEditExperience,
    }))
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  deleteWork = async (id) => {
    const newData = {
      key_api: config.KEY_API,
    }
    try {
      const res = await api.deleteExperience(id, {
        params: { data: JSON.stringify(newData) },
      })
      const { status } = res
      if (status === 200) {
        this.getDataEmployee()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      console.log(data.dataWorExperiences)
      if (status === 200) {
        this.setState({ listWorkHistory: data.dataWorExperiences })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  notify() {
    const { warningError } = this.state
    toast(warningError)
  }

  saveNewExperience = async () => {
    const {
      companyName,
      bussinessField,
      position,
      workingPeriod,
      salary,
      companyAddress,
      companyTelephoneNumber,
      directSupervisorName,
      directSupervisorPosition,
      reasonForLeaving,
      jobDescription,
    } = this.state
    if (
      companyName === '' ||
      bussinessField === '' ||
      position === '' ||
      workingPeriod === '' ||
      salary === '' ||
      companyAddress === '' ||
      companyTelephoneNumber === '' ||
      directSupervisorName === '' ||
      directSupervisorPosition === '' ||
      reasonForLeaving === '' ||
      jobDescription === ''
    ) {
      await this.setState({ warningError: 'Data Belum Lengkap !' })
      this.notify()
    } else {
      const idEmployee = authHelperMethods.getIdEmployee()
      const newData = {
        key_api: config.KEY_API,
        company_name: companyName,
        bussiness_field: bussinessField,
        position: position,
        working_period: workingPeriod,
        salary: salary,
        company_address: companyAddress,
        company_telephone_number: companyTelephoneNumber,
        direct_supervisor_name: directSupervisorName,
        direct_supervisor_position: directSupervisorPosition,
        reason_for_leaving: reasonForLeaving,
        job_description: jobDescription,
      }
      try {
        const res = await api.saveNewExperience(idEmployee, {
          params: { data: JSON.stringify(newData) },
        })
        const { status } = res
        if (status === 200) {
          this.getDataEmployee()
          this.toggleAddEditExperience()
          await this.setState({ warningError: 'Berhasil update data !' })
          this.notify()
        }
      } catch (err) {
        console.log('Error Response :', err)
      }
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    EXPERIENCE
                  </Label>
                </Col>
                <Col
                  id="btnEditExperienceUser"
                  className="text-right text-blue"
                >
                  <Link to="#" onClick={this.toggleAddEditExperience}>
                    <Label className="text-16 mb-0 text-blue">Add</Label>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table hover responsive className="mb-0">
                <thead className="text-14">
                  <tr>
                    <th>Description</th>
                    <th>Periode</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-12">
                  {this.state.listWorkHistory.map((work) => {
                    return (
                      <tr>
                        <td>{work.company_name}</td>
                        <td>{work.working_period}</td>
                        <td className="text-right">
                          <a
                            className="text-danger"
                            onClick={() => this.deleteWork(work.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
        <Modal
          isOpen={this.state.modalAddEditExperience}
          toggle={this.toggleAddEditExperience}
          backdrop="static"
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-danger btn-close-circle"
            onClick={this.toggleAddEditExperience}
          />
          <ModalBody
            style={{ height: '400px', overflow: 'scroll' }}
            className="pt-5 pb-5 pl-2 pr-2"
          >
            <h5 className="text-black font-weight-bold text-center mb-5 ">
              Add/Change Experience
            </h5>
            <Col className="mb-3">
              <Label className="d-block text-16 font-weight-bold">
                Company Name
              </Label>
              <Input name="companyName" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Business Field
              </Label>
              <Input name="bussinessField" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Position
              </Label>
              <Input name="position" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Working Period
              </Label>
              {/* <Row>
                                <Col><Input type="date" name="workingPeriodStart" onChange={this.handleChange} /></Col> 
                                <Label className="my-auto">-</Label>
                                <Col><Input type="date" name="workingPeriodEnd" onChange={this.handleChange} /></Col>
                            </Row> */}
              <Input name="workingPeriod" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">Salary</Label>
              <Input type="number" name="salary" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Company Address
              </Label>
              <Input name="companyAddress" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Telephone Number
              </Label>
              <Input
                type="number"
                name="companyTelephoneNumber"
                onChange={this.handleChange}
              />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Name of Direct Supervisor
              </Label>
              <Input name="directSupervisorName" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Position of Direct Supervisor
              </Label>
              <Input
                name="directSupervisorPosition"
                onChange={this.handleChange}
              />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Reason for Leaving
              </Label>
              <Input name="reasonForLeaving" onChange={this.handleChange} />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Job Description
              </Label>
              <Input name="jobDescription" onChange={this.handleChange} />
            </Col>
            <Col>
              <Button
                onClick={this.saveNewExperience}
                className="btn-block btn-blue-normal"
              >
                Save
              </Button>
            </Col>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default ExperienceUser
