// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import FrontHeaderUser from './ComponentUser/FrontHeaderUser';
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import 'react-toastify/dist/ReactToastify.css';

class PayReturn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        this.getDetail();
    }

    getDetail = async() => {
        const url = window.location.href;
        const orderId = url.split('/')[4];
        const res = await api.getDetailTransaksi(orderId, {
            params: { data: JSON.stringify({ key_api: config.KEY_API }) }
        });
        this.setState({ data: res.data.data });
    }

    render() {
        const { data } = this.state;
        return (          
            <Fragment>
                <FrontHeaderUser/>
                <div className="row bg-body-grey justify-content-center">
                    <div className="col-md-8">
                        <div className="card mt-2">
                            <h5 className="card-header">Status Pembayaran</h5>
                            <div className="card-body">
                                <table>
                                    <tr>
                                        <th>Nama</th>
                                        <td>: {data.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Item</th>
                                        <td>: {`${data.package_name} (Paket ${data.tipe})`}</td>
                                    </tr>
                                    <tr>
                                        <th>Nominal</th>
                                        <td>: Rp {data.total_payment}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>: {<b className={`text-${this.warnaStatus(data.status_payment)}`}>{data.status_payment}</b>}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );   
    }

    warnaStatus = status => {
        let warna = '';
        if (status == 'Payment Success') {
            warna += 'success';
        } else if (status == 'Payment Failed' || status == 'Payment Reject') {
            warna += 'danger';
        } else if (status == 'Waiting Payment' || status == 'Waiting Confirmation') {
            warna += 'warning';
        }
        return warna;
    }
};

export default PayReturn;