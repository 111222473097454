// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import FrontHeaderClient from './ComponentClient/FrontHeaderClient';
import Termcon           from '../User/ComponentUser/Term&ConPlain';

import { Link } from 'react-router-dom';

// import * as firebase from 'firebase/app';
import 'firebase/auth';

import { auth, provider } from '../Helpers/Firebase.js';

import imgBanner1 from '../Asset/Img/img1.svg';
// import logoGoogle from '../Asset/Logo/Google.svg';

// Styling
import {
    Container, Row, Col, Label, Modal, ModalBody,
    Button, Form, FormGroup, Input
} from 'reactstrap';

import Select from 'react-select';
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import { ToastContainer, toast }                            from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FormSignupClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            companyName: '',
            email: '',
            // idIndustryType:0, //real
            idIndustryType: 1, //testing
            phoneNumber: '',
            position: '',
            password: '',
            confirmPassword: '',
            companySize: '',
            phoneCompany: '',
            dataIndustryTypes: [],
            // listEmail:[],
            // listPhoneNumber:[],
            checked: false,
            aggrementCheckBox: false,
            modalAgreement:false,
            warningError:"",
            isComplateData:false,
            validate: {
                companyName:'',
                email:'',
                position:'',
                phoneNumber: '',
                fullName:'',
                password:'',
                confirmPassword:''
            },
            dataCompanySize: [
                {
                    value: "1-50 Pegawai",
                    label: "1-50 Pegawai"
                },
                {
                    value: "51-200 Pegawai",
                    label: "51-200 Pegawai"
                },
                {
                    value: "201-500 Pegawai",
                    label: "201-500 Pegawai"
                },
                {
                    value: "501-1000 Pegawai",
                    label: "501-1000 Pegawai"
                },
                {
                    value: "1001-2000 Pegawai",
                    label: "1001-2000 Pegawai"
                },
                {
                    value: "2001-5000 Pegawai",
                    label: "2001-5000 Pegawai"
                }
            ],

        };
    }

    componentDidMount = async () => {
        await this.getIndustryTypes();
    }

    registerClientGoogle = async (e) => {
        await auth.signInWithPopup(provider)
            .then((res) => {
                const { email } = res.user;
                localStorage.setItem("email", email);
                window.location.href = config.WEB_URL + `DashboardClient`;
            });
    }

    notify(){
        const { warningError } = this.state;
        toast( warningError )
    }

    getIndustryTypes = async () => {
        try {
            const newData = ({
                key_api: config.KEY_API,
            })
            const res = await api.getIndustryTypes({
                params: { data: JSON.stringify(newData) }
            })
            const { data } = res.data;
            this.setState({ dataIndustryTypes: data.map((k) => ({ value: k.id, label: k.industry_type })) });
        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChosee = async (element) => {
        this.setState({ idIndustryType: element.value });
    }

    validateCompanyName(nameCompany) {
        const { validate } = this.state
        if (nameCompany==="") {
            this.setState({isComplateData:false});
            validate.companyName = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.companyName = 'has-success'
        }
        this.setState({ validate })
    }

    validateEmail = async (value) => {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if(value===""){
            validate.email = 'has-danger';
            this.setState({isComplateData:false});
        } else {
            if (emailRex.test(value)) {
                this.setState({isComplateData:true});
                validate.email = 'has-success';
            } else {
                this.setState({isComplateData:false});
                validate.email = 'has-danger';
            }
        }
        this.setState({ validate })
    }

    validateFullName(name) {
        const { validate } = this.state
        if (name==="") {
            this.setState({isComplateData:false});
            validate.fullName = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.fullName = 'has-success'
        }
        this.setState({ validate })
    }

    validatePosition(position) {
        const { validate } = this.state
        if (position==="") {
            this.setState({isComplateData:false});
            validate.position = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.position = 'has-success'
        }
        this.setState({ validate })
    }

    validateHP = async (phone) => {
        const HPRex = /^.{10,}$/;
        const { validate } = this.state
        if(phone===""){
            validate.phoneNumber = 'has-danger';
            this.setState({isComplateData:false});
        } else{
            if (HPRex.test(phone)) {
                validate.phoneNumber = 'has-success';
                this.setState({isComplateData:true});    
            } else {
                validate.phoneNumber = 'has-danger';
                this.setState({isComplateData:false});
            }
        }
        this.setState({ validate })
    }

    validatePhoneCompany = async (phoneCompany) => {
        const HPRex = /^.{10,}$/;
        const { validate } = this.state
        if(phoneCompany===""){
            validate.phoneCompany = 'has-danger';
            this.setState({isComplateData:false});
        } else{
            if (HPRex.test(phoneCompany)) {
                validate.phoneCompany = 'has-success';
                this.setState({isComplateData:true});    
            } else {
                validate.phoneCompany = 'has-danger';
                this.setState({isComplateData:false});
            }
        }
        this.setState({ validate })
    }

    validatePassword(password) {
        const { validate } = this.state
        if (password==="") {
            this.setState({isComplateData:false});
            validate.password = 'has-danger'
        } else {
            this.setState({isComplateData:true});
            validate.password = 'has-success'
        }
        this.setState({ validate })

    }

    validateConfirmPassword(password) {
        const { validate, checkPassword } = this.state
        if (password==="") {
            this.setState({isComplateData:false});
            validate.confirmPassword = 'has-danger';
        } else if(checkPassword === password){
            this.setState({isComplateData:true});
            validate.confirmPassword = 'has-success';
        }else {
            this.setState({isComplateData:false});
            validate.confirmPassword = 'has-danger';
        }
        this.setState({ validate })

    }

    handleRegister = async (e) => {
        e.preventDefault();
        const { fullName, companyName, email, idIndustryType, phoneNumber, position, password, confirmPassword, companySize, phoneCompany } = this.state;
        if ((fullName == '') || (companyName == '') || (email == '') || (idIndustryType == 0) || (phoneNumber == '') || (position == '') || (password == '') || (confirmPassword == '') || (companySize == '') || (phoneCompany == '')
        ) {
            alert('Data belum lengkap');
        } else {
            if (password != confirmPassword) {
                alert('Password dan konfirmasi password tidak sama');
            } else {
                const newData = {
                    key_api: config.KEY_API,
                    full_name: fullName,
                    company_name: companyName,
                    email: email,
                    id_industry_type: idIndustryType,
                    phone_number: phoneNumber,
                    position: position,
                    password: password,
                    confirm_password: confirmPassword,
                    company_size: companySize,
                    phone_company: phoneCompany
                }
                try {
                    const res = await api.registerClient({
                        params: {
                            data: JSON.stringify(newData)
                        }
                    })
                    const { status } = res;
                    if (status === 200) {
                        window.location.href = config.WEB_URL + `FormLoginClient`;
                    }
                } catch (err) {
                    console.log("Error Response :", err)
                }
            }
        }
    }

    handleChoseeCompanySize = async (element) => {
        this.setState({ companySize: element.value });
    }

    toggleModalAgreement = async () => {
        const {fullname, email, phoneNumber, password, checkPassword} = this.state;
        await this.validateFullName(fullname);
        await this.validateEmail(email);
        await this.validateHP(phoneNumber);
        await this.validatePassword(password);
        await this.validateConfirmPassword(checkPassword);
        this.setState(prevState => ({
            modalAgreement: !prevState.modalAgreement
        }));

        // if(this.state.isComplateData===true){
        //     if(listEmail.includes(email)===true){
        //         await this.setState({warningError:"Email sudah pernah digunakan !"});
        //         this.notify();
        //     } else if (listPhoneNumber.includes(phoneNumber) === true){
        //         await this.setState({warningError:"Nomor Handphone sudah pernah digunakan !"});
        //         this.notify();
        //     } else {
        //         this.setState(prevState => ({
        //             modalAgreement: !prevState.modalAgreement
        //         }));
        //     }
        // }
    }

    onChangeCheckBox(e) {
        this.setState({ aggrementCheckBox: e.target.checked});
    }

    render() {
        const {aggrementCheckBox, dataIndustryTypes, dataCompanySize } = this.state;
        return (
            <Fragment>
                <ToastContainer/>
                <FrontHeaderClient />
                <Container fluid className="bg-body-grey">
                    <Container className="pt-5 pl-1 pr-1 pb-5">
                        <Row>
                            <Col className="d-none d-lg-block my-auto">
                                <img src={imgBanner1} alt="banner-avisha" />
                            </Col>
                            <Col md="8" lg="6" className="mx-auto">
                                <Col className="bg-white box-shadow p-4">
                                    <h5 className="text-center font-weight-bolder">Sign Up for Company</h5><br />
                                    <Form onSubmit={this.toggleModalAgreement}>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Full Name</Label>
                                                    <Input onChange={this.handleChange} type="text" name="fullName" id="EmailUser" className="input-box-grey" valid={ this.state.validate.fullName === 'has-success' } invalid={ this.state.validate.fullName === 'has-danger' } required /><br />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Email</Label>
                                                    <Input onChange={this.handleChange} type="email" name="email" id="EmailUser" className="input-box-grey" valid={ this.state.validate.email === 'has-success' } invalid={ this.state.validate.email === 'has-danger' } required/><br />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Phone Number</Label>
                                                    <Input onChange={this.handleChange} type="number" name="phoneNumber" id="phoneNumber" className="input-box-grey" valid={ this.state.validate.phoneNumber === 'has-success' } invalid={ this.state.validate.phoneNumber === 'has-danger' } required/><br />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Password</Label>
                                                    <Input onChange={this.handleChange} type="password" name="password" id="password" className="input-box-grey" valid={ this.state.validate.password === 'has-success' } invalid={ this.state.validate.password === 'has-danger' } required/><br />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Re-Password</Label>
                                                    <Input onChange={this.handleChange} type="password" name="confirmPassword" id="confirmPassword" className="input-box-grey" valid={ this.state.validate.confirmPassword === 'has-success' } invalid={ this.state.validate.confirmPassword === 'has-danger' } required/><br />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Name Company</Label>
                                                    <Input onChange={this.handleChange} type="text" name="companyName" id="companyName" className="input-box-grey" valid={ this.state.validate.companyName === 'has-success' } invalid={ this.state.validate.companyName === 'has-danger' } required/><br />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Industry</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        // value={}
                                                        // placeholder={regencyName}
                                                        options={dataIndustryTypes}
                                                        onChange={this.handleChosee.bind(this)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Position</Label>
                                                    <Input onChange={this.handleChange} type="text" name="position" id="position" className="input-box-grey" /><br />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label>Company Size</Label>
                                                    <Select
                                                        name="form-field-name"
                                                        // value={dataCompanySize.filter(option => option.value === companySize)}
                                                        options={dataCompanySize}
                                                        onChange={this.handleChoseeCompanySize.bind(this)}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-0">
                                                    <Label className="text-14 mb-1">Phone Company</Label>
                                                    <Input onChange={this.handleChange} type="number" name="phoneCompany" id="phoneCompany" className="input-box-grey" valid={ this.state.validate.phoneCompany === 'has-success' } invalid={ this.state.validate.phoneCompany === 'has-danger' } required/><br />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button className="btn-blue btn-block" onClick={this.toggleModalAgreement} disabled={ this.state.fullName === "" || this.state.companyName === "" || this.state.phoneNumber === "" || this.state.phoneCompany === "" || this.state.password === "" || this.state.position === "" }>SIGN UP</Button>
                                                <p className="text-center m-0 p-2 separator">or</p>
                                                {/* <Link to="/DashboardUser"><Button className="btn-grey btn-block" onClick={this.registerClientGoogle}>SIGN UP WITH GOOGLE <img src={logoGoogle}  className="float-right" alt="logo-google" width="18px"/></Button></Link><br/> */}
                                                <p className="text-9 text-mute text-center">
                                                    By signing in to your account, you agree to indeed’s <u>term of service</u> and
                                                    consent to our <u>cookie policy</u> and <u>privacy policy</u>
                                                </p>
                                                <p className="text-10 text-center text-mute mb-0">Already have an account? <Link to="/FormLoginClient"><Label className="text-blue mb-0">Sign In</Label></Link></p>
                                                <Link to="#"><p className="text-10 text-center text-mute">Forgot Password?</p></Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    <Modal isOpen={this.state.modalAgreement} toggle={this.toggleModalAgreement}>
                        <ModalBody>
                            <h3 className="mb-3">Terms and Conditions</h3>
                            <div className="rounded-lg mb-3 p-2" style={{ height: '400px', overflowX: 'scroll', backgroundColor: '#E8E8E8' }}>
                                <Termcon />
                            </div>
                            <FormGroup check className="mb-3">
                                <Label check>
                                    <Input type="checkbox" onChange={e => this.onChangeCheckBox(e)} required={"Please check this box if you want to proceed"} />{' '}<Label className="text-16 font-weight-bold">I accept the Terms and Conditions</Label>
                                </Label>
                            </FormGroup>
                            <Button className="btn-block btn-blue-normal mb-3" disabled={aggrementCheckBox === false ? true : false} id="submitSignUp" onClick={this.handleRegister}>Continue</Button>
                            <Button className="btn-block btn-warning" onClick={this.toggleModalAgreement}>Decline</Button>
                        </ModalBody>
                    </Modal>
                </Container>
            </Fragment>
        );
    }
};

export default FormSignupClient;