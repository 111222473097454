// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  

// Styling
import { Container, Row, Col, Card, Button, 
         CardHeader, CardBody, Table }                      from 'reactstrap';   

import * as config                                          from '../../../Helpers/Config';
import * as api                                             from '../../../Helpers/Api';      
import * as authHelperMethods                               from '../../../Helpers/AuthHelperMethods';

class ContactInfoUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName:'',
            profileImage:null,
            domicileAddress:'',
            idCardAddress:'',
            phoneNumber:'',
            email:'',
        };
    }

    componentDidMount = async () =>{
        await this.getDataEmployee();
    }

    getDataEmployee = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.profileEmployee(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
                const {full_name, email, phone_number, id_card_address, domicile_address} = data.dataProfile
                this.setState({
                    fullName:full_name,
                    // profileImage:null,
                    domicileAddress:domicile_address,
                    idCardAddress:id_card_address,
                    phoneNumber:phone_number,
                    email:email,
                  })
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
      }

    render() {  
        const {fullName, profileImage, domicileAddress, idCardAddress, phoneNumber, email} = this.state;
        return (          
            <Fragment>  
                <Container className="mb-4">
                    <Card>
                        <CardBody>
                            <Col className="pl-0 pr-0">
                                <p className="text-20 font-weight-bold">{fullName}</p>
                                <p className="text-16 mb-0 text-justify">{domicileAddress}</p>
                                <p className="text-16 mb-0">{email}</p>
                                <p className="text-16 mb-0">{phoneNumber}</p>
                            </Col>
                        </CardBody>
                    </Card>
                </Container>
            </Fragment>
        );   
    }
};

export default ContactInfoUser;