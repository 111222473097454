import React, { Fragment } from 'react'
import HeaderClient from './ComponentClient/HeaderClient'
import classnames from 'classnames'

import noData from '../Asset/Icon/no-data.svg'
import archivedMark from '../Asset/Img/archived-mark.svg'

import {Container, Row, Col, TabContent, TabPane, Label, Nav, NavItem, NavLink, Button, Input, Card, CardBody, Modal, ModalBody, Form, ModalHeader} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'

import * as authHelperMethods from '../Helpers/AuthHelperMethods'
import * as api from '../Helpers/Api'
import * as config from '../Helpers/Config'
import * as method from '../Helpers/Method'
import logoCompany from '../Asset/Logo/logo-company.png'
import { ToastContainer, toast } from 'react-toastify'
import DateTimePicker from 'react-datetime-picker'

import 'react-toastify/dist/ReactToastify.css'
import { ConsoleIcon } from 'evergreen-ui'

class JobPostClient extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: '0',
            id: 0,
            idJob: 0,
            location: 'all',
            searchString: '',
            spesificSalary: 0,
            spesificQuota: 0,
            totalPaidRupiah: '',
            managementCost: '',
            subtotalFee: '',
            managementCostPersenValue: 0,
            ppnPersenValue: 0,
            pphPersenValue: 0,
            partTimeFinanceBalance: 0,
            totalPaid: 0,
            ppn: '',
            pph: '',
            canceledReason: '',
            newWorkTimeStart: '',
            newWorkTimeFinish: '',
            newWorkTimeStartConvert: '',
            newWorkTimeFinishConvert: '',
            typeDeleteJob: '',
            dataJobExpired: [],
            dataJobPartTime: [],
            dataJobFullTime: [],
            dataDraftJob: [],
            listRegency: [],
            dataVariable: [],
            dataClient: {},
            detailJobFullTime: {},
            detailJobPartTime: {},
            dataCancelJob: {},
            rowsPhoneNumberInvite: [],
            modalDetailFullTime: false,
            modalDetailPartTime: false,
            modalPublishJobFullTime: false,
            modalPublishJobPartTime: false,
            modalCancelJob: false,
            modalEditJobWorkTime: false,
            modalDeleteFullTimeOrDraft: false,
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
            isCheckCandidate: false,
            isErrorDataInviteCandidate: false,
            paketPartTime: {},
            archivedEmployees: [],
            selectInvitedEmployee: [],
            selectedPartTimePublish: 0
        }
        this.toggleDetailFullTime = this.toggleDetailFullTime.bind(this)
        this.toggleDetailPartTime = this.toggleDetailPartTime.bind(this)
    }

    componentDidMount = async () => {
        this.getId()
        await this.getJobFulltime();
        await this.getJobParttime();
        await this.getDraftJob();
        await this.getDataClient();
        await this.getJobExpired();
        await this.getAllRegency();
        await this.getVariableFormula();
        await this.getPaketPartTime();
        await this.getArchivedEmployee();
    }

    notify = text => toast(text);

    getArchivedEmployee = async() => {
        const {id, idJob} = this.state;
        const res = await api.getArchivedEmployee({params: {data: JSON.stringify({id, idJob})}});
        const {data} = res.data;
        this.setState({archivedEmployees: data});
    }

    updateWorkTime = async () => {
        const {detailJobPartTime, id, newWorkTimeStart, newWorkTimeFinish} = this.state
        const newData = {
            key_api: config.KEY_API,
            id_job: detailJobPartTime.id,
            id_client_company: id,
            start_part_time_date: newWorkTimeStart,
            finish_part_time_date: newWorkTimeFinish,
        }
        try {
            const res = await api.updateWorkTime({params: {data: JSON.stringify(newData)}})
            const { status } = res
            if (status === 200) {
                this.setState((prevState) => ({
                    modalEditJobWorkTime: !prevState.modalEditJobWorkTime,
                }))
                this.notify('Edit Work Time Job Part Time is Successful')
                await this.getJobParttime()
            }
        } catch (err) {
            console.log('Error Response :', err)
        }
    }

    deleteJob = async () => {
        const { detailJobFullTime, id, typeDeleteJob } = this.state
        const newData = {
            key_api: config.KEY_API,
            id_job: detailJobFullTime.id,
            id_client_company: id,
            type_delete_job: typeDeleteJob,
        }
        try {
            const res = await api.deleteJob({
                params: {data: JSON.stringify(newData)},
            })
            const { status } = res
            if (status === 200) {
                this.setState((prevState) => ({
                    modalDeleteFullTimeOrDraft: !prevState.modalDeleteFullTimeOrDraft,
                }))
                if (typeDeleteJob === 'Draft') {
                    this.notify('Delete Job Full Draft is Successful')
                    await this.getDraftJob()
                } else {
                    this.notify('Delete Job Full Time Time is Successful')
                    await this.getJobFulltime()
                }
            }
        } catch (err) {
            console.log('Error Response :', err)
        }
    }

    cancelJob = async () => {
        const { dataCancelJob, id, canceledReason } = this.state
        const newData = {
            key_api: config.KEY_API,
            id_job: dataCancelJob.id,
            id_client_company: id,
            canceled_reason: canceledReason,
        }
        try {
            const res = await api.cancelJob({params: {data: JSON.stringify(newData)}})
            const { status } = res
            if (status === 200) {
                this.setState((prevState) => ({modalCancelJob: !prevState.modalCancelJob}))
                this.notify('Cancel Job Part Time is Successful')
                await this.getJobParttime()
            }
        } catch (err) {
            console.log('Error Response :', err)
        }
    }

    publishJobPartTime = async () => {
        const {selectInvitedEmployee, selectedPartTimePublish} = this.state;
        const invitedEmployees = [];
        selectInvitedEmployee.forEach(employee => invitedEmployees.push(employee.nilai));
        const res = await api.publishJobPartTime({params: {data: JSON.stringify({
            id_job: selectedPartTimePublish,
            invitedEmployees
        })}});
        const {error, message} = res.data;
        if(error) {
            alert(message);
        } else {
            await this.getJobFulltime();
            await this.getJobParttime();
            await this.getDraftJob();
            await this.getJobExpired();
            this.notify('Successfully post a part time job!');
            this.closeTogglePublishJobPartTime();
        }
    }

    savePublishJobPartTime = async () => {
        const {id, idJob, totalPaid, isCheckCandidate, rowsPhoneNumberInvite} = this.state
        let newListDataIdCandidate = [];
        let i;
        if (rowsPhoneNumberInvite.length > 0) {
            for (i = 0; i < rowsPhoneNumberInvite.length; i++) {
                var str = rowsPhoneNumberInvite[i].id_candidate.substring(1)
                str = (str * 1).toString()
                const newArray = { id_candidate: str }
                newListDataIdCandidate.push(newArray)
            }
        }
        const newData = {
            key_api: config.KEY_API,
            id_client_company: id,
            id_job: idJob,
            total_paid: totalPaid,
            is_invite_candidate: isCheckCandidate === true ? 1 : 0,
            list_phone_number_invite: newListDataIdCandidate,
        }
        try {
            const res = await api.publishJob({params: {data: JSON.stringify(newData)}})
            const { status } = res
            if (status === 200) {
                if (this.state.modalPublishJobPartTime === true) {
                    this.setState((prevState) => ({modalPublishJobPartTime: !prevState.modalPublishJobPartTime}))
                }
                this.notify('Publish Job Part Time is Successful')
                await this.getDraftJob()
            }
        } catch (err) {
            console.log('Error Response :', err)
        }
    }

    publishJobFullTime = async () => {
        const { id, idJob } = this.state
        const newData = {
            key_api: config.KEY_API,
            id_client_company: id,
            id_job: idJob,
        }
        try {
            const res = await api.publishJob({params: {data: JSON.stringify(newData)}});
            const { status } = res
            if (status === 200) {
                this.setState((prevState) => ({modalPublishJobFullTime: !prevState.modalPublishJobFullTime}))
                this.notify('Publish Job Full Time is Successful')
                await this.getDraftJob()
            }
        } catch (err) {
            console.log('Error Response :', err)
        }
    }

    togglePublishJob = async (data) => {
        await this.setState({ idJob: data.id })
        await this.getArchivedEmployee();

        if (data.work_type === 'Part-Time') {
            if (data.is_invite_candidate === 1) {
                this.savePublishJobPartTime()
            } else {
                this.setState({
                    spesificSalary: data.part_time_salary,
                    spesificQuota: data.quota,
                })
                await this.countSimulationPartTime(data.part_time_salary, data.quota)
                this.setState((prevState) => ({
                    modalPublishJobPartTime: !prevState.modalPublishJobPartTime,
                    selectedPartTimePublish: data.id
                }))
            }
        } else {
            this.setState((prevState) => ({modalPublishJobFullTime: !prevState.modalPublishJobFullTime}))
        }
    }

    closeTogglePublishJobFullTime = () => {
        this.setState((prevState) => ({
            modalPublishJobFullTime: !prevState.modalPublishJobFullTime,
        }))
    }

    closeTogglePublishJobPartTime = () => {
        this.setState((prevState) => ({
            modalPublishJobPartTime: !prevState.modalPublishJobPartTime,
        }))
    }

    countSimulationPartTime = async (salary, quota) => {
        const { paketPartTime } = this.state
        let managementCost = parseFloat(paketPartTime.management_cost)
        let ppn = parseFloat(paketPartTime.ppn)
        let pph = parseFloat(paketPartTime.pph)
        const managementCostHasil = Math.round((parseFloat(managementCost) / 100) * parseFloat(salary),)
        const ppnHasil = Math.round((ppn / 100) * managementCostHasil)
        const pphHasil = Math.round((parseFloat(pph) / 100) * parseFloat(managementCostHasil),)
        let subtotalFee = Math.round(parseFloat(salary) + parseInt(managementCostHasil),)
        const total = Math.round((subtotalFee + ppnHasil - pphHasil) * quota)
        const data = {
            managementCostPersenValue: managementCost,
            ppnPersenValue: ppn,
            pphPersenValue: pph,
            isShowWarningSimulation: false,
            isCount: true,
            totalPaid: total,
            totalPaidRupiah: method.formatRp(total),
            managementCost: method.formatRp(managementCostHasil),
            subtotalFee: method.formatRp(subtotalFee),
            ppn: method.formatRp(ppnHasil),
            pph: method.formatRp(pphHasil),
        }
        this.setState(data)
    }

    editJob = async (data) => {
        localStorage.setItem('idJob', data.id)
        if (data['work_type'] === 'Part-Time') {
            window.location.href = config.WEB_URL + `edit-job-part-time`
        } else {
            window.location.href = config.WEB_URL + `edit-job-full-time`
        }
    }

    editDraft = async (data) => {
        localStorage.setItem('idJob', data.id)
        window.location.href = `${config.WEB_URL}edit-draft-${data['work_type'] === 'Part-Time' ? 'part' : 'full'}-time`
    }

    toggleDeleteFullTimeOrDraft = async (data, typeJob) => {
        await this.setState({ detailJobFullTime: data, typeDeleteJob: typeJob })
        this.setState((prevState) => ({
            modalDeleteFullTimeOrDraft: !prevState.modalDeleteFullTimeOrDraft,
        }))
    }

    toggleDetailFullTime = async (data) => {
        if (data['work_type'] === 'Part-Time') {
            await this.setState({ detailJobPartTime: data })
            this.setState((prevState) => ({
                modalDetailPartTime: !prevState.modalDetailPartTime,
            }))
        } else {
            await this.setState({ detailJobFullTime: data })
            this.setState((prevState) => ({
                modalDetailFullTime: !prevState.modalDetailFullTime,
            }))
        }
    }

    toggleDetailPartTime = async (data) => {
        await this.setState({ detailJobPartTime: data })
        this.setState((prevState) => ({
            modalDetailPartTime: !prevState.modalDetailPartTime,
        }))
    }

    // only part time
    toggleCancelJob = async (data) => {
        await this.setState({ dataCancelJob: data })
        this.setState((prevState) => ({
            modalCancelJob: !prevState.modalCancelJob,
        }))
    }

    // only part time
    toggleEditJobWorkTime = async (data) => {
        await this.setState({ detailJobPartTime: data })
        this.setState((prevState) => ({
            modalEditJobWorkTime: !prevState.modalEditJobWorkTime,
        }))
    }

  getPaketPartTime = async () => {
    const newData = {
      key_api: config.KEY_API,
      id: this.state.id,
    }
    try {
      const res = await api.getPaketPartTime({
        params: { data: JSON.stringify(newData) },
      })
      const { data } = res.data
      // console.log(data);
      this.setState({ paketPartTime: data })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getVariableFormula = async () => {
    const newData = { key_api: config.KEY_API }
    try {
      const res = await api.getVariableFormula({
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      // console.log(data);
      this.setState({
        dataVariable: data,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getDataClient = async () => {
    const { id } = this.state
    const newData = { key_api: config.KEY_API }
    try {
      const res = await api.getDataClient(id, {
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      this.setState({
        dataClient: data,
        partTimeFinanceBalance:
          data.part_time_finance_balance === null
            ? 0
            : data.part_time_finance_balance,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getJobExpired = async () => {
    const { id } = this.state
    const newData = { key_api: config.KEY_API }
    try {
      const res = await api.getJobExpired(id, {
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      this.setState({
        dataJobExpired: data,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getDraftJob = async () => {
    const { id } = this.state
    const newData = { key_api: config.KEY_API }
    try {
      const res = await api.getDraftJob(id, {
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      this.setState({
        dataDraftJob: data,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getJobFulltime = async () => {
    const { id } = this.state
    const newData = { key_api: config.KEY_API, work_type: 'Full-Time' }
    try {
      const res = await api.getJobByWorkType(id, {
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      this.setState({
        dataJobFullTime: data,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getJobParttime = async () => {
    const { id } = this.state
    const newData = { key_api: config.KEY_API, work_type: 'Part-Time' }
    try {
      const res = await api.getJobByWorkType(id, {
        params: {
          data: JSON.stringify(newData),
        },
      })
      const { data } = res.data
      this.setState({
        dataJobPartTime: data,
      })
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getId = () => {
    const id = authHelperMethods.getIdClient()
    this.setState({ id: id })
  }

  createJobFullTime = () => {
    window.location.href = config.WEB_URL + `add-job-full-time`
  }

  createJobPartTime = () => {
    window.location.href = config.WEB_URL + `create-job-part-time`
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }

    if (tab == 0) {
      this.getJobFulltime()
    } else if (tab == 1) {
      this.getJobParttime()
    }
  }

  handleChoose = async (element) => {
    await this.setState({ location: element.label })
    this.filterJobs(element.label)
  }

  handleChangeSearch = (e) => {
    this.setState({ searchString: e.currentTarget.value })
  }

  handleChangeDatetimeStart = (e) => {
    const nilai = e.target.value;
    this.setState({
      newWorkTimeStart: nilai,
      newWorkTimeStartConvert: method.formatDateAndTime(nilai),
      newWorkTimeFinishConvert: method.formatDateAndTime(nilai),
    })
  }

  handleChangeDatetimeFinish = (e) => {
    const nilai = e.target.value;
    this.setState({
      newWorkTimeFinish: nilai,
      newWorkTimeFinishConvert: method.formatDateAndTime(nilai),
    })
  }

  getAllRegency = async (e) => {
    const newData = {
      key_api: config.KEY_API,
    }
    try {
      const res = await api.getAllRegencies({
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        this.setState({
          listRegency: data.dataAllRegencies.map((k) => ({
            value: k.id,
            label: k.regency_name,
          })),
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  filterJobs = async (value) => {
    const { id, location, activeTab } = this.state
    try {
      const newData = {
        key_api: config.KEY_API,
        work_type: 'Full-Time',
      }
      if (activeTab == 1) {
        newData['work_type'] = 'Part-Time'
      }

      const res = await api.getJobByWorkType(id, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        if (activeTab == 0) {
          if (location == 'all') {
            this.setState({ dataJobFullTime: data.data })
          } else {
            const listIdLoanLocation = []
            for (var i = 0; i < data.data.length; i++) {
              if (data.data[i].work_location === value) {
                listIdLoanLocation.push(data.data[i].id)
              }
            }

            const allListFilter = [...listIdLoanLocation]
            var counts = {}
            allListFilter.forEach(function (x) {
              counts[x] = (counts[x] || 0) + 1
            })

            const finalListFilter = []
            Object.entries(counts).map(([key, value]) => {
              for (var i = 0; i < data.data.length; i++) {
                if (data.data[i].id == key) {
                  finalListFilter.push(data.data[i])
                }
              }
              this.setState({ dataJobFullTime: finalListFilter })
            })
            if (finalListFilter.length === 0) {
              this.setState({ dataJobFullTime: finalListFilter })
            }
          }
        } else if (activeTab == 1) {
          if (location == 'all') {
            this.setState({ dataJobPartTime: data.data })
          } else {
            const listIdLoanLocation = []
            for (var i = 0; i < data.data.length; i++) {
              if (data.data[i].work_location === value) {
                listIdLoanLocation.push(data.data[i].id)
              }
            }

            const allListFilter = [...listIdLoanLocation]
            var counts = {}
            allListFilter.forEach(function (x) {
              counts[x] = (counts[x] || 0) + 1
            })

            const finalListFilter = []
            Object.entries(counts).map(([key, value]) => {
              for (var i = 0; i < data.data.length; i++) {
                if (data.data[i].id == key) {
                  finalListFilter.push(data.data[i])
                }
              }
              this.setState({ dataJobPartTime: finalListFilter })
            })
            if (finalListFilter.length === 0) {
              this.setState({ dataJobPartTime: finalListFilter })
            }
          }
        }
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handleChangeSortPeriod = async (e) => {
    const { isSortingPeriod, activeTab } = this.state
    if (e.target.checked === true) {
      this.sortJob(e.target.value)
    } else {
      if (activeTab == 0) {
        this.getJobFulltime()
      } else if (activeTab == 1) {
        this.getJobParttime()
      }
    }
    this.setState({
      isSortingSlot: false,
      isSortingPeriod: !isSortingPeriod,
      isSortingMaxSalary: false,
      isSortingMinSalary: false,
    })
  }

  handleChangeSortSlot = async (e) => {
    const { isSortingSlot, activeTab } = this.state
    if (e.target.checked === true) {
      this.sortJob(e.target.value)
    } else {
      if (activeTab == 0) {
        this.getJobFulltime()
      } else if (activeTab == 1) {
        this.getJobParttime()
      }
    }
    this.setState({
      isSortingSlot: !isSortingSlot,
      isSortingPeriod: false,
      isSortingMaxSalary: false,
      isSortingMinSalary: false,
    })
  }

  handleChangeSortMax = async (e) => {
    const { isSortingMaxSalary, activeTab } = this.state
    if (e.target.checked === true) {
      this.sortJob(e.target.value)
    } else {
      if (activeTab == 0) {
        this.getJobFulltime()
      } else if (activeTab == 1) {
        this.getJobParttime()
      }
    }
    this.setState({
      isSortingSlot: false,
      isSortingPeriod: false,
      isSortingMaxSalary: !isSortingMaxSalary,
      isSortingMinSalary: false,
    })
  }

  handleChangeSortMin = async (e) => {
    const { isSortingMinSalary, activeTab } = this.state
    if (e.target.checked === true) {
      this.sortJob(e.target.value)
    } else {
      if (activeTab == 0) {
        this.getJobFulltime()
      } else if (activeTab == 1) {
        this.getJobParttime()
      }
    }
    this.setState({
      isSortingSlot: false,
      isSortingPeriod: false,
      isSortingMaxSalary: false,
      isSortingMinSalary: !isSortingMinSalary,
    })
  }

  sortJob = async (category) => {
    const { id, activeTab } = this.state
    try {
      const newData = {
        key_api: config.KEY_API,
        work_type: 'Full-Time',
      }

      if (activeTab == 1) {
        newData['work_type'] = 'Part-Time'
      }
      const res = await api.getJobByWorkType(id, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        if (activeTab == 0) {
          if (category == 'Slot') {
            const slotArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) => (a.quota > b.quota ? 1 : -1))
              .map((item) => slotArray.push(item))
            this.setState({ dataJobFullTime: slotArray })
          } else if (category == 'Periode') {
            const periodArray = []
            ;[]
              .concat(data.data)
              .sort(
                (a, b) =>
                  new Date(a.deadline_job_vacancy) -
                  new Date(b.deadline_job_vacancy),
              )
              .map((item) => periodArray.push(item))
            this.setState({ dataJobFullTime: periodArray })
          } else if (category == 'Highest Salary') {
            const maxSalaryArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) =>
                parseInt(b.min_salary) > parseInt(a.min_salary) ? 1 : -1,
              )
              .map((item) => maxSalaryArray.push(item))
            this.setState({ dataJobFullTime: maxSalaryArray })
          } else if (category == 'Lowest Salary') {
            const minSalaryArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) =>
                parseInt(a.min_salary) > parseInt(b.min_salary) ? 1 : -1,
              )
              .map((item) => minSalaryArray.push(item))
            this.setState({ dataJobFullTime: minSalaryArray })
          }
        } else if (activeTab == 1) {
          if (category == 'Slot') {
            const slotArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) => (a.quota > b.quota ? 1 : -1))
              .map((item) => slotArray.push(item))
            this.setState({ dataJobPartTime: slotArray })
          } else if (category == 'Periode') {
            const periodArray = []
            ;[]
              .concat(data.data)
              .sort(
                (a, b) =>
                  new Date(a.start_part_time_date) -
                  new Date(b.start_part_time_date),
              )
              .map((item) => periodArray.push(item))
            this.setState({ dataJobPartTime: periodArray })
          } else if (category == 'Highest Salary') {
            const maxSalaryArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) =>
                parseInt(b.part_time_salary) > parseInt(a.part_time_salary)
                  ? 1
                  : -1,
              )
              .map((item) => maxSalaryArray.push(item))
            this.setState({ dataJobPartTime: maxSalaryArray })
          } else if (category == 'Lowest Salary') {
            const minSalaryArray = []
            ;[]
              .concat(data.data)
              .sort((a, b) =>
                parseInt(a.part_time_salary) > parseInt(b.part_time_salary)
                  ? 1
                  : -1,
              )
              .map((item) => minSalaryArray.push(item))
            this.setState({ dataJobPartTime: minSalaryArray })
          }
        }
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  checkInviteCandidate = (e) => {
    if (e.target.checked === true) {
      const item = {
        id_candidate: '',
      }
      this.setState({
        rowsPhoneNumberInvite: [...this.state.rowsPhoneNumberInvite, item],
      })
    } else {
      this.setState({ rowsPhoneNumberInvite: [] })
    }
    this.setState({ isCheckCandidate: !this.state.isCheckCandidate })
  }

  addNewCandidate = () => {
    const item = {
      id_candidate: '',
    }
    this.setState({
      rowsPhoneNumberInvite: [...this.state.rowsPhoneNumberInvite, item],
    })
  }

  handleRemoveSpecificRow = (e, idx) => {
    const rowsPhoneNumberInvite = [...this.state.rowsPhoneNumberInvite]
    rowsPhoneNumberInvite.splice(idx, 1)
    this.setState({ rowsPhoneNumberInvite })
  }

  handleChangeRowPhone = (idx) => (e) => {
    const { name, value } = e.target
    const rowsPhoneNumberInvite = [...this.state.rowsPhoneNumberInvite]
    rowsPhoneNumberInvite[idx] = {
      [name]: value,
    }
    this.setState({
      rowsPhoneNumberInvite,
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  tombolJobFullTimeHTML = () => {
    const { dataClient } = this.state
    return (
      <Button
        onClick={this.createJobFullTime}
        disabled={
          dataClient.full_time_quota == null ||
          dataClient.full_time_quota == 0 ||
          dataClient.is_active == 0 ||
          dataClient.is_active == null
            ? true
            : false
        }
        className="btn-block btn-blue-normal rounded-lg pl-2 pr-2 pt-0 pb-1 my-1"
      >
        <Label className="text-12 font-weight-bold mb-0">
          New Post Job Full Time
        </Label>
      </Button>
    )
  }

  tombolJobPartTimeHTML = () => {
    const { dataClient } = this.state
    return (
      <Button
        onClick={this.createJobPartTime}
        disabled={
          dataClient.is_active == 0 || dataClient.is_active == null
            ? true
            : false
        }
        className="btn-block btn-blue-normal rounded-lg pl-2 pr-2 pt-0 pb-1 my-1"
      >
        <Label className="text-12 font-weight-bold mb-0">
          New Post Job Part Time
        </Label>
      </Button>
    )
  }

  dataKosongHTML = (
    <center>
      <img
        src={noData}
        width="350px"
        className="mx-auto"
        style={{ marginBottom: '-50px' }}
        alt="Data Kosong"
      />
      <Label className="d-block text-16">Data Empty</Label>
    </center>
  )

  dataFullTimeHTML = (filteredDataFulltime) => {
    return filteredDataFulltime.map((item) => (
      <Col className="box-shadow p-3 mb-3">
        <Row>
          <Col>
            <u>
              <b className="text-16">ID JOB : {item.id}</b>
            </u>
            <p className="text-16 font-weight-bolder text-capitalize">
              {item.job_category}
            </p>
            <Row>
              <Col>
                <p className="mb-1 text-14">{item.quota} People</p>
                <p className="mb-1 text-14">{item.work_location}</p>
                <p className="mb-0 text-14">{item.job_position}</p>
              </Col>
              <Col>
                <p className="mb-1 text-14">
                  {method.formatRupiah(item.min_salary)} -{' '}
                  {method.formatRupiah(item.max_salary)}
                </p>
                <p className="mb-1 text-14">
                  {item.min_experience_year} Year Experience
                </p>
                <p className="mb-0 text-14">
                  Deadline {method.formatDateDay(item.deadline_job_vacancy)}
                </p>
              </Col>
              <Col>
                <Button
                  className="btn-block btn-info text-white"
                  onClick={() => this.editJob(item)}
                >
                  Edit
                </Button>
                <Button
                  className="btn-block btn-warning text-white"
                  onClick={() => this.toggleDetailFullTime(item)}
                >
                  Detail
                </Button>
                <Button
                  className="btn-block btn-danger text-white"
                  onClick={() =>
                    this.toggleDeleteFullTimeOrDraft(item, 'Job Full Time')
                  }
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ))
  }

  dataPartTimeHTML = (filteredDataParttime) => {
    return filteredDataParttime.map((item) => (
      <Col className="box-shadow p-3 mb-3">
        <Row>
          <Col>
            <u>
              <b className="text-16">ID JOB : {item.id}</b>
            </u>
            <p className="text-16 font-weight-bolder text-capitalize">
              {item.job_category}
            </p>
            <Row>
              <Col>
                <p className="mb-1 text-14">{item.quota} People</p>
                <p className="mb-1 text-14">{item.work_location}</p>
                <p className="mb-0 text-14">{item.job_position}</p>
                <p className="mb-0 text-14">{item.job_category}</p>
                <p className="mb-0 text-14">{item.last_education}</p>


              </Col>
              <Col>
                <p className="mb-1 text-14">
                  {method.formatRupiah(item.part_time_salary)}
                </p>
                <p className="mb-1 text-14">
                  {item.min_experience_year} Year Experience
                </p>
                <p className="mb-0 text-14">
                  {`${item.start_part_time_date} s/d ${item.finish_part_time_date}`}
                </p>
                <p className="mb-0 text-14">{item.job_description}</p>
                

              </Col>
              
              <Col>
                <Button
                  className="btn-block btn-warning text-white"
                  onClick={() => this.toggleDetailPartTime(item)}
                >
                  Detail
                </Button>
                <Button
                  className="btn-block btn-info text-white"
                  onClick={() => this.editJob(item)}
                >
                  Edit
                </Button>
                {/* <Button
                  className="btn-block btn-info text-white"
                  onClick={() => this.toggleEditJobWorkTime(item)}
                >
                  Edit Work Time
                </Button> */}
                <Button
                  className="btn-block btn-danger text-white"
                  onClick={() => this.toggleCancelJob(item)}
                >
                  Cancel Job
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ))
  }

  draftJobHTML = (dataDraftJob) => {
    return dataDraftJob.map((item) => (
      <Col className="box-shadow p-3 mb-3">
        <Row>
          <Col>
            <u>
              <b className="text-16">ID JOB : {item.id}</b>
            </u>
            <p className="text-16 font-weight-bolder text-capitalize">
              {item.job_category} ({item.work_type})
            </p>
            <Row>
              <Col>
                <p className="mb-1 text-14">{item.quota} People</p>
                <p className="mb-1 text-14">{item.work_location}</p>
                <p className="mb-0 text-14">{item.job_position}</p>
                <p className="mb-0 text-14">{item.last_education}</p>

              </Col>
              <Col>
                <p className="mb-1 text-14">
                  {method.formatRupiah(item.min_salary)} -{' '}
                  {method.formatRupiah(item.max_salary)}
                </p>
                <p className="mb-1 text-14">
                  {item.min_experience_year} Year Experience
                </p>
                <p className="mb-0 text-14">
                  {(item.work_type === 'Part-Time')? `Work Time ${item.start_part_time_date} s/d ${item.finish_part_time_date}` : 'Deadline ' + method.formatDateDay(item.deadline_job_vacancy)}
                </p>
                <p className="mb-0 text-14">
                  Waktu Posting {item.posting_time}
                </p>
              </Col>
              <Col>
                <Button
                  className="btn-block btn-info text-white"
                  onClick={() => this.editDraft(item)}
                >
                  Edit
                </Button>
                <Button
                  className="btn-block btn-warning text-white"
                  onClick={() => this.toggleDetailFullTime(item)}
                >
                  Detail
                </Button>
                <Button className="btn-block btn-success text-white" onClick={() => this.togglePublishJob(item)}>Publish Job</Button>
                <Button
                  className="btn-block btn-danger text-white"
                  onClick={() =>
                    this.toggleDeleteFullTimeOrDraft(item, 'Draft')
                  }
                >
                  Delete
                </Button>
              </Col>
            </Row>
            {item.is_invite_candidate === 1 ? (
              <p className="mb-0 text-14" style={{ color: 'green' }}>
                Publish otomatis pada{' '}
                {method.formatDateAndTimeForPublishJob(
                  item.inviting_candidate_date,
                )}
              </p>
            ) : null}
          </Col>
        </Row>
      </Col>
    ))
  }

  expiredJobHTML = (dataJobExpired) => {
    return dataJobExpired.map((item) => {
      // const kaloParttime = `Work Time ${method.formatDateAndTime(item.start_part_time_date)} s/d ${method.formatDateTime(item.finish_part_time_date)}`;
      const kaloParttime = `Work Time ${method.formatDateDay(item.start_part_time_date)} s/d ${method.formatDateDay(item.finish_part_time_date)}`;
      const kaloFulltime = `Deadline ${ method.formatDateDay(item.deadline_job_vacancy)}`;
      return <Col className="box-shadow p-3 mb-3">
          <Row>
            <Col>
              <u>
                <b className="text-16">ID JOB : {item.id}</b>
              </u>
              <p className="text-16 font-weight-bolder text-capitalize">
                {item.job_category} ({item.work_type})
              </p>
              <Row>
                <Col>
                  <p className="mb-1 text-14">{item.quota} People</p>
                  <p className="mb-1 text-14">{item.work_location}</p>
                  <p className="mb-0 text-14">{item.job_position}</p>
                </Col>
                <Col>
                  <p className="mb-1 text-14">
                    {method.formatRupiah(item.min_salary)} -{' '}
                    {method.formatRupiah(item.max_salary)}
                  </p>
                  <p className="mb-1 text-14">
                    {item.min_experience_year} Year Experience
                  </p>
                  <p className="mb-0 text-14">
                    {(item.work_type === 'Part-Time')? kaloParttime : kaloFulltime}
                  </p>
                </Col>
                <Col>
                  <Button className="btn-block btn-warning text-white" onClick={() => this.toggleDetailFullTime(item)}>Detail</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>;
    });
  }

  modalDetailJobFullTime = () => {
    const { modalDetailFullTime, dataClient, detailJobFullTime } = this.state
    return (
      <Modal isOpen={modalDetailFullTime} toggle={this.toggleDetailFullTime}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleDetailFullTime}
        />
        <ModalBody>
          <Col className="border-bottom pl-0 pr-0">
            <Col>
              <Row>
                <Col lg="auto" className="text-center my-auto">
                  <img
                    src={
                      dataClient.company_logo_image === null
                        ? logoCompany
                        : `${config.URL_IMAGE}company_logo/${dataClient.company_logo_image}`
                    }
                    alt="photo_client"
                    className="box-shadow p-1 rounded-lg mb-3"
                    width="130px"
                  />
                </Col>
                <Col className="text-14">
                  <p className="font-weight-bold mb-0">
                    {detailJobFullTime.job_category}
                  </p>
                  <p className="mb-0">{dataClient.company_name}</p>
                  <p className="mb-0">{detailJobFullTime.work_type}</p>
                  <p className="mb-0">{dataClient.work_location}</p>
                  <p className="mb-0">
                    {method.formatMoney(detailJobFullTime.min_salary)} -{' '}
                    {method.formatMoney(detailJobFullTime.max_salary)}
                  </p>
                  <Row>
                    <Col lg="auto">
                      <Label className="text-green mb-0">
                        {method.formatDateDay(detailJobFullTime.posting_time)}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col className="border-bottom text-14 mt-3">
            <Row>
              <Col>
                <p className="font-weight-bolder mb-0">Slot</p>
                <p className="text-12">{detailJobFullTime.quota} Person</p>
              </Col>
              <Col>
                <p className="font-weight-bolder mb-0">Deadline</p>
                <p className="text-12">
                  {method.formatDateDay(detailJobFullTime.deadline_job_vacancy)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="font-weight-bolder mb-0">Category</p>
                <p className="text-12">{detailJobFullTime.job_category}</p>
              </Col>
              <Col>
                <p className="font-weight-bolder mb-0">Experience Year</p>
                <p className="text-12">
                  {detailJobFullTime.min_experience_year} Year
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="border-bottom text-14 mt-3">
            <p className="font-weight-bolder mb-0">Job Description</p>
            <p className="text-justify">{detailJobFullTime.job_description}</p>
          </Col>
        </ModalBody>
      </Modal>
    )
  }

  modalDetailJobPartTime = () => {
    const { modalDetailPartTime, dataClient, detailJobPartTime } = this.state
    let shift = []
    if(detailJobPartTime.absen !== undefined){
        shift =  detailJobPartTime.absen.split(",");
    }

    return (
      <Modal isOpen={modalDetailPartTime} toggle={this.toggleDetailPartTime}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleDetailPartTime}
        />
        <ModalBody>
          <Col className="border-bottom pl-0 pr-0">
            <Col>
              <Row>
                <Col lg="auto" className="text-center">
                  <img
                    src={
                      dataClient.company_logo_image === null
                        ? logoCompany
                        : config.URL_IMAGE +
                          'company_logo/' +
                          dataClient.company_logo_image
                    }
                    alt="photo_client"
                    className="box-shadow p-1 rounded-lg mb-3"
                    width="80px"
                  />
                </Col>
                <Col className="text-14">
                  <p className="font-weight-bold mb-0">
                    {detailJobPartTime.job_category}
                  </p>
                  <p className="mb-0">{dataClient.company_name}</p>
                  <p className="mb-0">{detailJobPartTime.work_type}</p>
                  <p className="mb-0">{detailJobPartTime.work_location}</p>
                  <p className="mb-0">
                    {method.formatRupiah(detailJobPartTime.part_time_salary)}
                  </p>
                  <Row>
                    <Col lg="auto">
                      <Label className="text-green mb-0">
                        {method.formatDateDay(detailJobPartTime.posting_time)}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col className="border-bottom text-14 mt-3">
            <Row>
              <Col>
                <p className="font-weight-bolder mb-0">Slot</p>
                <p className="text-12">{detailJobPartTime.quota} Person</p>
              </Col>
              <Col>
                <p className="font-weight-bolder mb-0">Experience Year </p>
                <p className="text-12">
                  {detailJobPartTime.min_experience_year} Year
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="font-weight-bolder mb-0">Posisi Pekerjaan</p>
                <p className="text-12">{detailJobPartTime.job_position}</p>
              </Col>
              <Col>
              <p className="font-weight-bolder mb-0">Kategori Pekerjaan</p>
                <p className="text-12">{detailJobPartTime.job_category}</p>
               
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="font-weight-bolder mb-1">Tanggal Kerja Part-time</p>
                <p className="text-12 mb-0">
                  { detailJobPartTime.start_part_time_date}{' '}
                  s/d{' '}
                  { detailJobPartTime.finish_part_time_date}{' '}
                </p>
              </Col>
              {/* {shift.map((it) => {
                return <p>
                  it
                </p>
              })} */}
              <Col>
                <p className="font-weight-bolder mb-1">Shift Kerja Part-time</p>
                 {shift.map((it)=>
                 <li key={it}>{it}</li>
                )}
              </Col>
            </Row>
            
            
            <Row className="mt-3">
              <Col>
                <p className="font-weight-bolder mb-0">Total Candidate</p>
                <p className="text-12">
                  {detailJobPartTime.total_candidate === 0 ||
                  detailJobPartTime.total_candidate === undefined
                    ? '-'
                    : detailJobPartTime.total_candidate + ' Person'}
                </p>
              </Col>
              <Col>
                <p className="font-weight-bolder mb-0">Total Reserve</p>
                <p className="text-12 mb-0">
                  {detailJobPartTime.total_reserve === 0 ||
                  detailJobPartTime.total_reserve === undefined
                    ? '-'
                    : detailJobPartTime.total_reserve + ' Person'}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="border-bottom text-14 mt-3">
                <p className="font-weight-bolder mb-0">Job Description</p>
                <p className="text-justify">{detailJobPartTime.job_description}</p>
             </Col>
              <Col>
                <p className="font-weight-bolder mb-0">Last Education</p>
                <p className="text-justify">{detailJobPartTime.last_education}</p>
              </Col>
            </Row>
          </Col>
      

        </ModalBody>
      </Modal>
    )
  }

  modalPublishJobFullTime = () => {
    const { modalPublishJobFullTime, dataClient } = this.state
    return (
      <Modal
        isOpen={modalPublishJobFullTime}
        toggle={this.closeModal}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.closeTogglePublishJobFullTime}
        />
        <ModalBody>
          <h5 className="text-center mb-5">Publish Job Full Time</h5>
          <Row>
            <Col lg="10">
              <Label className="font-weight-bold d-block">
                Balance Full Time
              </Label>
            </Col>
            <Col lg="2">
              <Label className="mb-1">{dataClient.full_time_quota}</Label>
            </Col>
          </Row>
          <Row>
            <Col lg="10"></Col>
            <Col lg="2">
              <Label style={{ color: 'red' }} className="mb-1">
                -1
              </Label>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg="10">
              <Label className="font-weight-bold d-block">
                Update Balance Credit Full Time
              </Label>
            </Col>
            <Col lg="2">
              <Label className="mb-1">{dataClient.full_time_quota - 1}</Label>
            </Col>
          </Row>
          <Col lg="6" className="mx-auto">
            <Button
              className="btn-block btn-warning"
              onClick={this.publishJobFullTime}
            >
              Publish Job
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    )
  }

    handleAddInvitEmployee = (e, index) => {
        const {selectInvitedEmployee} = this.state;
        if(e.target.checked) {
            selectInvitedEmployee.push({
                index: index,
                nilai: e.target.value
            });
        } else {
            const hasil = [];
            selectInvitedEmployee.forEach(item => {
                if(item.index != index) hasil.push(item);
            });
            this.setState({selectInvitedEmployee: hasil});
        }
    }

    modalPublishJobPartTime = () => {
        const {modalPublishJobPartTime, spesificSalary, spesificQuota, managementCost, managementCostPersenValue, subtotalFee, ppnPersenValue, ppn, pph, pphPersenValue, totalPaidRupiah, partTimeFinanceBalance, archivedEmployees, totalPaid, isCheckCandidate, rowsPhoneNumberInvite, isErrorDataInviteCandidate} = this.state
        return <Modal isOpen={modalPublishJobPartTime} toggle={this.closeTogglePublishJobPartTime} size="xl">
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeTogglePublishJobPartTime}/>
            <ModalBody>
                <h5 className="text-center font-weight-bold">Publish Job Part Time</h5><hr/>
                <div className="row mb-2">
                    <div className="col-md mb-2">
                        <div className="row">
                            <div className="col-8 font-weight-bold">Salary</div>
                            <div className="col text-right">{method.formatRupiah(spesificSalary)} / day</div>
                        </div>
                        <div className="row">
                            <div className="col-8 font-weight-bold">Quota</div>
                            <div className="col text-right">{spesificQuota} people</div>
                        </div><hr/>
                        <div className="row">
                            <div className="col-8 text-muted"><span className="font-weight-bold text-dark">Management Cost</span><br/>Salary Part Time x {managementCostPersenValue}%</div>
                            <div className="col text-right">{managementCost}</div>
                        </div>
                        <div className="row">
                            <div className="col-8 text-muted"><span className="font-weight-bold text-dark">Subtotal Fees</span><br/>Salary Part Time + Management Cost</div>
                            <div className="col text-right">{subtotalFee}</div>
                        </div>
                        <div className="row">
                            <div className="col-8 text-muted"><span className="font-weight-bold text-dark">PPN</span><br/>Management Cost x {ppnPersenValue}%</div>
                            <div className="col text-right">{ppn}</div>
                        </div>
                        <div className="row">
                            <div className="col-8 text-muted"><span className="font-weight-bold text-dark">PPH</span><br/>Management Cost x {pphPersenValue}%</div>
                            <div className="col text-right text-italic">{pph}</div>
                        </div>
                        <div>
                            <div className="bg-info p-2 rounded text-light font-weight-bold">
                                <div className="row">
                                    <div className="col-8">Grand Total</div>
                                    <div className="col text-right">{totalPaidRupiah}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 font-weight-bold">Part Time Finance Balance</div>
                            <div className="col text-right">{method.formatRupiah(partTimeFinanceBalance)}</div>
                        </div>
                        <div className="row">
                            <div className="col-8 font-weight-bold"></div>
                            <div className="col text-right text-danger">-{totalPaidRupiah}</div>
                        </div>
                        <div>
                            <div className="bg-success p-2 rounded text-light font-weight-bold">
                                <div className="row">
                                    <div className="col-8">Update Finance Balance</div>
                                    <div className="col text-right">{method.formatRupiah(partTimeFinanceBalance)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="table-responsive" style={{maxHeight: '400px'}}>
                            <table className="table table-hover table-bordered table-sm">
                                <tbody>
                                    {archivedEmployees.map(({id, email, phone_number, full_name, gender, profile_image}, i) => <tr>
                                        <td className="text-center" style={{width: '20%'}}>
                                            <input type="checkbox" className="form-control" onChange={e => this.handleAddInvitEmployee(e, i)} value={id} id="customCheck1"/>
                                        </td>
                                        <td>
                                            <div className="row">
                                                <div className="col-2">
                                                    <img style={{
                                                        width: '50px',
                                                        height: '50px'
                                                    }} className="rounded-circle" src={`https://cmsforofficer.avisha.co.id/image/image_profile_employee/${(profile_image == 'new-logo-avisa.png')? 'user.png' : profile_image}`} alt={`Gambar ${profile_image}`} />
                                                </div>
                                                <div className="col text-12">
                                                    <h6 className="font-weight-bold">{method.fixId(id)}</h6>
                                                    <p>{(full_name)? full_name : '-'}<br/>{(gender)? gender : '-'}<br/>{(email)? email : '-'}<br/>{(phone_number)? phone_number : '-'}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <button className="btn btn-warning btn-sm font-weight-bold float-right" onClick={this.publishJobPartTime}>Publish Job</button>
            </ModalBody>
        </Modal>
    }

  modalPublishJobPartTime_last = () => {
    const {modalPublishJobPartTime, spesificSalary, spesificQuota, managementCost, managementCostPersenValue, subtotalFee, ppnPersenValue, ppn, pph, pphPersenValue, totalPaidRupiah, partTimeFinanceBalance, totalPaid, isCheckCandidate, rowsPhoneNumberInvite, isErrorDataInviteCandidate} = this.state
    return (
      <Modal isOpen={modalPublishJobPartTime} toggle={this.closeModal} size="xl" backdrop="static">
        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.closeTogglePublishJobPartTime}/>
        <ModalBody>
          <h5 className="text-center mb-5">Publish Job Part Time</h5>
          <Form>
            <Col className="pl-0 pr-0 mb-3">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    Salary
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">
                    {method.formatRupiah(spesificSalary)} / day
                  </Label>
                </Col>
              </Row>
            </Col>
            <Col className="pl-0 pr-0 mb-3">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    Quota
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">
                    {spesificQuota} people
                  </Label>
                </Col>
              </Row>
            </Col>
            <hr />
            <Col className="pl-0 pr-0 mb-3">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    Management Cost
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">
                    {managementCost}
                  </Label>
                </Col>
              </Row>
              <p style={{ color: 'grey' }}>
                Salary Part Time x {managementCostPersenValue} %
              </p>
            </Col>
            <Col className="pl-0 pr-0 mb-3">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    Subtotal fees
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">{subtotalFee}</Label>
                </Col>
              </Row>
              <p style={{ color: 'grey' }}>
                Salary Part Time + Management Cost
              </p>
            </Col>
            <Col className="pl-0 pr-0 mb-3">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    PPN
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">{ppn}</Label>
                </Col>
              </Row>
              <p style={{ color: 'grey' }}>
                Management Cost x {ppnPersenValue} %
              </p>
            </Col>
            <Col className="pl-0 pr-0 mb-3 ">
              <Row>
                <Col className="text-left">
                  <Label className="d-block font-weight-bold text-16 mb-0">
                    PPH
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="d-block text-16 mb-0">{pph}</Label>
                </Col>
              </Row>
              <p style={{ color: 'grey' }}>
                Management Cost x {pphPersenValue} %
              </p>
            </Col>
            <Col className="bg-info my-auto rounded-lg mb-3 text-white p-3">
              <Row>
                <Col className="text-left">
                  <Label className="text-16 font-weight-bold mb-0">
                    Grand total
                  </Label>
                </Col>
                <Col className="text-right">
                  <Label className="text-16 mb-0">: {totalPaidRupiah}</Label>
                </Col>
              </Row>
            </Col>
          </Form>
          <hr />
          <Col>
            <Row>
              <Col className="text-left">
                <Label className="text-16 font-weight-bold mb-0">
                  Part Time finance Balance
                </Label>
              </Col>
              <Col className="text-right">
                <Label className="text-16 mb-0">
                  : {method.formatRupiah(partTimeFinanceBalance)}
                </Label>
              </Col>
            </Row>
          </Col>
          <br />
          <Col>
            <Row>
              <Col className="text-right">
                <Label className="text-16 mb-0" style={{ color: 'red' }}>
                  - {totalPaidRupiah}
                </Label>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col className="bg-success my-auto rounded-lg mb-3 text-white p-3">
            <Row>
              <Col className="text-left">
                <Label className="text-16 font-weight-bold mb-0">
                  Update Finance Balance
                </Label>
              </Col>
              <Col className="text-right">
                <Label className="text-16 mb-0">
                  : {method.formatRupiah(partTimeFinanceBalance - totalPaid)}
                </Label>
              </Col>
            </Row>
          </Col>
          {totalPaid > partTimeFinanceBalance ? (
            <p>
              <small style={{ color: 'red' }}>Your balance is not enough</small>
            </p>
          ) : null}
          <br />
          <input type="checkbox" onClick={this.checkInviteCandidate} /> Invite
          Candidate yang biasa digunakan.
          {isCheckCandidate === true ? (
            <div>
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-center"> Id Candidate </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {rowsPhoneNumberInvite.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <input
                          type="number"
                          name="id_candidate"
                          value={rowsPhoneNumberInvite[idx].id_candidate}
                          onChange={this.handleChangeRowPhone(idx)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        {rowsPhoneNumberInvite.length > 1 ? (
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={(e) =>
                              this.handleRemoveSpecificRow(e, idx)
                            }
                          >
                            Remove
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              {rowsPhoneNumberInvite.length < spesificQuota ? (
                <button
                  onClick={this.addNewCandidate}
                  className="btn btn-primary"
                >
                  {' '}
                  Add New Id Candidate{' '}
                </button>
              ) : null}
            </div>
          ) : null}
          {isErrorDataInviteCandidate === true ? (
            <p style={{ color: 'red' }}>Id Kandidat belum diisi</p>
          ) : null}
          <hr />
          <Col lg="6" className="mx-auto">
            <Button
              disabled={totalPaid > partTimeFinanceBalance ? true : false}
              className="btn-block btn-warning"
              onClick={this.publishJobPartTime}
            >
              Publish Job
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    )
  }

  modalDeleteFullTimeOrDraft = () => {
    const { modalDeleteFullTimeOrDraft } = this.state
    return (
      <Modal
        isOpen={modalDeleteFullTimeOrDraft}
        toggle={this.toggleDeleteFullTimeOrDraft}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleDeleteFullTimeOrDraft}
        />
        <ModalBody>
          <h5 className="text-center mb-5">
            Are you sure you want to delete this job ?
          </h5>
          <Col lg="6" className="mx-auto">
            <Button className="btn-block btn-warning" onClick={this.deleteJob}>
              Yes, Delete Job
            </Button>
          </Col>
        </ModalBody>
      </Modal>
    )
  }

  modalCancelJob = () => {
    const { modalCancelJob, canceledReason } = this.state
    return (
      <Modal
        isOpen={modalCancelJob}
        toggle={this.toggleCancelJob}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleCancelJob}
        />
        <ModalBody>
          <h5 className="text-center mb-5">
            Are you sure you want to cancel this job ?
          </h5>
          <Label>Cancel Reason :</Label>
          <Input
            placeholder="Cancel reason ..."
            type="textarea"
            onChange={this.handleChange}
            name="canceledReason"
            value={canceledReason}
            className="input-box-white"
          />
          <br />
          {canceledReason != '' ? (
            <Col lg="6" className="mx-auto">
              <Button
                className="btn-block btn-warning"
                onClick={this.cancelJob}
              >
                Yes, Cancel Job
              </Button>
            </Col>
          ) : null}
        </ModalBody>
      </Modal>
    )
  }

    modalEditJobWorkTime = () => {
        const {modalEditJobWorkTime, newWorkTimeStart, newWorkTimeFinish} = this.state
        return <Modal isOpen={modalEditJobWorkTime} toggle={this.toggleEditJobWorkTime}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleEditJobWorkTime}/>
            <ModalHeader>Edit Work Time</ModalHeader>
            <ModalBody>
                <div className="row mb-1">
                    <div className="col-3"><label htmlFor="newWorkTimeStart">Start</label></div>
                    <div className="col"><input type="date" className="form-control form-control-sm" value={newWorkTimeStart} onChange={this.handleChangeDatetimeStart}/></div>
                </div>
                <div className="row mb-1">
                    <div className="col-3"><label htmlFor="newWorkTimeFinish">Finish</label></div>
                    <div className="col"><input type="date" className="form-control form-control-sm" value={newWorkTimeFinish} onChange={this.handleChangeDatetimeFinish}/></div>
                </div>
                <button className="btn btn-warning btn-sm float-right" onClick={this.updateWorkTime}>Update</button>
            </ModalBody>
        </Modal>
    }

  modalEditJobWorkTimee = () => {
    const {modalEditJobWorkTime, newWorkTimeStart, newWorkTimeFinish} = this.state
    return (
      <Modal
        isOpen={modalEditJobWorkTime}
        toggle={this.toggleEditJobWorkTime}
        backdrop="static"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-danger btn-close-circle"
          onClick={this.toggleEditJobWorkTime}
        />
        <ModalBody>
          <h5 className="text-center mb-5">
            If you sure you want to edit work time job, please sign new date ?
          </h5>
          <Row>
            <Col xs="12" sm="12" md="4" className="my-auto">
              <Label className="my-auto">Start</Label>
            </Col>
            <Col className="my-auto text-right">
              <DateTimePicker
                onChange={this.handleChangeDatetimeStart}
                value={newWorkTimeStart}
                name="newWorkTimeStart"
              />
            </Col>
          </Row>
          <br />
          {newWorkTimeStart != '' ? (
            <Row>
              <Col xs="12" sm="12" md="4" className="my-auto">
                <Label className="my-auto">Finish </Label>
              </Col>
              <Col className="my-auto text-right">
                <DateTimePicker
                  disableCalendar={true}
                  onChange={this.handleChangeDatetimeFinish}
                  value={newWorkTimeFinish}
                  name="newWorkTimeFinish"
                />
              </Col>
            </Row>
          ) : null}
          <hr />
          {newWorkTimeStart != '' && newWorkTimeFinish != '' ? (
            <Col lg="6" className="mx-auto">
              <Button
                className="btn-block btn-warning"
                onClick={this.updateWorkTime}
              >
                Update Work Time
              </Button>
            </Col>
          ) : null}
        </ModalBody>
      </Modal>
    )
  }

  render() {
    const {
      dataJobFullTime,
      dataJobPartTime,
      dataClient,
      activeTab,
      listRegency,
      searchString,
      isSortingSlot,
      isSortingPeriod,
      isSortingMaxSalary,
      isSortingMinSalary,
      dataDraftJob,
      dataJobExpired,
    } = this.state
    const lowercasedFilter = searchString.toLowerCase()
    let filteredDataFulltime = []
    let filteredDataParttime = []
    if (activeTab == 0) {
      filteredDataFulltime = dataJobFullTime.filter((item) => {
        return Object.keys(item).some((key) =>
          item['job_category'].toLowerCase().includes(lowercasedFilter),
        )
      })
    } else if (activeTab == 1) {
      filteredDataParttime = dataJobPartTime.filter((item) => {
        return Object.keys(item).some((key) =>
          item['job_category'].toLowerCase().includes(lowercasedFilter),
        )
      })
    }

    return (
      <Fragment>
        <HeaderClient />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <ToastContainer />
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">
                    Job Posting
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
          <div fluid className="pb-5 muli-font bg-body-grey">
            <Container className="pt-4">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="3">
                      <Col className="bg-white rounded box-shadow mb-3 p-3">
                        <Col className="mb-3">
                          <Label className="d-block text-14 font-weight-bolder">
                            Search
                          </Label>
                          <Input
                            type="text"
                            id="search"
                            className="search input-box-grey text-12"
                            placeholder="search job name"
                            value={searchString}
                            onChange={this.handleChangeSearch}
                          />
                        </Col>
                        <Col className="mb-3">
                          <Label className="d-block text-14 font-weight-bolder">
                            Location
                          </Label>
                          <Select
                            name="form-field-name"
                            options={listRegency}
                            onChange={this.handleChoose.bind(this)}
                          />
                        </Col>
                        <Col className="mb-3">
                          <Label className="d-block text-14 font-weight-bolder">
                            Sort By:
                          </Label>
                          <div className="spacing-checkbox text-12">
                            <Input
                              type="checkbox"
                              id="Periode"
                              checked={isSortingPeriod === false ? false : true}
                              className="checkboxLocation check-box"
                              value="Periode"
                              onChange={this.handleChangeSortPeriod}
                            />{' '}
                            Periode
                            <br />
                            <Input
                              type="checkbox"
                              id="Slot"
                              checked={isSortingSlot === false ? false : true}
                              className="checkboxLocation check-box"
                              value="Slot"
                              onChange={this.handleChangeSortSlot}
                            />{' '}
                            Slot
                            <br />
                            <Input
                              type="checkbox"
                              id="Highest Salary"
                              checked={
                                isSortingMaxSalary === false ? false : true
                              }
                              className="checkboxLocation check-box"
                              value="Highest Salary"
                              onChange={this.handleChangeSortMax}
                            />{' '}
                            Highest Salary
                            <br />
                            <Input
                              type="checkbox"
                              id="Lowest Salary"
                              checked={
                                isSortingMinSalary === false ? false : true
                              }
                              className="checkboxLocation check-box"
                              value="Lowest Salary"
                              onChange={this.handleChangeSortMin}
                            />{' '}
                            Lowest Salary
                            <br />
                          </div>
                        </Col>
                      </Col>
                    </Col>
                    <Col lg="9">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '0',
                            })}
                            onClick={(e) => {
                              this.toggle('0')
                            }}
                            style={{ borderRadius: '12px 12px 0 0' }}
                          >
                            <p className="mb-0 font-weight-bold text-14">
                              Full Time Job
                            </p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({active: this.state.activeTab === '1'})} onClick={(e) => {this.toggle('1')}} style={{ borderRadius: '12px 12px 0 0' }}>
                            <p className="mb-0 font-weight-bold text-14">Part Time Job</p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink className={classnames({active: this.state.activeTab === '2'})} onClick={(e) => {this.toggle('2')}} style={{ borderRadius: '12px 12px 0 0' }}>
                            <p className="mb-0 font-weight-bold text-14">
                              Draft
                            </p>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '3',
                            })}
                            onClick={(e) => {
                              this.toggle('3')
                            }}
                            style={{ borderRadius: '12px 12px 0 0' }}
                          >
                            <p className="mb-0 font-weight-bold text-14">
                              Post Expired
                            </p>
                          </NavLink>
                        </NavItem>
                        <NavItem className="ml-auto">
                          {activeTab === '0'
                            ? this.tombolJobFullTimeHTML()
                            : activeTab === '1'
                            ? this.tombolJobPartTimeHTML()
                            : null}
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="border-left border-right border-bottom text-12"
                      >
                        <TabPane
                          tabId="0"
                          className="bg-white p-3"
                          style={{ minHeight: '470px' }}
                        >
                          {filteredDataFulltime.length != 0
                            ? this.dataFullTimeHTML(filteredDataFulltime)
                            : this.dataKosongHTML}
                        </TabPane>
                        <TabPane
                          tabId="1"
                          className="bg-white p-3"
                          style={{ minHeight: '470px' }}
                        >
                          {filteredDataParttime.length != 0
                            ? this.dataPartTimeHTML(filteredDataParttime)
                            : this.dataKosongHTML}
                        </TabPane>
                        <TabPane
                          tabId="2"
                          className="bg-white p-3"
                          style={{ minHeight: '470px' }}
                        >
                          {dataDraftJob.length != 0
                            ? this.draftJobHTML(dataDraftJob)
                            : this.dataKosongHTML}
                        </TabPane>
                        <TabPane
                          tabId="3"
                          className="bg-white p-3"
                          style={{ minHeight: '470px' }}
                        >
                          {dataJobExpired.length != 0? this.expiredJobHTML(dataJobExpired) : this.dataKosongHTML}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
        {this.modalDetailJobFullTime()}
        {this.modalDetailJobPartTime()}
        {this.modalPublishJobFullTime()}
        {this.modalPublishJobPartTime()}
        {this.modalDeleteFullTimeOrDraft()}
        {this.modalCancelJob()}
        {this.modalEditJobWorkTime()}
      </Fragment>
    )
  }
}

export default JobPostClient
