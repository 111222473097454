import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import * as method                                          from '../Helpers/Method';
import "react-datepicker/dist/react-datepicker.css";
import '../Css/style.css';
import {
    Container, Row, Col, Nav, NavItem,
    NavLink
}                                                           from 'reactstrap'
import classnames                                           from 'classnames';
import { DownloadTableExcel }                               from 'react-export-table-to-excel';

class CreateFullTime extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        id:                         0,
        activeTab:                  0,
        fullTimes:                  [],
        partTimes:                  [],
        partTimeEmployees:          [],
        fullTimeEmployees:          [],
        selectedEmployeePartTime:   0,
        selectedEmployeeFullTime:   0,
        dari:                       '',
        sampai:                     ''
    }

    componentDidMount = async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getAllEmployees();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({id});
    }

    _generateParam = (param = {}) => {
        param.key_api = config.KEY_API;
        return {params: {data: JSON.stringify(param)}};
    }

    _generateDataEmployee = ({id, full_name}) => ({
        value: id,
        label: `${full_name} (${method.fixId(id)})`
    })

    getAllEmployees = async() => {
        const {id, partTimeEmployees, fullTimeEmployees} = this.state;
        const res = await api.getAllEmployees(this._generateParam({id_client: id}));
        partTimeEmployees.splice(0, partTimeEmployees.length);
        fullTimeEmployees.splice(0, fullTimeEmployees.length);
        partTimeEmployees.push({value : '0', label: 'All'});
        fullTimeEmployees.push({value : '0', label: 'All'});
        res.data.employees.forEach(employee => (employee.work_type === 'Part-Time')? partTimeEmployees.push(this._generateDataEmployee(employee)) : fullTimeEmployees.push(this._generateDataEmployee(employee)));
        this.setState({partTimeEmployees, fullTimeEmployees});
        console.log(fullTimeEmployees)
      
    }

    toggle = tab => {
        this.getAllEmployees();
        const {activeTab} = this.state
        if(activeTab !== tab) this.setState({activeTab: tab})
        this._resetState();
    }

    _resetState = () => {
        this.setState({
            fullTimes:          [],
            partTimes:          [],
            partTimeEmployees:  [],
            fullTimeEmployees:  [],
            dari:               '',
            sampai:             ''
        });
    }

    renderTab = activeTab => {
        const {fullTimes, partTimes, fullTimeEmployees, partTimeEmployees} = this.state;
        let html;
        if (activeTab == 0) {
            console.log("tab 0")
            html = this.tabelHTML('selectedEmployeeFullTime', fullTimeEmployees, fullTimes);
        } else if (activeTab == 1) {
            console.log("tab 1")
            html = this.tabelHTML('selectedEmployeePartTime', partTimeEmployees, partTimes);
        } else {
            html = null;
        }
        return html;
    }

    renderExcel = activeTab => {
        const {fullTimes, partTimes, fullTimeEmployees, partTimeEmployees} = this.state;
        let html;
        if (activeTab == 0) {
            html = this.tableExcel(fullTimes);
        } else if (activeTab == 1) {
            html = this.tableExcel(partTimes);
        } else {
            html = null;
        }
        return html;
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = '';
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                console.log('Called', reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    handleChange = e => this.setState({[e.target.name]: e.target.value});

    handleChangeSelectEmployee = (e, u) => this.setState({[u.name]: e.value});

    filterParttime = async() => {
        const {id, selectedEmployeePartTime, dari, sampai} = this.state;
        if( dari == '' || sampai == '') {
            alert('Filter wajib diisi!');
        } else {
            const res = await api.getAllAbsen(this._generateParam({
                id_employee: selectedEmployeePartTime,
                work_type: 'Part-Time',
                id_client: id,
                dari,
                sampai
            }));
            const {data} = res.data;
            await this.setState({partTimes: data});
        }
    }

    filterFulltime = async() => {
        const {id, selectedEmployeeFullTime, dari, sampai} = this.state;
        console.log(id);
        console.log(selectedEmployeeFullTime);

        if( dari == '' || sampai == '') {
            alert('Filter wajib diisi!');
        } else {
            const res = await api.getAllAbsen(this._generateParam({
                id_employee: selectedEmployeeFullTime,
                work_type: 'Full-Time',
                id_client: id,
                dari,
                sampai
            }));
            const {data} = res.data;
            await this.setState({fullTimes: data});
        }
    }

    
    renderTbody = datas => {
        return datas.map(({
            full_name,
            job_id,
            work_type,
            id_client_company,
            absen_id,
            employee_id,
            tanggal,
            jam_in,
            jam_out,
            jam_shift_in,
            jam_shift_out,
            alamat_in,
            alamat_out,
            photo_in,
            photo_out,

            }, i) => <tr key={i}>
            <td>{tanggal}</td>
            <td>{full_name}</td>
            <td>{(!jam_shift_in)? this.belumTersediaHTML : jam_shift_in}</td>
            <td>{(!jam_in)? this.belumTersediaHTML : jam_in}</td>
            <td>{(!jam_shift_out)? this.belumTersediaHTML : jam_shift_out}</td>
            <td>{(!jam_out)? this.belumTersediaHTML : jam_out}</td>
            <td>{(work_type === "Full-Time")? '' : work_type + '(' + job_id + ')'}</td>
            <td>{(work_type === "Full-Time")? '' : work_type}</td>
            <td>{(!alamat_in)? this.belumTersediaHTML : alamat_in}</td>
            <td>{(!alamat_out)? this.belumTersediaHTML : alamat_out}</td>
            <td>{(!photo_in)? this.belumTersediaHTML : this._photoHTML(job_id, photo_in)}</td>
            <td>{(!photo_out)? this.belumTersediaHTML : this._photoHTML(job_id, photo_out)}</td>
        </tr>);
    }

    _linkGambar = 'https://apimobile.avisha.co.id/image/attendance/';

    _photoHTML = (dir, file) => <img style={{width: '100%'}} alt={file} src={`${this._linkGambar+dir}/${file}`}/>

    _linkPhotoHTML = (dir, file) => <a href={`${this._linkGambar+dir}/${file}`}>Open</a>;

    tableExcel = datas => {
        return <div style={{display: 'none'}}>
            <table ref={this.tableRef}>
                <tr className="text-center">
                    <th>Date</th>
                    <th>Name</th>
                    <th>Shift In</th>
                    <th>Check In</th>
                    <th>Shift Out</th>
                    <th>Check Out</th>
                    <th>Job Name</th>
                    <th>Job Description</th>
                    <th>Location In</th>
                    <th>Location Out</th>
                    <th>Photo In</th>
                    <th>Photo Out</th>
                </tr>
                {datas.map(({ 
                            job_id,
                            full_name,
                            work_type,
                            id_client_company,
                            absen_id,
                            employee_id,
                            tanggal,
                            jam_in,
                            jam_out,
                            alamat_in,
                            alamat_out,
                            photo_in,
                            photo_out,
                    }, i) => (<tr key={i}>
                    <td>{tanggal}</td>
                    <td>{full_name}</td>
                    <td>{(!jam_in)? this.belumTersediaHTML : jam_in}</td>
                    <td>{(!jam_in)? this.belumTersediaHTML : jam_in}</td>
                    <td>{(!jam_out)? this.belumTersediaHTML : jam_out}</td>
                    <td>{(!jam_out)? this.belumTersediaHTML : jam_out}</td>
                    <td>{(work_type === "Full-Time")? '' : work_type + '(' + job_id + ')'}</td>
                    <td>{(work_type === "Full-Time")? '' : work_type}</td>
                    <td>{(!alamat_in)? this.belumTersediaHTML : alamat_out}</td>
                    <td>{(!alamat_out)? this.belumTersediaHTML : alamat_out}</td>
                    <td>{(!photo_in)? this.belumTersediaHTML : this._linkPhotoHTML(job_id, photo_in)}</td>
                    <td>{(!photo_out)? this.belumTersediaHTML : this._linkPhotoHTML(job_id, photo_out)}</td>
                </tr>))}
            </table>
        </div>
    }

    belumTersediaHTML = <span className="text-danger">Belum tersedia</span>;

    tableRef = React.createRef();

    sekarang = () => {
        const today = new Date();
        const Y = today.getFullYear();
        const m = ''+(today.getMonth()+1);
        const d = ''+today.getDate();
        return `${Y}-${m}-${d}`;
    }

    filterHTML = (id, employees) => {
        const {dari, sampai, selectedEmployeeFullTime, selectedEmployeePartTime} = this.state;
        const idEmployee = method.fixId((id == 'selectedEmployeePartTime')? selectedEmployeePartTime : selectedEmployeeFullTime);
        return <div className="my-2">
            <div className="row mb-2">
                <div className="col-md">
                    <label htmlFor={id}>Employee</label>
                    <Select name={id} id={id} options={employees} onChange={this.handleChangeSelectEmployee.bind(this)}/>
                </div>
                <div className="col-md">
                    <label htmlFor="dari">Dari</label>
                    <input className="form-control form-control-sm" type="date" value={dari} id="dari" name="dari" onChange={this.handleChange}/>
                </div>
                <div className="col-md">
                    <label htmlFor="sampai">Sampai</label>
                    <input className="form-control form-control-sm" type="date" value={sampai} id="sampai" name="sampai" onChange={this.handleChange}/>
                </div>
            </div>
            <button className="btn btn-primary btn-sm" onClick={(id == 'selectedEmployeePartTime')? this.filterParttime : this.filterFulltime}>Filter</button>&nbsp;
            <DownloadTableExcel
                filename={`${(id == 'selectedEmployeePartTime')? 'Part Time' : 'Full Time'} Attendance (${idEmployee}) ${this.sekarang()}`}
                sheet="Attendance"
                currentTableRef={this.tableRef.current}
            >
                <button className="btn btn-success btn-sm">Export</button>
            </DownloadTableExcel>
        </div>;
    }

    tabelHTML = (idEmployee, employees, datas) => {
        console.log(employees)
        return <div>
        {this.filterHTML(idEmployee, employees)}
        <div className="table-responsive">
            <table className="table table-hover table-bordered table-sm" id="tableUtama" style={{ width: '100%' }}>
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th>Date</th>
                        <th>Name</th>
                        <th>Shift In</th>
                        <th>Check In</th>
                        <th>Shift Out</th>
                        <th>Check Out</th>
                        <th>Job Name</th>
                        <th>Job Description</th>
                        <th>Location In</th>
                        <th>Location Out</th>
                        <th style={{width: '15%'}}>Photo In</th>
                        <th style={{width: '15%'}}>Photo Out</th>
                    </tr>
                </thead>
                <tbody>{this.renderTbody(datas)}</tbody>
            </table>
        </div>
    </div>
    } ;

    bodyHTML = () => {
    const {activeTab} = this.state;
    return <div>
            {this.navHTML(activeTab)}
            {this.renderTab(activeTab)}
            {this.renderExcel(activeTab)}
        </div>;
    }
    
    navHTML = activeTab => <Nav tabs>
        <Fragment>
            <NavItem>
                <NavLink className={classnames({ active: activeTab === 0 })} onClick={(e) => {this.toggle(0)}} style={{ borderRadius: '10px 10px 0 0' }}>
                    <p className="mb-0 font-weight-bold text-14">Full Time</p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={classnames({ active: activeTab === 1 })} onClick={(e) => {this.toggle(1)}} style={{ borderRadius: '10px 10px 0 0' }}>
                    <p className="mb-0 font-weight-bold text-14">Part Time</p>
                </NavLink>
            </NavItem>
        </Fragment>
    </Nav>;

    render() {
        return <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <div className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">List Attendance</h5>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="m-4">
                        <div className="card">
                            <div className="card-body">{this.bodyHTML()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>;
    }
}

export default CreateFullTime;