// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';

import classnames                                           from 'classnames';
import { Link }                                             from 'react-router-dom';

import imgProfile                                           from '../Asset/Icon/img-profile.png'

// Styling
import { Container, Row, Col, Card, CardBody, Button,
         Label, Modal, ModalHeader, ModalBody, Input, Table, Nav, NavLink,
         NavItem, TabContent, TabPane, UncontrolledDropdown, 
         DropdownToggle, DropdownMenu, DropdownItem }       from 'reactstrap';   
import { faCheckCircle, faCheck, faTimes , faList, faStar}  from '@fortawesome/free-solid-svg-icons'
import * as api                                             from '../Helpers/Api';
import * as helpersApiCms                                   from '../Helpers/HelpersApiCms';
import * as config                                          from '../Helpers/Config';
import * as method                                          from '../Helpers/Method';
import noData                                               from '../Asset/Icon/no-data.svg';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome'
import { faTimesCircle }                                    from '@fortawesome/free-solid-svg-icons'
import DateTimePicker                                       from 'react-datetime-picker';

class DetailApprovalJobs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '0',
            idEmployee:0,
            idJob:0,
            idCandidate:0,
            spesificName:"",
            interview_date:"",
            picName:"",
            picPhoneNumber:"",
            description:"",
            salary:"",
            rejectReason:"",
            workType:"",
            urlCv:"",
            dataJob:{},
            spesificDataCandidate:{},
            dataCandidates:[],
            dataWaitingApproveFullTime:[],
            dataShortListFullTime:[],
            dataInvitesFulltime:[],
            dataRejectedFullTime:[],
            dataCancelFulltime:[],
            dataWaitingApprovePartTime:[],
            dataShortListPartTime:[],
            dataInvitesParttime:[],
            dataRejectedPartTime:[],
            dataCancelParttime:[],
            dataFinishPartTime:[],
            dataCandidateWaitingInterview:[],
            dataFinishFullTime:[],
            dataCandidatePartTime:[],
            modalOpenApproveCandidate: false,
            modalOpenApproveCandidates: false,
            modalSuccessApprove: false,
            modalFinishInterview: false,
            modalDecline:false,
            modalApproveParttime:false,
            modalShortlist:false,
            modalFinishPartTime:false,
            modalLookCV: false,
            modalShowProfile: false,
            isReject:false,
            shifts: [],
            job_shift_id: null
        };
        this.toggleApproveCandidates = this.toggleApproveCandidates.bind(this);
        this.toggleSuccessApprove = this.toggleSuccessApprove.bind(this);
        this.toggleLookCV = this.toggleLookCV.bind(this);
        this.toggleShowProfile = this.toggleShowProfile.bind(this);
        this.closeToggleLookCV = this.closeToggleLookCV.bind(this);
    }

    componentDidMount= async () => {
        await this.getDataJob();
        await this.getCandidates();
        await this.getListShift();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    getListShift = async() => {
        const {dataJob} = this.state;
        const res = await api.getShift({params:{data:JSON.stringify({id_job: dataJob.id})}});
        const {data} = res.data;
        this.setState({shifts: data.shift});
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeDateInterview = (e) => {
        this.setState({ 
                        interview_date : e, 
                    });
    }

    getCandidates = async () => {
        const {workType, dataCancelParttime, dataRejectedPartTime, dataFinishPartTime, dataInvitesParttime, dataShortListPartTime, dataWaitingApprovePartTime, dataCancelFulltime, dataCandidatePartTime, dataShortListFullTime, dataFinishFullTime, dataRejectedFullTime, dataCandidateWaitingInterview, dataWaitingApproveFullTime, dataInvitesFulltime} = this.state;
        const idJob = localStorage.getItem('idJob');
        const newData = {key_api:config.KEY_API, work_type:workType};
        try{
            const res = await api.getCandidates(idJob,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            let i;
            if(data.length>0){
                for(i=0; i<data.length; i++){
                    // part time and full time
                    if((data[i]['status_candidate'] === "Waiting Approved") && (data[i]['is_shot_list'] === null)){
                        dataWaitingApproveFullTime.push(data[i]);
                        dataWaitingApprovePartTime.push(data[i]);
                    };
                    if((data[i]['is_shot_list'] === 1)){
                        dataShortListFullTime.push(data[i]);
                        dataShortListPartTime.push(data[i]);
                    };
                    if((data[i]['status_candidate'] === "Waiting Interview") || (data[i]['status_candidate'] === "Waiting Confirmation Employee") || (data[i]['status_candidate'] === "Waiting Confirmation Invite")){
                        dataInvitesFulltime.push(data[i]);
                    };
                    if((data[i]['work_status'] === 'Declined')){
                        dataCancelFulltime.push(data[i]);
                        dataCancelParttime.push(data[i]);
                    };
                    if((data[i]['is_approved_client'] === 0) || (data[i]['employee_response'] === 0)){
                        dataRejectedFullTime.push(data[i]);
                        dataRejectedPartTime.push(data[i]);
                    };
                    if((data[i]['status_candidate'] === "Finished")){
                        dataFinishFullTime.push(data[i]);
                    };
                    if((data[i]['work_status'] === "Done")){
                        dataFinishPartTime.push(data[i]);
                    };
                    if(((data[i]['work_status'] === null) || (data[i]['work_status'] === "On Proggress")) && (data[i]['status_candidate'] === "Accepted") || (data[i]['status_candidate'] ==="Waiting Confirmation Invite")){
                        dataInvitesParttime.push(data[i]);
                    };
        
                }
                this.setState({
                    dataWaitingApproveFullTime:dataWaitingApproveFullTime,
                    dataInvitesFulltime:dataInvitesFulltime,
                    dataCandidateWaitingInterview:dataCandidateWaitingInterview,
                    dataFinishFullTime:dataFinishFullTime,
                    dataRejectedFullTime:dataRejectedFullTime
                });
            }

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getDataJob = async () => {
        const idJob = localStorage.getItem('idJob');
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataJob(idJob,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { data } = res.data;
            this.setState({
                activeTab: data.work_type ==="Full-Time"?"0":"6",
                dataJob:data,
                workType:data.work_type,
                salary:data.part_time_salary===null?method.formatMoney(data.min_salary) + " - "+method.formatMoney(data.max_salary):method.formatMoney(data.part_time_salary)
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    toggleDeclineCandidates = async (item) => {
        this.setState({spesificName:item['full_name'], idCandidate: item['id_candidate']});
        // const { modalOpenApproveCandidates, idCandidate, interview_date } = this.state;
        this.setState(prevState => ({
            modalDecline: !prevState.modalDecline
        }));            
    }

    toggleModalApprovePartTime = data => {
        this.setState(prevState => ({
            modalApproveParttime: !prevState.modalApproveParttime
        }));
    }

    closeToggle = (modalType) => {
        if(modalType==="modalOpenApproveCandidate"){
            this.setState(prevState => ({
                modalOpenApproveCandidate: !prevState.modalOpenApproveCandidate
            }));            
        } else if(modalType==="modalOpenApproveCandidates"){
            this.setState(prevState => ({
                modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
            }));            
        } else if(modalType==="modalSuccessApprove"){
            this.setState(prevState => ({
                modalSuccessApprove: !prevState.modalSuccessApprove
            }));            
        } else if(modalType==="modalFinishInterview"){
            this.setState(prevState => ({
                modalFinishInterview: !prevState.modalFinishInterview
            }));            
        } else if(modalType==="modalDecline"){
            this.setState(prevState => ({
                modalDecline: !prevState.modalDecline
            }));            
        } else if(modalType==="modalApproveParttime"){
            this.setState(prevState => ({
                modalApproveParttime: !prevState.modalApproveParttime
            }));            
        } else if(modalType==="modalShortlist"){
            this.setState(prevState => ({
                modalShortlist: !prevState.modalShortlist
            }));            
        } else if(modalType==="modalFinishPartTime"){
            this.setState(prevState => ({
                modalFinishPartTime: !prevState.modalFinishPartTime
            }));            
        } else if(modalType==="modalShowProfile"){
            this.setState(prevState => ({
                modalShowProfile: !prevState.modalShowProfile
            }));            
        }
    }

    toggleShortListCandidates = async (item) => {
        this.setState({spesificName:item['full_name'], idCandidate: item['id_candidate']});
        // const { modalOpenApproveCandidates, idCandidate, interview_date } = this.state; haha
        this.setState(prevState => ({
            modalShortlist: !prevState.modalShortlist
        }));            
    }

    toggleApproveCandidates = async (item) => {
        const {dataJob} = this.state;
        if(dataJob['work_type']==="Full-Time"){
            this.setState({
                spesificName:item['full_name'],
                job_shift_id: item.job_shift_id,
                idCandidate: item['id_candidate'
            ]});
            const { modalOpenApproveCandidates, idCandidate, interview_date, picName, description, picPhoneNumber } = this.state;
            this.setState(prevState => ({
                modalOpenApproveCandidates: !prevState.modalOpenApproveCandidates
            }));
            if(modalOpenApproveCandidates === true ){
                const newData = {
                    key_api:config.KEY_API, 
                    interview_date:interview_date,
                    pic_name:picName,
                    pic_phone_number:picPhoneNumber,
                    description:description
                };
                try{
                    const res = await api.approveCandidate(idCandidate,{params:{data:JSON.stringify(newData)}});
                    if(res.status===200){
                        window.location.href = config.WEB_URL+"DetailApprovalJobs";
                    }
                } catch(err){
                    console.log("Error Response :",err)

                }
            }
        } else {
            this.setState({job_shift_id: item.job_shift_id});
            this.setState(prevState => ({
                modalApproveParttime: !prevState.modalApproveParttime
            }));
            this.setState({spesificName:item['full_name'], idCandidate: item['id_candidate']});

        }
    }

    approvePartTime = async () => {
        const {idCandidate, dataJob, job_shift_id} = this.state;
        console.log(job_shift_id);
        const res = await api.approveJobPartTime({params:{data:JSON.stringify({
            id_candidate: idCandidate,
            id_job: dataJob.id,
            job_shift_id : job_shift_id
        })}});
        const {error, message} = res.data;
        if(error) {
            alert(message);
        } else {
            window.location.href = `${config.WEB_URL}DetailApprovalJobs`;
        }
    }


    toggleSuccessApprove () {
        this.setState(prevState => ({
            modalSuccessApprove: !prevState.modalSuccessApprove
        }));
    }
    
    
    reject =  () => {
        this.setState({isReject:true});
    }

    saveRejectAfterInterview =  async () => {
        const { idCandidate, rejectReason } = this.state;
        const newData = {key_api:config.KEY_API, reject_reason:rejectReason, id_job:localStorage.getItem('idJob')};
        try{
            const res = await api.saveRejectAfterInterview(idCandidate,
                {params:{
                    data:JSON.stringify(newData)
                }        
            });
            const {status} =res;
            if(status===200){
                window.location.href = config.WEB_URL+"DetailApprovalJobs";
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    finishInterview = async () => {
        const { idCandidate } = this.state;
        const newData = {key_api:config.KEY_API, id_job:localStorage.getItem('idJob')};
        try{
            const res = await api.finishInterview(idCandidate,
                {params:{
                    data:JSON.stringify(newData)
                }        
            });
            const {status} =res;
            if(status===200){
                // this.setState(prevState => ({
                //     modalFinishInterview: !prevState.modalFinishInterview
                // }));
                window.location.href = config.WEB_URL+"DetailApprovalJobs";

            }

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    toggleFinishInterview = (item) => {
        this.setState({idCandidate:item['id_candidate']});
        this.setState(prevState => ({
            modalFinishInterview: !prevState.modalFinishInterview
        }));            
    }

    toggleFinishPartTime = (item)=>{
        this.setState({idJob:item['id_job'], idEmployee:item['id_employee'], idCandidate:item['id_candidate'], spesificName:item['full_name']});
        this.setState(prevState => ({
            modalFinishPartTime: !prevState.modalFinishPartTime
        }));            
    }

    closeToggleLookCV = () =>{
        this.setState(prevState => ({
            modalLookCV: !prevState.modalLookCV
        }));
    }

    toggleShowProfile = (item) =>{
        this.setState({spesificDataCandidate:item});
        this.setState(prevState => ({
            modalShowProfile: !prevState.modalShowProfile
        }));
    }

    toggleLookCV  = async (item) => {
        try{
            const res = await helpersApiCms.lookCv(item.id_employee);
            const {status, data} =res;
            if(status===200){
                this.setState({urlCv:data.data[0]['url']});
                this.setState(prevState => ({
                    modalLookCV: !prevState.modalLookCV
                }));
        
            }
        } catch(err){
            console.log("Error Response :",err)
        }

    }  

    finishPartTime = async () => {
        const { idJob, idEmployee } = this.state;
        const newData = {key_api:config.KEY_API, id_job:idJob, id_employee: idEmployee};
        try{
            const res = await api.finishPartTime(
                {params:{
                    data:JSON.stringify(newData)
                }        
            });
            const {status} =res;
            if(status===200){
                window.location.href = config.WEB_URL+"DetailApprovalJobs";
            }

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    shortListEmployee = async () => {
        const { dataJob } = this.state;
        const { idCandidate } = this.state;
        const newData = {key_api:config.KEY_API, id_job:localStorage.getItem('idJob'), work_type:dataJob.work_type};
        try{
            const res = await api.shortListEmployee(idCandidate,
                {params:{
                    data:JSON.stringify(newData)
                }        
            });
            const {status} =res;
            if(status===200){
                window.location.href = config.WEB_URL+"DetailApprovalJobs";
            }

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    declineJobApplication = async () => {
        const { dataJob } = this.state;
        const { idCandidate } = this.state;
        const newData = {key_api:config.KEY_API, id_job:localStorage.getItem('idJob'), work_type:dataJob.work_type};
        try{
            const res = await api.declineJobApplication(idCandidate,
                {params:{
                    data:JSON.stringify(newData)
                }        
            });
            const {status} =res;
            if(status===200){
                window.location.href = config.WEB_URL+"DetailApprovalJobs";
            }

        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {      
        const { 
                workType, 
                isReject, 
                salary, 
                dataJob, 
                spesificName, 
                interview_date,
                picName,
                picPhoneNumber,
                description,
                urlCv,
                spesificDataCandidate,
                dataWaitingApproveFullTime,
                dataInvitesFulltime,
                dataShortListFullTime,
                dataRejectedFullTime,
                dataCancelFulltime,
                dataFinishFullTime,
                dataWaitingApprovePartTime,
                dataShortListPartTime,
                dataInvitesParttime,
                dataRejectedPartTime,
                dataCancelParttime,
                dataFinishPartTime,
                modalFinishPartTime,
                modalShortlist,
                modalFinishInterview, 
                modalApproveParttime, 
                modalDecline
            } = this.state;    
                  
            let shift = []
                                            if(dataJob.absen !== undefined){
                                                shift =  dataJob.absen.split(",");
                                            }
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Detail Approval Jobs - {dataJob.work_type}</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Row>
                            <Col className="pl-0 pr-0">
                                <Col>
                                    <Row>
                                        {/* Candidates List Left */}
                                        <Col lg="3" className="mb-3">
                                            <Card>
                                                <CardBody>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4"  sm="3" md="6" lg="5">
                                                                <Label className="my-auto pt-2 pb-2">Id Pekerjaan</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{method.fixIdJob(localStorage.getItem('idJob'))}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4"  sm="3" md="6" lg="5">
                                                                <Label className="my-auto pt-2 pb-2">Job Type</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.work_type}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4"  sm="3" md="6" lg="5">
                                                                <Label className="my-auto pt-2 pb-2">Position</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.job_position}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4"  sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Location</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.work_address}, {dataJob.village_name}, {dataJob.sub_district_name}, {dataJob.regency_name}, {dataJob.province_name}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Salary (Rp)</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{salary}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Slot</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.quota} people</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Last Education</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.last_education}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="border-bottom pl-0 pr-0 text-14">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Experience</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">Minimal {dataJob.min_experience_year} Year</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="pl-0 pr-0 text-14 mb-3">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Total Aplicant (Approve)</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{dataJob.total_applicant != null ? dataJob.total_applicant+" people" : "-"} </Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {
                                                        workType ==="Part-Time" ?
                                                        <Col className="pl-0 pr-0 text-14 mb-3">
                                                            <Row>
                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                    <Label className="my-auto pt-2 pb-2">Total Reserve (Short List)</Label>
                                                                </Col>
                                                                <Col className="text-right">
                                                                    <Label className="my-auto pt-2 pb-2">{dataJob.total_reserve != null ? dataJob.total_reserve+" people" : "-"} </Label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        : null
                                                    }
                                                    <Col className="pl-0 pr-0 text-14 mb-3">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Posting Time</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                                <Label className="my-auto pt-2 pb-2">{method.formatDateDay(dataJob.posting_time)} </Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {
                                                        workType ==="Part-Time" ?
                                                        <Col className="pl-0 pr-0 text-14 mb-3">
                                                            <Row>
                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                    <Label className="my-auto pt-2 pb-2">Periode </Label>
                                                                </Col>
                                                                <Col className="text-right">
                                                                    <Label className="my-auto pt-2 pb-2">{dataJob.start_part_time_date} s/d {dataJob.finish_part_time_date} </Label>
                                                                  
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        : null
                                                    }
                                                         <Col className="pl-0 pr-0 text-14 mb-3">
                                                        <Row>
                                                            <Col xs="4" sm="3" md="6" lg="6">
                                                                <Label className="my-auto pt-2 pb-2">Shift</Label>
                                                            </Col>
                                                            <Col className="text-right">
                                                            { shift.map((it)=>
                                                                        <><li key={it}>{it}</li><br /></>
                                                                    )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    

                                                </CardBody>
                                            </Card>
                                            <Button tag={Link} to="/ApprovalJobs" className="btn-block btn-info" onClick={this.back}>Back</Button>
                                        </Col>
                                        {/* End Candidates List Left */}
                                        {/* Candidates List Right */}
                                        <Col>
                                            <Nav tabs>
                                                {
                                                    workType ==="Full-Time" ?
                                                        <Fragment>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '0' })}
                                                                        onClick={(e) => { this.toggle('0'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Result</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                                                                        onClick={(e) => { this.toggle('1'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Shortlist</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                                                                        onClick={(e) => { this.toggle('2'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Invite</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '3' })}
                                                                        onClick={(e) => { this.toggle('3'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Rejected</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '4' })}
                                                                        onClick={(e) => { this.toggle('4'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Cancel</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '5' })}
                                                                        onClick={(e) => { this.toggle('5'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Finish</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                        </Fragment>
                                                    : 
                                                        <Fragment>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '6' })}
                                                                        onClick={(e) => { this.toggle('6'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Result</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '7' })}
                                                                        onClick={(e) => { this.toggle('7'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Reserve</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '8' })}
                                                                        onClick={(e) => { this.toggle('8'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Invite</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '9' })}
                                                                        onClick={(e) => { this.toggle('9'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Rejected</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '10' })}
                                                                        onClick={(e) => { this.toggle('10'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Cancel</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === '11' })}
                                                                        onClick={(e) => { this.toggle('11'); }} style={{borderRadius:'10px 10px 0 0'}}><p className="mb-0 font-weight-bold text-14">Finish</p> 
                                                                </NavLink>
                                                            </NavItem>
                                                        </Fragment>
                                                }
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab} className="border-left border-right border-bottom text-12">
                                                {/* ------- Tab 0 --------*/}
                                                <TabPane tabId="0" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'200px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                            <th className="border-top-0">No</th>
                                                            <th className="border-top-0">Name Candidate</th>
                                                            <th className="border-top-0">CV</th>
                                                            {/* <th className="border-top-0">Profile Candidate</th> */}
                                                            <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataWaitingApproveFullTime.length === 0 ?
                                                                    <th colspan="6">
                                                                        <h2>No Data</h2>
                                                                    </th>
                                                                :
                                                                dataWaitingApproveFullTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th className="my-auto" scope="row">{index+1}</th>
                                                                                <td className="text-capitalize">{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                {/* <td className="text-blue"><Label className="bg-secondary rounded-pill text-white pl-3 pr-3 cursor-link" onClick={()=>this.toggleShowProfile(item)}>Show</Label></td> */}
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleShortListCandidates(item)}><FontAwesomeIcon icon={faList} className="mr-2"/> Shortlist</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 0 --------*/}
                                                {/* ------- Tab 1 --------*/}
                                                <TabPane tabId="1" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                            <th className="border-top-0">No</th>
                                                            <th className="border-top-0">Name Candidate</th>
                                                            <th className="border-top-0">Waktu Interview</th>
                                                            <th className="border-top-0">CV</th>
                                                            <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                        {
                                                                dataShortListFullTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataShortListFullTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td>{item.interview_date == null ? "-":method.formatDateDay(item.interview_date)}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 1 --------*/}

                                                {/* ------- Tab 2 --------*/}
                                                <TabPane tabId="2" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">Schedules Interview</th>
                                                                <th className="border-top-0">CV</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataInvitesFulltime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataInvitesFulltime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td>{method.formatDateAndTime(item.interview_date)}</td>
                                                                                <td> <a id="mylink" style={{color:'blue'}} className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {
                                                                                        workType==="Part-Time" ?
                                                                                            item.work_status ==="Done"?
                                                                                                method.formatDateTime(item.start_time)+" s/d " +method.formatDateTime(item.finish_time)
                                                                                            : "-" 
                                                                                        : null
                                                                                    }
                                                                                </td>
                                                                                {/* Full time button */}
                                                                                <td>
                                                                                    {
                                                                                        item.status_candidate ==="Waiting Interview" ? 
                                                                                            <Button className="btn-block btn-success mb-3" onClick={()=>this.toggleFinishInterview(item)}>Finish</Button>
                                                                                        : null
                                                                                    }   
                                                                                </td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 2 --------*/}
                                                {/* ------- Tab 3 --------*/}
                                                <TabPane tabId="3" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">CV</th>
                                                                <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataRejectedFullTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataRejectedFullTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleShortListCandidates(item)}><FontAwesomeIcon icon={faList} className="mr-2"/> Shortlist</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 3 --------*/}
                                                {/* ------- Tab 4 --------*/}
                                                <TabPane tabId="4" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">Schedules Interview</th>
                                                                <th className="border-top-0">CV</th>
                                                                <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataCancelFulltime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataCancelFulltime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td>{method.formatDateAndTime(item.interview_date)}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 4 --------*/}
                                                {/* ------- Tab 5 --------*/}
                                                <TabPane tabId="5" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">Time Interview</th>
                                                                <th className="border-top-0">CV</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataFinishFullTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataFinishFullTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td>{method.formatDateDay(item.interview_date)}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {
                                                                                        item.work_status ==="Done"?
                                                                                            method.formatDateTime(item.start_time)+" s/d " +method.formatDateTime(item.finish_time)
                                                                                        :  item.finish_time === null ?
                                                                                            method.formatDateTime(item.start_time)
                                                                                        : ((item.start_time ==="") && (item.finish_time===""))?
                                                                                        "-"
                                                                                        :null
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 5 --------*/}
                                                {/* ------- Tab 6 --------*/}
                                                <TabPane tabId="6" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                            <th className="border-top-0">No</th>
                                                            <th className="border-top-0">Name Candidate</th>
                                                            <th className="border-top-0">CV</th>
                                                            <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataWaitingApprovePartTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataWaitingApprovePartTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th className="my-auto" scope="row">{index+1}</th>
                                                                                <td className="text-capitalize">{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleShortListCandidates(item)}><FontAwesomeIcon icon={faList} className="mr-2"/> Reserve</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 6 --------*/}
                                                {/* ------- Tab 7 --------*/}
                                                <TabPane tabId="7" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                            <th className="border-top-0">No</th>
                                                            <th className="border-top-0">Name Candidate</th>
                                                            <th className="border-top-0">CV</th>
                                                            <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                        {
                                                                dataShortListPartTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataShortListPartTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 7 --------*/}

                                                {/* ------- Tab 8 --------*/}
                                                <TabPane tabId="8" className="bg-white">
                                                <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">CV</th>
                                                                {/* <th className="border-top-0">Attendance</th>
                                                                <th className="border-top-0" colspan="2">Photo Attendance</th>
                                                                <th className="border-top-0">Lokasi Absensi</th>
                                                                <th className="border-top-0">Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16" style={{overflow:'auto'}}>
                                                            {
                                                                dataInvitesParttime.length === 0 ?
                                                                <th colspan="10">
                                                                    <h2><center>No Data</center></h2>
                                                                </th>
                                                                :
                                                                dataInvitesParttime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                {/* <td>
                                                                                    {
                                                                                        ((item.start_time != null) && (item.finish_time != null))?
                                                                                            method.formatDateTime(item.start_time)+" s/d " +method.formatDateTime(item.finish_time)
                                                                                        :  ((item.start_time != null) && (item.finish_time===null)) ?
                                                                                            method.formatDateTime(item.start_time)
                                                                                        : ((item.start_time ===null) && (item.finish_time===null))?
                                                                                        "-"
                                                                                        :null
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    { 
                                                                                        item.start_time === null ?"-":
                                                                                        <a href={config.URL_IMAGE_ABSENCY+"absencyImage/"+item.start_absence_image} target="_blank">Photo CheckIn</a>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.finish_time === null ?"-":
                                                                                        <a href={config.URL_IMAGE_ABSENCY+"absencyImage/"+item.finish_absence_image} target="_blank">Photo CheckOut</a>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    Mulai :{
                                                                                        item.start_location === null ?"-":JSON.parse(item.start_location)[0] + ", " +JSON.parse(item.start_location)[1] 
                                                                                    }<br/>
                                                                                    Selesai :{
                                                                                        item.finish_location === null ?"-":JSON.parse(item.finish_location)[0] + ", " +JSON.parse(item.finish_location)[1] 
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        ((item.start_time != null) && (item.finish_time != null))?
                                                                                            <Button className="btn-block btn-blue-normal" onClick={()=>this.toggleFinishPartTime(item)}>Finish</Button>
                                                                                        :  (item.status_candidate ==="Waiting Confirmation Invite") ? 
                                                                                            "Menunggu Konfirmasi Kandidat"
                                                                                        : ((item.start_time ===null) && (item.finish_time===null))?
                                                                                        "-"
                                                                                        :null
                                                                                    }
                                                                                </td> */}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 8 --------*/}
                                                {/* ------- Tab 9 --------*/}
                                                <TabPane tabId="9" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">CV</th>
                                                                <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataRejectedPartTime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataRejectedPartTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    {/* Part time button */}
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleShortListCandidates(item)}><FontAwesomeIcon icon={faList} className="mr-2"/> Shortlist</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 9 --------*/}
                                                {/* ------- Tab 10 --------*/}
                                                <TabPane tabId="10" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">CV</th>
                                                                <th className="border-top-0">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataCancelParttime.length === 0 ?
                                                                <th colspan="6">
                                                                    <h2>No Data</h2>
                                                                </th>
                                                                :
                                                                dataCancelParttime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td className="text-blue"> <a id="mylink" className="cursor-link" onClick={()=>this.toggleLookCV(item)}>Detail</a></td>
                                                                                <td>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle caret className="btn-blue-normal">
                                                                                            Actions
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem tag={Link} Link to="#" onClick={()=>this.toggleApproveCandidates(item)}><FontAwesomeIcon icon={faCheck} className="mr-2"/> Invite</DropdownItem>
                                                                                            <DropdownItem tag={Link} to="#" onClick={()=>this.toggleDeclineCandidates(item)}><FontAwesomeIcon icon={faTimes} className="mr-2"/> Reject</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 10 --------*/}
                                                {/* ------- Tab 11 --------*/}
                                                <TabPane tabId="11" className="bg-white">
                                                    <Table hover responsive style={{minHeight:'217px'}}>
                                                        <thead>
                                                            <tr className="text-center text-16">
                                                                <th className="border-top-0">No</th>
                                                                <th className="border-top-0">Name Candidate</th>
                                                                <th className="border-top-0">Attendance</th>
                                                                <th className="border-top-0" colspan="2">Photo Attendance</th>
                                                                <th className="border-top-0"><center></center></th>
                                                                <th className="border-top-0">Lokasi Absensi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center text-16">
                                                            {
                                                                dataFinishPartTime.length === 0 ?
                                                                <th colspan="8">
                                                                    <h2><center>No Data</center></h2>
                                                                </th>
                                                                :
                                                                dataFinishPartTime.map((item, index) => {
                                                                    return(
                                                                            <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                <td>{item.full_name}</td>
                                                                                <td>
                                                                                    {
                                                                                        item.work_status ==="Done"?
                                                                                            method.formatDateTime(item.start_time)+" s/d " +method.formatDateTime(item.finish_time)
                                                                                        :  item.finish_time === null ?
                                                                                            method.formatDateTime(item.start_time)
                                                                                        : ((item.start_time ==="") && (item.finish_time===""))?
                                                                                        "-"
                                                                                        :null
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    { 
                                                                                        item.start_absence_image === null ?"-":
                                                                                        <a href={config.URL_IMAGE_ABSENCY+"absencyImage/"+item.start_absence_image} target="_blank">Photo CheckIn</a>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.finish_absence_image === null ?"-":
                                                                                        <a href={config.URL_IMAGE_ABSENCY+"absencyImage/"+item.finish_absence_image} target="_blank">Photo CheckOut</a>
                                                                                    }
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    Mulai :{
                                                                                        item.start_location === null ?"-":JSON.parse(item.start_location)[0] + ", " +JSON.parse(item.start_location)[1] 
                                                                                    }<br/>
                                                                                    Selesai :{
                                                                                        item.finish_location === null ?"-":JSON.parse(item.finish_location)[0] + ", " +JSON.parse(item.finish_location)[1] 
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                        </tbody>
                                                    </Table> 
                                                </TabPane>
                                                {/* ------- End Tab 11 --------*/}
                                            </TabContent>
                                        </Col>                                                       
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                    {/* Modal Approval Right */}
                    <Modal  isOpen={this.state.modalOpenApproveCandidates} toggle={this.toggleApproveCandidates} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalOpenApproveCandidates")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Approval Candidate</h5>
                            <Row>
                                <Col>
                                    <Label className="font-weight-bold d-block">Name of Candidate</Label>
                                    <Label className="d-block mb-4">{spesificName}</Label>

                                    <Label className="font-weight-bold d-block">Schedule Interview</Label>
                                    <Label className="d-block mb-4">{interview_date===""?"-":method.formatDateAndTime(interview_date)}</Label>
                                </Col>                                
                                <Col>
                                    <Label className="font-weight-bold d-block">Create Schedule Interview</Label>
                                    <DateTimePicker onChange={(e)=>this.handleChangeDateInterview(e)} value={interview_date} name="interview_date"/>
                                    {/* <Button disabled={interview_date=="-" ? true : false} className="btn-block btn-blue-normal" onClick={this.toggleSuccessApprove}>Set Appointment</Button> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label> PIC Name </Label>
                                </Col>
                                <Col>
                                    <Input name="picName" onChange={this.handleChange} placeholder="Masukkan nama pic " type="text"> </Input>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Label> PIC Phone Number </Label>
                                </Col>
                                <Col>
                                    <Input name="picPhoneNumber" onChange={this.handleChange} placeholder="Masukkan nomor hp pic" type="number"> </Input>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Label> Keterangan </Label>
                                </Col>
                                <Col>
                                    <Input name="description" onChange={this.handleChange} placeholder="Masukkan keterangan terkait interview" type="textarea"> </Input>
                                </Col>
                            </Row>
                            <br/>
                            <Col lg="6" className="mx-auto"><Button disabled={(interview_date=="") ||(picName=="") || (picPhoneNumber=="") || (description=="")? true : false} className="btn-block btn-warning" onClick={this.toggleApproveCandidates}>Done</Button></Col>
                        </ModalBody>
                    </Modal>
                    {/* End Modal Approval Right */}

                    {/* Modal Success Approve */}
                    <Modal  isOpen={this.state.modalSuccessApprove} toggle={this.toggleSuccessApprove} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalSuccessApprove")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Set Appointment Interview Success</h5>
                            <center><FontAwesomeIcon icon={faCheckCircle} className="text-success mb-5" style={{fontSize:'70px'}}/></center>
                            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.toggleSuccessApprove}>Ok</Button></Col>
                        </ModalBody>
                    </Modal>
                    {/* End Modal Success Approve */}
                    <Modal  isOpen={modalFinishInterview} toggle={this.finishInterview} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalFinishInterview")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Selesai Interview</h5>
                            <Row>
                                <p>Apakah anda akan menerima candidate ini ?</p>
                            </Row>
                            <Col lg="6" className="mx-auto">
                                <Button className="btn-block btn-warning" onClick={this.finishInterview}>Yes</Button>
                                {
                                    isReject ===false ? 
                                        <Button className="btn-block btn-warning" onClick={this.reject}>Decline</Button>
                                    : null
                                }
                            </Col>
                            <hr/>
                            {
                                isReject ===true ? 
                                <div>
                                    <Row>
                                        <Label> Alasan Menolak : </Label>
                                        <Input name="rejectReason" onChange={this.handleChange} placeholder="Masukkan alasan anda menolak candidate ini " type="textarea"> </Input>
                                    </Row>
                                    <p></p>
                                    <Button className="btn-block btn-warning" onClick={this.saveRejectAfterInterview}>Kirim Alasan</Button>
                                </div>
                                :null
                            }
                        </ModalBody>
                    </Modal>
                    {/* Modal Decline */}
                    <Modal  isOpen={modalDecline} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalDecline")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Apakah anda yakin untuk menolak lamaran pekerjaan dari {spesificName} ?</h5>
                            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.declineJobApplication}>Yes, Reject</Button></Col>
                        </ModalBody>
                    </Modal>
                    {/* Modal Decline */}
                    <Modal  isOpen={modalShortlist} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalShortlist")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Apakah anda yakin ?</h5>
                            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.shortListEmployee}>Yes</Button></Col>
                        </ModalBody>
                    </Modal>
                    {this.modalApprovePartTimeHTML()}
                    <Modal  isOpen={modalFinishPartTime} backdrop="static">
                        <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalFinishPartTime")}/>
                        <ModalBody>
                            <h5 className="text-center mb-5">Apakah anda yakin {spesificName} telah menyelesaikan pekerjaannya ?</h5>
                            <Col lg="6" className="mx-auto"><Button className="btn-block btn-warning" onClick={this.finishPartTime}>Yes</Button></Col>
                        </ModalBody>
                    </Modal>

                    {/* Modal Look CV */}
                    <Modal isOpen={this.state.modalLookCV} toggle={()=>this.closeToggleLookCV()}>
                        <ModalBody className="text-center">
                            <p className="font-weight-bolder text-20 mb-2">Detail</p>
                            <p className="text-18 p-4 mb-4">See detail candidates</p>
                            <Col><Button className="btn-block btn-info mb-1" href={urlCv} target="_blank">Detail</Button></Col>
                            <Col><Button className="btn-block btn-warning" onClick={()=>this.closeToggleLookCV()}>Close</Button></Col>
                        </ModalBody>
                    </Modal>
                    {/* End Modal Look CV */}

                    {/* Modal Profile Candidates */}
                    <Modal isOpen={this.state.modalShowProfile} toggle={this.toggleShowProfile}>
                        <ModalBody>
                            <div style={{height:'430px', overflow:'scroll'}} className="mb-4">
                                <Col className="border-bottom pl-0 pr-0">
                                    <Col>
                                        <Row>
                                            <Col lg="auto" className="text-center">
                                                <img src={imgProfile} alt="" className="border border-info rounded-circle p-1 mb-4" width="120px"/>
                                            </Col>
                                            <Col className="text-14 my-auto">
                                            <p className="font-weight-bold mb-0">Name</p>  
                                            <p className="font-weight-bold mb-0">Category Position</p>  
                                            <p className="font-weight-bold mb-0">AVI012211221 (id user if any)</p>  
                                            <Row>
                                                <Col lg="auto">
                                                    <Label className="text-green mb-0"></Label>  
                                                </Col>
                                            </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col className="text-14 mt-3">
                                    <h5 className="font-weight-bolder mb-0">Personal Info</h5>
                                    <hr/>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Full Name</p></Col>
                                        <Col><p className="text-12 text-right">Afdhal Yaned</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Gender</p></Col>
                                        <Col><p className="text-12 text-right">Male</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Religion</p></Col>
                                        <Col><p className="text-12 text-right">Islam</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Body Height</p></Col>
                                        <Col><p className="text-12 text-right">170 cm</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Body Weight</p></Col>
                                        <Col><p className="text-12 text-right">56 Kg</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Location</p></Col>
                                        <Col><p className="text-12 text-right">Jakarta</p></Col>
                                    </Row>
                                    <hr className="mt-1"/>
                                </Col>
                                <Col className="text-14 mt-3">
                                    <h5 className="font-weight-bolder mb-0">Education</h5>
                                    <hr/>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Last Education</p></Col>
                                        <Col><p className="text-12 text-right">SMA</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Name of Institution</p></Col>
                                        <Col><p className="text-12 text-right">SMA 3 Jakarta</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Major</p></Col>
                                        <Col><p className="text-12 text-right">IPA</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Graduate Year</p></Col>
                                        <Col><p className="text-12 text-right">2012</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Score GPA</p></Col>
                                        <Col><p className="text-12 text-right">4.00</p></Col>
                                    </Row>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Location</p></Col>
                                        <Col><p className="text-12 text-right">Jakarta</p></Col>
                                    </Row>
                                    <hr className="mt-1"/>
                                </Col>
                                <Col className="text-14 mt-3">
                                    <h5 className="font-weight-bolder mb-0">Expertise</h5>
                                    <hr/>
                                    <Row>
                                        <Col><p className="text-12 font-weight-bold">Expertise 1</p></Col>
                                        <Col className="text-right"><FontAwesomeIcon icon={faStar} className="text-primary mr-2"/></Col>
                                    </Row>
                                    <hr className="mt-1"/>
                                </Col>
                                <Col className="border-bottom text-14 mt-3">
                                    <h5 className="font-weight-bolder mb-0">Experience</h5>
                                    <hr/>
                                    <Row>
                                        <Col md="4"><p className="text-12 font-weight-bold">Experience 1</p></Col>
                                        <Col md="5"><p className="text-12 text-justify">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet</p></Col>
                                        <Col md="3"><p className="text-12 text-right">2010 - 2014</p></Col>
                                    </Row>
                                </Col>
                            </div>
                            <Col className="pl-0 pr-0"><Button className="btn-block btn-warning" onClick={this.toggleShowProfile}>Close</Button></Col>
                        </ModalBody>
                    </Modal>
                    {/* End Modal Profile Candidates */}
                </div>
            </Fragment>
        );   
    }

    modalApprovePartTimeHTML = () => {
        const {modalApproveParttime, spesificName, shifts, job_shift_id} = this.state;
        return <Modal  isOpen={modalApproveParttime} toggle={()=>this.closeToggle("modalApproveParttime")}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={()=>this.closeToggle("modalApproveParttime")}/>
            <ModalHeader>Accept Employee Part Time</ModalHeader>
            <ModalBody>
                <p>Are you sure to accept applications for part time jobs from {spesificName}?</p>
                <div className="table-responsive">
                    <table className="table table-hover table-bordered table-sm">
                        <thead className="thead-dark">
                            <tr className="text-center">
                                <th style={{width: '10%'}}></th>
                                <th>Shift In</th>
                                <th>Shift Out</th>
                                <th>Quota</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(shifts.length == 0)? <tr><td colspan={4} className="text-muted">~ Data No Found ~</td></tr> : this.shiftHTML()}
                        </tbody>
                    </table>
                </div>
                <button className="btn btn-warning btn-sm font-weight-bold float-right" onClick={this.approvePartTime}>Yes, Approve</button>
            </ModalBody>
        </Modal>;
    }

    handleChangeJobShiftId = job_shift_id => {
        this.setState({job_shift_id});
    }

    shiftHTML = () => {
        const {shifts, job_shift_id} = this.state;
        return shifts.map(shift => <tr onClick={() => this.handleChangeJobShiftId(shift.job_shift_id)}>
            <td className="text-center"><input type="radio" checked={(shift.job_shift_id == job_shift_id)? true : false} /></td>
            <td>{shift.in}</td>
            <td>{shift.out}</td>
            <td>{shift.kuota} person</td>
        </tr>);
    }
};

export default DetailApprovalJobs;