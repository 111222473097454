// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
// Import React
import { Link }                                             from 'react-router-dom';

import LogoAvisha                                           from '../../Asset/Logo/avisha-logo-full.svg';
import gPlayAvisha                                          from '../../Asset/Icon/google-play.svg';

// Styling
import { Row, Col }                                         from 'reactstrap';   
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin }              from '@fortawesome/free-brands-svg-icons'


class Footer extends React.Component {
    render() {                                     
        return (          
            <Fragment>                        
                <div className="footer bg-blue">
                    <Col className="text-justify mx-auto p-4">
                        <hr style={{backgroundColor:'white', opacity:'0.5'}}/>
                        <Row>
                            <Col xs="12" sm="6" md="8" lg="9" xl="10">
                                <Col className="p-0" xs="auto" sm="9" md="3" lg="2" xl="2">
                                    <Link to="/"><div className="bg-white rounded mb-3"><center><img src={LogoAvisha} className="p-3" alt="Logo Avisha" width="130px" hspace="10"/></center></div></Link>
                                </Col>
                            </Col>
                            <Col className="my-auto">
                                <Col className="p-0 ml-auto" xs="12" sm="9" md="8" lg="10" xl="12">
                                    <Link to="#"><img src={gPlayAvisha} alt="download avisha on play store" className="mb-3"/></Link>
                                    <Row className="text-center">
                                        <Col xs="4"><a target="_blank" href="https://id.linkedin.com/in/avisha-citra-mandiri-758a1516b"><FontAwesomeIcon icon={faLinkedin} className="text-24 text-white mr-3"/></a></Col>
                                        <Col xs="4"><a target="_blank" href="https://www.facebook.com/avishagroup/"><FontAwesomeIcon icon={faFacebook} className="text-24 text-white ml-3 mr-3"/></a></Col>
                                        <Col xs="4"><a target="_blank" href="https://www.instagram.com/avishagroup/"><FontAwesomeIcon icon={faInstagram} className="text-24 text-white ml-3"/></a></Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        <hr style={{backgroundColor:'white', opacity:'0.5'}}/>
                        <Row>
                            <Col className="text-12 mb-0">
                                <Row>
                                    <Col xs="auto" md="2" lg="auto"><a href="https://avisha.co.id/os/vision-mission.php" target="_blank" className="text-white">About</a></Col>
                                    <Col xs="auto" md="2" lg="auto"><a href="https://avisha.co.id/os/contact-us.php" className="text-white">Contact Us</a></Col>
                                    <Col xs="auto" md="2" lg="auto"><a href="https://avisha.co.id/os/our-clients.php" target="_blank" className="text-white">Our Client</a></Col>
                                    {/* <Col xs="auto" md="2" lg="auto"><Link to="#" className="text-white">Term of Use</Link></Col> */}
                                    <Col xs="auto" md="2" lg="auto"><Link to="#" className="text-white">Privacy & Policy</Link></Col>
                                    <Col xs="12" sm="auto" className="text-white text-center mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">&copy; 2020 copyright</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Fragment>
        );   
    }
};

export default Footer;