// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';
import { Link }                                             from 'react-router-dom';

// Styling
import { Container, Row, Col, Label, Button, CardFooter,
         Card, CardHeader, CardBody }                       from 'reactstrap';   
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faTimesCircle, 
         faShoppingCart, faExchangeAlt, faList }            from '@fortawesome/free-solid-svg-icons';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from '../Helpers/Method';
import NoCandidates                                         from '../Asset/Icon/AV-icon-candidate-white.svg';
class DashboardClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          id:0,
          dataClient:{},
          dataCandidate:[],
          isHavePaymentTransaction:false,
          partTimeFinanceBalance:"",
        };
    };

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getDataClient();
        await this.getPaymentHistory();
        await this.getDataCandidate();

    }
    
    getDataCandidate = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getDataCandidate(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const {data} = res.data;
            if(data){
                const finalDataCandidate = [];
                let i;
                if(data){
                    for(i=0;i<data.length;i++){
                        let newFormatDate = new Date(data[i]['interview_date']);
                        let dateNow = new Date();
                        if(newFormatDate >= dateNow){
                            finalDataCandidate.push(data[i]);
                        }
                    }
                }
                this.setState({ 
                    // isHavePaymentTransaction:true,
                    dataCandidate:finalDataCandidate
                });
            } else {
                this.setState({ 
                    // isHavePaymentTransaction:false
                });
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getPaymentHistory = async () => {
        try{
            const newData = ({
                key_api:config.KEY_API
            });
            const res = await api.getPaymentHistory(this.state.id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const {data} = res.data;
            if(data){
                this.setState({ 
                    isHavePaymentTransaction:true
                });
            } else {
                this.setState({ 
                    isHavePaymentTransaction:false
                });
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
      }
      
    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })

            const { data } = res.data;
            console.log("tets", data)
            await this.setState({
                dataClient:data,
                partTimeFinanceBalance:method.formatRupiah(data.part_time_finance_balance)
            });

        } catch(err){
            console.log("Error Response :",err)
        }
    }
    
    buy = () => {
        const {is_active} = this.state.dataClient;
        if(is_active === null){
            alert("Lengkapi terlebih dahulu data anda, supaya admin kami dapat memverifikasi data anda");
        } else if (is_active===0){
            alert("Akun ini telah di block oleh admin");
        } else {
            window.location.href = config.WEB_URL+"Buy";
        }
    }

    render() {                                     
        const { 
            dataClient,
            isHavePaymentTransaction,
            partTimeFinanceBalance,
            dataCandidate
        } = this.state;
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row>
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Dashboard</h5>
                                    <p className="text-12 font-weight-bolder mb-0 ">Halo, <span className="text-capitalize">{dataClient.company_name}</span> welcome to Avisha Human Resource Solutions</p>
                                </Col>
                                <Col className="my-auto text-right">
                                    {
                                        dataClient.is_active === null ?
                                            <div>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="text-warning"/>
                                                <Label className="ml-2 font-weight-bolder text-warning my-auto">Waiting Verification</Label>
                                            </div>                                        
                                        : dataClient.is_active === 1 ?
                                            <div>
                                                <FontAwesomeIcon icon={faCheck} className="text-green"/>
                                                <Label className="ml-2 font-weight-bolder text-green my-auto">Verified</Label>
                                            </div>
                                        : 
                                            <div>
                                                <FontAwesomeIcon icon={faTimesCircle} className="text-danger"/>
                                                <Label className="ml-2 font-weight-bolder text-danger my-auto">Block</Label>
                                            </div>                                                                                
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col className="mb-3 pl-0 pr-0">
                            <Row>
                                <Col xs="12" md="4"><Button onClick={this.buy} disabled={isHavePaymentTransaction===false ? false : true} className="btn-block btn-blue-normal p-5 mb-4 text-16 font-weight-bold text-center"><FontAwesomeIcon icon={faShoppingCart} className="text-24"/> <br/> Buy Credit</Button></Col>
                                <Col xs="12" md="4"><Button tag={Link} to="/PaymentHistory" className="btn-block btn-success p-5 mb-4 text-16 font-weight-bold text-center"><FontAwesomeIcon icon={faExchangeAlt} className="text-24"/> <br/> History Transaction</Button></Col>
                                <Col xs="12" md="4"><Button tag={Link} to="/ApprovalJobs" className="btn-block btn-warning p-5 mb-4 text-16 font-weight-bold text-white text-center"><FontAwesomeIcon icon={faList} className="text-24"/> <br/> List Candidate</Button></Col>
                            </Row> 
                        </Col>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                <Card className="mb-4">
                                    <CardHeader>
                                        <Row>
                                            <Col md="10" className="my-auto">
                                                <h6 className="font-weight-bolder my-auto">Upcoming Interview Schedules</h6>
                                            </Col>
                                            <Col className="my-auto text-right">
                                                <Label className="my-auto font-weight-bold">{dataCandidate.length}</Label>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody style={{height:'454px', overflowY:'scroll'}}>
                                        {
                                            dataCandidate.length > 0 ?
                                                dataCandidate.map((item) => 
                                                    <Col className="box-shadow pt-2 pb-2 mb-3">
                                                        <Row>
                                                            <Col xs="8" sm="8" md="9" lg="8" xl="8" >
                                                                <Col className="my-auto">
                                                                    <p className="text-12 text-green mb-2">{method.formatDateDay(item.interview_date)}</p>
                                                                    <p className="text-12 font-weight-bold d-block mb-0">{item.full_name} {"-"} IT Developer Web App</p>
                                                                </Col>
                                                                <Col className="my-auto">
                                                                    <p className="text-12 d-block mb-0 text-justify">{item.work_address +", "+item.village_name+", "+item.sub_district_name+", "+item.regency_name+", "+item.province_name}</p>
                                                                </Col>
                                                            </Col>
                                                            <Col xs="4" sm="4" md="3" lg="4" xl="4" className="my-auto">
                                                                <Label className="d-block text-12 text-center font-weight-bolder mb-0">Status</Label>
                                                                {
                                                                    item.status_candidate === "Waiting Confirmation Employee" ?
                                                                        <p className="text-12 text-center text-white font-weight-bolder bg-secondary rounded-pill mb-0">Waiting Confirmation</p>
                                                                    : 
                                                                        <p className="text-12 text-center text-white font-weight-bolder bg-success rounded-pill mb-0">Waiting Interview</p>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                            : 
                                            <Col className="text-center">
                                                <img src={NoCandidates} width="100px" alt="no-list-candidate" className="mt-3" />
                                                <p className="font-weight-bolder text-14 mb-0">
                                                    Not yet schedules interview
                                                </p>
                                                <p className="text-12">
                                                    Find your candidates now
                                                </p>
                                                <Col xs="12" sm="12" md="6" lg="6" className="mx-auto">
                                                    <Link to="/find-talent" className="text-decoration-none"><Button className="btn-blue btn-block" id="findtalent" name="find-talent">Find Candidates</Button></Link>
                                                </Col>
                                            </Col>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="mb-4">
                                    <CardHeader><h6 className="muli-font font-weight-bolder my-auto">Balance Credit</h6></CardHeader>
                                    <CardBody style={{height:'400px', overflowY:'scroll'}}>
                                        <Col className="box-shadow pt-3 pb-3 text-14 mb-3">
                                            <Label className="d-block font-weight-bold text-16">Full Time</Label>
                                            <Row>
                                                <Col lg="4" className="text-14">Job Posting</Col>
                                                <Col className="text-14">{":"} {(dataClient.full_time_quota === null) || (dataClient.full_time_quota === 0) ? "-" : dataClient.full_time_quota}</Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" className="text-14">Find Talent</Col>
                                                <Col className="text-14">{":"} {(dataClient.talent_search_quota === null) || (dataClient.talent_search_quota === 0) ? "-" : dataClient.talent_search_quota}</Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" className="text-14">Expired Quota</Col>
                                                <Col className="text-14">{":"} {dataClient.expired_date_quota === null ? "-":method.formatDateDay(dataClient.expired_date_quota)}</Col>
                                            </Row>
                                        </Col>
                                        <Col className="box-shadow pt-3 pb-3 text-14 mb-3">
                                            <Label className="d-block font-weight-bold text-16">Part Time</Label>
                                            <Row>
                                                <Col lg="4" className="text-14">Credit</Col>
                                                <Col className="text-14">{":"} {partTimeFinanceBalance}</Col>
                                            </Row>
                                        </Col>
                                    </CardBody>
                                    <CardFooter>
                                        <Col lg="6" className="pl-0 pr-0 mx-auto align-bottom"><Button onClick={this.buy} disabled={isHavePaymentTransaction===false ? false : true} className="btn-block btn-warning p-1 muli-font text-white">Buy Packaged</Button></Col>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default DashboardClient;