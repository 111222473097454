// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import HeaderUser from './ComponentUser/HeaderUser';


import { Link } from 'react-router-dom';
import noData from '../Asset/Icon/no-data.svg';

// Styling
import {
    Container, Row, Col, Card, CardBody, Button,
    Label, Modal, ModalBody, Input, InputGroup,
    InputGroupAddon, Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import * as method from '../Helpers/Method';
import * as authHelperMethods from '../Helpers/AuthHelperMethods';
import logoCompany from '../Asset/Logo/logo-company.png'
import logoAvisha from '../Asset/Logo/avisha-logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ParttimeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpenJobParttime: false,
            modalLoading: false,
            listPartTimeJob: [],
            spesificData: {},
            salaryRange: 0,
            listRegency: [],
            listJobPosition: [],
            location: 'all',
            jobPosition: 'all',
            gender: "",
            searchString: '',
            spesificData: {},
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
            modalConfirm: false,
            dataConfirm: {},
            shift_id: 0
        };
        this.toggleOpenJobParttime = this.toggleOpenJobParttime.bind(this);
        // this.toggleLoading = this.toggleLoading.bind(this);
    }

    componentDidMount = async () => {
        await this.getAllJobs();
        await this.getAllRegency();
        await this.getAllJobPositions();
        await this.getDataEmployee();
    }

    toggleLoading = () => {
        this.setState(prevState => ({
            modalLoading: !prevState.modalLoading
        }));
    }

    getDataEmployee = async (e) => {
        const newData = { key_api: config.KEY_API };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            // this.toggleLoading();
            const res = await api.profileEmployee(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                // this.toggleLoading();
                this.setState({ gender: data.dataProfile.gender })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }


    toggleOpenJobParttime = async (dataJob) => {
        await this.setState({ spesificData: dataJob });
        this.setState(prevState => ({
            modalOpenJobParttime: !prevState.modalOpenJobParttime
        }));
    }

    getAllJobs = async (e) => {
        const newData = {
            key_api: config.KEY_API,
            work_type: "Part-Time"
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            // this.toggleLoading();
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                // this.toggleLoading();
                this.setState({ listPartTimeJob: data.dataAvailableJobs });
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    applyJob = async (idJob) => {
        const {shift_id} = this.state;
        const newData = {
            key_api: config.KEY_API,
            id_job: idJob,
            job_shift_id: shift_id
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            this.toggleLoading();
            const res = await api.applyJob(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            if (res.status === 200) {
                this.getAllJobs();
                this.notify();
                this.toggleLoading();
            }
        } catch (err) {
            this.toggleLoading();
            alert("Error, Please check your connection or status then try refresh your page");
            //   console.log("Error Response :",err)
        }
    }

    apply = async() => {
        const {shift_id, dataConfirm} = this.state;
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            this.toggleLoading();
            const res = await api.applyPartTime(idEmployee, {
                params: {data: JSON.stringify({
                    idEmployee,
                    id_job: dataConfirm.id,
                    job_shift_id: shift_id
                })}
            });
            if (res.status === 200) {
                this.getAllJobs();
                this.notify();
                this.toggleLoading();
                this.toggleModalConfirm();
                this.setState({modalOpenJobParttime: false});
            }
        } catch (err) {
            this.toggleLoading();
            alert("Error, Please check your connection or status then try refresh your page");
        }
    }

    filterJobs = async (category, value) => {
        const { location, salaryRange, jobPosition } = this.state
        const newData = {
            key_api: config.KEY_API,
            work_type: "Part-Time",
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            this.toggleLoading();
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            // console.log(res);
            if (status === 200) {
                this.toggleLoading();
                if ((location == "all") && (salaryRange == 0) && (jobPosition == "all")) {
                    this.setState({ listPartTimeJob: data.dataAvailableJobs })
                } else {
                    const listFilterCategory = [];
                    const listIdLoanLocation = [];
                    const listIdLoanSalary = [];
                    const listIdLoanPosition = [];
                    if (category == "location") {
                        listFilterCategory.push("1");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if (data.dataAvailableJobs[i].regency_name === value) {
                                listIdLoanLocation.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    } else if (category == "salary") {
                        listFilterCategory.push("2");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if (value < parseInt(data.dataAvailableJobs[i].part_time_salary)) {
                                listIdLoanSalary.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    }
                    else if (category == "position") {
                        listFilterCategory.push("3");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if (data.dataAvailableJobs[i].position_type === value) {
                                listIdLoanPosition.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    }

                    const allListFilter = [...listIdLoanLocation, ...listIdLoanSalary, ...listIdLoanPosition]
                    var counts = {};
                    allListFilter.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

                    const finalListFilter = []
                    Object.entries(counts).map(([key, value]) => {
                        if (value == listFilterCategory.length) {
                            for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                                if (data.dataAvailableJobs[i].id == key) {
                                    finalListFilter.push(data.dataAvailableJobs[i])
                                }
                            }
                            this.setState({ listPartTimeJob: finalListFilter })
                        }
                    })
                    if (finalListFilter.length === 0) {
                        this.setState({ listPartTimeJob: finalListFilter })
                    }
                }
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    handleChoose = async (element) => {
        await this.setState({ location: element.label });
        this.filterJobs("location", element.label);
    }

    handleChangeSearch = (e) => {
        this.setState({ searchString: e.currentTarget.value });
    }

    sortJob = async (category) => {
        const newData = {
            key_api: config.KEY_API,
            work_type: "Part-Time",
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                if (category == "Slot") {
                    const slotArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => a.quota > b.quota ? 1 : -1)
                        .map((item) =>
                            slotArray.push(item)
                        );
                    this.setState({ listPartTimeJob: slotArray })
                } else if (category == "Periode") {
                    const periodArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => new Date(a.part_time_date) - new Date(b.part_time_date))
                        .map((item) =>
                            periodArray.push(item)
                        );
                    this.setState({ listPartTimeJob: periodArray })
                } else if (category == "Highest Salary") {
                    const maxSalaryArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => parseInt(b.part_time_salary) > parseInt(a.part_time_salary) ? 1 : -1)
                        .map((item) =>
                            maxSalaryArray.push(item)
                        );
                    this.setState({ listPartTimeJob: maxSalaryArray })
                } else if (category == "Lowest Salary") {
                    const minSalaryArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => parseInt(a.part_time_salary) > parseInt(b.part_time_salary) ? 1 : -1)
                        .map((item) =>
                            minSalaryArray.push(item)
                        );
                    this.setState({ listPartTimeJob: minSalaryArray })
                }
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    handleChangeSortPeriod = async (e) => {
        const { isSortingPeriod } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: !isSortingPeriod,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
        })
    }

    handleChangeSortSlot = async (e) => {
        const { isSortingSlot } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: !isSortingSlot,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
        });
    }

    handleChangeSortMax = async (e) => {
        const { isSortingMaxSalary } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: !isSortingMaxSalary,
            isSortingMinSalary: false,
        })
    }

    handleChangeSortMin = async (e) => {
        const { isSortingMinSalary } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: !isSortingMinSalary,
        })
    }

    notify() {
        toast("Submit application is successful !")
    }

    handleChangeRangeSalary = (e) => {
        this.setState({ salaryRange: e.target.value });
        this.filterJobs("salary", e.target.value);
    }

    handlePosition = async (position) => {
        // console.log(position);
        await this.setState({ jobPosition: position });
        this.filterJobs("position", position);
    }

    getAllRegency = async (e) => {
        const newData = {
            key_api: config.KEY_API,
        };
        try {
            const res = await api.getAllRegencies({
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({ listRegency: data.dataAllRegencies.map((k) => ({ value: k.id, label: k.regency_name })) })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    getAllJobPositions = async (e) => {
        const newData = {
            key_api: config.KEY_API,
        };
        try {
            const res = await api.getJobPosition({
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({ listJobPosition: data.data })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    toggleModalConfirm = async(job = null) => {
        this.setState(prevState => ({
            modalConfirm: !prevState.modalConfirm
        }));
        if(job.id) {
            const res = await api.getShift({
                params: { data: JSON.stringify({id_job: job.id}) }
            });
            const {data} = res.data;
            this.setState({dataConfirm: data});
            if(data.shift.length > 0) this.setState({shift_id: data.shift[0]['job_shift_id']});
        }
    }

    render() {
        const { gender, spesificData, listPartTimeJob, searchString, listRegency, listJobPosition, salaryRange, isSortingMaxSalary, isSortingMinSalary, isSortingPeriod, isSortingSlot } = this.state;
        const lowercasedFilter = searchString.toLowerCase();
        const filteredData = listPartTimeJob.filter(item => {
            return Object.keys(item).some(key =>
                item['company_name'].toLowerCase().includes(lowercasedFilter)
            );
        });
        return (
            <Fragment>
                <HeaderUser />
                <ToastContainer />
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Part time Job </h5>
                                    <p className="text-12 mb-0">Showing part time job</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col>
                        <Row>
                            <Col xs="12" sm="12" md="4" lg="3" className="pl-0 pr-0">
                                {/* <Filter/> */}
                                <Col className="bg-white rounded mb-3 p-3">
                                    <Col className="mb-3">
                                        <Label className="d-block text-14 font-weight-bolder mb-1">Search</Label>
                                        <InputGroup>
                                            <Input
                                                type="text"
                                                id="search"
                                                placeholder="Company Name"
                                                className="search input-box-grey text-12"
                                                value={searchString}
                                                onChange={this.handleChangeSearch} />
                                            <InputGroupAddon addonType="append">
                                                <Button id="btn-FindTalent" name="btn-find-talent" className="btn-blue" ><FontAwesomeIcon icon={faSearch} /></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </Col>
                                    <Col className="mb-3">
                                        {/* <Label className="d-block text-14 font-weight-bolder mb-1">Location</Label> */}
                                        <Label className="d-block text-14 font-weight-bolder mb-1" for="kotaAddress">Location</Label>
                                        <Select
                                            name="form-field-name"
                                            options={listRegency}
                                            onChange={this.handleChoose.bind(this)}
                                        />
                                    </Col>
                                    <Col>
                                        <Label className="d-block text-14 font-weight-bolder mb-1">Salary Range {method.formatRupiah(salaryRange)}</Label>
                                        <Input type="range" id="rangeSalary" className="rangeSalary" onChange={this.handleChangeRangeSalary} defaultValue={0} min="0" max="50000000" step="10000" />
                                    </Col>
                                </Col>
                                <Col className="bg-white rounded mb-3 p-3">
                                    <Col className="mb-3">
                                        <Label className="d-block text-14 font-weight-bolder mb-1">Sort By:</Label>
                                        <div className="spacing-checkbox text-12">
                                            <Input type="checkbox" id="Periode" checked={isSortingPeriod === false ? false : true} className="checkboxLocation check-box" value="Periode" onChange={this.handleChangeSortPeriod} /> Periode<br />
                                            <Input type="checkbox" id="Slot" checked={isSortingSlot === false ? false : true} className="checkboxLocation check-box" value="Slot" onChange={this.handleChangeSortSlot} /> Slot<br />
                                            <Input type="checkbox" id="Highest Salary" checked={isSortingMaxSalary === false ? false : true} className="checkboxLocation check-box" value="Highest Salary" onChange={this.handleChangeSortMax} /> Highest Salary<br />
                                            <Input type="checkbox" id="Lowest Salary" checked={isSortingMinSalary === false ? false : true} className="checkboxLocation check-box" value="Lowest Salary" onChange={this.handleChangeSortMin} /> Lowest Salary<br />
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                            <Col xs="12" sm="12" md="8" lg="9" className="pl-0 pr-0">
                                <Col className="mb-3">
                                    <p className="font-weight-bolder mb-2">Job Positions</p>
                                    <Row className="pl-2 pr-2">
                                        {listJobPosition.map((position) => {
                                            return (
                                                <Button
                                                    className="text-14 p-2 category-job btn-blue m-1"
                                                    onClick={() => this.handlePosition(position.position_type)}>
                                                    {position.position_type}
                                                </Button>
                                            );
                                        })}
                                    </Row>
                                    <hr />
                                </Col>
                                <Col>
                                    <Row>
                                        {(filteredData.length === 0) ? this.dataKosong : filteredData.map((job) => this.adaData(job))}
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        </Col>
                    </Container>
                    {this.modalSeeMoreHTML()}
                    {this.modalLoadingHTML()}
                    {this.modalConfirmHTML()}
                </div>
            </Fragment>
        );
    }

    dataKosong = <div className="text-center mx-auto">
        <img src={noData} width="300px" className="mx-auto" />
        <h5 className="Muli-font font-weight-bold mt-n5">Data Empty</h5>
    </div>

    adaData = job => <div className="col-md-6 mb-3">
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-center">
                    <img src={(job.company_logo_image != null)? `${config.URL_IMAGE}company_logo/${job.company_logo_image}` : logoCompany} style={{
                        width: '130px',
                        height: '130px',
                    }} />
                </div>
                <div className="text-14">
                    <p className="font-weight-bold text-center border-bottom">{job.company_name}</p>
                    <div className="row border-bottom pb-2">
                        <div className="col-4">Job Type</div>
                        <div className="col text-right">{job.work_type}</div>
                    </div>
                    <div className="row border-bottom pb-2 pt-2">
                        <div className="col-4">Position</div>
                        <div className="col text-right">{job.job_sub_category}</div>
                    </div>
                    <div className="row border-bottom pb-2 pt-2">
                        <div className="col-4">Location</div>
                        <div className="col text-right">{`${job.work_address}, ${method.capitalizeText(job.village_name)}, ${method.capitalizeText(job.sub_district_name)}, ${method.capitalizeText(job.regency_name)}, ${method.capitalizeText(job.province_name)}`}</div>
                    </div>
                    <div className="row border-bottom pb-2 pt-2">
                        <div className="col-4">Salary (Rp)</div>
                        <div className="col text-right">{(job.is_hide_nominal_salary == 0) ? method.formatMoney(job.part_time_salary) : 'Confidential'}</div>
                    </div>
                    <div className="row border-bottom pb-2 pt-2">
                        <div className="col-4">Date Post</div>
                        <div className="col text-right">{method.formatDateDay(job.posting_time)}</div>
                    </div>
                    <div className="row border-bottom pb-2 pt-2 mb-2">
                        <div className="col-4">Work Period</div>
                        <div className="col text-right">{`${job.start_part_time_date} s/d ${job.finish_part_time_date}`}</div>
                    </div>
                    <div className="text-center mb-2">
                        <a onClick={() => this.toggleOpenJobParttime(job)} className="text-success">
                            <FontAwesomeIcon icon={faEye} className="text-14" /> See more
                        </a>
                    </div>
                    <button className="btn btn-info btn-sm btn-block" onClick={() => this.toggleModalConfirm(job)}>Apply</button>
                </div>
            </div>
        </div>
    </div>;

    modalConfirmHTML = () => {
        const {modalConfirm, dataConfirm} = this.state;
        return <Modal isOpen={modalConfirm} toggle={this.toggleModalConfirm} size="xl">
            <ModalBody>
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalConfirm} />
                <div className="row">
                    <div className="col-md-6">
                        <div className="row mb-1">
                            <div className="col-3">Company</div>
                            <div className="col"><input type="text" className="form-control form-control-sm" disabled value={dataConfirm.company_name} /></div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3">Work type</div>
                            <div className="col"><input type="text" className="form-control form-control-sm" disabled value={dataConfirm.work_type} /></div>
                        </div>
                    </div>
                </div>
                {(!dataConfirm.shift)? null : ((dataConfirm.shift.length == 0)? null : <button className="btn btn-primary btn-sm mb-2 mt-2" onClick={this.apply}>Confirm</button>)}
                <div className="table-responsive">
                    <table className="table table-hover table-bordered table-sm" style={{width: '100%'}}>
                        <thead className="thead-dark">
                            <tr className="text-center">
                                <th></th>
                                <th>Before In</th>
                                <th>After In</th>
                                <th>Shift In</th>
                                <th>Before Out</th>
                                <th>After Out</th>
                                <th>Shift Out</th>
                                <th>Quota</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!dataConfirm.shift)? null : this.shiftHTML(dataConfirm)}
                        </tbody>
                    </table>
                </div>
            </ModalBody>
        </Modal>;
    }

    handleChangeShiftId = shift_id => this.setState({shift_id});

    shiftHTML = data => {
        const {shift_id} = this.state;
        let html;
        if(data.shift.length == 0) {
            html = <tr><td colSpan={8} className="text-center text-secondary">~ Data not found ~</td></tr>;
        } else {
            html = data.shift.map((shift, i) => <tr key={i} onClick={() => this.handleChangeShiftId(shift.job_shift_id)}>
                <td><div className="form-check text-center"><input type="radio" checked={(shift_id == shift.job_shift_id)? true : false} value={shift.job_shift_id} className="form-check-input"/></div></td>
                <td>{shift.before_in} minute</td>
                <td>{shift.after_in} minute</td>
                <td>{shift.in} WIB</td>
                <td>{shift.before_out} minute</td>
                <td>{shift.after_out} minute</td>
                <td>{shift.out} WIB</td>
                <td>{shift.kuota} person</td>
            </tr>)
        }
        return html
    }

    modalLoadingHTML = () => {
        const {modalLoading} = this.state;
        return <Modal isOpen={modalLoading} toggle={this.toggleLoading} backdrop="static">
            <ModalBody className="text-center p-5">
                <Spinner color="warning" style={{ width: '8rem', height: '8rem', borderWidth: '7px' }} />
                <img src={logoAvisha} alt="logo-google" width="60px" className="position-absolute" style={{ margin: '30px 0 0 -95px' }} />
                <Label className="d-block mt-3">Please Wait</Label>
            </ModalBody>
        </Modal>;
    }

    modalSeeMoreHTML = () => {
        const {modalOpenJobParttime, spesificData} = this.state;
        return <Modal isOpen={modalOpenJobParttime} toggle={this.toggleOpenJobParttime}>
            <ModalBody>
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleOpenJobParttime} />
                <Col className="border-bottom pl-0 pr-0">
                    <Col>
                        <Row>
                            <Col lg="auto" className="text-center">
                                <img src={spesificData.company_logo_image != null ? config.URL_IMAGE + "company_logo/" + spesificData.company_logo_image : logoCompany} style={{ zIndex: "1" }} className="rounded-circle" alt="" width="130px" height="130px" />
                            </Col>
                            <Col className="text-14">
                                <p className="font-weight-bold mb-0">{spesificData.position_type}</p>
                                <p className="mb-0">{spesificData.company_name}</p>
                                <p className="mb-0">{spesificData.work_type}</p>
                                <p className="mb-0">{spesificData.work_address}, {method.capitalizeText(spesificData.village_name)}, {method.capitalizeText(spesificData.sub_district_name)}, {method.capitalizeText(spesificData.regency_name)}, {method.capitalizeText(spesificData.province_name)}</p>
                                <p className="mb-0">{(spesificData.is_hide_nominal_salary == 0) ? method.formatRupiah(spesificData.part_time_salary) : "Salary is secret"}</p>
                                <Row>
                                    <Col lg="auto">
                                        <Label className="text-green mb-0">{method.formatDateDay(spesificData.posting_time)}</Label>
                                    </Col>
                                </Row>
                                <Col className="pl-0 pr-0 mb-3">
                                    <Col lg="8" className="pl-0 pr-0">
                                        <Button className="btn-block btn-blue" onClick={() => this.toggleModalConfirm(spesificData.id)}>APPLY</Button>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <Col className="border-bottom text-14 mt-3">
                    <Row>
                        <Col>
                            <p className="font-weight-bolder mb-0">Slot</p>
                            <p className="text-12">{spesificData.quota} Person</p>
                        </Col>
                        <Col>
                            <p className="font-weight-bolder mb-0">Gender</p>
                            <p className="text-12">{spesificData.gender_in_job}</p>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col>
                            <p className="font-weight-bolder mb-0">Category</p>
                            <p className="text-12">{spesificData.job_category} - {spesificData.job_sub_category}</p>
                        </Col>
                        <Col>
                            <p className="font-weight-bolder mb-0">Part Time Date</p>
                            <p className="text-12 mb-0">{`${spesificData.start_part_time_date} s/d ${spesificData.finish_part_time_date}`}</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="border-bottom text-14 mt-3">
                    <p className="font-weight-bolder mb-0">Job Description</p>
                    <p className="text-justify">
                        {spesificData.job_description}
                    </p>
                </Col>
            </ModalBody>
        </Modal>;
    }
};

export default ParttimeList;