// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import NavUser                                              from './ComponentUser/NavUser';

import { Link } from 'react-router-dom'

// Styling
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Label,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class EducationUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataEmployee: {},
      isEdit: false,
      error: '',
    }
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const {
        id,
        bank_account_name,
        bank_account_number,
        bank_name,
      } = res.data.dataProfile
      this.setState({
        dataEmployee: { id, bank_account_name, bank_account_number, bank_name },
        isEdit:
          bank_account_name && bank_account_number && bank_name ? false : true,
      })
    } catch (err) {
      //   console.log("Error Response :",err);
    }
  }

  simpanBank = async () => {
    const { dataEmployee } = this.state
    const res = await api.simpanBank({
      params: {
        data: JSON.stringify({ key_api: config.KEY_API }),
        dataEmployee,
      },
    })
    const { status, data } = res
    if (status == 200) {
      this.setState({ isEdit: false })
    } else {
      this.setState({
        isEdit: true,
        error: data.msg,
      })
    }
  }

  tampilHTML = (dataEmployee) => {
    const { bank_account_name, bank_account_number, bank_name } = dataEmployee
    return (
      <div>
        <Col className="border-top pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Bank Name</p>
            </Col>
            <Col className="p-0">
              <p id="ValueLevelEducation" className="text-justify mb-0">
                {bank_name}
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="border-top pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Account Number</p>
            </Col>
            <Col className="p-0">
              <p id="ValueNameOfInstitution" className="text-justify mb-0">
                {bank_account_number}
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="border-top border-bottom pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Account Name</p>
            </Col>
            <Col className="p-0">
              <p id="ValueMajorEducation" className="text-justify mb-0">
                {bank_account_name}
              </p>
            </Col>
          </Row>
        </Col>
      </div>
    )
  }

  inputHTML = (dataEmployee) => {
    const { bank_account_name, bank_account_number, bank_name } = dataEmployee
    const { error } = this.state
    return (
      <div>
        {error ? (
          <div class="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          ''
        )}
        <Col className="border-top pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Bank Name</p>
            </Col>
            <Col className="p-0">
              <Input
                type="text"
                id="bank_name"
                name="bank_name"
                onChange={this.handleChange}
                value={bank_name}
              />
            </Col>
          </Row>
        </Col>
        <Col className="border-top pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Account Number</p>
            </Col>
            <Col className="p-0">
              <Input
                type="number"
                id="bank_account_number"
                name="bank_account_number"
                onChange={this.handleChange}
                value={bank_account_number}
              />
            </Col>
          </Row>
        </Col>
        <Col className="border-top pt-2 pb-2">
          <Row>
            <Col xs="6" sm="5" md="5" lg="5" className="pl-0 pr-0 my-auto">
              <p className="mb-0">Account Name</p>
            </Col>
            <Col className="p-0">
              <Input
                type="text"
                id="bank_account_name"
                name="bank_account_name"
                onChange={this.handleChange}
                value={bank_account_name}
              />
            </Col>
          </Row>
        </Col>
        <Col className="pl-0 pr-0" id="saveChangeEducationUser">
          <hr />
          <button
            className="btn btn-block btn-blue-normal text-light"
            onClick={this.simpanBank}
          >
            Save Changes
          </button>
        </Col>
      </div>
    )
  }

  handleChange = (e) => {
    const { dataEmployee } = this.state
    dataEmployee[e.target.name] = e.target.value
    this.setState({ dataEmployee })
  }

  render() {
    const { isEdit, dataEmployee } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    BANK ACCOUNT
                  </Label>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {isEdit
                ? this.inputHTML(dataEmployee)
                : this.tampilHTML(dataEmployee)}
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    )
  }
}

export default EducationUser
