// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
// import HeaderUser                                           from './ComponentUser/HeaderUser';

import { Link } from 'react-router-dom'
import Select from 'react-select'

// Styling
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  Button,
  CardHeader,
  CardBody,
  Table,
  Label,
  Input,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import * as config from '../../../Helpers/Config'
import * as api from '../../../Helpers/Api'
import * as authHelperMethods from '../../../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class FileUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileKey: '',
      fileUrl: '',
      warningError: '',
      listFileEmployee: [],
      listType: [],
      modalAddEditFileEmployee: false,
    }
    this.toggleAddEditFileEmployee = this.toggleAddEditFileEmployee.bind(this)
  }

  componentDidMount = async () => {
    await this.getFileTypes()
    await this.getData()
  }

  handleFileEmployee = (event) => {
    this.setState({
      fileUrl: event.target.files[0],
    })
  }

  toggleAddEditFileEmployee() {
    this.setState((prevState) => ({
      modalAddEditFileEmployee: !prevState.modalAddEditFileEmployee,
    }))
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChoose = async (element) => {
    await this.setState({ fileKey: element.value })
  }

  notify() {
    const { warningError } = this.state
    toast(warningError)
  }

  lihatFile = async (fileName) => {
    try {
      var url = config.URL_IMAGE_ABSENCY + `file-employee/${fileName}`
      window.open(url, '_blank')
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  saveNewFileEmployee = async () => {
    const { fileKey, fileUrl } = this.state
    const idEmployee = authHelperMethods.getIdEmployee()
    let newData = new FormData()
    // newData.append('_methode', 'PUT')
    newData.append('key_api', config.KEY_API)
    newData.append('fileKey', fileKey)
    newData.append('url_file', fileUrl)
    const key = {
      key_api: config.KEY_API,
    }
    try {
      const res = await api.saveNewFileEmployee(idEmployee, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: newData,
      })
      const { data, error } = res.data
      if (error === false) {
        await this.getData()
        this.toggleAddEditFileEmployee()
        await this.setState({ warningError: 'Berhasil upload dokumen' })
        this.notify()
      } else {
        await this.setState({ warningError: error })
        this.notify()
      }
    } catch (err) {
      await this.setState({ warningError: 'Error Response :', err })
      this.notify()
    }
  }

  deleteFile = async (id) => {
    const newData = {
      key_api: config.KEY_API,
    }
    try {
      const res = await api.deleteFiles(id, {
        params: { data: JSON.stringify(newData) },
      })
      const { error, data } = res.data
      if (error == false) {
        await this.getData()
        await this.setState({ warningError: 'Berhasil hapus dokumen' })
      } else {
        await this.setState({ warningError: 'Info : ' + error })
        this.notify()
      }
    } catch (error) {
      await this.setState({ warningError: 'Info : ' + error })
      this.notify()
    }
  }

  getFileTypes = async () => {
    try {
      const res = await api.getFileTypes({
        params: {
          data: JSON.stringify({ key_api: config.KEY_API }),
        },
      })
      const { error, data } = res.data
      console.log(res)
      if (error === false) {
        this.setState({ listType: data })
      } else {
        await this.setState({ warningError: 'Info 1: ' + error })
        this.notify()
      }
    } catch (error) {
      await this.setState({ warningError: 'Info 2: ' + error })
      this.notify()
    }
  }

  getData = async () => {
    try {
      const idEmployee = authHelperMethods.getIdEmployee()
      const res = await api.getFileEmployee(idEmployee, {
        params: {
          data: JSON.stringify({ key_api: config.KEY_API }),
        },
      })
      const { error, data } = res.data
      console.log(res)
      if (error === false) {
        this.setState({ listFileEmployee: data })
      } else {
        await this.setState({ warningError: 'Info 1: ' + error })
        this.notify()
      }
    } catch (error) {
      await this.setState({ warningError: 'Info 2: ' + error })
      this.notify()
    }
  }

  render() {
    const { listType } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <Container className="mb-4">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <Label className="text-16 font-weight-bold mb-0">
                    EMPLOYEE FILES
                  </Label>
                </Col>
                <Col id="btnEditFileEmployee" className="text-right text-blue">
                  <Link to="#" onClick={this.toggleAddEditFileEmployee}>
                    <Label className="text-16 mb-0 text-blue">Add</Label>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table hover responsive className="mb-0">
                <thead className="text-14">
                  <tr>
                    <th>File Name</th>
                    <th>File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-12">
                  {this.state.listFileEmployee.map((work) => {
                    return (
                      <tr>
                        <td>{work.file_key}</td>
                        <td>
                          <Button
                            className="btn-blue-normal btn-block"
                            onClick={() => this.lihatFile(work.url_file)}
                          >
                            Lihat File
                          </Button>
                        </td>
                        <td className="text-right">
                          <a
                            className="text-danger"
                            onClick={() => this.deleteFile(work.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
        <Modal
          isOpen={this.state.modalAddEditFileEmployee}
          toggle={this.toggleAddEditFileEmployee}
          backdrop="static"
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-danger btn-close-circle"
            onClick={this.toggleAddEditFileEmployee}
          />
          <ModalBody
            style={{ height: '400px', overflow: 'scroll' }}
            className="pt-5 pb-5 pl-2 pr-2"
          >
            <h5 className="text-black font-weight-bold text-center mb-5 ">
              Add/Change Document
            </h5>
            <Col className="mb-3">
              <Label className="d-block text-16 font-weight-bold">
                File Key
              </Label>
              <Select
                name="file_key"
                options={listType}
                onChange={this.handleChoose.bind(this)}
              />
            </Col>
            <Col className="mb-4">
              <Label className="d-block text-16 font-weight-bold">
                Document
              </Label>
              <Button
                onClick={() =>
                  document.getElementById('inputFileEmployee').click()
                }
                className="btn-block btn-blue-normal"
              >
                UPLOAD FILE
                <Input
                  className="d-none"
                  id="inputFileEmployee"
                  type="file"
                  onChange={this.handleFileEmployee}
                />
              </Button>
              <div className="alert alert-danger" role="alert">
                Hanya file PDF, PNG, JPG, JPEG
              </div>
            </Col>
            <Col>
              <Button
                onClick={this.saveNewFileEmployee}
                className="btn-block btn-blue-normal"
              >
                Save
              </Button>
            </Col>
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default FileUser
