import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from  '../Helpers/Method';
import {
    Container, Row, Col,
    Card, CardHeader, CardBody
}                                                           from 'reactstrap';   

class PaymentHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          id:0,
          accountName:'',
          isHavePaymentTransaction:false,
          isPaid:false,
          totalPayment:"",
          packageName:"",
          deadline:"",
          dataAllPaymentHistory:[],
          bank_name: '',
          idTransaction: '',
          is_part_time_package: '',
          is_payment_gateway: '',
          rejects: []
        };
    };

    componentDidMount= async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getPaymentHistory();
        await this.getRejectedHistory();
        await this.getAllPaymentHistory();

    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getRejectedHistory = async() => {
        try{
            const res = await api.getAllRejectedPaymentHistory(this.state.id, {
                params:{data: JSON.stringify({key_api: config.KEY_API})}
            });
            const {data} = res.data;
            this.setState({rejects: data});
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getAllPaymentHistory = async () => {
        try{
            const res = await api.getAllPaymentHistory(this.state.id, {
                params:{
                    data:JSON.stringify({key_api: config.KEY_API})
                }
            });
            const {data} = res.data;
            this.setState({dataAllPaymentHistory: data});
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    getPaymentHistory = async () => {
        try{
            const res = await api.getPaymentHistory(this.state.id,{
                params:{
                    data:JSON.stringify({key_api:config.KEY_API})
                }
            });
            const {data} = res.data;
            if(data){
                this.setState({isHavePaymentTransaction:true});
                const {id, package_name, total_payment, payment_deadline, payment_receipt_image, bank_name, is_part_time_package, is_payment_gateway } = res.data.data;
                this.setState({ 
                    isPaid:(payment_receipt_image==null ? false : true),
                    totalPayment:total_payment,
                    deadline:payment_deadline,
                    packageName:package_name,
                    idTransaction: id,
                    is_part_time_package,
                    bank_name,
                    is_payment_gateway
                });
            } else {
                this.setState({isHavePaymentTransaction:false});
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    goToDetailTransaction = () => {
        const {idTransaction, is_payment_gateway, is_part_time_package} = this.state;
        localStorage.setItem('id_transaction', idTransaction);
        if(is_payment_gateway === 0) {
            window.location.href = `${config.WEB_URL}WaitingPayment`;
        } else if(is_part_time_package === 1) {
            window.location.href = `${config.WEB_URL}WaitingPaymentGatewayPartTime`;
        } else {
            window.location.href = `${config.WEB_URL}WaitingPaymentGateway`;
        }
    }

    noTransactionHTML = <Col><h5 className="font-weight-bolder text-center text-blue">No Transaction, yet.</h5></Col>;

    waitingPaymentHTML = () => {
    const {deadline, packageName, totalPayment, isPaid, bank_name} = this.state;
    return (<Col onClick={this.goToDetailTransaction} className="border rounded p-4 mt-4 d-block box-shadow mb-3">
        <p className="mb-0"><b>Sebelum {method.formatDate(deadline)}</b></p>
        <p className="mb-0">{packageName}</p>
        <p className="mb-0">{method.formatRupiah(totalPayment)} (<b>{bank_name}</b>)</p>
        <p className="mb-0 text-warning">{(isPaid === false)? 'Menunggu Pembayaran' : 'Menunggu Konfirmasi'}</p>
    </Col>);
    }

    successPaymentHTML = datas => datas.map(item =>
    <Col className="border rounded p-4 d-block box-shadow mb-3">
        <p className="mb-0">{method.formatDateDay(item.updated_at)}</p>
        <p className="mb-0">{item.package_name}</p>
        <p className="mb-0">{method.formatRupiah(item.total_payment)} (<b>{item.bank_name}</b>)</p>
        <p className="mb-0">{this.statusIndo(item.status_payment)}</p>
    </Col>);

    rejectPaymentHTML = datas => datas.map(item => <Col className="border rounded p-4 d-block box-shadow mb-3">
        <p className="mb-0">{method.formatDateDay(item.updated_at)}</p>
        <p className="mb-0">{item.package_name}</p>
        <p className="mb-0">{method.formatRupiah(item.total_payment)} (<b>{item.bank_name}</b>)</p>
        <p className="mb-0">{this.statusIndo(item.status_payment)}</p>
    </Col>);

    statusIndo = status => {
        let hasil = '';
        let warna = '';
        if (status === 'Payment Success') {
            hasil += 'Pembayaran Berhasil'
            warna += 'success';
        } else {
            hasil += 'Pembayaran Gagal';
            warna += 'danger';
        }
        return <span class={`text-${warna}`}>{hasil}</span>;
    }

    render() {                                     
        const {isHavePaymentTransaction, dataAllPaymentHistory, rejects} = this.state;
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Payment History</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4 text-14">
                        <Card className="mb-4">
                            <CardHeader><h6 className="font-weight-bolder my-auto">Payment Waiting</h6></CardHeader>
                            <CardBody>
                                {(isHavePaymentTransaction === true)? this.waitingPaymentHTML() : this.noTransactionHTML}
                            </CardBody>
                        </Card>
                        <Card className="mb-4">
                            <CardHeader><h6 className="font-weight-bolder my-auto">Payment Rejected</h6></CardHeader>
                            <CardBody>
                                {(rejects.length === 0)? this.noTransactionHTML : this.rejectPaymentHTML(rejects)}
                            </CardBody>
                        </Card>
                        <Card className="pb-3">
                            <CardHeader><h6 className="font-weight-bolder my-auto">Payment Successful</h6></CardHeader>
                            <CardBody>
                            {(dataAllPaymentHistory.length === 0)? this.noTransactionHTML : this.successPaymentHTML(dataAllPaymentHistory)}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default PaymentHistory;