// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';
import LogoBCA                                              from '../Icon/bca.png';

// Styling
import { Container, Row, Col, Card,
         CardBody, Button, Label, Input, CardFooter, CardHeader}        from 'reactstrap';   
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';
import * as method                                          from  '../Helpers/Method';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import { Link }                                             from 'react-router-dom';
import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome';
import { faDownload }                                       from '@fortawesome/free-solid-svg-icons';
import * as helpersApiCms                                   from '../Helpers/HelpersApiCms';
import { saveAs }                                           from 'file-saver';

class WaitingPayment extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            id:0,
            totalPayment:0,
            accountName:"",
            deadline:"",
            status:"",
            bankName:"",
            paymentMethod:"",
            bankAccountNumber:0,
            idTransaction:0,
            dataBankTypes:[],
            imagePaymentTransaction:"",
            imageFile:null,
            isPayment:false,
            isUploadFile: false,
            adaFile: false,
            status_payment: '',
            statusBayar: ''
        };
    }

    componentDidMount= async () => {
        await this.getId();
        await this.getPaymentHistory();
    }

    uploadFile = async () => {
        const {imageFile, idTransaction, id} = this.state;
        let newData = new FormData()
        newData.append('_methode', 'PUT')  
        newData.append('key_api', config.KEY_API)
        newData.append('id_transaction', idTransaction)
        newData.append('image_file', imageFile)
        try{
            const res = await api.updateTransaction({
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                data: newData
            }, id)
            const { status } = res;
                if (status === 200) {
                    window.location.href = config.WEB_URL + `WaitingPayment`;
                }
                else {
                    alert("File kebesaran");
                }
        } catch(err){
            console.log("Error Response :",err)
            alert("server error");
        }
    }

    countDownDueDate = (dueDate) => {
        // Set the date we're counting down to
        var x;
        if(dueDate == "stop"){
            clearInterval(x);
            this.uploadFile();
        } else {
            var countDownDate = new Date(dueDate).getTime();

            // Update the count down every 1 second
            x = setInterval(function() {
    
                // Get today's date and time
                var now = new Date().getTime();
                    
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                    
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    
                // Output the result in an element with id="demo"
                document.getElementById("countDown").innerHTML = 
                hours + " Jam " + ": " + minutes + " Menit " + ": " + seconds + " Detik ";
                    
                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    document.getElementById("demo").innerHTML = "EXPIRED";
                }
            }, 1000);
        }

    }

    handleBrowseFile = (event) => {
        this.setState({
            imagePaymentTransaction: URL.createObjectURL(event.target.files[0]),
            imageFile: event.target.files[0],
            isUploadFile: true
        });
        this.setState({adaFile: (event.target.files.length > 0)? true : false});
    }

    getPaymentHistory = async () => {
        try {
            const idTransaction = localStorage.getItem('id_transaction');
            const res = await api.getSinglePayment(idTransaction, {
                params: { data: JSON.stringify({ key_api: config.KEY_API }) }
            });
            console.log(res);
            // const newData = ({
            //     key_api:config.KEY_API
            // });
            // const res = await api.getPaymentHistory(this.state.id, {
            //     params: { data: JSON.stringify(newData) }
            // })
            const { status_payment, payment_receipt_image, id, payment_deadline, bank_name, total_payment, bank_account_number } = res.data.data;
            if(status_payment == "Waiting Payment"){
                this.countDownDueDate(payment_deadline);
            }

            this.setState({ 
                idTransaction: id, 
                totalPayment: total_payment,  
                bankName:bank_name, 
                deadline:payment_deadline, 
                bankAccountNumber:bank_account_number,
                isPayment:(status_payment == 'Waiting Payment')? false : true,
                imagePaymentTransaction: payment_receipt_image != null ? config.URL_IMAGE + "file_transaction/" + payment_receipt_image : "",
                statusBayar: this.statusBayar(status_payment),
                status_payment
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    statusBayar = status => {
        let hasil = '';
        if (status == 'Waiting Payment') {
            hasil += 'Belum dibayar';
        } else if (status == 'Payment Reject' || status == 'Payment Failed') {
            hasil += 'Pembayaran tidak diterima';
        } else if (status == 'Payment Success' || status == 'Waiting Confirmation') {
            hasil += 'Sudah dibayar';
        }
        return hasil
    }

    getId = () => this.setState({id: authHelperMethods.getIdClient()});

    downloadInvoice = async (idTransaction) => {
        try{
            const res = await helpersApiCms.downloadInvoice(idTransaction);
            const {status, data} =res;
            if(status===200){
                let FileSaver = require('file-saver');
                FileSaver.saveAs(data.data[0]['url'], "Invoice.pdf");
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {      
        const { 
                totalPayment,  
                bankName, 
                deadline,
                bankAccountNumber,
                isPayment,
                isUploadFile,
                imagePaymentTransaction, 
                idTransaction,
                status_payment
            } = this.state;                               
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Konfirmasi Pembayaran</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container  className="mt-4">
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <Link className="text-decoration-none">
                                        <Row onClick={()=>this.downloadInvoice(idTransaction)}>
                                            <Col className="text-left"><Label className="d-block font-weight-bold text-14 text-black mb-0">Download Invoice</Label></Col>
                                            <Col className="text-right"><FontAwesomeIcon icon={faDownload} className="text-success"/></Col>
                                        </Row>
                                        </Link>
                                    </CardHeader>
                                    <CardBody>
                                        <Label className="d-block font-weight-bold text-20">Metode Pembayaran Transfer Bank</Label>
                                        <hr className="mt-0"/>
                                        <Row>
                                            <Col md="5"><Label className="d-block text-16">Tanggal Jatuh Tempo</Label></Col>
                                            <Col>   
                                                <Label className="d-block text-16">{': '} {method.formatDate(deadline)}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="5"><Label className="d-block text-16">Bank Tujuan</Label></Col>
                                            <Col>
                                                <Label className="d-block text-16">{': '} {bankName}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="5"><Label className="d-block text-16">Status</Label></Col>
                                            <Col>
                                                <Label className="d-block text-16">{': '} <span className="text-red">{isPayment===false?"Belum di bayar":"Sudah di bayar"}</span></Label>
                                            </Col>
                                        </Row>
                                        <Col className="bg-blue rounded-sm p-2">
                                            <Row>
                                                <Col md="5"><Label className="d-block text-white font-weight-bold text-16 mb-0">Total Bayar</Label></Col>
                                                <Col>
                                                    <Label className="d-block text-white font-weight-bold text-16 mb-0">{': '} {method.formatRupiah(totalPayment)}</Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <hr/>
                                        {
                                            isPayment === false ?
                                                <div>
                                                    <Container>
                                                        <div className="text-center">
                                                            <Label className="d-block text-16 font-weight-bold mb-0">Batas Waktu Pembayaran</Label>
                                                            <Label className="d-block text-24 font-weight-bolder mb-0" id="countDown"></Label>
                                                            <Label className="d-block text-14 text-secondary mb-0"> (Sebelum {method.formatDate(deadline)})</Label>
                                                        </div>
                                                        <hr/>
                                                        <div>
                                                            <Row>
                                                                {/* {listPaymentPartner.map((paymentPartner)=>{
                                                                    if(idPaymentPartner==paymentPartner.id){
                                                                        return(
                                                                            <Col><img src={configuration.URL_IMAGE_PAYMENT_PARTNER+paymentPartner.logo_partner} width="90px" alt={paymentType}/></Col>
                                                                        );
                                                                    }
                                                                })} */}
                                                                <Col className="text-center">
                                                                    <Label className="d-block text-14 font-weight-bold">Pembayaran Transfer Ke Bank : </Label>
                                                                    <Row className="d-inline-flex">
                                                                        <Col className="my-auto"><img src="https://apiweb.avisha.co.id/image/logo_bank/Mandiri.png" width="75px"/></Col>
                                                                        <Col className="my-auto">
                                                                            <div className="verticalLine-TitlePage">
                                                                                <Col><Label className="text-24 font-weight-bold mb-0">{bankAccountNumber}</Label></Col>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>  
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Container>
                                                    <Container className="text-center">
                                                        <Label className="d-block text-14 mb-0">Masukkan kode pembayaran anda melalui atm/m-banking/internet banking {bankName} dan pastikan nilai pembayaran anda sesuai dengan tagihan di aplikasi Avisa senilai: </Label>
                                                        <Label className="d-block text-14 mb-0 font-weight-bold"> {method.formatRupiah(totalPayment)} </Label>
                                                    </Container>
                                                </div>
                                            : 
                                            <Container>
                                                    <Label className="d-block text-center font-weight-bold">{(status_payment == 'Waiting Confirmation')? 'Menunggu Konfirmasi' : ((status_payment == 'Payment Success')? 'Transaksi Sukses' : 'Transaksi Ditolak')}</Label>
                                                <Link to="/DashboardClient"> 
                                                    <Button className="btn-blue btn-block btn btn-secondary">Back to Dashboard</Button>
                                                </Link>
                                            </Container>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Label className="font-weight-bold text-14">Silakan upload bukti pembayaran anda atau bisa lampirkan ke <u><i style={{color:"blue"}} ><a target="_blank" href="https://mail.google.com">finance.avisha@gmail.com</a></i></u></Label>
                                        <div className="border text-center mt-2 mb-4 rounded-lg" style={{height:'200px'}}>
                                            {
                                                imagePaymentTransaction === "" ?
                                                    <Label className="font-weight-bold text-secondary mb-0">No file</Label>
                                                :
                                                <img src={imagePaymentTransaction} name="imagePaymentTransaction" id="imagePaymentTransaction" className="border rounded-lg p-2 mb-3" width="200" height="150" alt=""/>
                                            }
                                        </div>
                                        {(isPayment === false)? <Input type="file" onChange={this.handleBrowseFile}/> : null}
                                    </CardBody>
                                    {(isPayment === false)? this.tombolKonfirmasi() : null}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
        );   
    }

    tombolKonfirmasi = () => {
        const { adaFile } = this.state;
        return (<CardFooter tag={Button} onClick={(adaFile)? () => this.countDownDueDate('stop') : ''} className="btn-blue-normal">
            <Label className="text-14 font-weight-bold mb-0">Konfirmasi</Label>
        </CardFooter>);
    }
};

export default WaitingPayment;