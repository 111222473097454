// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
// import HeaderUser                                           from './ComponentUser/HeaderUser';

import { Link }                                             from 'react-router-dom';

// Styling
import { Container, Row, Col, Card, Label, Modal, Button, 
         CardHeader, CardBody, ModalBody, Input }           from 'reactstrap';

import { FontAwesomeIcon }                                  from '@fortawesome/react-fontawesome'
import { faStar, faTimesCircle }                            from '@fortawesome/free-solid-svg-icons'
 
import * as config                                          from '../../../Helpers/Config';
import * as api                                             from '../../../Helpers/Api';      
import * as authHelperMethods                               from '../../../Helpers/AuthHelperMethods';
import { ToastContainer, toast }                            from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SkillUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listEmployeeSkill:[],
            modalAddEditExpertise: false,
            skillName:"",
            skillRate:""
        };
        this.toggleAddEditExpertise = this.toggleAddEditExpertise.bind(this);
    }
  
    toggleAddEditExpertise() {
      this.setState(prevState => ({
        modalAddEditExpertise: !prevState.modalAddEditExpertise
      }));
    }

    componentDidMount = async () =>{
        await this.getDataEmployee();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    getDataEmployee = async (e) => {
        const newData = {key_api: config.KEY_API};
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.profileEmployee(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
                this.setState({listEmployeeSkill:data.dataSkills})
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    notify(){
        toast( "Berhasil update data !" )
    }

    saveSkill = async () => {
        const {
                skillName, skillRate
            } = this.state;
        const idEmployee = authHelperMethods.getIdEmployee();
        const newData = {
                            key_api: config.KEY_API,
                            skill_name:skillName,
                            skill_rate:skillRate,
                        };
        try{
            const res = await api.saveNewSkill(idEmployee,{
                params: {data: JSON.stringify(newData)}
            })
            const {status} = res;
            if(status === 200){
                this.getDataEmployee();
                this.toggleAddEditExpertise();
                this.notify();
            }
        } catch(err){
              console.log("Error Response :",err)
        }
    }

    
    deleteSkill = async (id) =>{
        const newData = {
                            key_api: config.KEY_API,
                        };
        try{
            const res = await api.deleteSkill(id,{
                params: {data: JSON.stringify(newData)}
            })
            const {status} = res;
            if(status === 200){
                this.getDataEmployee();
            }
        } catch(err){
            console.log("Error Response :",err)
        }
    }

    render() {                           
        const {
            skillName,
            skillRate
        } = this.state;         
        return (          
            <Fragment>  
                <ToastContainer />
                <Container className="mb-4">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col><Label className="text-16 font-weight-bold mb-0">EXPERTISE</Label></Col>
                                <Col id="btnEditSkillUser" className="text-right text-blue"><Link to="#" onClick={this.toggleAddEditExpertise}><Label className="text-16 mb-0 text-blue">Add</Label></Link></Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.state.listEmployeeSkill.map((skill)=>{
                                return(
                                    <Col className="border-bottom">
                                        <Row>
                                            <Col xs="6" sm="5" md="5" lg="5" className="p-2">
                                                <p className="mb-0">{skill.skill_name}</p>
                                            </Col>
                                            <Col className="p-2">
                                                <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                                {/* <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                                <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                                <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                                <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                                <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>     */}
                                            </Col>
                                            <Col xs="6" sm="5" md="5" lg="5" className="p-2 text-right">
                                                <a className="text-danger" href="#" onClick={()=>this.deleteSkill(skill.id)} style={{cursor:'pointer'}}>Delete</a>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                            
                            {/* <Col className="border-bottom">
                                <Row>
                                    <Col xs="6" sm="5" md="5" lg="5" className="p-2">
                                        <p className="mb-0">Adobe PS & AI</p>
                                    </Col>
                                    <Col className="p-2">
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                    </Col>
                                </Row>
                            </Col> */}
                            {/* <Col className="border-bottom">
                                <Row>
                                    <Col xs="6" sm="5" md="5" lg="5" className="p-2">
                                        <p className="mb-0">Visual Studio Code</p>
                                    </Col>
                                    <Col className="p-2">
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                        <FontAwesomeIcon icon={faStar} className="text-primary mr-2"/>    
                                    </Col>
                                </Row>
                            </Col> */}
                        </CardBody>
                    </Card>
                </Container>
                <Modal isOpen={this.state.modalAddEditExpertise} toggle={this.toggleAddEditExpertise} backdrop="static">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleAddEditExpertise}/>
                    <ModalBody style={{height:'400px', overflow:'scroll'}} className="pt-5 pb-5 pl-4 pr-4">
                        <h5 className="text-black font-weight-bold text-center mb-5 ">Add/Change Expertise</h5>
                        <Col className="mb-3">
                            <Row>
                                <Col xs="3"><Label className="d-block text-16 font-weight-bold">Skill</Label></Col>
                                <Col><Input id="skillRate" name="skillName" value={skillName} onChange={this.handleChange} /></Col>
                            </Row>
                        </Col>
                        <Col className="mb-4">
                            <Row>
                                <Col xs="3"><Label className="d-block text-16 font-weight-bold">Level</Label></Col>
                                <Col><Input type="number" id="skillRate" name="skillRate" value={skillRate} onChange={this.handleChange} /></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Button className="btn-block btn-blue-normal" onClick={this.saveSkill}>Save</Button>
                        </Col>
                    </ModalBody>
                </Modal>
            </Fragment>
        );   
    }
};

export default SkillUser;