// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import HeaderUser from './ComponentUser/HeaderUser';

import LogoAvisha from '../Asset/Logo/avisha-logo-full.svg';
import logoAvisha from '../Asset/Logo/avisha-logo.svg';
import Wallet from '../Asset/Icon/wallet.svg';

import { Link } from 'react-router-dom';

// Styling
import {
    Container, Row, Col, Label, Button, Modal, ModalBody,
    Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faClock, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import * as authHelperMethods from '../Helpers/AuthHelperMethods';
import * as method from '../Helpers/Method';

class DashboardUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeName: '',
            financeBalance: 0,
            dataBanner: [],
            dataEmployee: {},
            modalLoadingDashboardDashboard: false
        };
        this.modalLoadingDashboard = this.modalLoadingDashboard.bind(this);
    }

    componentDidMount = async () => {
        await this.getDataEmployee();
        await this.getDataBanner();
    }

    modalLoadingDashboard = () => {
        this.setState(prevState => ({
            modalLoadingDashboard: !prevState.modalLoadingDashboard
        }));            
    }

    getDataBanner = async () => {
        try {
            const newData = ({
                key_api: config.KEY_API,
            })
            const res = await api.getDataBanner({
                params: { data: JSON.stringify(newData) }
            })
            const { data } = res.data;
            this.setState({ dataBanner: data });
        } catch (err) {
            console.log("Error Response :", err)
        }
    }

    getDataEmployee = async (e) => {
        this.modalLoadingDashboard();
        const newData = { key_api: config.KEY_API };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.profileEmployee(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.modalLoadingDashboard();
                this.setState({
                    dataEmployee: data.dataProfile,
                    employeeName: data.dataProfile.full_name,
                    financeBalance: data.dataProfile.total_salary_this_month
                })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    render() {
        const {
            dataBanner,
            employeeName,
            financeBalance,
            dataEmployee
        } = this.state;
        return (
            <Fragment>
                <HeaderUser />
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Dashboard</h5>
                                    <p className="text-12 font-weight-bolder mb-0">Halo, <span className="text-capitalize">{employeeName}</span> welcome to HiReD by Avisha Human Resource Solution</p>
                                    {
                                        dataEmployee.status === null ?
                                            <p className="text-12 font-weight-bolder mb-0" style={{ color: "red" }}>Data anda dalam tahap verifikasi. Silahkan lengkapi data anda, agar tahap verifikasi dapat lebih cepat dilakukan</p>
                                            : dataEmployee.status === 0 ?
                                                <p className="text-12 font-weight-bolder mb-0" style={{ color: "red" }}>Data anda sudah di blokir</p>
                                                : null
                                    }
                                </Col>
                                <Col className="my-auto text-right">
                                    <img src={Wallet} alt="wallet-avisha" width="30px" className="mr-2" />
                                    <p className="d-inline-flex font-weight-bolder mb-0">
                                        Balance : {(financeBalance == null)? 'Rp. 0' : method.formatRupiah(financeBalance)}
                                    </p>&nbsp;
                                    {/* <a className='btn btn-warning btn-sm' href="/withdraw">
                                        <span className="text-center font-weight-bold">Withdraw</span>
                                    </a> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col className="pl-0 pr-0">
                            <Container className="pl-0 pr-0">
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4" className="mx-auto mb-3 mb-md-0">
                                        <Button disabled={dataEmployee.status === 1 ? false : true} tag={Link} to="/FulltimeJob" className="btn-block rounded box-shadow p-4" style={{ backgroundColor: '#165C7D' }}>
                                            <FontAwesomeIcon icon={faUser} style={{ fontSize: '70px', marginBottom: '20px' }} />
                                            <h6 className="text-center font-weight-bolder">Full Time <FontAwesomeIcon icon={faChevronRight} /></h6>
                                        </Button>
                                    </Col>
                                    <Col xs="0" sm="0" md="4" lg="4" className="my-auto text-center pb-3 pb-md-0">
                                        <img src={LogoAvisha} alt="logo-avisha-color" className="img-responsive col-5 col-sm-5 col-md-10 col-lg-5 col-xl-8" />
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4" className="mx-auto mb-3">
                                        <Button disabled={dataEmployee.status === 1 ? false : true} tag={Link} to="/ParttimeJob" className="btn-warning btn-block rounded box-shadow p-4 text-white">
                                            <FontAwesomeIcon icon={faClock} style={{ fontSize: '70px', marginBottom: '20px' }} />
                                            <h6 className="text-center font-weight-bolder">Part Time <FontAwesomeIcon icon={faChevronRight} /></h6>
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <h5 className="font-weight-bolder mt-3">News</h5>
                        <div className="overflow-auto" style={{ height: '600px' }}>
                            {dataBanner.map((item) =>
                                <div>
                                    <Col className="rounded-lg mb-4 pl-0 pr-0" style={{ minHeight:'200px', maxHeight: '480px' }}>
                                        <img src={config.API_IMAGE_FROM_CMS + "image/banner_image/" + item.banner_image} className="rounded" style={{ minHeight:'200px', maxHeight: '480px', width: '100%' }} alt="banner"/>
                                    </Col>
                                    <Col className="pl-0 pr-0"><p className="text-left bg-dark p-2 text-white rounded-top mt-n5">{item.banner_name}</p></Col>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
                <Modal isOpen={this.state.modalLoadingDashboard} toggle={this.modalLoadingDashboard} backdrop="static">
                    <ModalBody className="text-center p-5">
                        <Spinner color="warning" style={{ width: '8rem', height: '8rem', borderWidth: '7px' }} />
                        <img src={logoAvisha} alt="logo-google" width="60px" className="position-absolute" style={{ margin: '30px 0 0 -95px' }} />
                        <Label className="d-block mt-3">Please Wait</Label>
                    </ModalBody>
                </Modal>
                {/* <Footer/> */}
            </Fragment>
        );
    }
};

export default DashboardUser;