//  export const WEB_URL = `http://localhost:3000/`; // Lokal
export const WEB_URL = "https://job.avisha.co.id/"; // Prod
export const TIMEOUT = 15000;
export const API_URL = `https://apiweb.avisha.co.id/`;
export const API_IMAGE_FROM_CMS = `https://cmsforofficer.avisha.co.id/`;
export const URL_IMAGE = `https://apiweb.avisha.co.id/image/`;
export const URL_IMAGE_EMPLOYEE = `https://cmsforofficer.avisha.co.id/image/`;
export const API_URL_CMS = `https://cmsforofficer.avisha.co.id/api/`;
export const URL_IMAGE_ABSENCY = `https://apimobile.avisha.co.id/image/`;
export const KEY_API = `Avisa123`;
export const KEY_ENCRYPT = "Avisa";