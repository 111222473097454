// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import HeaderUser from './ComponentUser/HeaderUser';
// import JobOffering from './ComponentUser/JobOffering';

// Styling
import { Container, Row, Col, Label, UncontrolledCollapse } from 'reactstrap';
import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import * as authHelperMethods from '../Helpers/AuthHelperMethods';
import * as method from '../Helpers/Method';
import ReactPaginate from 'react-paginate';

class NotificationUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '0',
      financeBalance: 0,
      listInboxMessage: [],
      offset: 0,
      perPage: 10,
      currentPage: 0
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount = async () => {
    await this.getDataEmployee();
    await this.getInboxMessages();
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API };
    const idEmployee = authHelperMethods.getIdEmployee();
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) }
      })
      const { status, data } = res;
      if (status === 200) {
        this.setState({
          financeBalance: data.dataProfile.finance_balance
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  getInboxMessages = async (e) => {
    const newData = { key_api: config.KEY_API };
    const idEmployee = authHelperMethods.getIdEmployee();
    try {
      const res = await api.allEmployeeInbox(idEmployee, {
        params: { data: JSON.stringify(newData) }
      })
      const { status, data } = res;
      if (status === 200) {
        this.setState({
          listInboxMessage: data.listEmployeeInbox.slice(this.state.offset, this.state.offset + this.state.perPage)
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.getInboxMessages()
    });
  };

  render() {
    return (
      <Fragment>
        <HeaderUser />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Notification</h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Container className="mt-4 bg-white rounded-lg p-3">
              {this.state.listInboxMessage.map((notif, key) => {
                return (
                  <Col id={"toggler" + key} className="p-2 section-message border-bottom mb-3">
                    <Row>
                      <Col md="10">
                        <p className="mb-0 text-9 text-mute font-weight-light">Info</p>
                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                          {notif.notif_category == null ? "" : notif.notif_category} <Label className="mb-0 font-weight-lighter"> {notif.job_sub_category}  {notif.job_category}</Label>
                        </p>
                        <b>
                          {notif.title}
                        </b>
                        <UncontrolledCollapse toggler={"#toggler" + key}>
                          <hr />
                          <p className="text-14">{notif.description}</p>
                        </UncontrolledCollapse>
                      </Col>
                      <Col md="2"><p className="text-post-time">{method.formatDateDay(notif.created_at)}</p></Col>
                    </Row>
                  </Col>
                );
              })}
            </Container>
            <div className="float-right">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
};

export default NotificationUser;