// Import Lib, Component React & Icon
import React, { Fragment, useState } from 'react'
import HeaderUser from './HeaderUser'
// import Filter                                               from './Filter';

import classnames from 'classnames'

// import { Link }                                             from 'react-router-dom';
import banner from '../../Asset/Img/banner.svg'
import PhotoClient from '../../Asset/Img/photo-grand-hyatt.png'
import Wallet from '../../Asset/Icon/wallet.svg'
import * as config from '../../Helpers/Config'
import * as api from '../../Helpers/Api'
import * as method from '../../Helpers/Method'
import * as authHelperMethods from '../../Helpers/AuthHelperMethods'
import 'react-html5-camera-photo/build/css/index.css'
import noData from '../../Asset/Icon/no-data.svg'
import ImagePreview from '../ImagePreview'
// Styling
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Label,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalBody,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Form,
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import logoCompany from '../../Asset/Logo/logo-company.png'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import * as helpersApiCms from '../../Helpers/HelpersApiCms'

class MyListJobs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '0',
      listMyJobFullTime: [],
      listMyJobPartTime: [],
      financeBalance: 0,
      successNotif: '',
      listMyJob: [],
      dataSave: {},
      spesificIdCandidate: 0,
      spesificAbsenceType: '',
      descriptionModal: '',
      report: '',
      isArrived: false,
      isNeedReport: false,
      modalShowDescAbsencyPartTime: false,
    }
  }

  componentDidMount = async () => {
    const { listMyJobFullTime } = this.state
    await this.getDataEmployee()
    await this.getAllMyJob()
  }

  notify() {
    const { successNotif } = this.state
    toast(successNotif)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        this.setState({
          financeBalance: data.dataProfile.finance_balance,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  konversiTgl = (tgl) => {
    let d = new Date(tgl)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = '' + d.getFullYear()
    let h = '' + d.getHours()
    let i = '' + d.getMinutes()
    let s = '' + d.getSeconds()
    if (month.length < 2) month = `0${month}`
    if (day.length < 2) day = `0${day}`
    if (h.length < 2) h = `0${h}`
    if (i.length < 2) i = `0${i}`
    if (s.length < 2) s = `0${s}`
    return parseInt(year + month + day + h + i + s)
  }

  getAllMyJob = async (e) => {
    const newData = {
      key_api: config.KEY_API,
    }
    const idEmployee = authHelperMethods.getIdEmployee()

    try {
      const res = await api.getAllMyJob(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      const newFullTime = []
      const newPartTime = []
      if (status === 200) {
        if (data.dataAllMyJob.length > 0) {
          let i
          for (i = 0; i < data.dataAllMyJob.length; i++) {
            if (
              data.dataAllMyJob[i]['work_type'] === 'Full-Time' &&
              data.dataAllMyJob[i]['is_active'] === 1
            ) {
              newFullTime.push(data.dataAllMyJob[i])
            }
            // job_category_code
            if (
              data.dataAllMyJob[i]['work_type'] === 'Part-Time' &&
              (data.dataAllMyJob[i]['is_active'] === 1 ||
                data.dataAllMyJob[i]['is_active'] === null)
            ) {
              if (data.dataAllMyJob[i]['job_category_code'] === 13) {
                data.dataAllMyJob[i]['is_need_report'] = 1
              } else {
                data.dataAllMyJob[i]['is_need_report'] = 0
              }

              // let startPartTimes = new Date(data.dataAllMyJob[i]['start_part_time_date']);
              let startPartTimes = data.dataAllMyJob[i]['start_part_time_date']
              const dateParams = startPartTimes
                .replace(/ UTC/, '')
                .split(/[\s-:]/)
              dateParams[1] = (parseInt(dateParams[1], 10) - 1).toString()

              let haaa = new Date(Date.UTC(...dateParams))

              let dateNow = new Date()
              let datePlus7 = dateNow.setDate(dateNow.getHours() + 7)
              if (datePlus7 >= haaa) {
                //if (20220714223128 >= 20220714000000) {
                data.dataAllMyJob[i]['is_part_time_can_began'] = 1
              } else {
                data.dataAllMyJob[i]['is_part_time_can_began'] = 0
              }
              newPartTime.push(data.dataAllMyJob[i])
            }
          }
          this.setState({
            listMyJobFullTime: newFullTime,
            listMyJobPartTime: newPartTime,
          })
        } else {
          this.setState({
            listMyJobFullTime: newFullTime,
            listMyJobPartTime: newPartTime,
          })
        }
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  responseFullTimeMyJob = async (idCandidate, response) => {
    const newData = {
      key_api: config.KEY_API,
      employee_response: response,
    }
    try {
      const res = await api.responseFullTimeMyJob(idCandidate, {
        params: { data: JSON.stringify(newData) },
      })
      const { status } = res
      if (status === 200) {
        this.getAllMyJob()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  saveAbsency = async () => {
    const { report, dataSave } = this.state
    let newData = new FormData()
    newData.append('key_api', config.KEY_API)
    newData.append('id_candidate', dataSave['id_candidate'])
    newData.append('absence', dataSave['absence'])
    newData.append('report', report)
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.absencePartTimeMyJob(idEmployee, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: newData,
      })
      const { status } = res
      if (status === 200) {
        if (dataSave['absence'] == 'ARRIVE') {
          await this.setState({
            isCameraArrive: false,
            isArrived: true,
            successNotif: 'Attendance Successful !',
          })
          this.notify()
        } else if (dataSave['absence'] === 'FINISH') {
          await this.setState({
            isCameraFinish: false,
            isArrived: true,
            successNotif: 'Your job is finish, Attendance Successful!',
          })
          this.notify()
        } else if (dataSave['absence'] === 'DECLINE') {
          await this.setState({
            isCameraFinish: false,
            isArrived: true,
            successNotif: 'Your decline this part time job!',
          })
          this.notify()
        }
        await this.getAllMyJob()
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  modalShowDescAbsencePartTime = (data, absence) => {
    this.setState({
      spesificIdCandidate: data['id_candidate_employee'],
      spesificAbsenceType: absence,
      isNeedReport: data['is_need_report'] === 0 ? false : true,
    })
    if (absence === 'ARRIVE') {
      this.setState({
        descriptionModal: 'Make sure you used full uniform!',
      })
    } else if (absence === 'DECLINE') {
      this.setState({
        descriptionModal: 'Are you sure decline this job offer?',
      })
    } else {
      this.setState({
        descriptionModal:
          'Make sure you have finished the work and your work time is over.',
      })
    }
    this.setState((prevState) => ({
      modalShowDescAbsencyPartTime: !prevState.modalShowDescAbsencyPartTime,
    }))
  }

  closeModalDescription = () => {
    this.setState({
      spesificIdCandidate: 0,
      spesificAbsenceType: '',
      descriptionModal: '',
    })
    this.setState((prevState) => ({
      modalShowDescAbsencyPartTime: !prevState.modalShowDescAbsencyPartTime,
    }))
  }

  absencePartTime = async (idCandidate, absence) => {
    this.setState((prevState) => ({
      modalShowDescAbsencyPartTime: !prevState.modalShowDescAbsencyPartTime,
    }))
    const newData = {
      id_candidate: idCandidate,
      absence: absence,
    }
    if (absence === 'ARRIVE' || absence === 'FINISH') {
      localStorage.setItem('dataAbsency', JSON.stringify(newData))
      window.location.href = config.WEB_URL + 'attendance-work'
    } else if (absence === 'DECLINE') {
      await this.setState({ dataSave: newData })
      this.saveAbsency()
    }
  }

  downloadLetterAssignment = async (idCandidate) => {
    try {
      const res = await helpersApiCms.downloadDeliveryOrder(idCandidate)
      const { status, data } = res
      if (status === 200) {
        let FileSaver = require('file-saver')
        FileSaver.saveAs(
          data.dataAssignmentLatter[0]['url'],
          'Assignment Letter.pdf',
        )
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
    // console.log('handleCameraStop', stream);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const {
      financeBalance,
      isArrived,
      listMyJobFullTime,
      listMyJobPartTime,
      spesificIdCandidate,
      spesificAbsenceType,
      descriptionModal,
      modalShowDescAbsencyPartTime,
      isNeedReport,
    } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <HeaderUser />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">
                    My List Job
                  </h5>
                </Col>
                <Col className="my-auto text-right">
                  <img
                    src={Wallet}
                    alt="wallet-avisha"
                    width="30px"
                    className="mr-2"
                  />
                  <p className="d-inline-flex font-weight-bolder mb-0">
                    Balance :{' '}
                    {financeBalance == null
                      ? 'Rp.0'
                      : method.formatRupiah(financeBalance)}
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="mt-4">
            <Row>
              <Col>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '0',
                      })}
                      onClick={(e) => {
                        this.toggle('0')
                      }}
                      style={{ borderRadius: '12px 12px 0 0' }}
                    >
                      <p className="mb-0 font-weight-bold">FullTime</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === '1',
                      })}
                      onClick={(e) => {
                        this.toggle('1')
                      }}
                      style={{ borderRadius: '12px 12px 0 0' }}
                    >
                      <p className="mb-0 font-weight-bold">PartTime</p>
                    </NavLink>
                  </NavItem>
                  <Col md="4" lg="4" className="pl-0 pr-0 ml-auto">
                    <InputGroup>
                      <Input
                        type="text"
                        id="search"
                        name="search"
                        className=""
                        placeholder="Cari Pekerjaan Disini"
                      />
                      <InputGroupAddon addonType="append">
                        <Button className="btn-blue">
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="text-white"
                          />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Nav>
                <TabContent
                  activeTab={this.state.activeTab}
                  className="border-left border-right border-bottom text-12"
                >
                  {/* ------- Tab My List Job --------*/}
                  <TabPane
                    tabId="0"
                    className="bg-white p-3"
                    style={{ minHeight: '470px' }}
                  >
                    {listMyJobFullTime.length === 0 ? (
                      <center>
                        <img
                          src={noData}
                          width="280px"
                          className="mx-auto"
                          style={{ marginBottom: '-50px' }}
                        />
                        <Label className="d-block text-16">Data Empty</Label>
                      </center>
                    ) : (
                      listMyJobFullTime.map((myJob) => {
                        return (
                          <div className="mb-5">
                            <Col>
                              <img
                                src={banner}
                                width="100%"
                                alt=""
                                style={{ borderRadius: '20px 20px 0 0' }}
                              />
                            </Col>
                            <Col>
                              <Col
                                xs="4"
                                sm="3"
                                md="3"
                                lg="2"
                                className="mb-3"
                                style={{
                                  height: '120px',
                                  borderRadius: '8px',
                                  marginTop: '-65px',
                                }}
                              >
                                <img
                                  src={
                                    myJob.company_logo_image != null
                                      ? config.URL_IMAGE +
                                        'company_logo/' +
                                        myJob.company_logo_image
                                      : logoCompany
                                  }
                                  style={{ zIndex: '1' }}
                                  className="rounded-circle"
                                  alt=""
                                  width="130px"
                                  height="130px"
                                />
                              </Col>
                              <Row>
                                <Col>
                                  <Col>
                                    <Row>
                                      <Col xs="12" sm="6" md="6" lg="4">
                                        <p className="tag-block-blue font-weight-bold">
                                          {myJob.work_type}
                                        </p>
                                      </Col>
                                      {/* <Col xs="12" sm="6" md="6" lg="4"><p className="text-post-time font-weight-bold">Tommorow</p></Col> */}
                                    </Row>
                                  </Col>
                                  <Col>
                                    <p className="mb-1 font-weight-bold text-capitalize">
                                      {myJob.job_sub_category}
                                    </p>
                                    <p className="mb-1 text-12">
                                      {myJob.company_name}
                                    </p>
                                    <p className="mb-1 text-12 text-capitalize">
                                      {myJob.work_address},{' '}
                                      {method.capitalizeText(
                                        myJob.village_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.sub_district_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.regency_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.province_name,
                                      )}
                                    </p>
                                    <p className="mb-1 text-12">
                                      {myJob.min_salary == null
                                        ? 'Rp.0'
                                        : method.formatRupiah(
                                            myJob.min_salary,
                                          )}{' '}
                                      {'-'}{' '}
                                      {myJob.max_salary == null
                                        ? 'Rp.0'
                                        : method.formatRupiah(
                                            myJob.max_salary,
                                          )}{' '}
                                    </p>
                                  </Col>
                                  <Col className="bg-grey p-3 mb-4">
                                    <Row>
                                      <Col>
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Slot
                                          </p>
                                          <p className="text-12 muli-font">
                                            {myJob.quota} People
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Category
                                          </p>
                                          <p className="text-12 muli-font">
                                            {myJob.job_category}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col>
                                        {/* <div>
                                                                                <p className="mb-0 text-12 font-weight-bold muli-font">Reserve</p>
                                                                                <p className="text-12 muli-font">{myJob.total_reserve==null ? "0":myJob.total_reserve} People</p>
                                                                            </div> */}
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Period
                                          </p>
                                          <p className="mb-0 text-12 muli-font">
                                            {method.formatDateDay(
                                              myJob.deadline_job_vacancy,
                                            )}
                                            <br />
                                            {/* 08:00 - 17:00 WIB */}
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row>
                                      {/* {myJob} */}
                                      {myJob.status_candidate ==
                                      'Waiting Interview' ? (
                                        <Col>
                                          <Button
                                            className="btn-finishjob btn-block"
                                            onClick={''}
                                          >
                                            REMIND ME
                                          </Button>
                                        </Col>
                                      ) : (
                                        <Row>
                                          <Col lg="6">
                                            <Button
                                              className="btn-startjob btn-block"
                                              onClick={() =>
                                                this.responseFullTimeMyJob(
                                                  myJob.id_candidate_employee,
                                                  'ACCEPT',
                                                )
                                              }
                                            >
                                              APPROVE INTERVIEW
                                            </Button>
                                          </Col>
                                          <Col lg="6">
                                            <Button
                                              className="btn-finishjob btn-block"
                                              onClick={() =>
                                                this.responseFullTimeMyJob(
                                                  myJob.id_candidate_employee,
                                                  'DECLINE',
                                                )
                                              }
                                            >
                                              DECLINE
                                            </Button>
                                          </Col>
                                        </Row>
                                      )}
                                    </Row>
                                  </Col>
                                </Col>
                                <Col>
                                  <Col className="bg-bluecloud p-3 pb-3">
                                    <p className="mb-0 text-12 font-weight-bold">
                                      Job Description
                                    </p>
                                    <p className="text-12 muli-font text-justify job-desc">
                                      {myJob.job_description}
                                    </p>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                            <hr />
                          </div>
                        )
                      })
                    )}
                  </TabPane>
                  {/* ------- End Tab My List Job --------*/}
                  <TabPane
                    tabId="1"
                    className="bg-white p-3"
                    style={{ minHeight: '470px' }}
                  >
                    {listMyJobPartTime.length === 0 ? (
                      <center>
                        <img
                          src={noData}
                          width="280px"
                          className="mx-auto"
                          style={{ marginBottom: '-50px' }}
                        />
                        <Label className="d-block text-16">Data Empty</Label>
                      </center>
                    ) : (
                      listMyJobPartTime.map((myJob) => {
                        let shift = []
                          if(myJob.absen !== undefined){
                              shift =  myJob.absen.split(",");
                          }

                        return (
                          <div className="mb-5">
                            <Col>
                              <img
                                src={banner}
                                width="100%"
                                alt=""
                                style={{ borderRadius: '20px 20px 0 0' }}
                              />
                            </Col>
                            <Col>
                              <Col
                                xs="4"
                                sm="3"
                                md="3"
                                lg="2"
                                className="mb-3"
                                style={{
                                  height: '120px',
                                  borderRadius: '8px',
                                  marginTop: '-65px',
                                }}
                              >
                                <img
                                  src={
                                    myJob.company_logo_image != null
                                      ? config.URL_IMAGE +
                                        'company_logo/' +
                                        myJob.company_logo_image
                                      : logoCompany
                                  }
                                  style={{ zIndex: '1' }}
                                  className="rounded-circle"
                                  alt=""
                                  width="130px"
                                  height="130px"
                                />
                              </Col>
                              <Row>
                                <Col>
                                  <Col>
                                    <Row>
                                      <Col xs="12" sm="6" md="6" lg="4">
                                        <p className="tag-block-blue font-weight-bold">
                                          {myJob.work_type}
                                        </p>
                                      </Col>
                                      <Col className="text-right my-auto">
                                        <p
                                          style={{ cursor: 'pointer' }}
                                          className="text-blue text-14"
                                          onClick={() =>
                                            this.downloadLetterAssignment(
                                              myJob.id_candidate_employee,
                                            )
                                          }
                                        >
                                          <u>Download Letter Assignment</u>
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <p className="mb-1 font-weight-bold text-capitalize">
                                      {myJob.id_job}
                                    </p>
                                    <p className="mb-1 font-weight-bold text-capitalize">
                                      {myJob.job_sub_category}
                                    </p>
                                    <p className="mb-1 text-12">
                                      {myJob.company_name}
                                    </p>
                                    <p className="mb-1 text-12 text-capitalize">
                                      {myJob.work_address},{' '}
                                      {method.capitalizeText(
                                        myJob.village_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.sub_district_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.regency_name,
                                      )}
                                      ,{' '}
                                      {method.capitalizeText(
                                        myJob.province_name,
                                      )}
                                    </p>
                                    <p className="mb-1 text-12">
                                      {myJob.part_time_salary == null
                                        ? 'Rp.0'
                                        : method.formatRupiah(
                                            myJob.part_time_salary,
                                          )}{' '}
                                      / day{' '}
                                    </p>
                                  </Col>
                                  <Col className="bg-grey p-3 mb-4">
                                    <Row>
                                      <Col>
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Slot
                                          </p>
                                          <p className="text-12 muli-font">
                                            {myJob.quota} People
                                          </p>
                                        </div>
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Category
                                          </p>
                                          <p className="text-12 muli-font">
                                            {myJob.job_category}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col>
                                        <div>
                                          <p className="mb-0 text-12 font-weight-bold muli-font">
                                            Working Date
                                          </p>
                                          <p className="mb-0 text-12 muli-font">
                                            {/* {method.formatDateAndTime(myJob.start_part_time_date) + " s/d " + method.formatDateTime(myJob.finish_part_time_date)} */}
                                            {myJob.start_part_time_date +
                                              ' s/d ' +
                                              myJob.finish_part_time_date}
                                            <br />
                                            {/* Sekarang: {this.now()} */}
                                          </p>
                                          <p className="font-weight-bolder mb-1 mt-2">Shift Kerja Part-time</p>
                                              {shift.map((it)=>
                                              <li key={it}>{it}</li>
                                            )}
                                        </div>
                                      </Col>
      

               
                                    </Row>
                                  </Col>
                                  <Col>
                                    <Row>
                                      {isArrived == true ||
                                      myJob.work_status === 'On Proggress' ? (
                                        <Col>
                                          <Button
                                            className="btn-finishjob btn-block"
                                            onClick={() =>
                                              this.modalShowDescAbsencePartTime(
                                                myJob,
                                                'FINISH',
                                              )
                                            }
                                          >
                                            FINISH
                                          </Button>
                                        </Col>
                                      ) : myJob.work_status ===
                                        'Declined' ? null : (
                                        <Row>
                                          <Col lg="6  ">
                                            <Button
                                              disabled={
                                                myJob.work_status === null &&
                                                myJob.is_part_time_can_began ===
                                                  1
                                                  ? false
                                                  : true
                                              }
                                              className="btn-startjob btn-block"
                                              onClick={() =>
                                                this.modalShowDescAbsencePartTime(
                                                  myJob,
                                                  'ARRIVE',
                                                )
                                              }
                                            >
                                              ARRIVE
                                            </Button>
                                          </Col>
                                          <Col lg="6">
                                            <Button
                                              disabled={
                                                myJob.is_part_time_can_began ===
                                                1
                                                  ? true
                                                  : false
                                              }
                                              className="btn-finishjob btn-block"
                                              onClick={() =>
                                                this.modalShowDescAbsencePartTime(
                                                  myJob,
                                                  'DECLINE',
                                                )
                                              }
                                            >
                                              DECLINE
                                            </Button>
                                          </Col>
                                        </Row>
                                      )}
                                    </Row>
                                  </Col>
                                </Col>
                                <Col>
                                  <Col className="bg-bluecloud p-3 pb-3">
                                    <p className="mb-0 text-12 font-weight-bold">
                                      Job Description
                                    </p>
                                    <p className="text-12 muli-font text-justify job-desc">
                                      {myJob.job_description}
                                    </p>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                            <hr />
                          </div>
                        )
                      })
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          {/* Modal Description Absency */}
          <Modal
            isOpen={modalShowDescAbsencyPartTime}
            toggle={this.closeModalDescription}
            backdrop="static"
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-danger btn-close-circle"
              onClick={this.closeModalDescription}
            />
            <ModalBody>
              <Form
                onSubmit={() =>
                  this.absencePartTime(spesificIdCandidate, spesificAbsenceType)
                }
              >
                <h5 className="text-center mb-5">Attention</h5>
                <p className="font-weight-bold text-center text-16">
                  {descriptionModal}
                </p>
                {isNeedReport === true ? (
                  <FormGroup className="mb-0">
                    <Label className="text-14 mb-1">Report</Label>
                    <Input
                      type="text"
                      placeholder="Fill the first report...."
                      onChange={this.handleChange}
                      name="report"
                      id="report"
                      className="input-box-white"
                      required
                    />
                    <br />
                  </FormGroup>
                ) : null}
                <Col lg="6" className="mx-auto">
                  <Button
                    className="btn-block btn-blue"
                    onClick={() =>
                      this.absencePartTime(
                        spesificIdCandidate,
                        spesificAbsenceType,
                      )
                    }
                  >
                    {spesificAbsenceType === 'DECLINE' ? 'Decline' : 'Confirm'}{' '}
                  </Button>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          {/* End Description Absency */}
        </Container>
      </Fragment>
    )
  }

  now = () => {
    const now = new Date()
    const Y = now.getFullYear()
    const m = now.getMonth() + 1
    const d = now.getDate()
    const h = now.getHours()
    const i = now.getMinutes()
    const s = now.getSeconds()
    return `${Y}-${m}-${d} ${h}:${i}:${s}`
  }
}

export default MyListJobs
