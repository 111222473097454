// Import Lib, Component React & Icon  
import React, { Fragment } from 'react';
import HeaderUser from './ComponentUser/HeaderUser';

import { Link } from 'react-router-dom';
import noData from '../Asset/Icon/no-data.svg';

// Styling
import {
    Container, Row, Col, Card, CardBody, Button,
    Label, Modal, ModalBody, Input, InputGroup, Spinner,
    InputGroupAddon
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import * as _ from "lodash";

import * as config from '../Helpers/Config';
import * as api from '../Helpers/Api';
import * as method from '../Helpers/Method';
import * as authHelperMethods from '../Helpers/AuthHelperMethods';
import logoCompany from '../Asset/Logo/logo-company.png';
import logoAvisha from '../Asset/Logo/avisha-logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FulltimeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpenJobFulltime: false,
            listFullTimeJob: [],
            salaryRange: 0,
            location: 'all',
            jobPosition: 'all',
            listRegency: [],
            listJobPosition: [],
            searchString: '',
            spesificData: {},
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
            modalLoading: false
        };
        this.toggleOpenJobFulltime = this.toggleOpenJobFulltime.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
    }

    componentDidMount = async () => {
        await this.getAllJobs();
        await this.getAllRegency();
        await this.getAllJobPositions();
    }

    toggleLoading = () => {
        this.setState(prevState => ({
            modalLoading: !prevState.modalLoading
        }));
    }

    toggleOpenJobFulltime = async (dataJob) => {
        await this.setState({ spesificData: dataJob });
        this.setState(prevState => ({
            modalOpenJobFulltime: !prevState.modalOpenJobFulltime
        }));
    }

    notify() {
        toast("Submit application is successful !")
    }

    filterJobs = async (category, value) => {
        const { location, salaryRange, jobPosition } = this.state
        const newData = {
            key_api: config.KEY_API,
            work_type: "Full-Time",
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {

                if ((location == "all") && (salaryRange == 0) && (jobPosition == "all")) {
                    this.setState({ listFullTimeJob: data.dataAvailableJobs })
                } else {
                    const listFilterCategory = [];
                    const listIdLoanLocation = [];
                    const listIdLoanSalary = [];
                    const listIdLoanPosition = [];
                    if (category == "location") {
                        listFilterCategory.push("1");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if (data.dataAvailableJobs[i].regency_name === value) {
                                listIdLoanLocation.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    } else if (category == "salary") {
                        listFilterCategory.push("2");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if ((parseInt(data.dataAvailableJobs[i].min_salary) < value) && (value < parseInt(data.dataAvailableJobs[i].max_salary))) {
                                listIdLoanSalary.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    }
                    else if (category == "position") {
                        listFilterCategory.push("3");
                        for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                            if (data.dataAvailableJobs[i].position_type === value) {
                                listIdLoanPosition.push(data.dataAvailableJobs[i].id)
                            }
                        }
                    }

                    const allListFilter = [...listIdLoanLocation, ...listIdLoanSalary, ...listIdLoanPosition]
                    var counts = {};
                    allListFilter.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

                    const finalListFilter = []
                    Object.entries(counts).map(([key, value]) => {
                        if (value == listFilterCategory.length) {
                            for (var i = 0; i < data.dataAvailableJobs.length; i++) {
                                if (data.dataAvailableJobs[i].id == key) {
                                    finalListFilter.push(data.dataAvailableJobs[i])
                                }
                            }
                            this.setState({ listFullTimeJob: finalListFilter })
                        }
                    })
                    if (finalListFilter.length === 0) {
                        this.setState({ listFullTimeJob: finalListFilter })
                    }
                }
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    handleChoose = async (element) => {
        await this.setState({ location: element.label });
        this.filterJobs("location", element.label);
    }

    handleChangeSearch = (e) => {
        this.setState({ searchString: e.currentTarget.value });
    }

    sortJob = async (category) => {
        const newData = {
            key_api: config.KEY_API,
            work_type: "Full-Time",
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                if (category == "Slot") {
                    const slotArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => a.quota > b.quota ? 1 : -1)
                        .map((item, i) =>
                            slotArray.push(item)
                        );
                    this.setState({ listFullTimeJob: slotArray })
                } else if (category == "Periode") {
                    const periodArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => new Date(a.deadline_job_vacancy) - new Date(b.deadline_job_vacancy))
                        .map((item) =>
                            periodArray.push(item)
                        );
                    this.setState({ listFullTimeJob: periodArray })
                } else if (category == "Highest Salary") {
                    const maxSalaryArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => parseInt(b.min_salary) > parseInt(a.min_salary) ? 1 : -1)
                        .map((item, i) =>
                            maxSalaryArray.push(item)
                        );
                    this.setState({ listFullTimeJob: maxSalaryArray })
                } else if (category == "Lowest Salary") {
                    const minSalaryArray = [];
                    [].concat(data.dataAvailableJobs)
                        .sort((a, b) => parseInt(a.min_salary) > parseInt(b.min_salary) ? 1 : -1)
                        .map((item, i) =>
                            minSalaryArray.push(item)
                        );
                    this.setState({ listFullTimeJob: minSalaryArray })
                }
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    handleChangeSortPeriod = async (e) => {
        const { isSortingPeriod } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: !isSortingPeriod,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
        })
    }

    handleChangeSortSlot = async (e) => {
        const { isSortingSlot } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: !isSortingSlot,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: false,
        });
    }

    handleChangeSortMax = async (e) => {
        const { isSortingMaxSalary } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: !isSortingMaxSalary,
            isSortingMinSalary: false,
        })
    }

    handleChangeSortMin = async (e) => {
        const { isSortingMinSalary } = this.state;
        if (e.target.checked === true) {
            this.sortJob(e.target.value);
        } else {
            this.getAllJobs();
        }
        this.setState({
            isSortingSlot: false,
            isSortingPeriod: false,
            isSortingMaxSalary: false,
            isSortingMinSalary: !isSortingMinSalary,
        })
    }

    handleChangeRangeSalary = async (e) => {
        this.setState({ salaryRange: e.target.value });
        this.filterJobs("salary", e.target.value);
    }

    handlePosition = async (position) => {
        await this.setState({ jobPosition: position });
        this.filterJobs("position", position);
    }

    getAllJobs = async (e) => {
        const newData = {
            key_api: config.KEY_API,
            work_type: "Full-Time"
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            // this.toggleLoading();
            const res = await api.allJobs(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                // this.toggleLoading();
                this.setState({ listFullTimeJob: data.dataAvailableJobs })
            }
            console.log(data.dataAvailableJobs)
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    applyJob = async (idJob) => {
        const newData = {
            key_api: config.KEY_API,
            id_job: idJob
        };
        const idEmployee = authHelperMethods.getIdEmployee();
        try {
            this.toggleLoading();
            const res = await api.applyJob(idEmployee, {
                params: { data: JSON.stringify(newData) }
            })
            if (res.status === 200) {
                this.getAllJobs();
                this.notify();
                this.toggleLoading();
            }
        } catch (err) {
            this.toggleLoading();
            alert("Error, Please check your connection or status then try refresh your page");
            // console.log("Error Response :", err)
        }
    }

    getAllRegency = async (e) => {
        const newData = {
            key_api: config.KEY_API,
        };
        try {
            const res = await api.getAllRegencies({
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({ listRegency: data.dataAllRegencies.map((k) => ({ value: k.id, label: k.regency_name })) })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    getAllJobPositions = async (e) => {
        const newData = {
            key_api: config.KEY_API,
        };
        try {
            const res = await api.getJobPosition({
                params: { data: JSON.stringify(newData) }
            })
            const { status, data } = res;
            if (status === 200) {
                this.setState({ listJobPosition: data.data })
            }
        } catch (err) {
            //   console.log("Error Response :",err)
        }
    }

    render() {
        const { spesificData, listFullTimeJob, listRegency, salaryRange, searchString, isSortingMaxSalary, isSortingMinSalary, isSortingPeriod, isSortingSlot, listJobPosition } = this.state
        const lowercasedFilter = searchString.toLowerCase();
        const filteredData = listFullTimeJob.filter(item => {
            return Object.keys(item).some(key =>
                item['company_name'].toLowerCase().includes(lowercasedFilter)
            );
        });

        return (
            <Fragment>
                <HeaderUser />
                <ToastContainer />
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                    <h5 className="font-weight-bolder mb-0">Fulltime Job </h5>
                                    <p className="text-12 mb-0">Showing fulltime job</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col>
                            <Row>
                                <Col xs="12" sm="12" md="4" lg="3" xl="3" className="pl-0 pr-0">
                                    <Col className="bg-white rounded mb-3 p-3">
                                        <Col className="mb-3">
                                            <Label className="d-block text-14 font-weight-bolder mb-1">Search</Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    id="search"
                                                    placeholder="Company Name"
                                                    className="search input-box-grey text-12"
                                                    value={searchString}
                                                    onChange={this.handleChangeSearch} />
                                                <InputGroupAddon addonType="append">
                                                    <Button id="btn-FindTalent" name="btn-find-talent" className="btn-blue" onChange=""><FontAwesomeIcon icon={faSearch} /></Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </Col>
                                        <Col className="mb-3">
                                            <Label className="d-block text-14 font-weight-bolder mb-1" for="kotaAddress">Location</Label>
                                            <Select
                                                name="form-field-name"
                                                options={listRegency}
                                                onChange={this.handleChoose.bind(this)}
                                            />
                                        </Col>
                                        <Col>
                                            <Label className="d-block text-14 font-weight-bolder mb-1">Salary Range {method.formatRupiah(salaryRange)}</Label>
                                            <Input type="range" id="rangeSalary" className="rangeSalary" onChange={this.handleChangeRangeSalary} defaultValue={0} min="0" max="50000000" step="10000" />
                                        </Col>
                                    </Col>
                                    <Col xs="12" sm="12" className="bg-white rounded mb-3 p-3">
                                        <Col className="mb-3">
                                            <Label className="d-block text-14 font-weight-bolder mb-1">Sort By:</Label>
                                            <div className="spacing-checkbox text-12">
                                                <Input type="checkbox" id="Periode" checked={isSortingPeriod === false ? false : true} className="checkboxLocation check-box" value="Periode" onChange={this.handleChangeSortPeriod} /> Periode<br />
                                                <Input type="checkbox" id="Slot" checked={isSortingSlot === false ? false : true} className="checkboxLocation check-box" value="Slot" onChange={this.handleChangeSortSlot} /> Slot<br />
                                                <Input type="checkbox" id="Highest Salary" checked={isSortingMaxSalary === false ? false : true} className="checkboxLocation check-box" value="Highest Salary" onChange={this.handleChangeSortMax} /> Highest Salary<br />
                                                <Input type="checkbox" id="Lowest Salary" checked={isSortingMinSalary === false ? false : true} className="checkboxLocation check-box" value="Lowest Salary" onChange={this.handleChangeSortMin} /> Lowest Salary<br />
                                            </div>
                                        </Col>
                                    </Col>
                                </Col>
                                <Col xs="12" sm="12" md="8" lg="9" className="pl-0 pr-0">
                                    <Col className="mb-3">
                                        <p className="font-weight-bolder mb-2">Job Positions</p>
                                        <Row className="pl-2 pr-2">
                                            {listJobPosition.map((position) => {
                                                return (
                                                    <Button className="text-14 p-2 category-job btn-blue m-1" onClick={() => this.handlePosition(position.position_type)} value={""}>
                                                        {position.position_type}
                                                    </Button>
                                                );
                                            })}
                                        </Row>
                                        <hr />
                                    </Col>
                                    <Col>
                                        <Row>
                                            {
                                                filteredData.length === 0 ?
                                                    <div className="text-center mx-auto">
                                                        <img src={noData} width="300px" className="mx-auto" />
                                                        <h5 className="Muli-font font-weight-bold mt-n5">Data Empty</h5>
                                                    </div>
                                                    : filteredData.map((job) => {
                                                        return (
                                                            <Col lg="4" className="mb-3">
                                                                <Card>
                                                                    <CardBody>
                                                                        <Col className="pl-0 pr-0 pb-3 border-bottom text-center d-inline-block ">
                                                                            <Col className="pl-0 pr-0">
                                                                                <img src={job.company_logo_image != null ? config.URL_IMAGE + "company_logo/" + job.company_logo_image : logoCompany} style={{ zIndex: "1" }} className="rounded-circle" alt="" width="130px" height="130px" />
                                                                            </Col>
                                                                            <Col className="text-12 font-weight-bold pl-0 pr-0">{job.company_name}</Col>
                                                                        </Col>
                                                                        <Col className="border-bottom pl-0 pr-0 text-14">
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="5">
                                                                                    <Label className="my-auto pt-2 pb-2">Job Type</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.work_type}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="border-bottom pl-0 pr-0 text-14" style={{ height: '60px', overflowY: 'scroll' }}>
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="5">
                                                                                    <Label className="my-auto pt-2 pb-2">Position</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.job_sub_category}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="border-bottom pl-0 pr-0 text-14" style={{ height: '130px', overflowY: 'scroll' }}>
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                                    <Label className="my-auto pt-2 pb-2">Location</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.work_address}, {method.capitalizeText(job.village_name)}, {method.capitalizeText(job.sub_district_name)}, {method.capitalizeText(job.regency_name)}, {method.capitalizeText(job.province_name)}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="border-bottom pl-0 pr-0 text-14">
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                                    <Label className="my-auto pt-2 pb-2">Salary (Rp)</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.is_hide_nominal_salary === null ? method.formatMoney(job.min_salary) + " - " + method.formatMoney(job.max_salary) : "Confidential"}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="border-bottom pl-0 pr-0 text-14 mb-3">
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                                    <Label className="my-auto pt-2 pb-2">Slot</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.quota} Person</Label>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xs="4" sm="3" md="6" lg="6">
                                                                                    <Label className="my-auto pt-2 pb-2">Deadline</Label>
                                                                                </Col>
                                                                                <Col className="text-right">
                                                                                    <Label className="my-auto pt-2 pb-2">{job.deadline_job_vacancy != null ? method.formatDateDay(job.deadline_job_vacancy) : "-"}</Label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col className="pl-0 pr-0">
                                                                            <Row>
                                                                                <Col className="text-left">
                                                                                    <Link to="#" onClick={() => this.toggleOpenJobFulltime(job)}>
                                                                                        <Label className="text-success muli-font text-center mb-3"><FontAwesomeIcon icon={faEye} className="text-14" /> See more</Label>
                                                                                    </Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Button className="btn-info btn-block" onClick={() => this.applyJob(job.id)}>Apply</Button>
                                                                        </Col>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    })}
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                    <Modal isOpen={this.state.modalOpenJobFulltime} toggle={this.toggleOpenJobFulltime}>
                        <ModalBody>
                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleOpenJobFulltime} />
                            <Col className="border-bottom pl-0 pr-0">
                                <Col>
                                    <Row>
                                        <Col lg="auto" className="text-center">
                                            <img src={spesificData.company_logo_image !== null ? config.URL_IMAGE + "company_logo/" + spesificData.company_logo_image : logoCompany} style={{ zIndex: "1" }} className="rounded-circle" alt="" width="130px" height="130px" />
                                        </Col>
                                        <Col className="text-14">
                                            <p className="font-weight-bold mb-0">{spesificData.position_type}</p>
                                            <p className="mb-0">{spesificData.company_name}</p>
                                            <p className="mb-0">{spesificData.work_type}</p>
                                            <p className="mb-0">{spesificData.work_address}, {method.capitalizeText(spesificData.village_name)}, {method.capitalizeText(spesificData.sub_district_name)}, {method.capitalizeText(spesificData.regency_name)}, {method.capitalizeText(spesificData.province_name)}</p>
                                            <p className="mb-0">{spesificData.is_hide_nominal_salary === null ? method.formatRupiah(spesificData.min_salary) + " - " + method.formatRupiah(spesificData.max_salary) : "Salary is secret"}</p>
                                            <Row>
                                                <Col lg="auto" className="mb-3">
                                                    <Label className="text-green mb-0">{method.formatDateDay(spesificData.posting_time)}</Label>
                                                </Col>
                                                {/* .
                                          <Col>
                                            <Label className="mb-0">{slot} Applicants</Label>  
                                          </Col> */}
                                            </Row>
                                            <Col className="mb-3">
                                                <Row>
                                                    <Col lg="8" className="pl-0 pr-0">
                                                        <Button className="btn-block btn-blue" onClick={() => this.applyJob(spesificData.id)}>APPLY</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                            <Col className="border-bottom text-14 mt-3">
                                <Row>
                                    <Col>
                                        <p className="font-weight-bolder mb-0">Slot</p>
                                        <p className="text-12">{spesificData.quota} Person</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="font-weight-bolder mb-0">Category</p>
                                        <p className="text-12">{spesificData.job_category} - {spesificData.job_sub_category}</p>
                                    </Col>
                                    <Col>
                                        <p className="font-weight-bolder mb-0">Period</p>
                                        <p className="text-12 mb-0">
                                            {spesificData.deadline_job_vacancy != null ?
                                                method.formatDateDay(spesificData.posting_time) + " - " + method.formatDateDay(spesificData.deadline_job_vacancy)
                                                :
                                                method.formatDateDay(spesificData.posting_time)
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="border-bottom text-14 mt-3">
                                <p className="font-weight-bolder mb-0">Job Description</p>
                                <p className="text-justify">
                                    {spesificData.job_description}
                                </p>
                            </Col>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.modalLoading} toggle={this.toggleLoading} backdrop="static">
                        <ModalBody className="text-center p-5">
                            <Spinner color="warning" style={{ width: '8rem', height: '8rem', borderWidth: '7px' }} />
                            <img src={logoAvisha} alt="logo-google" width="60px" className="position-absolute" style={{ margin: '30px 0 0 -95px' }} />
                            <Label className="d-block mt-3">Please Wait</Label>
                        </ModalBody>
                    </Modal>
                </div>
            </Fragment>
        );
    }
};

export default FulltimeList;