import React, { Fragment }                                  from 'react';
import HeaderClient                                         from './ComponentClient/HeaderClient';
import * as authHelperMethods                               from '../Helpers/AuthHelperMethods';
import * as api                                             from '../Helpers/Api';
import * as config                                          from '../Helpers/Config';
import Select                                               from 'react-select';
import NumberFormat                                         from 'react-number-format';
import * as method                                          from '../Helpers/Method';
// import { DownloadTableExcel }                               from 'react-export-table-to-excel';
import DatePicker                                           from 'react-datepicker';
import { registerLocale, setDefaultLocale }                 from 'react-datepicker';
import id                                                   from 'date-fns/locale/id';
import makeAnimated from 'react-select/animated';
import {FontAwesomeIcon}                                    from '@fortawesome/react-fontawesome'
import {faTimesCircle}                                      from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import '../Css/style.css';
import {
    Container, Row, Col, Nav, NavItem,
    NavLink, Modal, ModalHeader, ModalBody,
    ModalFooter,
}                                                           from 'reactstrap'
import { DownloadTableExcel }                               from 'react-export-table-to-excel';
const today = new Date();
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
class Overtime extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        id:             0,
        dataLemburs:    [],
        modalAksi:      false,
        pilihans:        [
            {
                value: 1,
                label: 'Approve'
            },
            {
                value: 2,
                label: 'Reject'
            }
        ],
        idLembur:       0,
        keputusan:      0,
        dari: formatDate(today),
        sampai: formatDate(lastDayOfMonth)
    }

    componentDidMount = async () => {
        await authHelperMethods.checkIsLogin();
        await this.getId();
        await this.getAllLembur();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({id});
    }

    _generateParam = (param = {}) => {
        param.key_api = config.KEY_API;
        return {params: {data: JSON.stringify(param)}};
    }

    _generateStatus = status => {
        let hasil;
        if(status == 0) {
            hasil = <span className="text-warning">New</span>;
        } else if(status == 1) {
            hasil = <span className="text-success">Approved</span>;
        } else if(status == 2) {
            hasil = <span className="text-danger">Rejected</span>;
        }
        return hasil;
    }

    getAllLembur = async() => {
        const {id, dari, sampai} = this.state;
        const res = await api.getAllLembur(this._generateParam({id_client: id , dari, sampai }));
        this.setState({dataLemburs: res.data.data});
    }

    toggleModalAksi = idLembur => {
        this.setState((prevState) => ({modalAksi: !prevState.modalAksi}));
        this.setState({idLembur});
    }

    action = async() => {
        const {idLembur, keputusan} = this.state;
        if(keputusan == 0) {
            alert('Required!');
        } else {
            const res = await api.updateLembur(this._generateParam({id: idLembur, keputusan}));
            const {error, message} = res.data;
            if(error) {
                alert(message);
            } else {
                window.location.reload();
            }
        }
    }

    tableRef = React.createRef();

    sekarang = () => {
        const today = new Date();
        const Y = today.getFullYear();
        const m = ''+(today.getMonth()+1);
        const d = ''+today.getDate();
        return `${Y}-${m}-${d}`;
    }
    handleChange = e => this.setState({[e.target.name]: e.target.value});

    bodyHTML = () => <div >
           <div className="row mb-2">
                <div className="col-md">
                    <label htmlFor="dari">Dari</label>
                    <input className="form-control form-control-sm" type="date" value={this.state.dari} id="dari" name="dari" onChange={this.handleChange}/>
                </div>
                <div className="col-md">
                    <label htmlFor="sampai">Sampai</label>
                    <input className="form-control form-control-sm" type="date" value={this.state.sampai} id="sampai" name="sampai" onChange={this.handleChange}/>
                </div>
               
            </div>

        <button className="btn btn-primary btn-sm mb-2"onClick={this.getAllLembur} >Filter</button>;
        <DownloadTableExcel
            filename={`Overtime ${this.sekarang()}`}
            sheet="Overtime"
            currentTableRef={this.tableRef.current}
        >      
        <button className="btn btn-success btn-sm mb-2">Export</button>
        </DownloadTableExcel>
        <div className="table-responsive">
            <table className="table table-hover table-bordered table-sm" ref={this.tableRef}>
                <thead className="thead-dark">
                    <tr className="text-center">
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Date</th>
                        <th>Jam</th>
                        <th>Description</th>
                        <th>Created</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{this.tbodyHTML()}</tbody>
            </table>
        </div>
    </div>;

    tbodyHTML = () => {
        const {dataLemburs} = this.state;
        return dataLemburs.map(({id, full_name, tanggal_lembur, keperluan, jam_lembur, created_at, status, id_lembur}, i) => <tr key={i}>
            <td className="text-center">{method.fixId(id)}</td>
            <td>{full_name}</td>
            <td className="text-center">{tanggal_lembur}</td>
            <td className="text-center">{jam_lembur}</td>
            <td>{keperluan}</td>
            <td className="text-center">{created_at}</td>
            <td className="text-center">{this._generateStatus(status)}</td>
            <td className="text-center">{(status != 0)? null : <button className="btn btn-info btn-sm" onClick={() => this.toggleModalAksi(id_lembur)}>Action</button>}</td>
        </tr>);
    }

    handleChangePilihan = (e, u) => this.setState({[u.name]: e.value});

    modalAksiHTML = () => {
        const {modalAksi, pilihans} = this.state;
        return <Modal isOpen={modalAksi} toggle={this.toggleModalAksi}>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger btn-close-circle" onClick={this.toggleModalAksi}/>
            <ModalHeader>Overtime's Action</ModalHeader>
            <ModalBody>
                <Select name="keputusan" id="keputusan" options={pilihans} onChange={this.handleChangePilihan}/>
            </ModalBody>
            <ModalFooter><button className="btn btn-primary" onClick={this.action}>Confirm</button></ModalFooter>
        </Modal>;
    }

    render() {
        return <Fragment>
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                <div className="bg-white box-shadow">
                    <Container className="pt-2 pb-2 pl-3">
                        <Row className="pl-3">
                            <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Overtime</h5>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="container mt-4">
                        <div className="card">
                            <div className="card-body">{this.bodyHTML()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {this.modalAksiHTML()}
        </Fragment>;
    }
}

export default Overtime;