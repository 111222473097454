// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
// import { Link }                                             from 'react-router-dom';
// Styling
import { Container, Row, Col }                              from 'reactstrap';   
// import '../Css/Footer.css';                         


class Pricing extends React.Component {
    render() {                                     
        return (          
            <Fragment>                        
                <Container>
                    <Row>
                        <Col>Biaya Layanan di Avisha</Col>
                    </Row>
                    <p className="text-justify">
                    Pelanggan setuju untuk membayar kepada AVISHA semua biaya dan ongkos sesuai
                    dengan paket yang dipilih di Situs Web, atau sesuai dengan Perjanjian Komersial.
                    </p>
                </Container>
            </Fragment>
        );   
    }
};

export default Pricing;