// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
import HeaderUser from './ComponentUser/HeaderUser'
import ContactInfo from './ComponentUser/InfoProfile/ContactInfoUser'
import PersonalInfo from './ComponentUser/InfoProfile/PersonalInfoUser'
import EmergencyContact from './ComponentUser/InfoProfile/EmergencyContact'
import EducationUser from './ComponentUser/InfoProfile/EducationUser'
import ExperienceUser from './ComponentUser/InfoProfile/ExperienceUser'
import SkillUser from './ComponentUser/InfoProfile/SkillUser'
import BankAccount from './ComponentUser/InfoProfile/BankAccount.jsx'
import FileUser from './ComponentUser/InfoProfile/FileUser'

import logoAvisha from '../Asset/Logo/avisha-logo.svg'
import imgProfile from '../Asset/Icon/img-profile.png'

// Styling
import {
  Container,
  Row,
  Col,
  Label,
  Button,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap'

import '../Css/Main.css'

import * as config from '../Helpers/Config'
import * as api from '../Helpers/Api'
import * as authHelperMethods from '../Helpers/AuthHelperMethods'
import { ToastContainer, toast } from 'react-toastify'
import * as helpersApiCms from '../Helpers/HelpersApiCms'

import 'react-toastify/dist/ReactToastify.css'

class ProfileUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fullName: '',
      profileImage: '',
      modalLoading: false,
      profileImageFile: null,
      domicileAddress: '',
      idCardAddress: '',
      phoneNumber: '',
      email: '',
      idEmployee: 0,
    }
    this.toggleLoading = this.toggleLoading.bind(this)
  }

  componentDidMount = async () => {
    await this.getDataEmployee()
  }

  toggleLoading = () => {
    this.setState((prevState) => ({
      modalLoading: !prevState.modalLoading,
    }))
  }

  handleChangePhotoProfile = async (event) => {
    console.log(event.target.files[0])
    await this.setState({
      profileImageFile: event.target.files[0],
      profileImage: URL.createObjectURL(event.target.files[0]),
    })
    this.savePhotoProfile()
  }

  notify() {
    toast('Berhasil update photo profile !')
  }

  savePhotoProfile = async () => {
    const { profileImageFile } = this.state
    const idEmployee = authHelperMethods.getIdEmployee()
    let newData = new FormData()
    newData.append('_methode', 'PUT')
    newData.append('key_api', config.KEY_API)
    newData.append('profile_image', profileImageFile)
    try {
      const res = await api.savePhotoProfile(
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: newData,
        },
        idEmployee,
      )
      const { status } = res.data
      this.notify()

      if (status === 200) {
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  getDataEmployee = async (e) => {
    const newData = { key_api: config.KEY_API }
    const idEmployee = authHelperMethods.getIdEmployee()
    try {
      const res = await api.profileEmployee(idEmployee, {
        params: { data: JSON.stringify(newData) },
      })
      const { status, data } = res
      if (status === 200) {
        const {
          full_name,
          email,
          profile_image,
          phone_number,
          id_card_address,
          domicile_address,
        } = data.dataProfile
        this.setState({
          idEmployee: idEmployee,
          fullName: full_name,
          profileImage:
            profile_image != null
              ? config.URL_IMAGE_EMPLOYEE +
                'image_profile_employee/' +
                profile_image
              : imgProfile,
          domicileAddress: domicile_address,
          idCardAddress: id_card_address,
          phoneNumber: phone_number,
          email: email,
        })
      }
    } catch (err) {
      //   console.log("Error Response :",err)
    }
  }

  handleEditProfile() {
    document.location.href = '/EditProfileUser'
  }

  
  downloadCV = async (idCandidate) => {
    try {
      this.toggleLoading()
      const res = await helpersApiCms.lookCv(idCandidate)
      const { status, data } = res
      if (status === 200) {
        this.toggleLoading()
        let FileSaver = require('file-saver')
        FileSaver.saveAs(data.data[0]['url'], 'Assignment Letter.pdf')
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
    // console.log('handleCameraStop', stream);
  }

  render() {
    const { profileImage, idEmployee } = this.state
    return (
      <Fragment>
        <ToastContainer />
        <HeaderUser />
        <div className="pb-5 muli-font bg-body-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Profile</h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="mt-4">
            <Col className="bg-white rounded-lg pt-4 pb-4 pl-0 pr-0">
              <Container className="pl-0 pr-0">
                <Col
                  xs="11"
                  sm="4"
                  md="4"
                  lg="3"
                  xl="2"
                  className="ml-auto mr-3 mb-3"
                >
                  <Button
                    className="btn-blue-normal btn-block"
                    onClick={() => this.downloadCV(idEmployee)}
                  >
                    Download CV
                  </Button>
                </Col>
                <Col>
                  <Row>
                    <Col className="text-center">
                      <img
                        src={profileImage}
                        alt=""
                        className="border border-info rounded-circle p-1 mb-3 my-auto "
                        width="150px"
                        height="150px"
                      />
                    </Col>
                    <Col
                      lg="7"
                      className="ml-auto mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0"
                    >
                      <ContactInfo />
                    </Col>
                  </Row>
                </Col>
                <Col lg="7" className="ml-auto">
                  <PersonalInfo />
                </Col>
                <Col lg="7" className="ml-auto">
                  <EducationUser />
                </Col>
                <Col lg="7" className="ml-auto">
                  <SkillUser />
                </Col>
                <Col lg="7" className="ml-auto">
                  <ExperienceUser />
                </Col>
                <Col lg="7" className="ml-auto">
                  <FileUser />
                </Col>
                <Col lg="7" className="ml-auto">
                  <EmergencyContact />
                </Col>
                <Col lg="7" className="ml-auto">
                  <BankAccount />
                </Col>
              </Container>
            </Col>
          </Container>
          <Modal
            isOpen={this.state.modalLoading}
            toggle={this.toggleLoading}
            backdrop="static"
          >
            <ModalBody className="text-center p-5">
              <Spinner
                color="warning"
                style={{ width: '8rem', height: '8rem', borderWidth: '7px' }}
              />
              <img
                src={logoAvisha}
                alt="logo-google"
                width="60px"
                className="position-absolute"
                style={{ margin: '30px 0 0 -95px' }}
              />
              <Label className="d-block mt-3">Please Wait</Label>
            </ModalBody>
          </Modal>
        </div>
      </Fragment>
    )
  }
}

export default ProfileUser
