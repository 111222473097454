// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
import HeaderClient                                         from './ComponentClient/HeaderClient';

// Styling
import { Container, Row, Col, Button, Form, 
         FormGroup, Label, Input, Modal, ModalBody }        from 'reactstrap';   

import '../Css/Main.css';
import * as authHelperMethods                               from  '../Helpers/AuthHelperMethods';
import * as config                                          from '../Helpers/Config';
import * as api                                             from '../Helpers/Api';

class ProfileClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:0,
            dataClient:{},
            modalSave: false,
            modalPassword: false,
            accountName:"",
            accountEmail:"",
            accountPhoneNumber:"",
            positionInCompany:"",
            password:""
        };
        this.toggleModalSave = this.toggleModalSave.bind(this);
        this.toggleModalPin = this.toggleModalPin.bind(this);
    }
    
    componentDidMount= async () => {
        await this.getId();
        await this.getDataClient();
    }

    getId = () => {
        const id = authHelperMethods.getIdClient();
        this.setState({ id: id});
    }

    getDataClient = async () => {
        const { id } = this.state;
        const newData = {key_api:config.KEY_API};
        try{
            const res = await api.getDataClient(id,
                {params:{
                    data:JSON.stringify(newData)
                }
            })
            const { position_in_company, account_name, account_email, account_phone_number } = res.data.data;
            await this.setState({
                accountName:account_name,
                accountEmail:account_email,
                accountPhoneNumber:account_phone_number,
                positionInCompany:position_in_company
            });
        } catch(err){
            console.log("Error Response :",err)
        }
    }


    toggleModalSave() {
        this.setState(prevState => ({
          modalSave: !prevState.modalSave
        }));
    }

    toggleModalPin() {
        this.setState(prevState => ({
          modalPin: !prevState.modalPin
        }));
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSave = async () => {
        const {id, accountName, accountEmail, accountPhoneNumber, positionInCompany, password } = this.state;
        const newData = {
            key_api:config.KEY_API,
            id_client_company:id,
            account_name:accountName,
            account_email:accountEmail,
            account_phone_number:accountPhoneNumber,
            position_in_company:positionInCompany,
            password:password
        }
        try{
            const res = await api.updateProfileAccount({
                params: {
                    data: JSON.stringify(newData)
                }
            })
            const { error } = res.data;
            if (error === false) {
                window.location.href = config.WEB_URL + `DashboardClient`;
            } else {
                alert("Password Salah");
            }
        } catch(err){
            console.log("Error Response :",err)
        }    
    }

    render() {            
        const {
                accountName,
                accountEmail,
                accountPhoneNumber,
                positionInCompany,
        } = this.state;                         
        return (          
            <Fragment>                        
                <HeaderClient/>
                <div className="pb-5 muli-font bg-body-grey">
                    <div className="bg-white box-shadow">
                        <Container className="pt-2 pb-2 pl-3">
                            <Row className="pl-3">
                                <Col className="verticalLine-TitlePage p-2">
                                <h5 className="font-weight-bolder mb-0 pt-2 pb-2">Personal Profile</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="mt-4">
                        <Col md="8" className="bg-white rounded-lg pt-4 pb-4 pl-0 pr-0 mx-auto">
                            <Container>
                                {/* <Row> */}
                                    {/* <Col>
                                        <center>
                                            <img src={imgProfile} name="photoUser" id="photouserclient" className="rounded-circle border p-1 mb-4"/>
                                            <Label className="d-block font-weight-bold text-14 mb-0">{"Name User Client"}</Label>
                                            <Label className="d-block text-success text-14">{"email@userclient.com"}</Label>
                                        </center>
                                        <Col md="4" lg="6" className="mx-auto"><Button className="btn-block btn-primary">Change Photo</Button></Col>
                                    </Col> */}
                                    <Col>
                                        {/* <Form> */}
                                            <FormGroup className="mb-3">
                                                <Label className="font-weight-bold">Full Name</Label>
                                                <Input type="text" onChange={this.handleChange} name="accountName" value={accountName} />
                                            </FormGroup>
                                            {/* <FormGroup className="mb-3">
                                                <Label className="font-weight-bold">Date Birth</Label>
                                                <Input type="date" name="dateBirthUser" id="datebirthuser" className="" placeholder="datetime placeholder" onChange=""/>
                                            </FormGroup> */}
                                            <FormGroup className="mb-3">
                                                <Label className="font-weight-bold">Email</Label>
                                                <Input type="email" onChange={this.handleChange} value={accountEmail} name="accountEmail"  />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label className="font-weight-bold">Phone Number</Label>
                                                <Input type="number" onChange={this.handleChange} value={accountPhoneNumber} name="accountPhoneNumber" maxLength="13" />
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label className="font-weight-bold">Position</Label>
                                                <Input type="text" onChange={this.handleChange} value={positionInCompany} name="positionInCompany" className=""/>
                                            </FormGroup>
                                            <Button className="btn-block btn-blue-normal" onClick={this.toggleModalSave}>Save</Button>
                                        {/* </Form> */}
                                    </Col>
                                {/* </Row> */}
                            </Container>
                        </Col>
                    </Container>
                    <Container>
                        <Modal isOpen={this.state.modalSave} toggle={this.toggleModalSave} backdrop="static">
                            <ModalBody className="p-5 text-center">
                                <Label className="d-block font-weight-bold text-20">Are you sure change data profile? </Label>
                                <Col className="mt-5">
                                    <Row>
                                        <Col><Button className="btn-block btn-warning rounded-lg" onClick={this.toggleModalSave}>Cancel</Button></Col>
                                        <Col><Button className="btn-block btn-blue-normal rounded-lg" onClick={this.toggleModalPin}>Yes, sure</Button></Col>
                                    </Row>
                                </Col>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={this.state.modalPin} toggle={this.toggleModalPin} backdrop="static">
                            <ModalBody className="p-5 text-center">
                                <Label className="d-block font-weight-bold text-20">Confirmation Change Data</Label>
                                <Form className="pt-5 pb-5">
                                    <Label className="d-block text-16">Password</Label>
                                    <Input type="password" onChange={this.handleChange} name="password" id="passuser"/>
                                    <Col className="mt-4 pl-0 pr-0">
                                        <Button className="btn-block btn-blue-normal rounded-lg" onClick={this.handleSave}>Confirm</Button>
                                    </Col>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Container>
                </div>
            </Fragment>
        );   
    }
};

export default ProfileClient;