// Import Lib, Component React & Icon
import React, { Fragment } from 'react'
import HeaderClient from './ComponentClient/HeaderClient'
import LogoBCA from '../Icon/bca.png'

// Styling
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardFooter,
  CardHeader,
} from 'reactstrap'
import * as config from '../Helpers/Config'
import * as api from '../Helpers/Api'
import * as method from '../Helpers/Method'
import * as authHelperMethods from '../Helpers/AuthHelperMethods'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import * as helpersApiCms from '../Helpers/HelpersApiCms'
import { saveAs } from 'file-saver'

class WaitingPaymentGateway extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      totalPayment: 0,
      accountName: '',
      deadline: '',
      status: '',
      bankName: '',
      paymentMethod: '',
      bankAccountNumber: 0,
      idTransaction: 0,
      dataBankTypes: [],
      imagePaymentTransaction: '',
      imageFile: null,
      isPayment: false,
      isUploadFile: false,
      bank: {},
      va_url_image: '',
      statusIndo: '',
      statusBayar: '',
    }
  }

  componentDidMount = async () => {
    await this.getId()
    await this.getPaymentGatewayHistory()
    await this.getBank()
  }

  getPaymentGatewayHistory = async () => {
    const idTransaction = localStorage.getItem('id_transaction')
    const res = await api.getPaymentGatewayHistory(idTransaction, {
      params: { data: JSON.stringify({ key_api: config.KEY_API }) },
    })
    const { history } = res.data
    this.setState({
      idTransaction: history.id,
      totalPayment: history.total_payment,
      bankName: history.bank_name,
      deadline: history.payment_deadline,
      isPayment:
        history.status_payment == 'Waiting Confirmation' ||
        history.status_payment == 'Waiting Payment'
          ? false
          : true,
      imagePaymentTransaction:
        history.payment_receipt_image != null
          ? config.URL_IMAGE +
            'file_transaction/' +
            history.payment_receipt_image
          : '',
      va_url_image: history.va_url_image,
      statusIndo: this.statusIndo(history.status_payment),
      statusBayar: this.statusBayar(history.status_payment),
    })
  }

  statusBayar = (status) => {
    let hasil = ''
    if (status == 'Waiting Payment') {
      hasil += 'Belum dibayar'
    } else if (status == 'Payment Reject' || status == 'Payment Failed') {
      hasil += 'Pembayaran tidak diterima'
    } else if (
      status == 'Payment Success' ||
      status == 'Waiting Confirmation'
    ) {
      hasil += 'Sudah dibayar'
    }
    return hasil
  }

  statusIndo = (status) => {
    let hasil = ''
    if (status == 'Waiting Payment' || status == 'Waiting Confirmation') {
      hasil += 'Menunggu Pembayaran'
    } else if (status == 'Payment Reject' || status == 'Payment Failed') {
      hasil += 'Transaksi Gagal'
    } else if (status == 'Payment Success') {
      hasil += 'Transaksi Sukses'
    }
    return hasil
  }

  getBank = async () => {
    // const idBank = localStorage.getItem('id_bank');
    // const bank = await api.getSingleBank(idBank, {
    //     params: { data: JSON.stringify({key_api:config.KEY_API}) }
    // });
    const { bankName } = this.state
    const bank = await api.getSingleBank(bankName, {
      params: { data: JSON.stringify({ key_api: config.KEY_API }) },
    })
    this.setState({ bank: bank.data.bank })
  }

  uploadFile = async () => {
    const { imageFile, idTransaction, id } = this.state
    let newData = new FormData()
    newData.append('_methode', 'PUT')
    newData.append('key_api', config.KEY_API)
    newData.append('id_transaction', idTransaction)
    newData.append('image_file', imageFile)
    try {
      const res = await api.updateTransaction(
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: newData,
        },
        id,
      )
      const { status } = res
      if (status === 200) {
        window.location.href = config.WEB_URL + `WaitingPayment`
      } else {
        alert('File kebesaran')
      }
    } catch (err) {
      console.log('Error Response :', err)
      alert('server error')
    }
  }

  countDownDueDate = (dueDate) => {
    // Set the date we're counting down to
    var x
    if (dueDate == 'stop') {
      clearInterval(x)
      this.uploadFile()
    } else {
      var countDownDate = new Date(dueDate).getTime()

      // Update the count down every 1 second
      x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Output the result in an element with id="demo"
        document.getElementById('countDown').innerHTML =
          hours +
          ' Jam ' +
          ': ' +
          minutes +
          ' Menit ' +
          ': ' +
          seconds +
          ' Detik '

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(x)
          document.getElementById('demo').innerHTML = 'EXPIRED'
        }
      }, 1000)
    }
  }

  handleBrowseFile = (event) => {
    this.setState({
      imagePaymentTransaction: URL.createObjectURL(event.target.files[0]),
      imageFile: event.target.files[0],
      isUploadFile: true,
    })
  }

  getId = () => {
    const id = authHelperMethods.getIdClient()
    this.setState({ id: id })
  }

  downloadInvoice = async (idTransaction) => {
    try {
      const res = await helpersApiCms.downloadInvoice(idTransaction)
      const { status, data } = res
      if (status === 200) {
        let FileSaver = require('file-saver')
        FileSaver.saveAs(data.data[0]['url'], 'Invoice.pdf')
      }
    } catch (err) {
      console.log('Error Response :', err)
    }
  }

  render() {
    const {
      totalPayment,
      bankName,
      deadline,
      isPayment,
      idTransaction,
      statusIndo,
      statusBayar,
    } = this.state
    return (
      <Fragment>
        <HeaderClient />
        <div className="pb-5 muli-font bg-grey">
          <div className="bg-white box-shadow">
            <Container className="pt-2 pb-2 pl-3">
              <Row className="pl-3">
                <Col className="verticalLine-TitlePage p-2">
                  <h5 className="font-weight-bolder mb-0 pt-2 pb-2">
                    Konfirmasi Pembayaran
                  </h5>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="mt-4">
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Link className="text-decoration-none">
                      <Row onClick={() => this.downloadInvoice(idTransaction)}>
                        <Col className="text-left">
                          <Label className="d-block font-weight-bold text-14 text-black mb-0">
                            Download Invoice
                          </Label>
                        </Col>
                        <Col className="text-right">
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="text-success"
                          />
                        </Col>
                      </Row>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <Label className="d-block font-weight-bold text-20">
                      Metode Pembayaran Payment Gateway
                    </Label>
                    <hr className="mt-0" />
                    <Row>
                      <Col md="5">
                        <Label className="d-block text-16">
                          Tanggal Jatuh Tempo
                        </Label>
                      </Col>
                      <Col>
                        <Label className="d-block text-16">
                          {': '} {method.formatDate(deadline)}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="5">
                        <Label className="d-block text-16">Bank Tujuan</Label>
                      </Col>
                      <Col>
                        <Label className="d-block text-16">
                          {': '} {bankName}
                        </Label>
                      </Col>
                    </Row>
                    {/* <Row>
                                            <Col md="5"><Label className="d-block text-16">Status</Label></Col>
                                            <Col>
                                                <Label className="d-block text-16">{': '} <span className="text-red">{statusBayar}</span></Label>
                                            </Col>
                                        </Row> */}
                    <Row>
                      <Col md="5">
                        <Label className="d-block text-16">
                          Status Transaksi
                        </Label>
                      </Col>
                      <Col>
                        <Label className="d-block text-16">
                          {': '} <span className="text-red">{statusIndo}</span>
                        </Label>
                      </Col>
                    </Row>
                    <Col className="bg-blue rounded-sm p-2">
                      <Row>
                        <Col md="5">
                          <Label className="d-block text-white font-weight-bold text-16 mb-0">
                            Total Bayar
                          </Label>
                        </Col>
                        <Col>
                          <Label className="d-block text-white font-weight-bold text-16 mb-0">
                            {': '} {method.formatRupiah(totalPayment)}
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                    <hr />
                    {isPayment === false
                      ? this.belumBayarHTML()
                      : this.sudahBayarHTML()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }

  belumBayarHTML = () => {
    const { deadline, bank, va_url_image } = this.state
    return (
      <div>
        <Container>
          <div className="text-center">
            <Label className="d-block text-16 font-weight-bold mb-0">
              Batas Waktu Pembayaran
            </Label>
            <Label className="d-block text-14 text-secondary mb-0">
              {' '}
              (Sebelum {deadline})
            </Label>
          </div>
          <hr />
          <div>
            <Row>
              <Col className="text-center">
                <Label className="d-block text-14 font-weight-bold">
                  Pembayaran Transfer Ke :{' '}
                </Label>
                <Row className="d-inline-flex">
                  <Col className="my-auto">
                    <img
                      alt={bank.bank_name}
                      src={`${config.URL_IMAGE}logo_bank/${bank.bank_logo}`}
                      style={{ width: '80px' }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
        {bank.bank_name == 'Dana' ? (
          <a
            href={va_url_image}
            class="btn btn-success btn-block text-center mt-3 font-weight-bold"
            target="blank"
          >
            Pay Now
          </a>
        ) : (
          <div className="text-center"> VIRTUAL ACCOUNT : {va_url_image}</div>
        )}
      </div>
    )
  }

  sudahBayarHTML = () => {
    return (
      <Container>
        <Link to="/DashboardClient">
          <Button className="btn-blue btn-block btn btn-secondary">
            Back to Dashboard
          </Button>
        </Link>
      </Container>
    )
  }
}

export default WaitingPaymentGateway
