import React, { Component }                                     from 'react';

// LandingPage
import Beranda                                                  from './LandingPage/HomeLanding.jsx';
import About                                                    from './LandingPage/About.jsx';
import Pricing                                                  from './LandingPage/Pricing.jsx';
import TermCon                                                  from './LandingPage/TermCon.jsx';
import PrivacyPolicy                                            from './LandingPage/PrivacyPolicy.jsx';
import HelpCompany                                              from './LandingPage/HelpForCompany.jsx';
import HelpUser                                                 from './LandingPage/HelpForUser.jsx';
import TermCondMobile                                           from './User/ComponentUser/Term&ConPlain.jsx';

// User
import FormSignupUser                                           from './User/FormSignupUser.jsx';
import FormLoginUser                                            from './User/FormLoginUser.jsx';
import DashboardUser                                            from './User/DashboardUser.jsx';
import HistoryUser                                              from './User/HistoryUser.jsx';
import ListJobUser                                              from './User/ComponentUser/MyListJobs.jsx';
import FulltimeJob                                              from './User/FulltimeList.jsx';
import ParttimeJob                                              from './User/ParttimeList.jsx';
import JobOffer                                                 from './User/Message.jsx';
import NotifUser                                                from './User/NotificationUser.jsx';
import ProfileUser                                              from './User/ProfileUser.jsx';
import EditProfileUser                                          from './User/EditProfileUser.jsx';
import Attendance                                               from './User/AttendanceUser.jsx';
import Withdraw                                                 from './User/Withdraw.jsx';
import PayReturn                                                from './User/PayReturn.jsx';

// Client
import Master                                                   from './Client/Master.jsx';
import ListAttendance                                           from './Client/ListAttendance.jsx';
import Leave                                                    from './Client/Leave.jsx';
import Overtime                                                 from './Client/Overtime.jsx';
import Izin                                                 from './Client/Izin.jsx';
import Employee                                                from './Client/Employee.jsx';
import FormSignupClient                                         from './Client/FormSignupClient.jsx';
import FormLoginClient                                          from './Client/FormLoginClient.jsx';
import DashboardClient                                          from './Client/DashboardClient.jsx';
import NotificationClient                                       from './Client/NotifClient.jsx';
import BuyPackaged                                              from './Client/BuyPackaged.jsx';
import PaymentMethod                                            from './Client/PaymentMethod.jsx';
import PaymentMethodPartTime                                    from './Client/PaymentMethodPartTime.jsx';
import WaitingPayment                                           from './Client/WaitingPayment.jsx';
import WaitingPaymentGateway                                    from './Client/WaitingPaymentGateway.jsx';
import WaitingPaymentGatewayPartTime                            from './Client/WaitingPaymentGatewayPartTime.jsx';
import PaymentHistory                                           from './Client/PaymentHistory.jsx';
import JobPostClient                                            from './Client/JobPostClient.jsx';
import CreateFullTime                                           from './Client/CreateFullTime.jsx';
import AddJobFullTime                                           from './Client/AddJobFullTime.jsx';
import CreatePartTime                                           from './Client/CreatePartTime.jsx';
import EditDraftFullTime                                        from './Client/EditDraftFullTime.jsx';
import EditDraftPartTime                                        from './Client/EditDraftPartTime.jsx';
import SimulationPartTime                                       from './Client/SimulationPartTime.jsx';
import DetailApprovalJobs                                       from './Client/DetailApprovalJobs.jsx';
import ProfileClient                                            from './Client/ProfileClient.jsx';
import ProfileCompany                                           from './Client/ProfileCompany.jsx';
import ChooseJob                                                from './Client/ChooseJob.jsx';
import FindTalent                                               from './Client/FindTalent.jsx';
import Absen                                                    from './Client/Absen.jsx';
import _FindTalent                                              from './Client/_FindTalent.jsx';
import DetailCandidatesFulltime                                 from './Client/DetailCandidatesFulltime.jsx';
import DetailCandidatesParttime                                 from './Client/DetailCandidatesParttime.jsx';
import ApprovalJobs                                             from './Client/ApprovalJobs.jsx';
import RecentTransaction                                        from './Client/RecentTransaction.jsx';
import AllRecentTransaction                                     from './Client/AllRecentTransaction.jsx';
import { BrowserRouter as Router, Route, Switch, useParams }    from 'react-router-dom';
class App extends Component {
    constructor(props){
        super(props);
        this.state ={isAuthenticated:false}
    }

    componentDidMount() {}

    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        {/* LandingPage */}
                        <Route path="/"                                     component={Beranda} exact />
                        <Route path="/AboutUs"                              component={About} />
                        <Route path="/Pricing"                              component={Pricing} />
                        <Route path="/TermCon"                              component={TermCon} />
                        <Route path="/PrivacyPolicy"                        component={PrivacyPolicy} />
                        <Route path="/HelpCompany"                          component={HelpCompany} />
                        <Route path="/HelpUser"                             component={HelpUser} />
                        <Route path="/term-cond-avisha"                     component={TermCondMobile} />

                        {/* User Route */}
                        <Route path="/Master"                               component={Master} />
                        <Route path="/overtime"                             component={Overtime} />
                        <Route path="/izin"                             component={Izin} />
                        <Route path="/employee"                             component={Employee} />
                        <Route path="/leave"                                component={Leave} />
                        <Route path="/list-attendance"                      component={ListAttendance} />
                        <Route path="/FormSignupUser"                       component={FormSignupUser} />
                        <Route path="/FormLoginUser"                        component={FormLoginUser} />
                        <Route path="/DashboardUser"                        component={DashboardUser} />
                        <Route path="/HistoryUser"                          component={HistoryUser} />
                        <Route path="/ListJobUser"                          component={ListJobUser} />
                        <Route path="/FulltimeJob"                          component={FulltimeJob} />
                        <Route path="/ParttimeJob"                          component={ParttimeJob} />
                        <Route path="/job-offer"                            component={JobOffer} />
                        <Route path="/notification-user"                    component={NotifUser} />
                        <Route path="/ProfileUser"                          component={ProfileUser} />
                        <Route path="/EditProfileUser"                      component={EditProfileUser} />
                        <Route path="/attendance-work"                      component={Attendance} />
                        <Route path="/withdraw"                             component={Withdraw} />
                        <Route path="/payreturn/:orderId"                   component={PayReturn} />
                        <Route path="/FormSignupClient"                     component={FormSignupClient} />
                        <Route path="/FormLoginClient"                      component={FormLoginClient} />
                        <Route path="/DashboardClient"                      component={DashboardClient} />
                        <Route path="/notification-client"                  component={NotificationClient} />
                        <Route path="/Buy"                                  component={BuyPackaged} />
                        <Route path="/PaymentMethod"                        component={PaymentMethod} />
                        <Route path="/PaymentMethodPartTime"                component={PaymentMethodPartTime} />
                        <Route path="/WaitingPayment"                       component={WaitingPayment} />
                        <Route path="/WaitingPaymentGateway"                component={WaitingPaymentGateway} />
                        <Route path="/WaitingPaymentGatewayPartTime"        component={WaitingPaymentGatewayPartTime} />
                        <Route path="/RecentTransaction"                    component={RecentTransaction} />
                        <Route path="/all-recent-transaction"               component={AllRecentTransaction} />
                        <Route path="/job"                                  component={JobPostClient} />
                        <Route path="/create-job-full-time"                 component={CreateFullTime} />
                        <Route path="/add-job-full-time"                    component={AddJobFullTime} />
                        <Route path="/create-job-part-time"                 component={CreatePartTime} />
                        <Route path="/edit-draft-full-time"                 component={EditDraftFullTime} />
                        <Route path="/edit-draft-part-time"                 component={EditDraftPartTime} />
                        <Route path="/edit-job-full-time"                   component={EditDraftFullTime} />
                        <Route path="/edit-job-part-time"                   component={EditDraftPartTime} />
                        <Route path="/simulation-credit-part-time"          component={SimulationPartTime} />
                        <Route path="/ProfileClient"                        component={ProfileClient} />
                        <Route path="/company-profile"                      component={ProfileCompany} />
                        <Route path="/attendance"                           component={Absen} />
                        <Route path="/find-talent"                          component={FindTalent} />
                        <Route path="/find-talent-new"                      component={_FindTalent} />
                        <Route path="/find-talent-candidate/choose-job"     component={ChooseJob} />
                        <Route path="/detail-candidates-fulltime"           component={DetailCandidatesFulltime} />
                        <Route path="/detail-candidates-parttime"           component={DetailCandidatesParttime} />
                        <Route path="/PaymentHistory"                       component={PaymentHistory} />
                        <Route path="/DetailApprovalJobs"                   component={DetailApprovalJobs} />
                        <Route path="/ApprovalJobs"                         component={ApprovalJobs} />
                    </Switch>
                </div>
            </Router>
        );
    }
};

export default App;
