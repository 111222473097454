import axios            from "axios";
import * as config      from "../Helpers/Config";

const client = axios.create({
    baseURL: config.API_URL,
    timeout: config.TIMEOUT,
});

const request = (options) => {
    client.interceptors.response.use(
        response    => response,
        error       => Promise.reject(error)
    );
    const onSuccess = response => response;
    const onError = error => Promise.reject(error.response || error.message);
    options = Object.assign(options, {headers: {Accept: "application/json"}});
    return client(options)
        .then(onSuccess)
        .catch(onError);
};

///////// General ////////////
export const getJobSpecializations = (options) => request({ method: "get", url: `job-specializations`, ...options });

export const getJobCategories = (options) => request({ method: "get", url: `job-categories`, ...options });

export const getJobCategoriesNew = (options) => request({ method: "get", url: `job-categories-new`, ...options });

export const getJobPosition = (options) => request({ method: "get", url: `job-position`, ...options });

export const getStudies = (options) => request({ method: "get", url: `studies`, ...options });

export const getProvincies = (options) => request({ method: "get", url: `provincies`, ...options });

export const getRegencies = (idProvincy, options) => request({ method: "get", url: `regencies/` + idProvincy, ...options });

export const getSubdistrict = (idRegency, options) => request({ method: "get", url: `subdistricts/` + idRegency, ...options });

export const getVillages = (idSubdistrict, options) => request({ method: "get", url: `villages/` + idSubdistrict, ...options });

///////// Client ////////////
export const getVariableFormula = (options) => request({ method: "get", url: `client-web/get-variable-formula`, ...options });

export const getIndustryTypes = (options) => request({ method: "get", url: `client-web/get-industry-types`, ...options });

export const getPackageClient = (options) => request({ method: "get", url: `client-web/get-package-client`, ...options });

export const getPackageClientPartTime = (options) => request({ method: "get", url: `client-web/get-package-client-part-time`, ...options });

export const getBankTypes = (options) => request({ method: "get", url: `client-web/get-bank-types`, ...options });

export const createTransaction = (options) => request({ method: "post", url: `client-web/create-transaction`, ...options });

export const createTransactionPartTime = (options) => request({ method: "post", url: `client-web/create-transaction-part-time`, ...options });

export const publishJob = (options) => request({ method: "post", url: `client-web/publish-job`, ...options });

export const deleteJob = (options) => request({ method: "post", url: `client-web/delete-job`, ...options });

export const cancelJob = (options) => request({ method: "post", url: `client-web/cancel-job`, ...options });

export const updateWorkTime = (options) => request({ method: "post", url: `client-web/edit-work-time-job`, ...options });

export const createJobFulltime = (options) => request({ method: "post", url: `client-web/create-job-full-time`, ...options });

export const createJobParttime = (options) => request({ method: "post", url: `client-web/create-job-part-time`, ...options });

export const getPaketPartTime = (options) => request({ method: "get", url: `client-web/getpaketparttime`, ...options });

export const editDraftJob = (options) => request({ method: "post", url: `client-web/edit-draft-job`, ...options });

export const registerClient = (options) => request({ method: "post", url: `client-web/register`, ...options });

export const loginClient = (options) => request({ method: "get", url: `client-web/login`, ...options });

export const checkEmailClient = (options) => request({ method: "get", url: `client-web/check-email`, ...options });

export const changePasswordClientBeforeLogin = (idClient, options) => request({ method: "post", url: `client-web/forget-password/` + idClient, ...options });

export const getDataClient = (id, options) => request({ method: "get", url: `client-web/get-data-client/` + id, ...options });

export const getDataCandidate = (id, options) => request({ method: "get", url: `client-web/get-data-candidate/` + id, ...options });

export const getPaymentHistory = (id, options) => request({ method: "get", url: `client-web/get-payment-history/` + id, ...options });

export const getSinglePayment = (id, options) => request({ method: "get", url: `client-web/get-single-payment?id=${id}`, ...options });

export const getNotification = (id, options) => request({ method: "get", url: `client-web/get-notification/` + id, ...options });

export const getAllPackageClient = (id, options) => request({ method: "get", url: `client-web/get-all-package-client/` + id, ...options });

export const getAllPaymentHistory = (id, options) => request({ method: "get", url: `client-web/get-all-payment-history/` + id, ...options });

export const getAllRejectedPaymentHistory = (id, options) => request({ method: "get", url: `client-web/get-all-rejected-payment-history?id_client=${id}`, ...options });

export const updateTransaction = (options, id) => request({ method: "post", url: `client-web/update-transaction/` + id, ...options });

export const getJobs = (id, options) => request({ method: "get", url: `client-web/get-jobs/` + id, ...options });

export const getDataJob = (id, options) => request({ method: "get", url: `client-web/get-data-job/` + id, ...options });

export const getCandidates = (id, options) => request({ method: "get", url: `client-web/get-candidates/` + id, ...options });

export const getDataEmployee = (options) => request({ method: "get", url: `client-web/get-data-employee`, ...options });

export const getTalentEmployee = (options, id) => request({ method: "get", url: `client-web/get-talent-employee/` + id, ...options });

export const getTalentEmployeeNew = (options) => request({ method: "get", url: `client-web/get-talent-employee-new`, ...options });

export const getDetailDraftJob = (id, options) => request({ method: "get", url: `client-web/get-detail-draft-job/` + id, ...options });

export const approveCandidate = (id, options) => request({ method: "post", url: `client-web/approve-candidates/` + id, ...options });

export const finishInterview = (id, options) => request({ method: "post", url: `client-web/finish-interview/` + id, ...options }); // approve after interview

export const finishPartTime = (options) => request({ method: "post", url: `client-web/finish-part-time`, ...options }); // finish part time

export const declineJobApplication = (id, options) => request({ method: "post", url: `client-web/decline-job-application/` + id, ...options }); // decline job from submit application employee Full Time anda part time

export const shortListEmployee = (id, options) => request({ method: "post", url: `client-web/short-list/` + id, ...options }); // decline job from submit application employee Full Time anda part time

export const approvePartTime = (id, options) => request({ method: "post", url: `client-web/approve-part-time/` + id, ...options }); // approve parttime

export const saveRejectAfterInterview = (id, options) => request({ method: "post", url: `client-web/save-reject-after-interview/` + id, ...options });

export const getDraftJob = (id, options) => request({ method: "get", url: `client-web/get-draft-jobs/` + id, ...options });

export const getJobExpired = (id, options) => request({ method: "get", url: `client-web/get-expired-jobs/` + id, ...options });

export const getJobByWorkType = (id, options) => request({ method: "get", url: `client-web/get-jobs-by-work-type/` + id, ...options });

export const getJobByIdEmployee = (id, options) => request({ method: "get", url: `client-web/get-jobs-by-id-employee/` + id, ...options });

export const updateProfileAccount = (options) => request({ method: "post", url: `client-web/update-profile-account`, ...options });

export const updateCompanyProfile = (options) => request({ method: "post", url: `client-web/update-company-account`, ...options });

export const getResume = (id, options) => request({ method: "post", url: `client-web/get-resume/` + id, ...options });

export const inviteCandidateToFullTime = (id, options) => request({ method: "post", url: `client-web/invite-candidate-to-full-time/` + id, ...options });

export const inviteCandidateToPartTime = (id, options) => request({ method: "post", url: `client-web/invite-candidate-to-part-time/` + id, ...options });

///////Employee///////////////////////
export const allEmployees = (options) => request({ method: "get", url: `employee-web/get-all-employees`, ...options });

export const registerEmployee = (options) => request({ method: "post", url: `employee-web/register`, ...options });

export const changePasswordBeforeLogin = (idEmployee, options) => request({ method: "post", url: `employee-web/forget-password/` + idEmployee, ...options });

export const loginEmployee = (options) => request({ method: "get", url: `employee-web/login`, ...options });

export const checkEmail = (options) => request({ method: "get", url: `employee-web/check-email`, ...options });

export const loginGoogle = (options) => request({ method: "get", url: `employee-web/login-with-google`, ...options });

export const profileEmployee = (idEmployee, options) => request({ method: "get", url: `employee-web/get-data-profile/` + idEmployee, ...options });

export const allJobs = (idEmployee, options) => request({ method: "get", url: `employee-web/get-all-jobs/` + idEmployee, ...options });

export const detailJob = (idJob, options) => request({ method: "get", url: `employee-web/get-detail-job/` + idJob, ...options });

export const allJobOffering = (idEmployee, options) => request({ method: "get", url: `employee-web/list-job-offering/` + idEmployee, ...options });

export const allEmployeeInbox = (idEmployee, options) => request({ method: "get", url: `employee-web/list-employee-inbox/` + idEmployee, ...options });

export const updateProfileEmployee = (idEmployee, options) => request({ method: "post", url: `employee-web/edit-profile-employee` + idEmployee, ...options });

export const updateResponseJobOffering = (idCandidate, options) => request({ method: "post", url: `employee-web/response-job-offering/` + idCandidate, ...options });

export const getAllMyJob = (idEmployee, options) => request({ method: "get", url: `employee-web/get-all-my-jobs/` + idEmployee, ...options });

export const responseFullTimeMyJob = (idCandidate, options) => request({ method: "post", url: `employee-web/response-full-time-my-job/` + idCandidate, ...options });

export const absencePartTimeMyJob = (idEmployee, options) => request({ method: "post", url: `employee-web/create-part-time-absence/` + idEmployee, ...options });

// export const finishAbsencePartTimeMyJob = (idAssignment, options) =>
//     request({ method: 'post', url: `employee-web/finish-part-time-absence/` + idAssignment, ...options });

export const applyJob = (idEmployee, options) => request({ method: "post", url: `employee-web/apply-job/` + idEmployee, ...options });

export const applyPartTime = (idEmployee, options) => request({ method: "get", url: 'applyjobparttime', ...options });

export const getAssignmentPartTimeHistory = (idEmployee, options) => request({ method: "get", url: `employee-web/get-assignment-part-time-history/` + idEmployee, ...options });

export const getPartTimeFeeTransactions = (idEmployee, options) => request({ method: "get", url: `employee-web/get-part-time-fee-transactions/` + idEmployee, ...options });

export const getTrackIncomeTransaction = (idEmployee, options) => request({ method: "get", url: `employee-web/get-track-income-part-time-fee/` + idEmployee, ...options });

export const savePersonalInformationsEmployee = (idEmployee, options) => request({ method: "post", url: `employee-web/save-personal-information-employee/` + idEmployee, ...options });

export const saveNewSkill = (idEmployee, options) => request({ method: "post", url: `employee-web/save-new-skill/` + idEmployee, ...options });

export const saveNewExperience = (idEmployee, options) => request({ method: "post", url: `employee-web/save-new-experience/` + idEmployee, ...options });

export const savePhotoProfile = (options, idEmployee) => request({ method: "post", url: `employee-web/save-photo-profile/` + idEmployee, ...options });

export const saveEmergencyContact = (idEmployee, options) => request({ method: "post", url: `employee-web/save-emergency-contact/` + idEmployee, ...options });

export const saveEducation = (idEmployee, options) => request({ method: "post", url: `employee-web/save-education/` + idEmployee, ...options });

export const deleteSkill = (idSkill, options) => request({ method: "post", url: `employee-web/delete-skill/` + idSkill, ...options });

export const deleteExperience = (idExperience, options) => request({ method: "post", url: `employee-web/delete-experience/` + idExperience, ...options });

export const getDataBanner = (options) => request({ method: "get", url: `employee-web/get-data-banner`, ...options });

export const getAllRegencies = (options) => request({ method: "get", url: `get-all-regencies`, ...options });

export const getAllRegenciesNew = (options) => request({ method: "get", url: `get-all-regencies-new`, ...options });

export const getWithdrawHistories = (idEmployee, options) => request({ method: "get", url: `/withdraw/histories?id_employee=${idEmployee}`, ...options });

export const getWithdrawDetails = (idWithdraw, options) => request({ method: "get", url: `/withdraw/details?withdraw_id=${idWithdraw}`, ...options });

export const getTransactions = (idEmployee, options) => request({ method: "get", url: `/withdraw/transactions?id_employee=${idEmployee}`, ...options });

export const withdraw = (options) => request({ method: "get", url: `/withdraw`, ...options });

export const newPaymentGateway = (options) => request({ method: "get", url: `/paymentgateway`, ...options });

export const getSingleBank = (bank_name, options) => request({ method: "get", url: `/bank?bank_name=${bank_name}`, ...options });

export const getPaymentGatewayHistory = (idTransaction, options) => request({ method: "get", url: `/paymentgatewayhistory?id_transaction=${idTransaction}`, ...options });

export const newPaymentGatewayPartTime = (options) => request({ method: "get", url: `/paymentgatewayparttime`, ...options });

export const getDetailTransaksi = (orderId, options) => request({ method: "get", url: `/payment/detail?order_id=${orderId}`, ...options });

export const getProfilEmployee = (idEmployee, options) => request({ method: "get", url: `/profil-employee?id=${idEmployee}`, ...options });

export const simpanBank = (options) => request({ method: "get", url: `/simpan-bank?`, ...options });

//EMPLOYEE FILE
export const deleteFiles = (idFile, options) => request({ method: "post", url: `employee-web/delete-files/` + idFile, ...options });

export const saveNewFileEmployee = (idEmployee, options) => request({ method: "post", url: `employee-web/save-new-files/` + idEmployee, ...options });

export const getFileEmployee = (idEmployee, options) => request({ method: "get", url: `employee-web/get-files/` + idEmployee, ...options });

export const getFileTypes = (options) => request({ method: "get", url: `employee-web/get-file-type`, ...options });

export const getAbsenTypes = (id_client, options) => request({ method: "get", url: `/absentypes?id_client=${id_client}`, ...options });
export const getSingleType = options => request({ method: "get", url: `/absentype`, ...options });
export const addAbsenType = options => request({ method: "get", url: `/addabsentype`, ...options });
export const editAbsenType = options => request({ method: "get", url: `/editabsentype`, ...options });
export const deleteAbsenType = options => request({ method: "get", url: `/deleteabsentype`, ...options });

export const getAbsenLocations = (id_client, options) => request({ method: "get", url: `/absenlocations?id_client=${id_client}`, ...options });
export const getSingleLocation = options => request({ method: "get", url: `/absenlocation`, ...options });
export const addAbsenLocation = options => request({ method: "get", url: `/addabsenlocation`, ...options });
export const editAbsenLocation = options => request({ method: "get", url: `/editabsenlocation`, ...options });
export const deleteAbsenLocation = options => request({ method: "get", url: `/deleteabsenlocation`, ...options });

export const getShift = options => request({ method: "get", url: `/shift`, ...options });
export const getArchivedEmployee = options => request({ method: 'get', url: `/archived-employee`, ...options });
export const publishJobPartTime = options => request({ method: 'get', url: `/publish-job-part-time`, ...options });
export const approveJobPartTime = options => request({ method: 'get', url: `/approve-job-part-time`, ...options });

export const getAllPositions = (idKlien, options) => request({
    method: 'get',
    url: `/master/positions?id_client=${idKlien}`,
    ...options
});

export const addPosition = options => request({
    method: 'get',
    url: `master/positions/add`,
    ...options
});

export const editPosition = options => request({
    method: 'get',
    url: `master/positions/edit`,
    ...options
});

export const deletePosition = options => request({
    method: 'get',
    url: `master/positions/delete`,
    ...options
});

export const getAllLeaves = (idKlien, options) => request({
    method: 'get',
    url: `/master/leaves?id_client=${idKlien}`,
    ...options
});

export const addLeave = options => request({
    method: 'get',
    url: `master/leaves/add`,
    ...options
});

export const editLeave = options => request({
    method: 'get',
    url: `master/leaves/edit`,
    ...options
});

export const deleteLeave = options => request({
    method: 'get',
    url: `master/leaves/delete`,
    ...options
});

export const getAllEmployee = options => request({
    method: 'get',
    url: `employees`,
    ...options
});

export const addFulltimeJob = options => request({
    method: 'get',
    url: `add-full-time-job`,
    ...options
});

export const getAllEmployees = options => request({
    method: 'get',
    url: 'all-employees',
    ...options
});

export const getAllAbsen = options => request({
    method: 'get',
    url: 'all-absens',
    ...options
});

export const getAllLembur = options => request({
    method: 'get',
    url: 'all-lemburs',
    ...options
});


export const getAllIzin = options => request({
    method: 'get',
    url: 'all-izins',
    ...options
});

export const getAllEmployeeClient = options => request({
    method: 'get',
    url: 'all-employees-client',
    ...options
});
export const addEmployeeClient = options => request({
    method: 'get',
    url: 'add-employees-client',
    ...options
});


export const updateLembur = options => request({
    method: 'get',
    url: 'update-lembur',
    ...options
});

export const getAllCuti = options => request({
    method: 'get',
    url: 'all-cutis',
    ...options
});

export const updateCuti = options => request({
    method: 'get',
    url: 'update-cuti',
    ...options
});