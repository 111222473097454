// Import Lib, Component React & Icon  
import React, { Fragment }                                  from 'react';  
// import { Link }                                             from 'react-router-dom';

// Styling
import { Container, Row, Col, Label }                       from 'reactstrap';   
import * as config                                          from '../../Helpers/Config';
import * as api                                             from '../../Helpers/Api';
import * as method                                          from '../../Helpers/Method';
import * as authHelperMethods                               from '../../Helpers/AuthHelperMethods';
import noData                                               from '../../Asset/Icon/no-data.svg';

class HistoryJobUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listAssignmentPartTime:[],
        };
    }

    componentDidMount = async () =>{
        await this.getAllAssignmentPartTime();
    }

    getAllAssignmentPartTime = async (e) => {
        const newData = {
            key_api: config.KEY_API,
          };
        const idEmployee = authHelperMethods.getIdEmployee();
          try{
            const res = await api.getAssignmentPartTimeHistory(idEmployee,{
                params: {data: JSON.stringify(newData)}
              })
            const {status, data} = res;
            if(status === 200){
              this.setState({listAssignmentPartTime:data.dataAssignmentHistory})
            }
          } catch(err){
            //   console.log("Error Response :",err)
          }
    }

    render() {  
        const {listAssignmentPartTime}= this.state                                   
        return (          
            <Fragment>  
                {
                    listAssignmentPartTime.length === 0 ?
                        <center><img src={noData} width="240px"/></center>
                    : 
                    listAssignmentPartTime.map((assignment)=>{
                        return(
                            <Col className="p-2 section-message border-bottom">
                                <Row>
                                    <Col lg="4">
                                        <p className="mb-0 font-weight-bolder text-12 my-auto">
                                        {assignment.job_sub_category} in {assignment.job_category}
                                        </p>
                                        <p className="mb-0 font-weight-light text-11 text-justify my-auto">
                                        {assignment.company_name}
                                        </p><p className="mb-0 font-weight-light text-11 text-justify my-auto">
                                        {assignment.work_type}
                                        </p>
                                        <p className="font-weight-light text-10 text-justify my-auto">
                                        {assignment.job_location}
                                        </p>
                                    </Col>
                                    <Col lg="3" className="my-auto"><p className="my-auto">{assignment.part_time_salary==null ? "Rp0":method.formatRupiah(assignment.part_time_salary)}</p></Col>
                                    <Col lg="3" className="my-auto"><p className="text-post-time my-auto">{method.formatDateAndTime(assignment.start_part_time_date) +" s/d " + method.formatDateTime(assignment.finish_part_time_date)}</p></Col>
                                    <Col lg="2" className="my-auto"><p className="my-auto text-center">{assignment.work_status}</p></Col>
                                </Row>
                            </Col>
                        );
                    })
                }
            </Fragment>
        );   
    }
};

export default HistoryJobUser;